import React from "react"
import styled from "@emotion/styled"
import { reduxForm, Field, InjectedFormProps } from "redux-form"
import { fieldsRequired } from "../contexts/shared/validation"
import SubmitButton from "./SubmitButton"
import { HeadingTwo } from "./TextStyles"
import Loading from "./Loading"
import TextFieldForReduxForm from "./MuiExtensions/components/TextFieldForReduxForm"

const CenteredColumn = styled.div({
  display: "flex",
  flexDirection: "column",
  alignItems: "center"
})

const FormActions = styled.div({
  marginTop: 50
})

function validate(values) {
  const errors = {}
  fieldsRequired(values, errors, "email")
  return errors
}

type OnSubmitFunc = (data?) => any

interface IProps {
  syncing?: unknown
  onSubmit: OnSubmitFunc
}

const ForgotPasswordForm = ({
  syncing,
  onSubmit,
  handleSubmit
}: IProps & InjectedFormProps<{}, IProps>) => {
  const isSubmitting = syncing === "sending"

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <CenteredColumn>
        <HeadingTwo>Forgotten your password?</HeadingTwo>
        <p>
          Enter your email address below and we&apos;ll send you a link to
          change it.
        </p>
        <Field
          name="email"
          component={TextFieldForReduxForm}
          label="your email address"
          style={{ width: "250px" }}
          type="email"
        />
        <FormActions>
          {isSubmitting ? (
            <Loading />
          ) : (
            <SubmitButton>Email me a link</SubmitButton>
          )}
        </FormActions>
      </CenteredColumn>
    </form>
  )
}

export default reduxForm<{}, IProps>({
  form: "forgotPassword",
  validate
})(ForgotPasswordForm)
