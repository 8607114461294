import React from "react"
import { TextField } from "@material-ui/core"
import Link from "redux-first-router-link"
import {
  AuthForm,
  Header,
  Inputs,
  AlternativeActions,
  PrimaryActions
} from "../AuthFormStyles"
import FlashMessage from "../FlashMessage"
import SubmitButton from "../SubmitButton"
import AuthErrorMessages from "../AuthErrorMessages"
import type { Errors } from "../../state_types"

type Props = {
  clientOutdated: boolean
  submit: () => void
  onChangeEmailOrUsername: (value: string) => void
  onChangePassword: (value: string) => void
  errors?: Errors
  syncing?: boolean
  emailOrUsername?: string
  password?: string
}

const UserPassword = ({
  submit,
  onChangeEmailOrUsername,
  onChangePassword,
  errors,
  syncing,
  emailOrUsername,
  password,
  clientOutdated
}: Props) => {
  const handleSubmitForm = (event: React.SyntheticEvent<HTMLFormElement>) => {
    event.preventDefault()
    submit()
  }

  const handleChangeEmailOrUsername = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    onChangeEmailOrUsername(event.target.value)
  }

  const handleChangePassword = (event: React.ChangeEvent<HTMLInputElement>) => {
    onChangePassword(event.target.value)
  }

  return (
    <>
      <Header>Ripple Login</Header>
      {clientOutdated && (
        <FlashMessage
          height={70}
          flash={{
            isError: true,
            message: "Server has been updated. Please re-sign in."
          }}
        />
      )}
      <AuthErrorMessages errors={errors} />

      <AuthForm onSubmit={handleSubmitForm}>
        <Inputs>
          <TextField
            id="emailOrUsername"
            label="Email or username"
            onChange={handleChangeEmailOrUsername}
            value={emailOrUsername}
          />
          <TextField
            id="password"
            type="password"
            label="Password"
            onChange={handleChangePassword}
            value={password}
          />
        </Inputs>

        <PrimaryActions>
          <SubmitButton disabled={syncing}>Login</SubmitButton>
        </PrimaryActions>
        <AlternativeActions>
          <Link to={{ type: "FORGOT_PASSWORD" }}>Forgot your password?</Link>
        </AlternativeActions>
      </AuthForm>
    </>
  )
}

UserPassword.defaultProps = {
  syncing: false,
  password: "",
  emailOrUsername: ""
}

export default UserPassword
