import React from "react"
import Link from "redux-first-router-link"
import QRCode from "qrcode.react"
import styled from "@emotion/styled"
import { largePageWidth } from "../Layout"
import api from "../../api"
import AvatarPreview from "../AvatarPreview"
import colours from "../../themes/colours"

const Container = styled.div({
  width: "100%",
  maxWidth: "1200px",
  margin: "0 auto"
})

const Contents = styled.div({
  maxWidth: largePageWidth,
  margin: "0 10px"
})

const spacingRows = "0.5cm"

const QrCodesContainer = styled.div({
  width: "100%"
})

const QrRow = styled.div({
  pageBreakInside: "avoid",
  pageBreakAfter: "auto",
  display: "flex",
  justifyContent: "space-between",
  flexWrap: "wrap",
  columnGap: spacingRows
})

const QrDetails = styled.div({
  minWidth: "330px",
  flexBasis: "46%",
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  paddingTop: spacingRows,
  marginBottom: spacingRows,
  borderTop: "1px solid black"
})

const Cell = styled.div({
  flex: "1"
})

const Details = styled(Cell)({
  display: "flex",
  alignItems: "center"
})

const ClientDetails = styled.div({
  fontSize: "10pt"
  // textAlign: "center",
})

const Assessor = styled(Cell)({
  fontSize: "90%"
})

const Verb = styled(Cell)({
  color: colours.deemphasisedText,
  fontStyle: "italic",
  fontSize: "90%"
})

const ClientName = styled.div({
  fontWeight: 700
})

const ClientAvatar = styled.div({
  width: "50px",
  padding: "0 5px"
})

function createGroups(arr, numGroups) {
  const perGroup = Math.ceil(arr.length / numGroups)

  return new Array(numGroups)
    .fill("")
    .map((_, i) => arr.slice(i * perGroup, (i + 1) * perGroup))
}

const assessorAndClientSame = ({ assessor, ripple }) =>
  assessor.name === ripple.clentName ||
  assessor.name.includes(ripple.clentName) ||
  ripple.clientName.includes(assessor.name)

const renderClientDetails = ({ assessor, ripple }) => {
  const assessingSelf = assessorAndClientSame({ assessor, ripple })

  if (assessingSelf) {
    return <ClientName>{ripple.clientName}</ClientName>
  }

  return [
    <ClientName>{ripple.clientName}</ClientName>,
    <Verb>assessed by</Verb>,
    <Assessor>{assessor.name}</Assessor>
  ]
}

const renderQrCodeDetails = (assessor, ripples) => {
  const ripple = ripples.find((r) => r.id === assessor.rippleId)
  const url = api.qrCodes.url(assessor.token)

  return (
    <QrDetails key={assessor.id}>
      <Details>
        <ClientAvatar>
          <AvatarPreview
            value={ripple.clientAvatar}
            name={ripple.clientName}
            size={50}
          />
        </ClientAvatar>
        <ClientDetails>
          {renderClientDetails({ assessor, ripple })}
        </ClientDetails>
      </Details>
      <Link to={{ type: "ASSESS", payload: { token: assessor.token } }}>
        <QRCode value={url} size={100} />
      </Link>
    </QrDetails>
  )
}

const renderQrRow = (pair, ripples) => {
  const assessor1 = pair[0]
  const assessor2 = pair[1]

  if (assessor2) {
    return (
      <QrRow key={`${assessor1.id}-page-break`}>
        {renderQrCodeDetails(assessor1, ripples)}
        {renderQrCodeDetails(assessor2, ripples)}
      </QrRow>
    )
  }

  return (
    <QrRow key={`${assessor1.id}-page-break`}>
      {renderQrCodeDetails(assessor1, ripples)}
    </QrRow>
  )
}

const QrCodes = (props: { ripples: Array<any>; assessors: Array<any> }) => {
  const { assessors, ripples } = props

  const assessorPairs = createGroups(assessors, Math.ceil(assessors.length / 2))

  return (
    <Container>
      <Contents>
        <h1>QR codes</h1>
        <QrCodesContainer>
          {assessorPairs.map((pair) => renderQrRow(pair, ripples))}
        </QrCodesContainer>
      </Contents>
    </Container>
  )
}

export default QrCodes
