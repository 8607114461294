import React from "react"
import map from "lodash.map"
import styled from "@emotion/styled"
import capitalize from "lodash.capitalize"
import NumericStatistic from "./NumericStatistic"
import type { OrgStats } from "../state_types"

type Props = {
  headingMode?: boolean
  stats: OrgStats
  singleLine?: boolean
}

const StatisticGroup = styled.div({
  display: "flex",
  flexDirection: "row"
})

const StatisticWrapper = styled.div({
  marginRight: 10,
  ":last-child": {
    marginRight: 0
  }
})

const StatsGroup = ({ stats, singleLine, headingMode }: Props) => (
  <StatisticGroup>
    {map(stats, (value, key) => (
      <StatisticWrapper key={key}>
        <NumericStatistic
          value={value}
          noun={capitalize(key)}
          singleLine={singleLine}
          headingMode={headingMode}
        />
      </StatisticWrapper>
    ))}
  </StatisticGroup>
)

StatsGroup.defaultProps = {
  singleLine: false,
  headingMode: false
}

export default StatsGroup
