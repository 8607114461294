import React from "react"
import styled from "@emotion/styled"
import { connect } from "react-redux"
import { TallButton } from "./TallButton"
import { submitAddUserForm } from "../api_actions"
import colours from "../themes/colours"
import AddUserForm from "./AddUserForm"
import type { DispatchFunction } from "../state_types"
import { gutter } from "./Layout"

const transitionTime = "0.7s"

const Container = styled.div<{ expanded: boolean }>(
  {
    borderBottom: `1px solid ${colours.whiteSilverBorder}`,
    paddingBottom: gutter,
    transition: `height ${transitionTime} ease`,
    height: "4em",
    overflow: "hidden",
    position: "relative",
    flexShrink: 0
  },
  ({ expanded }) => {
    if (expanded) {
      return {
        height: "9.5em",
        "@media (max-width: 650px)": {
          height: "12em"
        },
        "@media (max-width: 480px)": {
          height: "16em"
        }
      }
    }

    return {}
  }
)

const TransitionItem = styled.div<{ visible; position }>((props) => {
  const hiddenTop = props.position === "top" ? "8em" : "-8em"

  return {
    opacity: props.visible ? 1.0 : 0.0,
    transition: `top ${transitionTime} ease, opacity ${transitionTime} ease`,
    zIndex: props.visible ? 2 : 1,
    position: "absolute",
    top: props.visible ? 0 : hiddenTop,
    left: gutter,
    right: gutter,
    height: "100%"
  }
})

const ButtonContainer = styled.div({
  display: "flex",
  justifyContent: "flex-end",
  paddingTop: gutter
})

type State = {
  showUserForm: boolean
}

type Props = {
  submitAddUserForm?: () => any
  onSubmit: DispatchFunction
}

class AddUser extends React.Component<Props, State> {
  static defaultProps = {}

  state = { showUserForm: false }

  handleClick = () => {
    this.setState({ showUserForm: true })
  }

  handleCancel = () => {
    this.setState({ showUserForm: false })
  }

  handleSubmit = () => {
    this.props.onSubmit()
    this.setState({ showUserForm: false })
  }

  render() {
    return (
      <Container expanded={this.state.showUserForm}>
        <TransitionItem visible={this.state.showUserForm} position="top">
          <AddUserForm
            handleCancel={this.handleCancel}
            onSubmit={this.handleSubmit}
          />
        </TransitionItem>
        <TransitionItem visible={!this.state.showUserForm} position="bottom">
          <ButtonContainer>
            <TallButton
              label="Add User"
              buttonType="submit"
              onClick={this.handleClick}
            />
          </ButtonContainer>
        </TransitionItem>
      </Container>
    )
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    onSubmit: () => {
      dispatch(submitAddUserForm())
    }
  }
}

export default connect(null, mapDispatchToProps)(AddUser)
