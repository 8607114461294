import React from "react"
import styled from "@emotion/styled"
import PedsQLLogo from "../../images/pedsql/pedsql_logo.svg"

const imageSources = {
  pedsql_logo: PedsQLLogo
}

const ImgWrapper = styled.img<Props>`
  width: ${(props) => props.width};
  height: ${(props) => props.height};
`

type Props = {
  src: string
  height?: string
  width?: string
}

const GenericImage = ({ height, width, src }: Props) => {
  const imgSource = imageSources[src]
  if (!imgSource) {
    return null
  }
  return <ImgWrapper src={imgSource} height={height} width={width} />
}

export default GenericImage
