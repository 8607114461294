import React from "react"
import { AnyAction } from "redux"
import { connect } from "react-redux"
import { NavLink } from "redux-first-router-link"
import type { ThunkDispatch } from "redux-thunk"
import FontAwesome from "react-fontawesome"
import styled from "@emotion/styled"
import find from "lodash.find"
import get from "lodash.get"
import { gutter } from "../Layout"
import RippleSlidableReport from "./RippleSlidableReport"
import { RippleDetailData } from "../../state_types"
import AssessmentsReport from "../../contexts/assessment/AssessmentsReport"
import colours from "../../themes/colours"
import UserStateDecorator from "../../contexts/user/UserStateDecorator"
import RippleStateDecorator from "../../contexts/ripple_details/StateDecorator"
import BubbleDialogContainer from "../BubbleDialogContainer"
import type { DialogFunctions } from "../BubbleDialogContainer"
import { archiveRipple } from "../../api_actions"
import ContainedButton from "../ContainedButton"
import FlatButton from "../FlatButton"

type RippleDataReportProps = {
  data: RippleDetailData
  assessmentNumber: number
  sectionId: string
  questionId: string
  returnTo?: {
    url: string
    name: string
  }
  userState?: UserStateDecorator
  rippleState?: RippleStateDecorator
  dispatchArchiveRipple?: () => {}
  showHeatmap?
}

const baseStyles = {
  cursor: "pointer",
  color: colours.deemphasisedText,
  textDecoration: "none",
  fontSize: "14px",
  ":hover": {
    color: colours.blue
  }
}

const activeStyles = {
  color: "inherit",
  borderBottom: `3px solid ${colours.blue}`
}

const RippleDetailsContainer = styled.div({
  display: "flex",
  flex: 1,
  flexDirection: "column",
  backgroundColor: colours.silver,
  height: "100%"
})

const ReportsSection = styled.div({
  display: "flex",
  flex: 1,
  padding: `0 ${gutter}`
})

const TabsAndActions = styled.div({
  display: "flex",
  justifyContent: "space-between",
  padding: "10px 1.3em"
})

const Tabs = styled.div({
  display: "flex"
})

const Tab = styled.div({
  paddingRight: "20px"
})

const Actions = styled.div({})

const Action = styled.a(baseStyles)

const DialogTitle = styled.h3({})

const DialogMessage = styled.div({
  fontSize: "14px",
  marginBottom: "10px"
})

const DialogButtons = styled.div({
  display: "flex",
  justifyContent: "space-around",
  padding: "10px"
})

function archiveDialog(dialog, title, target, dispatchArchiveRipple) {
  return (
    <div>
      <DialogTitle>Archive Ripple?</DialogTitle>
      <DialogMessage>
        This will remove access to the Ripple. This process can only be reversed
        by an Organisational Admin.
      </DialogMessage>
      <DialogButtons>
        <ContainedButton
          style={{
            fontSize: "14px"
          }}
          label="Confirm"
          onClick={() => {
            dispatchArchiveRipple()
          }}
        />
        <FlatButton
          label="Cancel"
          onClick={() =>
            dialog.toggle(
              title,
              target,
              archiveDialog(dialog, title, target, dispatchArchiveRipple)
            )
          }
        />
      </DialogButtons>
    </div>
  )
}

function archiveRippleDialog(
  dialog: DialogFunctions,
  title,
  dispatchArchiveRipple
) {
  return (event) => {
    if (event) {
      const target: HTMLElement = event.currentTarget
      dialog.toggle(
        title,
        target,
        archiveDialog(dialog, title, target, dispatchArchiveRipple)
      )
    }
  }
}

const RippleDataReport = ({
  data,
  assessmentNumber,
  sectionId,
  questionId,
  showHeatmap,
  returnTo,
  userState,
  rippleState,
  dispatchArchiveRipple
}: RippleDataReportProps) => {
  const { ripple, users } = data
  if (!ripple) return null

  const teamAssessments = ripple && ripple.teamAssessments
  const report = new AssessmentsReport(teamAssessments)
  const assessment = find(teamAssessments, ["number", assessmentNumber])
  const section = assessmentNumber
    ? find(assessment.attributes, ["id", sectionId])
    : null

  const question = section ? find(section.attributes, ["id", questionId]) : null
  const reportProps = {
    ripple,
    users,
    assessmentNumber,
    assessment,
    sectionId,
    section,
    questionId,
    question,
    report,
    showHeatmap,
    returnTo
  }
  const outOf = get(ripple, "frameworkInfo.outOf") || 100

  // if (!assessment) return <div>Cannot load assessment.</div>

  const { frameworkInfo } = ripple

  return (
    <RippleDetailsContainer>
      <BubbleDialogContainer maxWidth={400} outsideClickCloses>
        {(dialogCallbacks) => (
          <TabsAndActions>
            <Tabs>
              <Tab>
                <NavLink
                  activeStyle={activeStyles}
                  style={baseStyles}
                  to={{
                    type: "RIPPLE",
                    payload: { rippleUuid: ripple.uuid }
                  }}
                  exact
                >
                  Results
                </NavLink>
              </Tab>
              {!!frameworkInfo && !frameworkInfo.sdq && (
                <Tab>
                  <NavLink
                    activeStyle={activeStyles}
                    style={baseStyles}
                    to={{
                      type: "RIPPLE_HEATMAP",
                      payload: { rippleUuid: ripple.uuid }
                    }}
                  >
                    Heatmap
                  </NavLink>
                </Tab>
              )}
            </Tabs>
            <Actions>
              {!!userState.canArchiveRipple(rippleState) && (
                <Action
                  href="#"
                  onClick={archiveRippleDialog(
                    dialogCallbacks,
                    "Archive",
                    dispatchArchiveRipple
                  )}
                >
                  <FontAwesome
                    name="archive"
                    style={{ paddingRight: "10px" }}
                  />
                  Archive framework
                </Action>
              )}
            </Actions>
          </TabsAndActions>
        )}
      </BubbleDialogContainer>
      <ReportsSection id="reportSection">
        <RippleSlidableReport {...reportProps} outOf={outOf} />
      </ReportsSection>
    </RippleDetailsContainer>
  )
}

const mapStateToProps = (state) => {
  const ripple = get(state, "rippleDetails.data.ripple") || {}
  const userState = new UserStateDecorator(state)
  const rippleState = new RippleStateDecorator(ripple)

  return {
    ripple,
    userState,
    rippleState
  }
}

const mapDispatchToProps = (
  dispatch: ThunkDispatch<any, any, AnyAction>,
  ownProps: RippleDataReportProps
) => ({
  dispatchArchiveRipple: () => {
    dispatch(archiveRipple(ownProps.data.ripple.uuid))
  }
})

export default connect(mapStateToProps, mapDispatchToProps)(RippleDataReport)
