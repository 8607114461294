import React from "react"
import { connect } from "react-redux"
import styled from "@emotion/styled"
import { InjectedFormProps, reduxForm } from "redux-form"
import get from "lodash.get"
import filter from "lodash.filter"
import includes from "lodash.includes"
import UploadFile from "./UploadFile"
import { stepValidator } from "../../contexts/import_ripples_form/wizard"
import { todayIsoDate } from "../../components/helpers/date"

const ImportRipplesDetailsContainer = styled.div({
  display: "flex",
  flexDirection: "column",
  flexGrow: 1
})

const handleSubmit = () => {}
interface IProps extends InjectedFormProps {
  orgs?
}

const NewImportRipplesDetails = ({ orgs }: IProps) => (
  <ImportRipplesDetailsContainer>
    <form onSubmit={handleSubmit} id="ripple-details-form">
      <UploadFile orgs={orgs} />
    </form>
  </ImportRipplesDetailsContainer>
)

const NewImportRipplesDetailsForm = reduxForm({
  form: "importRipples",
  destroyOnUnmount: false,
  forceUnregisterOnUnmount: true,
  validate: stepValidator("upload-file")
})(NewImportRipplesDetails)

const mapStateToProps = (state) => {
  let orgs = get(state, "me.data.user.orgs") || []
  orgs = filter(
    orgs,
    (org) =>
      includes(org.roles, "ripple_creator") || includes(org.roles, "admin")
  )

  const testing = process.env.RAILS_ENV === "test"

  return {
    orgs,
    initialValues: {
      startOn: testing ? todayIsoDate() : undefined,
      periodType: "week",
      period: 1,
      daysOpen: 1
    }
  }
}

export default connect(mapStateToProps)(NewImportRipplesDetailsForm)
