import React, { Component } from "react"
import LayoutWithHeader from "../components/LayoutWithHeader"
import LoginLayout from "../layouts/LoginLayout"
import Header from "../components/Header"
import Theme from "../themes/Silver"
import ConfirmPassword from "../components/login_steps/ConfirmPassword"
import EnterCodeNormal from "../components/login_steps/EnterCodeNormal"
import type { Errors } from "../state_types"

type Props = {
  onSubmit: (password: string, otpCode: string) => unknown
  errors?: Errors
  syncing?: boolean
  loginStep: string
  onNoPass: () => unknown
}

type State = {
  password: string
  otpCode: string
}

export default class ConfirmAuthPage extends Component<Props, State> {
  state = {
    password: "",
    otpCode: ""
  }

  static getDerivedStateFromProps(props: Props, state: State): State {
    if (props.loginStep === "enter_password") {
      // if you were sent to the beginning, be at the beginning
      return {
        ...state,
        otpCode: "" // without this the controller will be confused where in the process you are
      }
    }

    // if you've skipped past the beginning (e.g. by reloading the page)
    // be sent to the beginning
    if (!state.password) {
      props.onNoPass()
    }

    return state
  }

  updateValueFn = (key: string) => {
    return (value: string) => {
      this.setState({ [key]: value } as Pick<State, keyof State>)
    }
  }

  updateToValueFn = (key: string, value: string) => {
    return () => {
      this.setState({ [key]: value } as Pick<State, keyof State>)
    }
  }

  handleSubmit = () => {
    this.props.onSubmit(this.state.password, this.state.otpCode)
  }

  render() {
    const { loginStep, errors, syncing } = this.props
    const { password, otpCode } = this.state

    return (
      <Theme>
        <LayoutWithHeader
          header={
            <div>
              <Header />
            </div>
          }
        >
          <LoginLayout hideLogo>
            {loginStep === "enter_password" && (
              <ConfirmPassword
                onChange={this.updateValueFn("password")}
                submit={this.handleSubmit}
                errors={errors}
                syncing={syncing}
                password={password}
              />
            )}

            {loginStep === "enter_otp" && (
              <EnterCodeNormal
                onChange={this.updateValueFn("otpCode")}
                submit={this.handleSubmit}
                errors={errors}
                syncing={syncing}
                otpCode={otpCode}
                submitLabel="Continue"
                heading="Please confirm your one-time password before continuing"
              />
            )}
          </LoginLayout>
        </LayoutWithHeader>
      </Theme>
    )
  }
}
