import React from "react"
import { CircularProgress } from "@material-ui/core"

import Overlay from "./Overlay"

const Loading = () => {
  return (
    <Overlay background="transparent">
      <CircularProgress />
    </Overlay>
  )
}

export default Loading
