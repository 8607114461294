import React from "react"
import type { ReactNode } from "react"
import styled from "@emotion/styled"
import { CardLink } from "../Card"

const Card = styled(CardLink)({
  display: "flex",
  flexDirection: "column",
  cursor: "pointer"
})

const CardTitle = styled.h3({
  color: "#444444",
  margin: 0,
  fontSize: "24px",
  textAlign: "center",
  fontWeight: 600
})

const CardSubtitle = styled.div({
  margin: "10px 0 0 0",
  fontWeight: 300
})

const StatusBar = styled.hr<{ colour }>((props) => ({
  borderWidth: 0,
  height: 8,
  margin: 0,
  backgroundColor: props.colour,
  position: "absolute",
  top: 0,
  left: 0,
  right: 0
}))

const CardContents = styled.div({
  flex: 1,
  display: "flex",
  flexDirection: "column",
  margin: "32px 10px 10px 10px"
})

type ReportCardProps = {
  width: number
  height: number
  title: string
  subtitle?: string
  indicatorColour?: string
  children: ReactNode
  onClick?: () => {}
}

const ReportCard = ({
  width,
  height,
  title,
  subtitle,
  indicatorColour,
  children,
  onClick
}: ReportCardProps) => {
  const fullTitle = subtitle ? `${title} (${subtitle})` : title

  return (
    <Card
      width={width}
      height={height}
      noPadding
      onClick={onClick}
      data-test-title={title}
    >
      {indicatorColour && <StatusBar colour={indicatorColour} />}
      <CardContents>
        <CardTitle data-full-title={fullTitle}>
          {title}
          {subtitle && <CardSubtitle>({subtitle})</CardSubtitle>}
        </CardTitle>
        {children}
      </CardContents>
    </Card>
  )
}

export default ReportCard
