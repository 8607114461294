import React from "react"
import styled from "@emotion/styled"
import ToggleOrgRoleButton from "./ToggleOrgRoleButton"

type Props = {
  orgRoles: Array<string>
  email: string
  isDisabled: boolean
}

const ButtonsContainer = styled.div({
  display: "flex",
  width: "auto",
  justifyContent: "flex-start",
  gap: "20px",
  flexWrap: "wrap"
})

const ButtonContainer = styled.div({
  flex: "1 1"
})

const OrgRoleButtons = ({ orgRoles, email, isDisabled }: Props) => (
  <ButtonsContainer>
    <ButtonContainer>
      <ToggleOrgRoleButton
        label="Org Admin"
        roleName="admin"
        orgRoles={orgRoles}
        email={email}
        isDisabled={isDisabled}
        small
        faIcon="star"
      />
    </ButtonContainer>
    <ButtonContainer>
      <ToggleOrgRoleButton
        label="Observer"
        roleName="observer"
        orgRoles={orgRoles}
        email={email}
        isDisabled={isDisabled}
        small
      />
    </ButtonContainer>
    <ButtonContainer>
      <ToggleOrgRoleButton
        label="Ripple Creator"
        roleName="ripple_creator"
        orgRoles={orgRoles}
        email={email}
        isDisabled={isDisabled}
        small
      />
    </ButtonContainer>
    <ButtonContainer style={{ marginRight: "30px" }}>
      <ToggleOrgRoleButton
        label="Ripple Editor"
        roleName="ripple_editor"
        orgRoles={orgRoles}
        email={email}
        isDisabled={isDisabled}
        small
      />
    </ButtonContainer>
    <ButtonContainer>
      <ToggleOrgRoleButton
        label="Client"
        roleName="client"
        orgRoles={orgRoles}
        email={email}
        isDisabled={isDisabled}
        small
      />
    </ButtonContainer>
  </ButtonsContainer>
)

export default OrgRoleButtons
