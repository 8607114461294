import React from "react"
import { connect } from "react-redux"
import { Button } from "@material-ui/core/"
import styled from "@emotion/styled"
import colours from "../themes/colours"
import FlashMessageWithinPage from "../components/FlashMessageWithinPage"
import type { FlashMessageType } from "../components/FlashMessageWithinPage"
import {
  Header,
  SubHeader,
  PrimaryActions,
  Instructions
} from "../components/AuthFormStyles"
import InformationBox from "../components/InformationBox"
import LayoutWithHeader from "../components/LayoutWithHeader"
import PageHeader from "../components/Header"
import Theme from "../themes/Silver"
import type { Message } from "../state_types"

type ManageOTPProps = {
  mfaEnabled: boolean
  mfaRequired: boolean
  onUpdateOtp: () => unknown
  onDisableOtp: () => unknown
}

type ManageBackupCodesProps = {
  backupCodesGenerated: boolean
  onCreateBackupCodes: () => unknown
  onRemoveBackupCodes: () => unknown
}

type ManageMFAPageProps = {
  mfaEnabled: boolean
  mfaRequired: boolean
  backupCodesGenerated: boolean
  onUpdateOtp: () => unknown
  onDisableOtp: () => unknown
  onCreateBackupCodes: () => unknown
  onRemoveBackupCodes: () => unknown
  message?: Message
}

const InnerLayout = styled.div({
  display: "flex",
  flexDirection: "column",
  background: "white",
  padding: "90px 20px",
  alignItems: "center",
  minHeight: "100%",
  boxSizing: "border-box"
})

const Columns = styled.div({
  display: "flex",
  flexDirection: "column",
  overflow: "hidden",
  flexShrink: 0,
  flexGrow: 0,
  marginBottom: "2px",
  justifyContent: "center",
  "@media (min-width: 700px)": {
    flexDirection: "row"
  }
})

const Column = styled.div({
  maxWidth: "500px",
  flexShrink: 0,
  flexGrow: 1,
  border: `2px solid ${colours.blue}`,
  padding: "40px 0",
  borderWidth: "2px 0",
  margin: "-2px 0 0 0",
  ":last-child": {
    borderWidth: "2px 0 0 0"
  },
  "@media (min-width: 700px)": {
    padding: "0 40px",
    borderWidth: "0 2px",
    margin: "0 0 0 -2px",
    ":last-child": {
      borderWidth: "0 0 0 2px"
    }
  },
  display: "flex",
  flexDirection: "column",
  alignItems: "center"
})

const ManageOTP = ({
  mfaEnabled,
  mfaRequired,
  onUpdateOtp,
  onDisableOtp
}: ManageOTPProps) => {
  if (mfaEnabled) {
    return (
      <Column>
        <SubHeader>Manage two-factor authentication</SubHeader>
        <InformationBox type="ok">
          <p>
            Two-factor authentication is currently <strong>enabled</strong> for
            your account.
          </p>
        </InformationBox>
        <Instructions>
          <p>
            If you need to set up a new device or authentication app, you can do
            that on this page.
          </p>
        </Instructions>
        <PrimaryActions>
          <Button
            onClick={onUpdateOtp}
            type="button"
            variant="contained"
            color="primary"
          >
            Reset two-factor authentication
          </Button>

          {!mfaRequired && (
            <Button
              onClick={onDisableOtp}
              type="button"
              variant="outlined"
              color="primary"
            >
              Turn off two-factor authentication
            </Button>
          )}
        </PrimaryActions>
      </Column>
    )
  }
  return (
    <Column>
      <SubHeader>Manage two-factor authentication</SubHeader>
      <InformationBox type="error">
        <p>
          Two-factor authentication is currently <strong>disabled</strong> for
          your account.
        </p>
        <p>
          Anyone with your username and password will be able to access your
          account.
        </p>
      </InformationBox>
      <Instructions>
        <p>
          If you need to set up a new device or authentication app, you can do
          that on this page.
        </p>
      </Instructions>
      <PrimaryActions>
        <Button
          onClick={onUpdateOtp}
          type="button"
          variant="contained"
          color="primary"
        >
          Turn on two-factor authentication
        </Button>
      </PrimaryActions>
    </Column>
  )
}

const ManageBackupCodes = ({
  backupCodesGenerated,
  onCreateBackupCodes,
  onRemoveBackupCodes
}: ManageBackupCodesProps) => {
  if (backupCodesGenerated) {
    return (
      <Column>
        <SubHeader>Generate backup codes</SubHeader>
        <InformationBox type="ok">
          <p>
            You currently have <strong>backup codes</strong> generated.
          </p>
          <p>
            If you lose access to your authentication app you can use these
            codes to regain access to your Ripple log in.
          </p>
        </InformationBox>
        <Instructions>
          <p>
            If you lose access to your authentication app, you can use these
            codes instead of the 6-digit One Time Passwords from your device.
          </p>
          <p>
            Each code can only be used once, and we recommend you keep them in a
            safe, secure place.
          </p>
        </Instructions>
        <PrimaryActions>
          <Button
            onClick={onCreateBackupCodes}
            type="button"
            variant="contained"
            color="primary"
          >
            Generate new backup codes
          </Button>

          <Button
            onClick={onRemoveBackupCodes}
            type="button"
            variant="outlined"
            color="primary"
          >
            Delete backup codes
          </Button>
        </PrimaryActions>
      </Column>
    )
  }
  return (
    <Column>
      <SubHeader>Generate backup codes</SubHeader>
      <InformationBox type="error">
        <p>
          You do not currently have <strong>backup codes</strong> generated.
        </p>
        <p>
          If you lose access to your authentication app you will not be able to
          regain access to your Ripple log in.
        </p>
      </InformationBox>
      <Instructions>
        <p>
          If you lose access to your authentication app, you can use these codes
          instead of the 6-digit One Time Passwords from your device.
        </p>
        <p>
          Each code can only be used once, and we recommend you keep them in a
          safe, secure place.
        </p>
      </Instructions>
      <PrimaryActions>
        <Button
          onClick={onCreateBackupCodes}
          type="button"
          variant="contained"
          color="primary"
        >
          Generate backup codes
        </Button>
      </PrimaryActions>
    </Column>
  )
}

const ManageMFAPage = ({
  mfaEnabled,
  mfaRequired,
  onUpdateOtp,
  onDisableOtp,
  message,
  backupCodesGenerated,
  onCreateBackupCodes,
  onRemoveBackupCodes
}: ManageMFAPageProps) => (
  <Theme>
    <LayoutWithHeader
      header={
        <div>
          <PageHeader />
        </div>
      }
    >
      <InnerLayout>
        <FlashMessageWithinPage type={message?.type}>
          {message?.text || null}
        </FlashMessageWithinPage>
        <Header>Two factor authentication (2FA)</Header>
        <Columns>
          <ManageOTP
            mfaEnabled={mfaEnabled}
            mfaRequired={mfaRequired}
            onUpdateOtp={onUpdateOtp}
            onDisableOtp={onDisableOtp}
          />
          {(mfaEnabled || backupCodesGenerated) && (
            <ManageBackupCodes
              backupCodesGenerated={backupCodesGenerated}
              onCreateBackupCodes={onCreateBackupCodes}
              onRemoveBackupCodes={onRemoveBackupCodes}
            />
          )}
        </Columns>
      </InnerLayout>
    </LayoutWithHeader>
  </Theme>
)

const mapStateToProps = (state) => {
  const me = state.me || {}

  let message = me.message

  // show error over other message
  if (me.errors?.title) {
    message = { text: me.errors.title, type: "error" as FlashMessageType }
  }

  return {
    mfaRequired: me.data?.user?.mfaRequired,
    mfaEnabled: me.data?.user?.mfaEnabled,
    backupCodesGenerated: me.data?.user?.backupCodesGenerated,
    message
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    onUpdateOtp: () =>
      // eslint-disable-next-line @typescript-eslint/no-unsafe-return
      dispatch({ type: "UPDATE_OTP" }),

    onDisableOtp: () => dispatch({ type: "DISABLE_OTP" }),
    onCreateBackupCodes: () => dispatch({ type: "CREATE_BACKUP_CODES" }),
    onRemoveBackupCodes: () => dispatch({ type: "REMOVE_BACKUP_CODES" })
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ManageMFAPage)
