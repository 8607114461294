import React, { Component } from "react"
import styled from "@emotion/styled"
import FontAwesome from "react-fontawesome"

export type FlashMessageType = "warn" | "ok" | "error" | "info"
type Props = {
  type: FlashMessageType
  children: React.ReactNode
}

type IconCircleProps = {
  backgroundColor: string
  textColor?: string
  name: string
}

const colorForType = (type: FlashMessageType) => {
  switch (type) {
    case "info":
      return "#644276"
    case "warn":
      return "#ebb64f"
    case "error":
      return "#BA2F2F"
    case "ok":
    default:
      return "#2F8348"
  }
}

const Container = styled.div<{ type: FlashMessageType }>(({ type }) => ({
  flex: "0 1",
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-between",
  alignItems: "center",
  border: `2px solid ${colorForType(type)}`,
  width: "auto",
  borderRadius: "100px",
  padding: "10px 16px 10px 10px",
  marginBottom: "1em"
}))

const IconColumn = styled.div({
  flex: "0 0",
  paddingRight: "14px"
})
const CloseColumn = styled.div({
  flex: "0 0",
  paddingLeft: "14px"
})

const TextColumn = styled.div({
  flex: "1 0",
  lineHeight: 1.5,
  " > :first-child": { marginTop: 0 },
  " > :last-child": { marginBottom: 0 },
  " a": {
    color: "black",
    fontWeight: "bold"
  }
})

const IconCircleBackground = styled.div<{
  backgroundColor: string
  textColor?: string
}>(({ backgroundColor, textColor }) => ({
  width: "20px",
  height: "20px",
  borderRadius: "50%",
  textAlign: "center",
  lineHeight: "20px",
  verticalAlign: "middle",
  fontSize: "16px",
  backgroundColor,
  color: textColor || "white"
}))

const CloseButton = styled.button<{ colorType: FlashMessageType }>(
  ({ colorType }) => ({
    appearance: "none",
    border: "0",
    background: "transparent",
    color: colorForType(colorType),
    cursor: "pointer",
    fontSize: "1.5em",
    marginTop: "-2px" // visually centered
  })
)

const IconCircle = ({ name, backgroundColor, textColor }: IconCircleProps) => (
  <IconCircleBackground textColor={textColor} backgroundColor={backgroundColor}>
    <FontAwesome name={name} />
  </IconCircleBackground>
)

const iconForType = (type: FlashMessageType) => {
  switch (type) {
    case "warn":
      return (
        <IconCircle
          backgroundColor={colorForType(type)}
          textColor="black"
          name="exclamation"
        />
      )
    case "error":
    case "info":
      return (
        <IconCircle backgroundColor={colorForType(type)} name="exclamation" />
      )
    case "ok":
    default:
      return <IconCircle backgroundColor={colorForType(type)} name="check" />
  }
}

type State = {
  hidden: boolean
}
class FlashMessageWithinPage extends Component<Props, State> {
  state = {
    hidden: false
  }

  handleClose = () => {
    this.setState({ hidden: true })
  }

  render() {
    if (this.state.hidden || !this.props.children) {
      return null
    }

    return (
      <Container type={this.props.type}>
        <IconColumn>{iconForType(this.props.type)}</IconColumn>
        <TextColumn>{this.props.children}</TextColumn>
        <CloseColumn>
          <CloseButton
            onClick={this.handleClose}
            type="button"
            colorType={this.props.type}
          >
            <FontAwesome name="close" />
          </CloseButton>
        </CloseColumn>
      </Container>
    )
  }
}

export default FlashMessageWithinPage
