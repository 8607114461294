import styled from "@emotion/styled"
import colours from "../themes/colours"
import { addToArray } from "./helpers/number_array"

const GradientBar = styled.div<{
  barRange: number[]
  gradientStops: number[]
}>((props) => {
  const [barStart, barEnd] = props.barRange
  const shiftedGradientStops = addToArray(props.gradientStops, -barStart)

  return {
    background: `linear-gradient(to right, ${colours.badScore} ${shiftedGradientStops[0]}px, ${colours.goodScore} ${shiftedGradientStops[1]}px)`,
    height: "100%",
    position: "absolute",
    left: Math.round(barStart),
    width: barEnd - barStart,
    opacity: "0.5",
    zIndex: 1
  }
})

export default GradientBar
