import React from "react"
import styled from "@emotion/styled"
import moment from "moment"
import withQuery from "with-query"
import type { TeamAssessment, UserHash } from "../../state_types"
import colours from "../../themes/colours"
import type { DialogFunctions } from "../BubbleDialogContainer"
import ScoreCircle from "../ScoreCircle"
import { gutter } from "../Layout"
import { HeadingOne } from "../TextStyles"
import SectionList from "./SectionList"
import NumUserResponses from "../NumUserResponses"
import CommentsList from "../CommentsList"
import Breadcrumb from "../Breadcrumb"

type Props = {
  users: UserHash
  assessmentPart: TeamAssessment
  rippleUuid: string
  dialog: DialogFunctions
  rippleName: string
  assessNum?: number
  assessDate?: string
  sectionId?: string
  sectionTitle?: string
  outOf: number
  queryParams?: {}
  thresholdClassifiers: Array<string> | null
}

const Assessment = styled.div({
  display: "flex",
  paddingTop: gutter,
  paddingBottom: gutter
})

const Container = styled.div({
  backgroundColor: colours.silver
})

const AssessmentInfo = styled.div({
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  marginLeft: gutter
})

const ScoreDetails = styled.div({})

const AssessmentDate = styled(HeadingOne)({
  fontSize: "1.4em",
  fontWeight: 600,
  // marginTop: gutter,
  marginBottom: 0
})

const SectionName = styled(HeadingOne)({
  fontSize: "1.4em",
  fontWeight: 600,
  // marginTop: gutter,
  marginBottom: 0
})

const Range = styled.div({
  fontSize: "0.8em",
  marginRight: "1.5em"
})

const Responses = styled.div({
  fontSize: "0.8em"
})

const SectionSummary = styled.div({})

const FurtherDetails = styled.div({
  display: "flex"
})

function breadcrumbName(
  sectionType,
  rippleName,
  assessDate,
  assessmentDate,
  sectionTitle
) {
  if (sectionType === "section") {
    return rippleName
  }

  if (sectionType === "question") {
    return assessDate && assessmentDate.format("MMM DD")
  }

  return sectionTitle && sectionTitle.replace(/-/g, " ")
}

function breadcrumbLink(sectionType, rippleUuid, assessNum, sectionId) {
  if (sectionType === "section") {
    return `/ripple/${rippleUuid}/`
  }

  if (assessNum && sectionType === "question") {
    return `/ripple/${rippleUuid}/assessments/${assessNum}/`
  }

  if (sectionId && assessNum) {
    return `/ripple/${rippleUuid}/assessments/${assessNum}`
  }

  return `/ripple/${rippleUuid}/`
}

const AssessmentPartDetails = ({
  users,
  assessmentPart,
  rippleUuid,
  dialog,
  rippleName,
  assessNum,
  assessDate,
  sectionId,
  sectionTitle,
  outOf,
  queryParams,
  thresholdClassifiers
}: Props) => {
  const assessmentDate = moment(assessDate)
  const sections = assessmentPart.attributes
  const sectionType = sections && sections[0] && sections[0].type
  const assessUsers = assessmentPart.userBreakdown
  const breadcrumb = breadcrumbName(
    sectionType,
    rippleName,
    assessDate,
    assessmentDate,
    sectionTitle
  )
  const breadLink = withQuery(
    breadcrumbLink(sectionType, rippleUuid, assessNum, sectionId),
    queryParams
  )

  return (
    <Container>
      <Breadcrumb to={breadLink} name={breadcrumb} />
      <Assessment>
        <ScoreDetails>
          <ScoreCircle
            score={assessmentPart.score}
            radius={30}
            thresholds={assessmentPart.thresholds}
          />
        </ScoreDetails>
        <AssessmentInfo>
          {sectionType === "section" ? (
            <AssessmentDate>
              {assessmentDate.format("MMM DD YYYY")}
            </AssessmentDate>
          ) : (
            <SectionName>{assessmentPart.title}</SectionName>
          )}
          <FurtherDetails>
            <Range>
              Range:
              {assessmentPart.range[0]}-{assessmentPart.range[1]}
            </Range>
            <Responses>
              <NumUserResponses userBreakdown={assessmentPart.userBreakdown} />
            </Responses>
          </FurtherDetails>
        </AssessmentInfo>
      </Assessment>
      <SectionSummary>
        {assessmentPart.type === "question" ? (
          <CommentsList userBreakdown={assessUsers} users={users} />
        ) : (
          <SectionList
            users={users}
            sectionId={sectionId}
            assessmentNumber={assessNum || assessmentPart.number}
            sections={sections}
            rippleUuid={rippleUuid}
            dialog={dialog}
            outOf={outOf}
            queryParams={queryParams}
            thresholdClassifiers={thresholdClassifiers}
          />
        )}
      </SectionSummary>
    </Container>
  )
}

AssessmentPartDetails.defaultProps = {
  assessNum: 0,
  assessDate: "",
  sectionId: "",
  sectionTitle: ""
}

export default AssessmentPartDetails
