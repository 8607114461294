import React from "react"
import styled from "@emotion/styled"
import Checkbox from "material-ui/Checkbox"

type Props = {
  assessorName: string
  onChange: (value: boolean) => any
  value?: boolean
  hasError?: boolean
}

const IdentityContainer = styled.div({
  paddingTop: "20px",
  paddingBottom: "20px",
  fontSize: "0.9em"
})

const SdqAssessmentIdentityInput = ({
  assessorName,
  onChange,
  value,
  hasError
}: Props) => {
  return (
    <IdentityContainer>
      <Checkbox
        label={`I confirm that I am ${assessorName}`}
        labelStyle={!value && hasError ? { color: "red" } : {}}
        iconStyle={{ fill: !value && hasError ? "red" : "grey" }}
        checked={value}
        onCheck={(_event, isInputChecked: boolean) => onChange(isInputChecked)}
      />
    </IdentityContainer>
  )
}

export default SdqAssessmentIdentityInput
