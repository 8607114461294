import React from "react"
import debounce from "lodash.debounce"
import AssessmentIntroPage from "./AssessmentIntroPage"
import AssessmentSummaryPage from "./AssessmentSummaryPage"
import SectionSummaryPage from "./SectionSummaryPage"
import QuestionPage from "./QuestionPage"
import CommentOnlyPage from "./CommentOnlyPage"
import SdqPage from "./SdqPage"
import SlideablePageGroup from "../components/SlidablePageGroup"
import type {
  Assessment,
  Question,
  AssessmentInput,
  QuestionInput,
  AssessmentAttributesInput,
  SectionSummary,
  AssessmentIntro
} from "../contexts/assessment/types"
import simpleRadioHOC from "../components/assessment/simple_radio/SimpleRadioHOC"
import FrameworkModel from "../contexts/assessment/FrameworkModel"
import RippleGA from "../ripple_ga"

type Props = {
  assessment: Assessment
  nextPage: () => void
  previousPage: () => void
  updateRating: (questionId: string, rating?: number) => void
  updateQuestionComment: (
    attributeId: string | null | undefined,
    comment: string
  ) => void
  updateCommentOnly: (
    attributeId: string | null | undefined,
    comment: string
  ) => void
  updateData: (
    attributeId: string | null | undefined,
    value: string | boolean
  ) => void
  clearRating: (questionId: string) => unknown
  submitAssessment: () => void
}

const emptyInput = {
  attributes: {}
}

const questionInput = (
  question: Question,
  assessmentInput: AssessmentInput
): QuestionInput | null | undefined => {
  return assessmentInput?.attributes[question.id]
}

const AssessmentPage = ({
  assessment,
  nextPage: next,
  previousPage,
  updateRating,
  updateQuestionComment,
  updateCommentOnly,
  updateData,
  clearRating,
  submitAssessment
}: Props) => {
  /*
    Debounce for as long as the transition in app/javascript/components/SlidablePageGroup.tsx#L30
    to prevent double click allowing skip of non-skippable questions

    Could also be implemented as a loading state passed down to components
    or directly at the redux level, however both of these options would require larger changes
    therefore this is here as the simplest place to put it while still fixing the bug for all frameworks
  */
  const nextPage = debounce(next, 800, { leading: true, trailing: false })

  const { framework, canSkipQuestions } = assessment
  const steps = framework.steps
  const previous: AssessmentAttributesInput = assessment.previous || {}
  const frameworkModel = new FrameworkModel(framework.steps)
  const count = frameworkModel.stepCount()

  const allPages = steps.map((step, index) => {
    const pages = {
      next: index >= steps.length - 1 ? null : nextPage,
      previous: index === 0 ? null : previousPage
    }

    if (framework.format === "simple_radio") {
      return simpleRadioHOC({
        assessment,
        step,
        nextPage,
        previousPage,
        updateRating,
        submitAssessment
      })
    }

    const nextPageAssessmentStart = (): unknown => {
      RippleGA.assessmentStart(assessment)
      return nextPage()
    }

    const nextPageAssessmentFinish = (): unknown => {
      RippleGA.assessmentFinish(assessment)
      return submitAssessment()
    }

    switch (step.type) {
      case "question": {
        const question = step as Question

        const input: QuestionInput | null | undefined = questionInput(
          question,
          assessment.input || emptyInput
        )
        const previousInput: QuestionInput | null | undefined =
          previous[question.id]

        if (question.responseType === "rating") {
          return (
            <QuestionPage
              key={question.id}
              current={assessment.currentStage === question.id}
              question={question}
              input={input}
              previousInput={previousInput}
              previousPage={previousPage}
              nextPage={nextPage}
              updateRating={(rating) => updateRating(question.id, rating)}
              clearRating={() => clearRating(question.id)}
              attributeIndex={frameworkModel.stepNumber(question.id)}
              attributeTotal={count}
              updateQuestionComment={(comment) =>
                updateQuestionComment(question.id, comment)
              }
              canSkip={canSkipQuestions}
              framework={framework}
            />
          )
        }

        return (
          <CommentOnlyPage
            key={step.id}
            current={assessment.currentStage === question.id}
            question={question}
            input={input}
            previousInput={previousInput}
            previousPage={previousPage}
            nextPage={nextPage}
            attributeIndex={frameworkModel.stepNumber(question.id)}
            attributeTotal={count}
            updateCommentOnly={(comment) =>
              updateCommentOnly(question.id, comment)
            }
          />
        )
      }

      case "assessment_intro": {
        const introStep = step as AssessmentIntro
        return (
          <AssessmentIntroPage
            key={step.id}
            step={introStep}
            nextPage={nextPageAssessmentStart}
          />
        )
      }
      case "assessment_summary":
        return (
          <AssessmentSummaryPage
            key={step.id}
            assessment={assessment}
            previousPage={previousPage}
            submitAssessment={nextPageAssessmentFinish}
          />
        )
      case "section_summary": {
        const summary = step as SectionSummary
        return (
          <SectionSummaryPage
            key={step.id}
            assessment={assessment}
            step={summary}
            previousPage={previousPage}
            nextPage={nextPage}
          />
        )
      }
      case "sdq_page":
        return (
          <SdqPage
            key={step.id}
            assessment={assessment}
            step={step}
            previousPage={pages.previous}
            nextPage={pages.next}
            updateRating={updateRating}
            updateQuestionComment={updateQuestionComment}
            updateData={updateData}
            submitAssessment={submitAssessment}
          />
        )
      default:
        return (
          <div key={step.id}>
            unknown section type
            {step.type}
          </div>
        )
    }
  })

  return (
    <SlideablePageGroup currentKey={assessment.currentStage}>
      {allPages}
    </SlideablePageGroup>
  )
}

export default AssessmentPage
