import React from "react"
import { CardContainer } from "./CardContainer"

type Props = {
  children
}

const RippleCardGrid = ({ children }: Props) => {
  return <CardContainer>{children}</CardContainer>
}

export default RippleCardGrid
