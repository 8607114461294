import React from "react"
import { connect } from "react-redux"
import styled from "@emotion/styled"
import Theme from "../themes/White"
import { submitEditPasswordForm } from "../api_actions"
import EditPasswordForm from "../components/EditPasswordForm"
import GoBackHeader from "../components/GoBackHeader"
import type { State, DispatchFunction } from "../state_types"

const EditPasswordContainer = styled.div({
  display: "flex",
  flexDirection: "column",
  flexGrow: 1
})

type Props = {
  userEmail: string
  submitEditPasswordForm: () => any
  onSubmit: DispatchFunction
  syncing: unknown
}

function handleCancel(event) {
  event.preventDefault()

  window.history.back()
}

class UserPasswordPage extends React.Component<Props> {
  handleSubmit = () => {
    const email = this.props.userEmail
    this.props.onSubmit(email)
  }

  render() {
    const { syncing } = this.props

    return (
      <Theme>
        <EditPasswordContainer>
          <GoBackHeader handleCancel={handleCancel} />
          <EditPasswordForm onSubmit={this.handleSubmit} syncing={syncing} />
        </EditPasswordContainer>
      </Theme>
    )
  }
}

const mapStateToProps = (state: State) => {
  const me = state.me || {}
  const loggedIn = me.data && me.data.token
  const user = loggedIn && me.data ? me.data.user : null
  return {
    userEmail: user && user.email,
    syncing: me.syncing
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    onSubmit: (userEmail: string) => {
      dispatch(submitEditPasswordForm(userEmail))
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(UserPasswordPage)
