import React from "react"
import includes from "lodash.includes"
import { connect } from "react-redux"
import get from "lodash.get"
import some from "lodash.some"
import styled from "@emotion/styled"
import {
  faEdit,
  faFileImport,
  faQrcode
} from "@fortawesome/free-solid-svg-icons"
import { TallButton } from "../TallButton"
import {
  DropDownFilter,
  SearchFilter,
  FilterContainer,
  FilterGridContainer
} from "../Filters"
import type { DispatchFunction } from "../../state_types"
import UserStateDecorator from "../../contexts/user/UserStateDecorator"
import RipplesFilterState from "./RipplesFilterState"
import settings from "../../settings"

const ButtonBuffer = styled.div({
  paddingTop: "8px"
})

type OrgListItem = {
  value: string
  key: string
}

type Props = {
  sortBy?: number
  rippleOrgs?: Array<OrgListItem>
  organisation
  changeRippleList?: DispatchFunction
  enableEditMode: DispatchFunction
  isEditing: boolean
  filters
  order
  group
  ripples?
  canBulkEdit: boolean
  canManageQrCodes: boolean
  showImportButton: boolean
  onChange: (state: {}) => void
  mostRecentOrganisationSlug: string
  manageQrCodes: (slug: string) => void
  importRipples: () => void
}

class RipplesFilterGrid extends React.Component<Props> {
  onEditModeToggle = () => {
    this.props.enableEditMode()
  }

  render() {
    const {
      filters,
      organisation,
      order,
      group,
      isEditing,
      canBulkEdit,
      canManageQrCodes,
      showImportButton,
      onChange,
      mostRecentOrganisationSlug
    } = this.props

    const buildChangeHandler = (key: string) => (newValue: string) => {
      if (key === "organisation") {
        filters.group = undefined
      }
      return onChange({ ...filters, [key]: newValue, page: undefined })
    }

    const manageQrCodeHandler = () => {
      this.props.manageQrCodes(mostRecentOrganisationSlug)
    }

    const buttons = []

    if (canBulkEdit && !isEditing) {
      buttons.push({
        key: "edit",
        label: "Edit Ripples",
        icon: faEdit,
        onClick: () => this.onEditModeToggle()
      })
    }

    if (showImportButton) {
      buttons.push({
        key: "import",
        label: "Import ripples",
        icon: faFileImport,
        onClick: this.props.importRipples
      })
    }

    if (canManageQrCodes) {
      buttons.push({
        key: "qr",
        label: "Manage QR codes",
        icon: faQrcode,
        onClick: manageQrCodeHandler
      })
    }

    return (
      <FilterGridContainer>
        <DropDownFilter
          className="organisation"
          {...organisation}
          onChange={buildChangeHandler("organisation")}
        />

        <DropDownFilter {...group} onChange={buildChangeHandler("group")} />

        <SearchFilter
          value={filters.searchTerm}
          onChange={buildChangeHandler("searchTerm")}
        />

        <DropDownFilter {...order} onChange={buildChangeHandler("order")} />

        {buttons.map((button) => (
          <FilterContainer key={button.key}>
            <ButtonBuffer>
              <TallButton {...button} />
            </ButtonBuffer>
          </FilterContainer>
        ))}
      </FilterGridContainer>
    )
  }
}

const testing = process.env.RAILS_ENV === "test"
const developing = process.env.RAILS_ENV === "development"

const showImportButton = (state) => {
  const canImportRipples = new UserStateDecorator(state).canImportRipples()
  const email = get(state, "me.data.user.email")

  return (
    canImportRipples &&
    (includes(settings.users.canSeeImportButton, email) ||
      testing ||
      developing)
  )
}

const mapStateToProps = (state) => {
  const filterState = new RipplesFilterState(state, "myRipples")

  const isEditing = get(state, "bulkActions.active", false)
  const canBulkEdit = some(state.myRipples.data.ripples, "canBulkEdit")
  const canManageQrCodes = some(state.me.data.user.orgs, {
    permissions: { qrCodes: true }
  })
  const mostRecentOrganisationSlug = get(
    state,
    "memory.mostRecent.organisationSlug"
  )

  const result = {
    filters: filterState.filters,
    organisation: {
      label: "Organisation",
      value: filterState.filters.organisation,
      items: filterState.availableOrgs
    },
    order: {
      label: "Sort by",
      value: filterState.filters.order,
      items: filterState.availableOrders
    },
    group: {
      label: "Group",
      value: filterState.filters.group,
      items: filterState.availableGroups
    }
  }

  return {
    ...result,
    canBulkEdit,
    canManageQrCodes,
    isEditing,
    showImportButton: showImportButton(state),
    mostRecentOrganisationSlug
  }
}

const mapDispatchToProps = (dispatch) => ({
  onChange: (newFilters: { organisation?: string }) => {
    dispatch({
      type: "RIPPLES",
      query: newFilters
    })
    dispatch({
      type: "REMEMBER_ORG_SLUG",
      payload: newFilters.organisation
    })
  },
  enableEditMode: () => {
    dispatch({ type: "BULK_MODE_ENTERED" })
  },
  importRipples: () => {
    dispatch({ type: "IMPORT_RIPPLES_STEP" })
  },
  manageQrCodes: (orgSlug) => {
    dispatch({ type: "EDIT_QR_CODES", filters: { organisation: orgSlug } })
  }
})

export default connect(mapStateToProps, mapDispatchToProps)(RipplesFilterGrid)
