import React from "react"
import styled from "@emotion/styled"
import QuestionAnswerRow from "./QuestionAnswerRow"
import InputModel from "../contexts/assessment/InputModel"
import { formatRating } from "./helpers/scores"
import type {
  AssessmentAttributesInput,
  Question
} from "../contexts/assessment/types"

const TotalRow = styled.tr({
  borderSpacing: 10,
  fontSize: "1.4em",
  fontWeight: "normal",
  "> td": {
    borderBottom: "2px solid black",
    paddingBottom: 10
  }
})

const LabelColumn = styled.td({
  width: "100%"
})

const ScoreColumn = styled.td({
  width: "4em",
  minWidth: "4em",
  textAlign: "right",
  verticalAlign: "top",
  fontWeight: "bold"
})

const QuestionTitle = styled.div({
  fontWeight: "bold"
})

type Props = {
  input: AssessmentAttributesInput
  questions: Array<Question>
}

const QuestionAnswers = ({ input, questions }: Props) => {
  const inputModel = new InputModel(input, questions)
  const average = inputModel.averageRating()

  return (
    <table cellPadding="0" cellSpacing="0" id="section-scores">
      <caption style={{ display: "none" }}>Section Scores</caption>
      <tbody>
        <TotalRow>
          <LabelColumn>
            <QuestionTitle>Section Score</QuestionTitle>
          </LabelColumn>
          <ScoreColumn>{formatRating(average)}</ScoreColumn>
        </TotalRow>
        {questions.map((question) => (
          <QuestionAnswerRow
            key={question.id}
            question={question}
            input={input[question.id]}
          />
        ))}
      </tbody>
    </table>
  )
}

export default QuestionAnswers
