import React from "react"
import styled from "@emotion/styled"
import WizardStepLabel from "./WizardStepLabel"
import type {
  StepStates,
  TransitionToStepFunc
} from "../contexts/shared/step_types"

const StepLabelList = styled.ul({
  listStyle: "none",
  padding: 0,
  marginTop: 0
})

type Props = {
  steps: StepStates
  currentStepID: string
  transitionToStep: TransitionToStepFunc
  iconSide?: "left" | "right"
}

const WizardStepLabels = ({
  steps,
  currentStepID,
  transitionToStep,
  iconSide
}: Props) => {
  return (
    <StepLabelList>
      {steps.map((step) => (
        <WizardStepLabel
          key={step.id}
          step={step}
          iconSide={iconSide}
          transitionToStep={transitionToStep}
          isCurrent={currentStepID === step.id}
        />
      ))}
    </StepLabelList>
  )
}

WizardStepLabels.defaultProps = {
  iconSide: "left"
}

export default WizardStepLabels
