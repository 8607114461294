import React from "react"
import { connect } from "react-redux"
import styled from "@emotion/styled"
import { reduxForm, formValueSelector, InjectedFormProps } from "redux-form"
import RippleScheduleForm from "../../components/RippleScheduleForm"
import { stepValidator } from "../../contexts/import_ripples_form/wizard"

const ScheduleContainer = styled.div({
  display: "flex",
  flexDirection: "column",
  flexGrow: 1
})
interface IProps extends InjectedFormProps {
  periodType: string
  startOn: string
}

const handleSubmit = () => {}
const Schedule = ({ periodType, startOn }: IProps) => (
  <ScheduleContainer>
    <form onSubmit={handleSubmit} id="ripple-details-form">
      <RippleScheduleForm startOn={startOn} periodType={periodType} />
    </form>
  </ScheduleContainer>
)

const ScheduleForm = reduxForm({
  form: "importRipples",
  destroyOnUnmount: false,
  forceUnregisterOnUnmount: true,
  validate: stepValidator("schedule")
})(Schedule)

const selector = formValueSelector("importRipples")

const mapStateToProps = (state) => {
  return {
    periodType: selector(state, "periodType"),
    startOn: selector(state, "startOn")
  }
}

export default connect(mapStateToProps)(ScheduleForm)
