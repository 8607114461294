import React from "react"
import get from "lodash.get"
import styled from "@emotion/styled"
import RippleTableRow from "./RippleTableRow"
import type { Ripple } from "../state_types"

type Props = {
  ripples: Array<Ripple>
}

const Table = styled.table({
  width: "100%"
})

const Container = styled.div({
  overflow: "hidden"
})

const TableHeader = styled.thead({
  textAlign: "left",
  fontSize: "0.9em"
})

const RippleTable = ({ ripples }: Props) => {
  return (
    <Container>
      <Table cellPadding={0} cellSpacing={0} id="ripples-table">
        <TableHeader>
          <tr>
            <th colSpan={2}>Name</th>
            <th>Organisation</th>
            <th>Last assessment</th>
            <th />
          </tr>
        </TableHeader>
        <tbody>
          {ripples.map((ripple) => (
            <RippleTableRow key={get(ripple, "uuid")} ripple={ripple} />
          ))}
        </tbody>
      </Table>
    </Container>
  )
}

export default RippleTable
