import React from "react"
import styled from "@emotion/styled"
import AvatarList from "./AvatarList"

const UserSummaryContainer = styled.div({
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  justifyContent: "center",
  position: "relative"
})

const RemainingNumberContainer = styled.span({
  width: 0,
  overflow: "visible"
})

const RemainingNumber = styled.span({
  color: "#6C727C",
  fontSize: "12px",
  paddingLeft: 10,
  width: 0
})

type SummaryUser = {
  id: string
  avatar: string
  name: string
}

type ReportUserSummaryProps = {
  total: number
  avatarSize: number
  users: Array<SummaryUser>
}

const ReportUserSummary = ({
  total,
  users,
  avatarSize
}: ReportUserSummaryProps) => {
  const remaining = total - users.length
  const displayRemaining = users.length > 0 && remaining >= 1

  return (
    <UserSummaryContainer>
      <AvatarList users={users} avatarSize={avatarSize} />
      {displayRemaining && (
        <RemainingNumberContainer>
          <RemainingNumber>+{remaining}</RemainingNumber>
        </RemainingNumberContainer>
      )}
    </UserSummaryContainer>
  )
}

export default ReportUserSummary
