import { connect } from "react-redux"
import ReportFilters from "./ReportFilters"
import ReportFilterState from "./ReportFilterState"
import type { State } from "../../state_types"

const orgsItems = (org) => ({
  value: org.slug,
  text: org.name
})

const mapObjectsToItems = (item) => ({
  value: item.id,
  text: item.title
})

const mapStateToProps = (state: State) => {
  const filterState = new ReportFilterState(state)

  const result: any = { filters: filterState.filters }

  result.organisation = {
    label: "Organisation",
    value: filterState.filters.organisation,
    items: filterState.availableOrgs.map(orgsItems)
  }

  result.framework = {
    label: "Framework",
    value: filterState.filters.framework,
    items: filterState.availableFrameworks.map(mapObjectsToItems)
  }

  result.group = {
    label: "Group",
    value: filterState.filters.group,
    items: filterState.availableGroups
  }

  result.timeframe = {
    label: "Timeframe",
    value: filterState.filters.timeframe,
    items: [
      { text: "All time", value: -1 },
      { text: "Last 90 days", value: 90 },
      { text: "Last 60 days", value: 60 },
      { text: "Last 30 days", value: 30 },
      { text: "Last 14 days", value: 14 },
      { text: "Last 7 days", value: 7 },
      { text: "Today", value: 0 }
    ]
  }

  result.threshold = {
    label: "Threshold",
    value: filterState.filters.threshold ?? 0.5,
    items: [
      { text: "100%", value: 1 },
      { text: "75%", value: 0.75 },
      { text: "50%", value: 0.5 },
      { text: "25%", value: 0.25 },
      { text: "10%", value: 0.1 }
    ]
  }

  result.searchTerm = filterState.filters.searchTerm ?? ""

  return result
}

const mapDispatchToProps = (dispatch, ownProps) => ({
  onChange: (newFilters: { organisation: string }) => {
    const { reportId, subReportId } = ownProps

    if (reportId && subReportId) {
      dispatch({
        type: "SUB_REPORT",
        query: newFilters,
        payload: { reportId, subReportId }
      })
    } else if (reportId) {
      dispatch({
        type: "REPORT",
        query: newFilters,
        payload: { reportId }
      })
    } else {
      dispatch({
        type: "REPORT_INDEX",
        query: newFilters
      })
    }

    dispatch({
      type: "REMEMBER_ORG_SLUG",
      payload: newFilters.organisation
    })
  }
})

export default connect(mapStateToProps, mapDispatchToProps)(ReportFilters)
