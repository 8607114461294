import React from "react"
import { Anchor } from "./TextStyles"

type Props = {
  value?: string
  scheme: string
  maxWidth?: string
  color?: string
}

const SchemeLink = ({ value, scheme, ...rest }: Props) =>
  value ? (
    <Anchor href={`${scheme}:${value}`} {...rest}>
      {value}
    </Anchor>
  ) : null

SchemeLink.defaultProps = {
  maxWidth: null,
  color: null
}

export default SchemeLink
