import React from "react"
import SchemeLink from "./SchemeLink"

type Props = {
  value?: string
  maxWidth?: string
  color?: string
}

const PhoneLink = (props: Props) => <SchemeLink scheme="tel" {...props} />

export default PhoneLink
