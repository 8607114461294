import React from "react"
import styled from "@emotion/styled"
import { Field } from "redux-form"
import range from "lodash.range"
import find from "lodash.find"
import StringSelectField from "./StringSelectField"
import NumberSelectField from "./NumberSelectField"
import ToggleButtonField from "./ToggleButtonField"
import { Anchor, HeadingThree, FormHelpText } from "./TextStyles"
import type {
  FrameworkInfo,
  RippleFormSettings
} from "../contexts/ripple_form/types"

const FormContainer = styled.div({
  display: "flex",
  flexDirection: "column",
  alignItems: "center"
})

const FormSectionHeading = styled(HeadingThree)({
  marginTop: 0
})

const FieldContainer = styled.div({
  minHeight: "4.5em",
  display: "flex",
  justifyContent: "flex-start",
  width: "320px",
  "@media(max-width: 475px)": {
    width: "100%"
  }
})

const FormSection = styled.div<{ disabled? }>(
  {
    marginBottom: "2em",
    ":last-child": {
      marginBottom: 0
    }
  },
  (props) => ({
    opacity: props.disabled ? 0.5 : 1.0
  })
)

type Props = {
  noFramework?: boolean
  frameworks: Array<FrameworkInfo>
  formSettings: RippleFormSettings
  currentFrameworkId: string

  edit?: boolean
}

class RippleAssessmentForm extends React.Component<Props> {
  frameworkOptions() {
    const frameworks = this.props.frameworks || []
    return frameworks.map((framework) => [framework.id, framework.title])
  }

  currentFramework() {
    const { currentFrameworkId, frameworks } = this.props
    if (currentFrameworkId) return find(frameworks, ["id", currentFrameworkId])
    return null
  }

  outOf() {
    const framework = this.currentFramework()
    if (framework) return framework.outOf
    return null
  }

  showThresholds() {
    const framework = this.currentFramework()
    if (framework) return framework.thresholdsUnlocked
    return false
  }

  render() {
    const { edit = false, formSettings } = this.props

    return (
      <FormContainer>
        {edit ? null : (
          <FormSection>
            <FormSectionHeading>Choose framework</FormSectionHeading>
            <FormHelpText>
              <p>
                The framework defines the criteria used for an assessment.{" "}
                <Anchor href="mailto:frameworks@ripple.com.au">
                  Contact us
                </Anchor>{" "}
                for information regarding custom frameworks.
              </p>
            </FormHelpText>
            <FieldContainer>
              <Field
                name="framework"
                component={StringSelectField}
                floatingLabelText="Framework"
                blankOption="none"
                style={{ marginTop: "-20px" }}
                options={this.frameworkOptions()}
              />
            </FieldContainer>
          </FormSection>
        )}

        {this.showThresholds() && (
          <FormSection>
            <FormSectionHeading>Assign thresholds</FormSectionHeading>
            <FormHelpText>
              <p>
                Select the lower threshold at a point you think ripple
                participants would wish to be notified if a client&apos;s
                overall status fell below this point and action may be required.
              </p>
            </FormHelpText>
            <FieldContainer>
              <Field
                name="lowerThreshold"
                component={NumberSelectField}
                floatingLabelText="Lower"
                blankOption="none"
                options={range(1, this.outOf() || 100)}
              />
            </FieldContainer>
            <FormHelpText>
              <p>
                Select the upper threshold at a point you consider to be the
                minimum goal of the client&apos;s overall status.
              </p>
            </FormHelpText>
            <FieldContainer>
              <Field
                name="upperThreshold"
                component={NumberSelectField}
                floatingLabelText="Upper"
                blankOption="none"
                options={range(1, this.outOf() || 100)}
              />
            </FieldContainer>
          </FormSection>
        )}
        {edit || !formSettings.allowAnonymous ? null : (
          <FormSection>
            <FormSectionHeading>Settings</FormSectionHeading>
            <FormHelpText>
              <p>
                If you make this Ripple anonymous, participants will be able to
                see results, but not who is the author of any particular score
                or comment.
              </p>
            </FormHelpText>
            <FieldContainer>
              <Field
                name="anonymous"
                component={ToggleButtonField}
                label="Anonymous"
                faIcon="user-secret"
              />
            </FieldContainer>
          </FormSection>
        )}
      </FormContainer>
    )
  }
}

export default RippleAssessmentForm
