import React from "react"
import styled from "@emotion/styled"
import windowDimensions from "react-window-dimensions"
import get from "lodash.get"
import debounce from "lodash.debounce"
import colours from "../themes/colours"
import Background from "../themes/Silver"
import { isEnabledByCondition } from "../components/helpers/sdq_group_helpers"
import AssessmentCard from "../components/AssessmentCard"
import SdqPageActions from "../components/SdqPageActions"
import SdqGrouping from "../components/SdqGrouping"
import type { Assessment, Attribute } from "../contexts/assessment/types"

type Option = {
  id: number
  description: string
}

type SdqPageStep = {
  preamble?: string
  attributes: Array<Attribute>
  options: Array<Option>
}

type Props = {
  assessment: Assessment
  mobile: boolean
  step: SdqPageStep
  previousPage?: () => unknown
  nextPage?: () => unknown
  updateRating: (questionId: string, newValue?: number) => unknown
  updateQuestionComment: (
    attributeId: string | null | undefined,
    comment: string
  ) => unknown
  updateData: (
    attributeId: string | null | undefined,
    value: string | boolean
  ) => unknown
  submitAssessment: () => unknown
}

type State = {
  validating: boolean
  questionErrors: Array<string>
  identityErrors: Array<string>
}

const ErrorsWarning = styled.div({
  backgroundColor: colours.badScore,
  opacity: 0.85,
  color: "white",
  lineHeight: "2em",
  textAlign: "center",
  fontWeight: 500,
  padding: 10
})

const HeadingOne = styled.h1({
  fontSize: "1.5em",
  fontWeight: 500,
  marginTop: 0,
  marginBottom: 0,
  lineHeight: "1.5em",
  flexGrow: 1
})

const HeadingContainer = styled.div({
  display: "flex",
  marginBottom: "1em"
})

const SubHeading = styled.div({
  lineHeight: "2.25em",
  whiteSpace: "nowrap"
})

const AgeGroup = styled.span({
  whiteSpace: "nowrap"
})

const RoleIndicator = styled.span({
  fontSize: "1.3em",
  whiteSpace: "nowrap"
})

const CopyrightFooter = styled.footer({
  fontSize: "0.8em",
  lineHeight: "100%",
  color: "#666666",
  textAlign: "center",
  paddingTop: 20
})

const SdqCopyright = () => {
  return <CopyrightFooter>&copy; Robert Goodman, 2015</CopyrightFooter>
}

class SdqPage extends React.Component<Props, State> {
  constructor(props) {
    super(props)
    this.state = {
      validating: false,
      questionErrors: [],
      identityErrors: []
    }
  }

  validateAction = (action, submission = false) => {
    const questionErrors = []
    const identityErrors = []

    this.setState({ validating: true })

    this.addQuestionErrors(questionErrors, this.props.step.attributes)
    if (submission) {
      this.addIdentityErrors(identityErrors, this.props.assessment)
    }

    this.setState({ validating: false, questionErrors, identityErrors }, () => {
      if (questionErrors.length === 0 && identityErrors.length === 0) {
        action()
      }
      window.scrollTo(0, 0)
    })
  }

  addQuestionErrors = (errors, attributes = []) => {
    const assessment = this.props.assessment

    for (const child of attributes) {
      switch (child.type) {
        case "question_set":
          for (const question of child.attributes) {
            const questionAnswered =
              assessment.input && assessment.input.attributes[question.id]

            if (!questionAnswered) {
              errors.push(question.id)
            }
          }
          break
        case "grouping": {
          const isActiveGroup =
            !child.enabledCondition ||
            isEnabledByCondition(child.enabledCondition, assessment)

          if (isActiveGroup) {
            this.addQuestionErrors(errors, child.attributes)
          }
          break
        }
        default:
          break
      }
    }
  }

  addIdentityErrors = (errors, assessment) => {
    const identityConfirmed = get(assessment, "input.data.assessment_identity")

    if (!identityConfirmed) {
      errors.push("assessment_identity_not_confirmed")
    }

    const parentOther =
      get(assessment, "input.data.assessment_relationship") === "Other"

    if (parentOther) {
      errors.push("assessment_relationship_other")
    }
  }

  hasNextPage = () => !!this.props.nextPage

  nextPage = () => {
    const nextPageProp = this.props.nextPage
    if (nextPageProp) {
      this.validateAction(nextPageProp)
    }
  }

  submitAssessment = () => {
    this.validateAction(this.props.submitAssessment, true)
  }

  render() {
    const {
      mobile,
      assessment,
      step,
      previousPage,
      updateRating,
      updateQuestionComment,
      updateData
    } = this.props
    const { validating, questionErrors, identityErrors } = this.state
    const nextPage = this.hasNextPage() && this.nextPage

    return (
      <Background scollableContents>
        {(questionErrors.length > 0 || identityErrors.length > 0) && (
          <ErrorsWarning>Please answer all questions</ErrorsWarning>
        )}
        <AssessmentCard>
          <HeadingContainer>
            <HeadingOne>Strengths and Difficulties Questionnaire</HeadingOne>
            <SubHeading>
              <RoleIndicator>
                {assessment.framework.roleIndicator}
              </RoleIndicator>{" "}
              <AgeGroup>{assessment.framework.subtitle}</AgeGroup>
            </SubHeading>
            {validating && <div>validating!</div>}
          </HeadingContainer>
          <SdqGrouping
            attribute={step as any}
            assessment={assessment}
            questionErrors={questionErrors}
            identityErrors={identityErrors}
            updateRating={updateRating}
            updateQuestionComment={updateQuestionComment}
            updateData={updateData}
            mobile={mobile}
          />
          <SdqPageActions
            previousPage={previousPage}
            nextPage={nextPage}
            submit={this.submitAssessment}
          />
          <SdqCopyright />
        </AssessmentCard>
      </Background>
    )
  }
}

export default windowDimensions({
  take: () => ({ mobile: window.innerWidth <= 600 }),
  debounce: (onResize) => debounce(onResize, 100)
})(SdqPage)
