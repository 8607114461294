import React from "react"
import styled from "@emotion/styled"
import ReportCard from "./ReportCard"
import type { ReportSummary as ReportSummaryType } from "../../state_types"
import colours from "../../themes/colours"
import { scoreColour } from "../../utils/scoreColour"

const CenterVertically = styled.div({
  flex: 1,
  display: "flex",
  flexDirection: "column",
  justifyContent: "flex-start",
  paddingBottom: 20,
  "& > *": {
    marginTop: 10
  }
})

type ReportSummaryCardProps = {
  width: number
  height: number
  report: ReportSummaryType
  onClick: () => {}
}

const HeatmapTable = styled.table({
  padding: "10px 1px 1px 0",
  width: "100%",
  borderSpacing: "1px",
  fontSize: "80%",
  tableLayout: "fixed"
})

const HeatmapRow = styled.tr({})

const Item = styled.td<{ gradientColor; numberOfAttributes: number }>(
  (props) => ({
    backgroundColor: props.gradientColor,
    textAlign: "right",
    width: `${100 / props.numberOfAttributes}%`,
    padding: "0 4px",
    margin: "1px",
    color: colours.darkGrey,
    borderBottom: "1px solid white",
    borderRight: "1px solid white"
  })
)

const randomScore = () => Math.floor(Math.random() * 10 + 1)

const HeatmapReportSummaryCard = ({
  width,
  height,
  report,
  onClick
}: ReportSummaryCardProps) => {
  const numberOfAttributes = 3

  return (
    <ReportCard
      width={width}
      height={height}
      title={report.title}
      subtitle={report.subtitle}
      onClick={onClick}
    >
      <CenterVertically>
        <HeatmapTable>
          <tbody>
            {[1, 2, 3, 4].map((row) => {
              return (
                <HeatmapRow key={`row-${row}`}>
                  {[1, 2, 3].map((cell) => {
                    const colour = scoreColour(randomScore(), [1, 10])
                    return (
                      <Item
                        key={`cell-${row}-${cell}`}
                        gradientColor={colour}
                        numberOfAttributes={numberOfAttributes}
                      >
                        &nbsp;
                      </Item>
                    )
                  })}
                </HeatmapRow>
              )
            })}
          </tbody>
        </HeatmapTable>
      </CenterVertically>
    </ReportCard>
  )
}
export default HeatmapReportSummaryCard
