import React from "react"
import styled from "@emotion/styled"
import map from "lodash.map"
import get from "lodash.get"
import Link from "redux-first-router-link"
import Markdown from "react-markdown"
import colours from "../themes/colours"
import ContentContainer from "./RippleInfoContentContainer"
import { FrameworkInfo, Ripple } from "../state_types"

const Header = styled.div({
  display: "flex",
  justifyContent: "space-between",
  width: "100%",
  color: "#000000",
  fontFamily: "Poppins",
  fontSize: "14px",
  lineHeight: "22px",
  fontWeight: 600
})

const Details = styled.div({
  color: "#000000",
  fontFamily: "Poppins",
  fontSize: "12px",
  lineHeight: "16px"
})

const List = styled.p({
  display: "flex",
  flexDirection: "column"
})

const FrameworkHeader = ({
  title,
  ripple
}: Partial<FrameworkInfo> & { ripple: Ripple }) => {
  const thresholdsUnlocked = get(ripple, "frameworkInfo.thresholdsUnlocked")
  return (
    <Header>
      {title}
      {ripple.canEdit && thresholdsUnlocked ? (
        <Link
          to={{
            type: "EDIT_RIPPLE_ASSESSMENT",
            payload: { rippleUuid: ripple.uuid }
          }}
          style={{
            textDecoration: "none",
            color: colours.blue,
            cursor: "pointer"
          }}
        >
          edit
        </Link>
      ) : null}
    </Header>
  )
}

const FrameworkBody = ({ intro, attributes }: Partial<FrameworkInfo>) => {
  return (
    <Details>
      <Markdown>{intro}</Markdown>

      {attributes && (
        <List>
          {map(attributes, (name) => (
            <span key={name}>{name}</span>
          ))}
        </List>
      )}
    </Details>
  )
}

type Props = {
  frameworkInfo: FrameworkInfo
  ripple: Ripple
}

const FrameworkDetails = ({ frameworkInfo, ripple }: Props) => {
  const { title, intro, attributes } = frameworkInfo

  return (
    <ContentContainer
      headerContent={<FrameworkHeader title={title} ripple={ripple} />}
      bodyContent={<FrameworkBody intro={intro} attributes={attributes} />}
    />
  )
}

export default FrameworkDetails
