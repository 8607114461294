export const cloudName = "ripple-connect"
const unsignedUploadPreset = "upload_avatar"

type OnSuccessFunc = (publicId: string) => void
type OnFailureFunc = (error: string) => void
type OnProgressFunc = (percent: number) => void

export function uploadFile(
  file: Blob,
  onSuccess: OnSuccessFunc,
  onFailure?: OnFailureFunc,
  onProgress?: OnProgressFunc
) {
  const url = `https://api.cloudinary.com/v1_1/${cloudName}/upload`
  const xhr = new XMLHttpRequest()
  const fd = new FormData()
  xhr.open("POST", url, true)
  xhr.setRequestHeader("X-Requested-With", "XMLHttpRequest")

  if (onProgress) {
    xhr.upload.addEventListener("progress", (e: ProgressEvent) => {
      const progress = Math.round((e.loaded * 100.0) / e.total)
      if (onProgress) onProgress(progress)
    })
  }

  xhr.onreadystatechange = (_event) => {
    if (xhr.readyState === 4) {
      if (xhr.status === 200) {
        const response = JSON.parse(xhr.responseText)
        onSuccess(response.public_id)
      } else {
        const response = JSON.parse(xhr.responseText)
        const message = response.error
          ? response.error.message
          : "error uploading image"
        onFailure(message)
      }
    }
  }

  fd.append("upload_preset", unsignedUploadPreset)
  fd.append("tags", window.gon.rails_env) // Optional - add tag for image admin in Cloudinary
  fd.append("file", file)
  xhr.send(fd)
}
