import React from "react"
import styled from "@emotion/styled"
import type { ReactNode } from "react"

const LayoutContainer = styled.div({
  height: "100%",
  width: "100%",
  display: "flex",
  flexDirection: "column",
  flexWrap: "nowrap"
})

const HeaderContainer = styled.div({
  flexShrink: 0,
  flex: "0 0 auto"
})

const Body = styled.div({
  WebkitOverflowScrolling: "touch",
  flex: "1 1 auto",
  position: "relative",
  minHeight: "2em",
  display: "flex",
  flexDirection: "column"
})

type Props = {
  children
  header: ReactNode
}

const LayoutWithHeader = ({ children, header }: Props) => {
  return (
    <LayoutContainer>
      <HeaderContainer>{header}</HeaderContainer>
      <Body>{children}</Body>
    </LayoutContainer>
  )
}

export default LayoutWithHeader
