import React from "react"
import { connect } from "react-redux"
import { faDownload } from "@fortawesome/free-solid-svg-icons"
import withQuery from "with-query"
import ReportFilterState from "./ReportFilterState"
import type { State } from "../../state_types"
import { TallButton } from "../TallButton"
import dbc from "../../utils/dbc"

import { jwtFromState, downloadCSV } from "../../api_helpers"

const buildReportUrl = (reportId, filters) => {
  dbc.requireNotBlank(reportId, "reportId is blank")

  const host = window.location.origin

  return withQuery(`${host}/api/reports/${reportId}`, filters)
}

const mapStateToProps = (state: State) => {
  const filters = new ReportFilterState(state).filters
  const reportId = state.report.reportId
  const downloadable = state.report.data.downloadable || false
  const url = buildReportUrl(reportId, filters)
  const filename = `report-${reportId}.csv`

  const onClick = () => {
    downloadCSV(url, jwtFromState(state), filename)
  }

  return { onClick, downloadable }
}

interface IProps {
  downloadable: boolean
  onClick: () => void
}

const ReportDownloadButton = (props: IProps) => {
  if (props.downloadable) {
    return (
      <TallButton
        style={{ whiteSpace: "nowrap" }}
        label="Download report"
        icon={faDownload}
        onClick={props.onClick}
      />
    )
  }

  return null
}

export default connect(mapStateToProps)(ReportDownloadButton)
