import React from "react"
import styled from "@emotion/styled"
import { reduxForm, Field, InjectedFormProps } from "redux-form"
import { connect } from "react-redux"
import FlatButton from "./FlatButton"
import { fieldsRequired } from "../contexts/shared/validation"
import ToggleButtonField from "./ToggleButtonField"
import TextFieldForReduxForm from "./MuiExtensions/components/TextFieldForReduxForm"
import TextButton from "./TextButton"

function validate(values) {
  const errors = {}
  fieldsRequired(values, errors, "name", "email")
  return errors
}

const AddUserFormContainer = styled.form({
  display: "flex",
  flexDirection: "column",
  alignItems: "center"
})

const FieldsContainer = styled.div({
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  flexWrap: "wrap",
  flexDirection: "row"
})

const FieldContainer = styled.div({
  display: "flex",
  marginRight: 10,
  marginLeft: 10,
  flex: 1,
  minWidth: 120,
  height: "40px"
})

const RolesContainer = styled.div({
  display: "flex",
  alignItems: "space-between",
  marginLeft: 10,
  marginRight: 10,
  flex: 1,
  minWidth: 280,
  paddingTop: 20,
  "@media (max-width: 915px)": {
    paddingTop: 0,
    flexWrap: "wrap",
    minWidth: 140
  }
})

const RoleButton = styled.div({
  marginRight: 10,
  ":last-child": {
    marginRight: 0
  },
  "@media (max-width: 915px)": {
    marginTop: 5,
    marginBottom: 5
  }
})

const Footer = styled.div({
  display: "flex",
  alignItems: "center",
  paddingTop: "20px",
  paddingBottom: "20px"
})

type OnSubmitFunc = () => any

interface IProps {
  handleCancel: () => any
  onSubmit: OnSubmitFunc
}

class AddUserForm extends React.Component<InjectedFormProps<IProps> & IProps> {
  handleCancel = () => {
    this.props.handleCancel()
  }

  render() {
    const { handleSubmit, onSubmit } = this.props
    return (
      <AddUserFormContainer
        onSubmit={handleSubmit(onSubmit)}
        id="add-user-form"
      >
        <FieldsContainer>
          <FieldContainer>
            <Field
              name="name"
              component={TextFieldForReduxForm}
              label="Name"
              fullWidth
            />
          </FieldContainer>
          <FieldContainer>
            <Field
              name="email"
              component={TextFieldForReduxForm}
              label="Email"
              fullWidth
            />
          </FieldContainer>
          <FieldContainer>
            <Field
              name="phone"
              component={TextFieldForReduxForm}
              label="Phone"
              fullWidth
            />
          </FieldContainer>
          <RolesContainer>
            <RoleButton>
              <Field
                name="admin"
                component={ToggleButtonField}
                label="Org Admin"
                faIcon="star"
              />
            </RoleButton>
            <RoleButton>
              <Field
                name="creator"
                component={ToggleButtonField}
                label="Ripple Creator"
              />
            </RoleButton>
          </RolesContainer>
        </FieldsContainer>
        <Footer>
          <TextButton
            style={{ height: "40px" }}
            label="Cancel"
            onClick={this.handleCancel}
            type="button"
          />
          <FlatButton
            style={{ height: "40px" }}
            label="Send Invite"
            type="submit"
          />
        </Footer>
      </AddUserFormContainer>
    )
  }
}

const AddNewUserForm = reduxForm({
  form: "addUser",
  validate
})(AddUserForm)

export interface IAddUserFormState {
  me: {
    data: {
      user: string
    }
    syncing: boolean
  }
}

const mapStateToProps = (state: IAddUserFormState) => {
  const me = state.me
  const user = me.data.user
  const syncing = me.syncing

  return {
    user,
    syncing
  }
}

export default connect<any, IAddUserFormState, IProps>(mapStateToProps)(
  AddNewUserForm
)
