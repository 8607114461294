import React from "react"
import { connect } from "react-redux"
import styled from "@emotion/styled"
import windowDimensions from "react-window-dimensions"
import debounce from "lodash.debounce"
import Theme from "../themes/Silver"
import Header from "../components/Header"
import RippleTable from "../components/RippleTable"
import UserStateDecorator from "../contexts/user/UserStateDecorator"
import LoadablePage from "../components/LoadablePage"
import { largePageWidth, gutter } from "../components/Layout"
import LayoutWithHeader from "../components/LayoutWithHeader"
import { HeadingOne } from "../components/TextStyles"
import Pagination from "../components/shared/Pagination"
import FilterGrid from "../components/archive/FilterGrid"
import { RipplesApiResult } from "../state_types"

const Contents = styled.div({
  flex: 1,
  display: "flex",
  flexDirection: "column",
  maxWidth: largePageWidth
})

const TableContainer = styled.div({
  backgroundColor: "white",
  padding: gutter,
  borderRadius: 3,
  boxShadow: "0 2px 10px 0 rgba(0,0,0,0.1)",
  marginBottom: 50
})

const horizontalPadding = (amount) => ({
  padding: `0 ${amount}`
})

const Container = styled.div<{ mobile: boolean }>(
  {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    flex: 1,
    margin: gutter
  },
  ({ mobile }) => {
    return mobile ? horizontalPadding(0) : horizontalPadding(gutter)
  }
)

const Heading = styled(HeadingOne)()

const useMobileView = (windowWidth: number) => windowWidth < 670

interface IProps {
  windowWidth: number
  archivedRipples: RipplesApiResult
}

const ArchivedRipples = (props: IProps) => {
  const { windowWidth, archivedRipples } = props
  const loaded = !!(archivedRipples && archivedRipples.data)
  const { filters } = archivedRipples || {}
  const { ripples, totalPages } = archivedRipples.data || {}
  const mobile = useMobileView(windowWidth)

  const to = (page) => {
    const query = { ...filters, page }
    return { type: "ARCHIVED_RIPPLES", query }
  }

  const RipplesPagination = () => (
    <Pagination
      currentPage={filters.page}
      pagesToShow={5}
      totalPages={totalPages}
      to={to}
    />
  )

  return (
    <Theme>
      <LayoutWithHeader
        header={
          <div>
            <Header />
          </div>
        }
      >
        <LoadablePage loaded={loaded}>
          {() => (
            <Container mobile={mobile}>
              <Contents>
                <Heading>Archived Ripples</Heading>
                <div>
                  {ripples && <FilterGrid ripples={ripples} />}
                  <TableContainer>
                    <RippleTable ripples={ripples} />
                    <RipplesPagination />
                  </TableContainer>
                </div>
              </Contents>
            </Container>
          )}
        </LoadablePage>
      </LayoutWithHeader>
    </Theme>
  )
}

const ArchivedRipplesWithWidth = windowDimensions({
  take: () => ({ windowWidth: window.innerWidth }),
  debounce: (onResize) => debounce(onResize, 100)
})(ArchivedRipples)

const mapStateToProps = (state) => {
  const userState = new UserStateDecorator(state)

  return {
    archivedRipples: state.archivedRipples,
    user: userState
  }
}

export default connect(mapStateToProps)(ArchivedRipplesWithWidth)
