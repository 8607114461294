import React from "react"
import styled from "@emotion/styled"
import colours from "../themes/colours"
import SdqRadioButton from "./SdqRadioButton"
import ControlledRadioButtonGroup from "./ControlledRadioButtonGroup"
import type { QuestionInput } from "../contexts/assessment/types"

type Props = {
  questionData
  options
  indented: boolean // eslint-disable-line react/no-unused-prop-types
  disabled?: boolean
  updateRating: (questionId: string, newValue?: number) => unknown // eslint-disable-line react/no-unused-prop-types
  mobile: boolean // eslint-disable-line react/no-unused-prop-types
  value: QuestionInput // eslint-disable-line react/no-unused-prop-types
  hasError?: boolean
}

type QuestionTypeProps = Props & {
  handleRadioChange: () => void
  rating: number | null
}

const defaultBools = {
  disabled: false,
  hasError: false
}

const TR = styled.tr({
  fontSize: "0.9em"
})

const QuestionColumn = styled.td<{ indented }>((props) => ({
  textAlign: "left",
  height: 30,
  width: "100%",
  paddingTop: 10,
  paddingBottom: 10,
  paddingLeft: props.indented ? "4em" : 0,
  position: "relative",
  borderBottom: `1px solid ${colours.boldLineOnSilver}`
}))

const AnswerColumn = styled.td({
  textAlign: "center",
  width: "1px",
  borderBottom: `1px solid ${colours.boldLineOnSilver}`
})

const Container = styled.div({
  display: "flex",
  flexDirection: "column",
  position: "relative",
  marginBottom: 15
})

const AnswerContainer = styled.div({
  display: "flex",
  flexDirection: "column",
  paddingTop: 15,
  borderBottom: `2px solid ${colours.boldLineOnSilver}`
})

const QuestionText = styled.div({
  fontSize: "0.9em"
})

const Bookmark = styled.div({
  backgroundColor: colours.badScore,
  opacity: 0.8,
  width: 5,
  position: "absolute",
  height: "90%",
  top: 5,
  left: -20
})

const OptionRow = styled.div({
  display: "flex",
  paddingBottom: 15
})

const OptionText = styled.span({
  paddingLeft: 20,
  fontSize: "0.8em"
})

const MobileQuestion = ({
  questionData,
  options,
  disabled,
  handleRadioChange,
  hasError,
  rating
}: QuestionTypeProps) => {
  const renderButton = ([currentValue, title], checked, handleChange) => (
    <OptionRow key={currentValue}>
      <SdqRadioButton
        name={questionData.id}
        value={currentValue}
        checked={checked}
        onChange={handleChange}
        disabled={disabled}
      />
      <OptionText>{title}</OptionText>
    </OptionRow>
  )

  return (
    <Container>
      {hasError && <Bookmark />}
      <QuestionText>{questionData.question}</QuestionText>
      <AnswerContainer>
        <ControlledRadioButtonGroup
          options={options}
          renderButton={renderButton}
          onChange={handleRadioChange}
          value={rating}
        />
      </AnswerContainer>
    </Container>
  )
}

const DesktopQuestion = ({
  questionData,
  options,
  indented,
  disabled,
  handleRadioChange,
  hasError,
  rating
}: QuestionTypeProps) => {
  const { question } = questionData

  const renderButton = ([currentValue, _title], checked, handleChange) => (
    <AnswerColumn key={currentValue}>
      <SdqRadioButton
        name={questionData.id}
        value={currentValue}
        checked={checked}
        onChange={handleChange}
        disabled={disabled}
      />
    </AnswerColumn>
  )

  return (
    <TR>
      <QuestionColumn key="question" indented={indented}>
        {hasError && <Bookmark />}
        {question}
      </QuestionColumn>
      <ControlledRadioButtonGroup
        options={options}
        renderButton={renderButton}
        onChange={handleRadioChange}
        value={rating}
      />
    </TR>
  )
}

const QuestionTableRow = ({ ...props }: Props) => {
  const { mobile, updateRating, questionData, value } = props

  const handleRadioChange = (newValue?: number) => {
    updateRating(questionData.id, newValue)
  }

  const rating: number | null = value ? value.rating : null

  return mobile ? (
    <MobileQuestion
      {...props}
      handleRadioChange={handleRadioChange}
      rating={rating}
    />
  ) : (
    <DesktopQuestion
      {...props}
      handleRadioChange={handleRadioChange}
      rating={rating}
    />
  )
}

MobileQuestion.defaultProps = defaultBools
DesktopQuestion.defaultProps = defaultBools
QuestionTableRow.defaultProps = defaultBools

export default QuestionTableRow
