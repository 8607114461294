import filter from "lodash.filter"
import find from "lodash.find"
import Wizard from "../shared/Wizard"
import {
  fieldRequired,
  fieldsRequired,
  isEmptyRecord,
  fieldMustBeGreaterThanOtherField
} from "../shared/validation"

export const steps = [
  { id: "upload-file", title: "Upload file" },
  { id: "assessment", title: "Assessment details" },
  { id: "schedule", title: "Schedule" }
]

export const initialStepID = steps[0].id

export const isEmptyMember = (member) =>
  isEmptyRecord(member, { exceptFields: ["roleName"] })
export const isNotEmptyMember = (member) => !isEmptyMember(member)

export const removeEmptyMembers = (members) =>
  members && filter(members, isNotEmptyMember)

const validators = {
  "upload-file": (values) => {
    const errors: { csvFile? } = {}

    fieldsRequired(values, errors, "organisation")

    if (window.gon.rails_env !== "test") {
      fieldsRequired(values, errors, "csvFile")
    }

    if (values.csvFile && values.csvFile.error) {
      errors.csvFile = values.csvFile.message
    }

    return errors
  },
  assessment: (values, { frameworks }) => {
    const errors = {}
    fieldsRequired(values, errors, "framework")
    if (values.framework && frameworks) {
      const framework = find(frameworks, { id: values.framework })
      if (framework.thresholdsUnlocked) {
        fieldsRequired(values, errors, "lowerThreshold", "upperThreshold")
        fieldMustBeGreaterThanOtherField(
          values,
          errors,
          "upperThreshold",
          "lowerThreshold",
          2
        )
      }
    }

    return errors
  },
  schedule: (values) => {
    const errors = {}
    fieldsRequired(values, errors, "periodType", "period", "daysOpen")
    fieldMustBeGreaterThanOtherField(values, errors, "endHour", "startHour")
    if (window.gon.rails_env !== "test")
      fieldRequired(values, errors, "startOn")
    return errors
  }
}
export const stepValidator = (stepID) => validators[stepID]

export function prepareDataForSubmit(data) {
  return { organisation: data.organisation, data }
}

const newImportRipplesWizard = new Wizard(steps)

export default newImportRipplesWizard
