import {
  createStore,
  applyMiddleware,
  compose,
  combineReducers,
  Reducer,
  AnyAction
} from "redux"
import type { StoreEnhancer } from "redux"
import { connectRoutes } from "redux-first-router"
import {
  reducer as flashReducer,
  middleware as flashMiddleware
} from "redux-flash"
import thunkMiddleware from "redux-thunk"
import { persistStore, persistReducer, PersistState } from "redux-persist"
import storage from "redux-persist/es/storage"
import queryString from "query-string"
import { reducer as formReducer } from "redux-form"
import assessmentReducer from "./contexts/assessment/reducers"
import userReducer, { userGlobalReducer } from "./contexts/user/reducers"
import rippleFormReducer from "./contexts/ripple_form/reducers"
import importRipplesFormReducer from "./contexts/import_ripples_form/reducers"
import myRipplesReducer from "./contexts/my_ripples/reducers"
import archivedRipplesReducer from "./contexts/archived_ripples/reducers"
import rippleDetailsReducer from "./contexts/ripple_details/reducers"
import userInviteReducer from "./contexts/user_invite/reducers"
import orgReducer from "./contexts/org/reducers"
import bulkActionsReducer from "./contexts/bulk_actions/reducers"
import reportReducer from "./contexts/reports/reducers"
import qrCodesReducer from "./contexts/qr/reducers"
import memoryReducer from "./contexts/memory/reducers"
import routes from "./routes"
import type { State, Action } from "./state_types"

const rootPersistConfig = {
  key: "root",
  storage,
  whitelist: ["me"]
}

const serializer = { ...queryString }

const routing = connectRoutes(routes, {
  initialDispatch: false,
  querySerializer: serializer
})

const rootReducer = combineReducers({
  assessment: assessmentReducer,
  userInvite: userInviteReducer,
  location: routing.reducer,
  me: userReducer,
  memory: memoryReducer,
  rippleForm: rippleFormReducer,
  importRipplesForm: importRipplesFormReducer,
  form: formReducer,
  myRipples: myRipplesReducer,
  archivedRipples: archivedRipplesReducer,
  rippleDetails: rippleDetailsReducer,
  org: orgReducer,
  flash: flashReducer,
  report: reportReducer,
  bulkActions: bulkActionsReducer,
  qrCodes: qrCodesReducer
})

const reducer = persistReducer(rootPersistConfig, rootReducer) as Reducer<
  State & { _persist?: PersistState },
  AnyAction
>

function reducerWithGlobals(state: State, action: Action): State {
  let newState = reducer(state, action)
  newState = userGlobalReducer(newState, action)
  return newState
}

/* eslint-disable no-underscore-dangle */
const composeEnhancers =
  (window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ as typeof compose) || compose
/* eslint-enable */

const enhancer: StoreEnhancer<State, Action> = composeEnhancers(
  routing.enhancer,
  applyMiddleware(thunkMiddleware, routing.middleware, flashMiddleware())
)

const createRippleStore = () => {
  const store = createStore(reducerWithGlobals, enhancer)
  const persistor = persistStore(store, {}, () => {
    routing.initialDispatch()
  })
  return { store, persistor }
}

export default createRippleStore
