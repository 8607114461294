import React from "react"
import { connect } from "react-redux"
import styled from "@emotion/styled"
import { reduxForm, formValueSelector, InjectedFormProps } from "redux-form"
import RippleScheduleForm from "../components/RippleScheduleForm"
import { stepValidator } from "../contexts/ripple_form/wizard"
import { Schedule } from "../state_types"

const ScheduleContainer = styled.div({
  display: "flex",
  flexDirection: "column",
  flexGrow: 1
})

interface IProps extends InjectedFormProps {
  periodType: string
  startOn: string
}

const handleSubmit = () => {}
const NewSchedule = ({ periodType, startOn }: IProps) => (
  <ScheduleContainer>
    <form onSubmit={handleSubmit} id="ripple-details-form">
      <RippleScheduleForm startOn={startOn} periodType={periodType} />
    </form>
  </ScheduleContainer>
)

const NewScheduleForm = reduxForm({
  form: "ripple",
  destroyOnUnmount: false,
  forceUnregisterOnUnmount: true,
  validate: stepValidator("schedule")
})(NewSchedule)

const selector = formValueSelector("ripple")

const mapStateToProps = (state: Partial<Schedule>) => {
  return {
    periodType: selector(state, "periodType"),
    startOn: selector(state, "startOn")
  }
}

export default connect(mapStateToProps)(NewScheduleForm)
