import React from "react"
import withQuery from "with-query"
import styled from "@emotion/styled"
import type { ScoreReport as ScoreReportType } from "../../state_types"
import AvatarPreview from "../AvatarPreview"
import ScoreReport from "./ScoreReport"

const TitleParts = styled.div({
  display: "flex",
  flexDirection: "row",
  marginBottom: 4
})

const AvatarContainer = styled.div({
  flexShrink: 0
})

const UserName = styled.div({
  fontSize: "12px",
  lineHeight: "16px",
  color: "black",
  marginLeft: 8
})

const titleGenerator = ({ user }) => (
  <TitleParts>
    <AvatarContainer>
      <AvatarPreview size={30} value={user.avatar} name={user.name} />
    </AvatarContainer>
    <UserName>{user.name}</UserName>
  </TitleParts>
)

type RiskReportParams = ScoreReportType & {
  title: string
  reportId: string
  reportUrl: string
  returnTo?: {
    to: string
    name: string
  }
}

const RiskReport = (params: RiskReportParams) => {
  const { reportUrl, title } = params

  const linkToGenerator = (row) =>
    withQuery(`/ripple/${row.rippleUuid}`, {
      returnTo: {
        name: `Reports: ${title}`,
        url: reportUrl
      }
    })

  return (
    <ScoreReport
      {...params}
      customTitle={titleGenerator}
      linkTo={linkToGenerator}
    />
  )
}

export default RiskReport
