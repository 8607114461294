import React from "react"
import { connect } from "react-redux"
import styled from "@emotion/styled"
import get from "lodash.get"
import filter from "lodash.filter"
import includes from "lodash.includes"
import { reduxForm, formValueSelector, InjectedFormProps } from "redux-form"
import RippleDetailsForm from "../components/RippleDetailsForm"
import { stepValidator } from "../contexts/ripple_form/wizard"
import type { OrgMembership } from "../state_types"
import { todayIsoDate } from "../components/helpers/date"

const RippleDetailsContainer = styled.div({
  display: "flex",
  flexDirection: "column",
  flexGrow: 1
})

interface IProps extends InjectedFormProps {
  orgs: Array<OrgMembership>
  currentOrg?: string
  defaultValues: {
    clientName?: string
    groupNames: []
  }
  clientSelected: boolean
}

const handleSubmit = () => {}
const NewRippleDetails = ({
  orgs,
  defaultValues,
  currentOrg,
  clientSelected
}: IProps) => (
  <RippleDetailsContainer>
    <form onSubmit={handleSubmit} id="ripple-details-form">
      <RippleDetailsForm
        orgs={orgs}
        defaultValues={defaultValues}
        currentOrg={currentOrg}
        clientSelected={clientSelected}
      />
    </form>
  </RippleDetailsContainer>
)

const NewRippleDetailsForm = reduxForm({
  form: "ripple",
  enableReinitialize: true,
  keepDirtyOnReinitialize: true,
  destroyOnUnmount: false,
  forceUnregisterOnUnmount: true,
  validate: stepValidator("ripple-details")
})(NewRippleDetails)

const selector = formValueSelector("ripple")

const mapStateToProps = (state) => {
  let orgs = get(state, "me.data.user.orgs") || []
  orgs = filter(orgs, (org) => includes(org.roles, "ripple_creator"))

  const initialValues = get(state, "rippleForm.initialValues")
  const currentOrg = selector(state, "organisation") || null
  const clientSelected = !!selector(state, "clientId")
  const result = {
    orgs,
    currentOrg,
    clientSelected,
    initialValues: {
      ...initialValues,
      members: [{ roleName: "viewer" }],
      startOn: todayIsoDate(),
      periodType: "week",
      period: 1,
      daysOpen: 1
    },
    defaultValues: {
      clientName: selector(state, "clientName")
    }
  }

  result.initialValues.organisation =
    initialValues.organisation || (orgs[0] && orgs[0].slug)

  return result
}

export default connect(mapStateToProps)(NewRippleDetailsForm)
