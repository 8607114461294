import immutable from "object-path-immutable"
import get from "lodash.get"
import isequal from "lodash.isequal"
import findIndex from "lodash.findindex"
import find from "lodash.find"
import { applyReducerHash } from "../../redux_helpers"
import type { OrgUsersApiResult, Action } from "../../state_types"

const userEmail = (orgUser) => get(orgUser, "user.email")

function emailMatchesUser(targetOrgUser) {
  return (orgUser) => {
    return userEmail(orgUser) === userEmail(targetOrgUser)
  }
}

export const standardizeFilters = (filters) => {
  const f = filters || {}

  return {
    page: (f.page && f.page.toString()) || "1",
    organisation: f.organisation || null,
    searchTerm: f.searchTerm || "",
    role: f.role || ""
  }
}

const reducers = {
  ORG: (state, action: Action) => {
    const filters = action.query || action.meta.query || {}

    return {
      ...state,
      slug: action.payload.slug,
      filters: standardizeFilters(filters)
    }
  },
  RECEIVED_ORG: (
    state: OrgUsersApiResult | null | undefined,
    action: Action
  ) => {
    return { ...state, ...action.payload }
  },
  RECEIVED_ORG_FRAMEWORKS: (state, action: Action) => {
    return { ...state, frameworks: action.payload }
  },
  RECEIVED_ORG_USERS: (
    state: OrgUsersApiResult | null | undefined,
    action: Action
  ) => {
    const dataFilters = standardizeFilters(action.payload.filters)
    const stateFilters = standardizeFilters(state && state.filters)

    if (isequal(dataFilters, stateFilters)) {
      return { ...state, ...action.payload }
    }

    return state
  },
  SUBMITTING_ORG_ARCHIVE_RIPPLES_FORM: (state) => {
    return { ...state, syncing: "sending" }
  },
  UPDATED_USER: (
    state: OrgUsersApiResult | null | undefined,
    action: Action
  ) => {
    const { id, email, name } = action.payload

    const existingUsers = get(state, "data.orgUsers")
    const existingUserIndex = findIndex(existingUsers, ["user.id", id])

    const newState = immutable.set(
      state,
      ["data", "orgUsers", existingUserIndex, "user", "email"],
      email
    )
    return immutable.set(
      newState,
      ["data", "orgUsers", existingUserIndex, "user", "name"],
      name
    )
  },
  UPDATING_ORG_ROLE: (
    state: OrgUsersApiResult | null | undefined,
    action: Action
  ) => {
    const email = action.payload.email
    const updatedRoleName = action.payload.roleName
    const keepRole = action.payload.roleValue

    const existingUsers = get(state, "data.orgUsers")
    const existingUser = find(existingUsers, ["user.email", email])
    const existingUserIndex = findIndex(existingUsers, ["user.email", email])
    let newRoles = existingUser.orgRoles

    if (!keepRole) {
      newRoles = newRoles.filter((role) => role !== updatedRoleName)
    }

    if (keepRole) {
      if (newRoles.indexOf(updatedRoleName) === -1) {
        newRoles = newRoles.concat(updatedRoleName)
      }
    }

    return immutable.set(
      state,
      ["data", "orgUsers", existingUserIndex, "orgRoles"],
      newRoles
    )
  },
  UPDATED_ORG_ROLE: (
    state: OrgUsersApiResult | null | undefined,
    action: Action
  ) => {
    const { email, roles } = action.payload

    const existingUsers = get(state, "data.orgUsers")
    const existingUserIndex = findIndex(existingUsers, ["user.email", email])

    return immutable.set(
      state,
      ["data", "orgUsers", existingUserIndex, "orgRoles"],
      roles
    )
  },
  UPDATED_USER_TAGS: (
    state: OrgUsersApiResult | null | undefined,
    action: Action
  ) => {
    const { email, tags } = action.payload

    const existingUsers = get(state, "data.orgUsers")
    const existingUserIndex = findIndex(existingUsers, ["user.email", email])

    return immutable.set(
      state,
      ["data", "orgUsers", existingUserIndex, "user", "tags"],
      tags
    )
  },
  DELETING_ORG_USER: (
    state: OrgUsersApiResult | null | undefined,
    action: Action
  ) => {
    const email = action.payload.email
    const existingUsers = get(state, "data.orgUsers")
    const existingUserIndex = findIndex(existingUsers, ["user.email", email])

    return immutable.set(
      state,
      ["data", "orgUsers", existingUserIndex, "user", "syncing"],
      "sending"
    )
  },
  REMOVED_ORG_USER: (
    state: OrgUsersApiResult | null | undefined,
    action: Action
  ) => {
    const email = action.payload.email
    const existingUsers = get(state, "data.orgUsers")
    const existingUserIndex = findIndex(existingUsers, ["user.email", email])
    const updatedState = immutable.del(state, [
      "data",
      "orgUsers",
      existingUserIndex
    ])
    return { ...updatedState }
  },
  SUBMITTED_ADD_USER_FORM: (
    state: OrgUsersApiResult | null | undefined,
    action: Action
  ) => {
    const payloadUser = action.payload.orgUser
    const existingUsers = get(state, "data.orgUsers")
    const existingUserIndex = findIndex(
      existingUsers,
      emailMatchesUser(payloadUser)
    )
    let result = state

    if (existingUserIndex >= 0) {
      result = immutable.del(result, ["data", "orgUsers", existingUserIndex])
    }

    return immutable.insert(result, ["data", "orgUsers"], payloadUser, 0)
  }
}

export default function orgReducer(
  state: OrgUsersApiResult = null,
  action: Action
): OrgUsersApiResult | null | undefined {
  return applyReducerHash(reducers, state, action)
}
