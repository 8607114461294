import filter from "lodash.filter"
import find from "lodash.find"
import flatMap from "lodash.flatmap"
import includes from "lodash.includes"
import immutable from "object-path-immutable"
import Wizard from "../shared/Wizard"
import {
  validateArray,
  fieldRequired,
  fieldsRequired,
  isEmptyRecord,
  fieldMustBeGreaterThanOtherField
} from "../shared/validation"

export const steps = [
  { id: "ripple-details", title: "Ripple details" },
  { id: "assessment", title: "Assessment details" },
  { id: "schedule", title: "Schedule" },
  { id: "participants", title: "Invite participants" }
]

export const initialStepID = steps[0].id

export const isEmptyMember = (member) =>
  isEmptyRecord(member, { exceptFields: ["roleName"] })
export const isNotEmptyMember = (member) => !isEmptyMember(member)

export const removeEmptyMembers = (members) =>
  members && filter(members, isNotEmptyMember)

const validateMember = (member) => {
  const errors = {}
  if (!isEmptyMember(member)) {
    fieldsRequired(member, errors, "name", "email")
  }
  return errors
}

const validators = {
  "ripple-details": (values) => {
    const errors: { clientAvatar? } = {}

    if (!values.clientId) {
      fieldsRequired(
        values,
        errors,
        "clientName",
        "rippleName",
        "clientExternalRef",
        "gender"
      )

      if (values.clientAvatar && values.clientAvatar.error) {
        errors.clientAvatar = values.clientAvatar.message
      }

      if (window.gon.rails_env !== "test")
        fieldRequired(values, errors, "dateOfBirth")
    }

    return errors
  },
  assessment: (values, { frameworks }) => {
    const errors = {}
    fieldsRequired(values, errors, "framework")
    if (values.framework && frameworks) {
      const framework = find(frameworks, { id: values.framework })
      if (framework.thresholdsUnlocked) {
        fieldsRequired(values, errors, "lowerThreshold", "upperThreshold")
        fieldMustBeGreaterThanOtherField(
          values,
          errors,
          "upperThreshold",
          "lowerThreshold",
          2
        )
      }
    }

    return errors
  },
  schedule: (values) => {
    const errors = {}
    fieldsRequired(values, errors, "periodType", "period", "daysOpen")
    fieldMustBeGreaterThanOtherField(values, errors, "endHour", "startHour")
    if (window.gon.rails_env !== "test")
      fieldRequired(values, errors, "startOn")
    return errors
  },
  participants: (values, options: { checkAdmins?: boolean } = {}) => {
    const errors: {
      members?: Array<any>
      _error?: string
    } = {}
    const members = values.members || []

    const memberErrors = validateArray(members, validateMember)
    if (memberErrors) errors.members = memberErrors

    if (options.checkAdmins) {
      const roles = flatMap(values.members || [], "roleNames")
      const hasAdmin = includes(roles, "admin")
      if (!hasAdmin) {
        errors._error = "You must always have at least one Admin."
      }
    }
    return errors
  }
}
export const stepValidator = (stepID) => validators[stepID]

export function prepareDataForSubmit(data) {
  let result = data
  if (data.clientAvatar && typeof data.clientAvatar !== "string") {
    result = immutable.del(result, "clientAvatar")
  }
  return result
}

const newRippleWizard = new Wizard(steps)

export default newRippleWizard
