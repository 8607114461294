import React from "react"
import styled from "@emotion/styled"
import type { ReactNode } from "react"

const LayoutContainer = styled.div({
  // height: '100%',
  // width: '100%',
  display: "flex",
  flex: "1 1 auto",
  flexDirection: "column",
  flexWrap: "nowrap"
})

const Body = styled.div({
  overflow: "auto",
  flex: "1 1 auto",
  minHeight: "2em",
  display: "flex",
  flexDirection: "column"
})

const Footer = styled.div({
  flex: "0 0 auto",
  backgroundColor: "white"
})

type Props = {
  children
  footer: ReactNode
}

const FixedBottomLayout = ({ children, footer }: Props) => {
  return (
    <LayoutContainer>
      <Body>{children}</Body>
      <Footer>{footer}</Footer>
    </LayoutContainer>
  )
}

export default FixedBottomLayout
