/* eslint-disable no-param-reassign */
import find from "lodash.find"
import isEmpty from "lodash.isempty"
import omit from "lodash.omit"
import getValues from "lodash.values"
import reduce from "lodash.reduce"

const arrayHasErrors = (errorsArray) =>
  !isEmpty(errorsArray) && find(errorsArray, (value) => !!value)

type ValidatorFunc = (data) => any

export const validateArray = (
  valuesArray: Array<any>,
  itemValidator: ValidatorFunc
) => {
  const errorsArray: Array<any> = valuesArray.map((value) => {
    const itemErrors = itemValidator(value)
    return isEmpty(itemErrors) ? null : itemErrors
  })
  return arrayHasErrors(errorsArray) ? errorsArray : null
}

export const fieldRequired = (values, errors, key: string) => {
  if (!values[key]) errors[key] = "Required"
}

export const fieldsRequired = (values, errors, ...keys: Array<string>) => {
  keys.forEach((key) => fieldRequired(values, errors, key))
}

export const fieldsAnyRequired = (values, errors, ...keys: Array<string>) => {
  const result = reduce(keys, (acc, key) => acc || !isEmpty(values[key]), false)

  if (!result) {
    keys.forEach((key) => {
      errors[key] = "Required"
    })
  }
}

export const usernameFormat = (values, errors, key: string) => {
  const usernameRegexp = /^[a-z0-9_-]+$/
  if (values[key] && !values[key].match(usernameRegexp))
    errors[key] = "Invalid format"
}

export const minLengthRequired = (
  values,
  errors,
  length: number,
  key: string
) => {
  const value = values[key]
  if (value && value.length < length) {
    errors[key] = `Must be at least ${length} characters`
  }
}

export const fieldMustBeGreaterThanOtherField = (
  values,
  errors,
  greaterField: string,
  lesserField: string,
  diff = 0
) => {
  const greater = values[greaterField]
  const lesser = values[lesserField]

  if (greater && lesser) {
    const minimum = lesser + diff
    if (greater <= minimum) {
      errors[greaterField] = `Minimum ${minimum}`
    }
  }
}

export const fieldMustBeSameAsOtherField = (
  values,
  errors,
  targetField: string,
  otherField: string
) => {
  const target = values[targetField]
  const other = values[otherField]

  if (target && target !== other) {
    errors[targetField] = "Does not match"
  }
}

type EmptyRecordOptions = {
  exceptFields?: Array<string>
}

export const isEmptyRecord = (
  record,
  { exceptFields }: EmptyRecordOptions = {}
) => {
  const toCheck = exceptFields ? omit(record, exceptFields) : record
  const valuesToCheck = getValues(toCheck)
  const truthyValue = find(valuesToCheck)
  return !truthyValue
}

/* eslint-enable */
