import ReactGA from "react-ga4"
import { GaOptions } from "react-ga4/types/ga4"

interface RippleGAInterface {
  boot: () => void
  initialize: (
    string,
    options?: {
      nonce?: string
      testMode?: boolean
      gtagUrl?: string
      gaOptions?: GaOptions | any
      gtagOptions?: any
    }
  ) => void
  RUNTIME_ENVIRONMENT: string
  setRuntimeEnvironment: (envValue) => void
  GOOGLE_ANALYTICS_ID: string
  setGoogleAnalyticsId: (envValue) => void
  event: (name: string, organisationSlug: string, payload?: any) => void
  config: (payload) => void
  set: (fieldsObject) => void
  gtag: (args) => void
  configUserProperties: (payload) => void
  configUserOrganisations: (user) => void
  configUserOrganisation: (organisationSlug) => void
  clearUserProperties: () => void
  _getOrganisationsFromUser: (user) => string[]
  _getAvailableEvents: (organisationSlug) => string[]
  assessmentStart: (assessment) => void
  assessmentFinish: (assessment) => void
  heatmapStart: (organisationSlug, payload) => void
  heatmapExit: (organisationSlug, payload) => void
}

declare global {
  interface Window {
    RippleGA: RippleGAInterface
    GOOGLE_ANALYTICS_ID: string
  }
}

const RippleGA: RippleGAInterface = {
  RUNTIME_ENVIRONMENT: "",
  GOOGLE_ANALYTICS_ID: "",
  boot: () => {
    RippleGA.setRuntimeEnvironment(process.env.RAILS_ENV)
    RippleGA.setGoogleAnalyticsId(window.GOOGLE_ANALYTICS_ID)

    if (
      RippleGA.RUNTIME_ENVIRONMENT !== "test" &&
      RippleGA.GOOGLE_ANALYTICS_ID
    ) {
      RippleGA.initialize(RippleGA.GOOGLE_ANALYTICS_ID)
    }
  },
  initialize: (TRACKING_ID: string, options = {}) => {
    ReactGA.initialize(TRACKING_ID, options)
  },
  setRuntimeEnvironment: (envValue) => {
    RippleGA.RUNTIME_ENVIRONMENT = envValue
  },
  setGoogleAnalyticsId: (envValue) => {
    RippleGA.GOOGLE_ANALYTICS_ID = envValue
  },
  _getOrganisationsFromUser: (user) => {
    return user.orgs.map((x) => {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-return
      return x.slug
    }) as string[]
  },
  _getAvailableEvents: (organisationSlug) => {
    const allowedOrgs = [
      "university_of_south_australia",
      "test",
      "acme_demo",
      "acme_two"
    ]
    if (RippleGA.RUNTIME_ENVIRONMENT === "development") {
      allowedOrgs.push("dummy-org")
    }

    if (!allowedOrgs.includes(organisationSlug)) {
      return []
    }

    return [
      "assessment_start",
      "assessment_finish",
      "heatmap_start",
      "heatmap_exit"
    ]
  },
  configUserOrganisations: (user) => {
    const organisations = RippleGA._getOrganisationsFromUser(user)
    const orgsPayload = Object.fromEntries(
      organisations.map((o, i) => [`org_slug_${i}`, o])
    )

    RippleGA.configUserProperties(orgsPayload)
  },
  configUserOrganisation: (organisationSlug) => {
    RippleGA.configUserProperties({ organisation: organisationSlug })
  },
  configUserProperties: (payload) => {
    RippleGA.set({
      user_properties: payload
    })
  },
  clearUserProperties: () => {
    RippleGA.set({ user_properties: {} })
  },
  config: (payload) => {
    ReactGA.gtag("config", RippleGA.GOOGLE_ANALYTICS_ID, payload)
  },
  set: (fieldsObject) => {
    ReactGA.set(fieldsObject)
  },
  gtag: (args) => {
    ReactGA.gtag(args)
  },
  event: (name, organisationSlug, payload?) => {
    if (!RippleGA._getAvailableEvents(organisationSlug).includes(name)) {
      return
    }
    ReactGA.gtag("event", name, payload)
  },
  // Custom Events
  assessmentStart: (assessment) => {
    RippleGA.event("assessment_start", assessment.ripple.organisationSlug, {
      organisation: assessment.ripple.organisationSlug
    })
  },
  assessmentFinish: (assessment) => {
    RippleGA.event("assessment_finish", assessment.ripple.organisationSlug, {
      organisation: assessment.ripple.organisationSlug
    })
  },
  heatmapStart: (organisationSlug, payload) => {
    RippleGA.event("heatmap_start", organisationSlug, payload)
  },
  heatmapExit: (organisationSlug, payload) => {
    RippleGA.event("heatmap_exit", organisationSlug, payload)
  }
}

window.RippleGA = RippleGA

export default RippleGA
