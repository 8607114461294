import React from "react"
import { ThemeProvider } from "emotion-theming"
import ColouredBackground from "../components/ColouredBackground"

const theme = {}

type Props = {
  children
}

const Background = ({ children }: Props) => {
  return (
    <ThemeProvider theme={theme}>
      <ColouredBackground colour="white">{children}</ColouredBackground>
    </ThemeProvider>
  )
}

export default Background
