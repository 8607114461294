import React from "react"
import styled from "@emotion/styled"
import ToggleButtonGroup from "./ToggleButtonGroup"
import { FunctionLink } from "./Link"
import colours from "../themes/colours"

type Props = {
  input: {
    value: Array<number>
    onChange: (dayIndeces: Array<number>) => any
    name: string
  }
  primaryColour: string
  floatingLabelText: string
}

const Container = styled.div({
  paddingTop: 20
})

const Label = styled.label({
  color: "rgb(108, 114, 124)"
})

const InputContainer = styled.div({
  marginTop: 8
})

const LinksContainer = styled.div({
  marginTop: 8,
  display: "flex",
  flexDirection: "row",
  justifyContent: "center"
})

const SeperatedLink = styled.span({
  borderRight: `1px solid ${colours.boldLineOnSilver}`,
  marginRight: 10,
  paddingRight: 10,
  "&:last-child": {
    borderRight: 0,
    marginRight: 0,
    paddingRight: 0
  }
})

const weekdayNames = ["Sun", "Mon", "Tue", "Wed", "Thur", "Fri", "Sat"]
const dayOrder = [1, 2, 3, 4, 5, 6, 0]

const dayNumbersFromNames = (names: Array<string>): Array<number> =>
  names.map((name: string) => weekdayNames.indexOf(name))

const namesFromDayNumbers = (dayNumbers: Array<number>): Array<string> =>
  dayNumbers.map((number: number) => weekdayNames[number])

const allDays = dayOrder
const weekdays = allDays.slice(0, 5)

const ToggleWeekdaysField = ({ input, floatingLabelText, ...rest }: Props) => {
  const { onChange, value, ...inputRest } = input
  return (
    <Container>
      <Label htmlFor={input.name}>{floatingLabelText}</Label>
      <div>
        <InputContainer>
          <ToggleButtonGroup
            {...inputRest}
            {...rest}
            style={{
              fontSize: "16px",
              width: "3em",
              minWidth: 0,
              padding: "0px 0px"
            }}
            value={namesFromDayNumbers(value || [])}
            buttonNames={namesFromDayNumbers(dayOrder)}
            multiSelect
            onChange={(names) => onChange(dayNumbersFromNames(names))}
          />
        </InputContainer>
        <LinksContainer>
          <SeperatedLink>
            <FunctionLink onClick={() => onChange(allDays)}>all</FunctionLink>
          </SeperatedLink>
          <SeperatedLink>
            <FunctionLink onClick={() => onChange([])}>none</FunctionLink>
          </SeperatedLink>
          <SeperatedLink>
            <FunctionLink onClick={() => onChange(weekdays)}>
              weekdays
            </FunctionLink>
          </SeperatedLink>
        </LinksContainer>
      </div>
    </Container>
  )
}

export default ToggleWeekdaysField
