import isNil from "lodash.isnil"
import Rainbow from "rainbowvis.js"
import colours from "../themes/colours"

// Re-written to avoid a depdency cycle. Should be in a generic util file/class
function clampAboveZero(value: number) {
  return Math.max(0, value)
}

export const scoreColour = (score: number, thresholds: Array<number>) => {
  if (isNil(score)) {
    return "rgba(161, 163, 166, .2)"
  }

  const clampedThresholds = thresholds.map((value) => clampAboveZero(value))
  const minThreshold =
    clampedThresholds[0] > clampedThresholds[1] ? 0 : clampedThresholds[0]

  if (clampedThresholds[0] === clampedThresholds[1]) {
    return colours.badScore
  }

  const rainbow = new Rainbow()
  rainbow.setSpectrum(colours.badScore, colours.goodScore)
  rainbow.setNumberRange(minThreshold, clampedThresholds[1])
  return `#${rainbow.colourAt(score)}`
}
