import React from "react"
import styled from "@emotion/styled"
import SelectField from "material-ui/SelectField"
import MenuItem from "material-ui/MenuItem"
import { TextField } from "@material-ui/core"

const Container = styled.div({
  paddingTop: "20px",
  paddingBottom: "20px",
  fontSize: "0.9em",
  display: "flex"
})

const TextContainer = styled.div({
  paddingLeft: "20px",
  marginTop: "16px"
})

type Props = {
  onChange: (value: string) => any
  hasError?: boolean
}

type State = {
  value: string
  text: string
}

const initialState = {
  value: "Mother",
  text: ""
}

class SdqAssessmentRelationshipInput extends React.Component<Props, State> {
  state = initialState

  componentDidUpdate(_prevProps: Props, prevState: State) {
    const oldBest = this.bestValue(prevState)
    const newBest = this.bestValue(this.state)
    if (newBest !== oldBest) {
      this.props.onChange(newBest)
    }
  }

  handleSelectChange = (
    _event: React.SyntheticEvent<HTMLInputElement>,
    _index: number,
    value: string
  ) => {
    this.setState({ value })
  }

  handleTextChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ text: event.target.value })
  }

  bestValue = (state: State) => {
    if (state.value === "Other" && state.text) {
      return state.text
    }
    return state.value
  }

  render() {
    const { hasError } = this.props

    return (
      <Container>
        <SelectField
          floatingLabelText="Relationship to child"
          value={this.state.value}
          iconStyle={{ fill: "grey" }}
          onChange={this.handleSelectChange}
        >
          <MenuItem value="Mother" primaryText="Mother" />
          <MenuItem value="Father" primaryText="Father" />
          <MenuItem value="Other" primaryText="Other" />
        </SelectField>
        {this.state.value === "Other" && (
          <TextContainer>
            <TextField
              label="Relationship"
              value={this.state.text}
              onChange={this.handleTextChange}
              error={hasError}
              helperText={hasError && "Specify the relationship"}
            />
          </TextContainer>
        )}
      </Container>
    )
  }
}

export default SdqAssessmentRelationshipInput
