import findIndex from "lodash.findindex"
import find from "lodash.find"
import { Steps, Step } from "./step_types"

export default class Wizard {
  steps: Steps

  constructor(steps: Steps) {
    this.steps = steps
  }

  nextStepID(currentStepID?: string): string | null | undefined {
    return this._changeStepID(1, currentStepID)
  }

  previousStepID(currentStepID?: string): string | null | undefined {
    return this._changeStepID(-1, currentStepID)
  }

  lastStepID(): string | null | undefined {
    const attribute = this.steps.slice(-1)[0]
    return attribute ? attribute.id : null
  }

  stepCount(): number {
    return this.steps.length
  }

  containsStep(stepID: string): boolean {
    return !!this.stepForID(stepID)
  }

  stepForID(stepID: string): Step {
    return find(this.steps, ["id", stepID])
  }

  stepNumber(stepID: string): number {
    return findIndex(this.steps, ["id", stepID]) + 1
  }

  _changeStepID(
    delta: number,
    currentStepID?: string
  ): string | null | undefined {
    const steps = this.steps
    const currentIndex = findIndex(steps, ["id", currentStepID])
    const newIndex = currentIndex + delta
    const maxIndex = steps.length - 1

    if (newIndex > maxIndex || newIndex < 0) return null

    const boundedIndex = Math.min(newIndex, maxIndex)
    const nextStep = steps[boundedIndex]
    return nextStep ? nextStep.id : null
  }
}
