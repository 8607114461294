import styled from "@emotion/styled"

type BackgroundColour = "transparent" | "translucent" | string

function bgValue(value: BackgroundColour) {
  switch (value) {
    case "translucent":
      return "rgba(255,255,255,0.7)"
    default:
      return value
  }
}

type OverlayProps = {
  background: BackgroundColour
  fullScreen?: boolean
}

const Overlay = styled.div<OverlayProps>((props) => ({
  backgroundColor: bgValue(props.background),
  height: props.fullScreen ? "100vh" : "100%",
  width: props.fullScreen ? "100vw" : "auto",
  position: props.fullScreen ? "fixed" : "static",
  zIndex: 99,
  left: props.fullScreen ? 0 : "unset",
  top: props.fullScreen ? 0 : "unset",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  flex: 1
}))

const defaultProps: OverlayProps = {
  background: "transparent",
  fullScreen: false
}

Overlay.defaultProps = defaultProps

export default Overlay
