import React from "react"
import { Vector2 } from "./types"

type Props = {
  value: number
  centre?: Vector2 // eslint-disable-line react/no-unused-prop-types
  colour?: string // eslint-disable-line react/no-unused-prop-types
  radius?: number // eslint-disable-line react/no-unused-prop-types
  previousValue?: number
}

const Change = ({ value, previousValue }: Props) => {
  const delta = Math.round(value - previousValue)
  if (value === null || previousValue == null) {
    return null
  }
  return (
    <tspan textAnchor="middle" x="0" dy="2.5em">
      Change:&nbsp;&nbsp; {delta > 0 ? "+" : ""} {delta}
    </tspan>
  )
}

const WidgetScore = ({
  value,
  centre,
  colour,
  radius,
  previousValue
}: Props) => {
  const displayedValue = value === 0 ? value : value || previousValue

  return (
    <text
      transform={`translate(${centre.x})`}
      dominantBaseline="alphabetic"
      textAnchor="middle"
      x={centre.x}
      y={centre.y}
      fill={value === null ? "white" : colour}
      height={radius}
    >
      <tspan
        textAnchor="middle"
        x="0"
        fontSize={radius * 0.9}
        textDecoration="underline"
        fontWeight="600"
      >
        {Math.round(displayedValue)}
      </tspan>
      <Change value={value} previousValue={previousValue} />
    </text>
  )
}

export default WidgetScore
