import React, { ChangeEventHandler, FocusEventHandler } from "react"
import { TextField as MuiTextField } from "@material-ui/core"

interface IProps {
  input: {
    onChange: ChangeEventHandler<HTMLTextAreaElement | HTMLInputElement>
    value: string
    onBlur: FocusEventHandler<{}>
    name: string
  }
  helperText?: string
  meta: { touched: boolean; error: string }
  name: string
}

const TextFieldForReduxForm = (props: IProps) => {
  const {
    input,
    meta: { touched, error },
    helperText,
    ...rest
  } = props
  return (
    <MuiTextField
      {...input}
      helperText={helperText || (touched && error)}
      id={input.name}
      error={touched && !!error}
      onChange={input.onChange}
      {...rest}
    />
  )
}

export default TextFieldForReduxForm
