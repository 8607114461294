import React from "react"
import styled from "@emotion/styled"
import capitalize from "lodash.capitalize"
import moment from "moment"
import colours from "../themes/colours"

const Container = styled.div({
  // border: '1px solid red',
  marginBottom: "1em"
})

const Label = styled.div({
  color: colours.grey,
  fontSize: "80%"
})

const Value = styled.div({
  fontWeight: 500,
  fontSize: "110%",
  borderBottom: `1px solid ${colours.boldLineOnSilver}`
})

const TwoColumns = styled.div({
  display: "flex",
  flexDirection: "row",
  marginTop: 10
})

const Column = styled.div({
  flex: 1,
  maxWidth: "10em",
  marginRight: "1em",
  ":last-child": {
    marginRight: 0
  }
})

function isChild(dateOfBirth) {
  const yearsSince = moment().diff(dateOfBirth, "years")
  return dateOfBirth && yearsSince < 18
}

type ClientDetailProps = {
  name: string
  gender: string
  dateOfBirth: string
}

const SdqClientDetails = ({ name, gender, dateOfBirth }: ClientDetailProps) => {
  const entityType = isChild(dateOfBirth) ? "Child" : "Client"
  return (
    <Container>
      <div>
        <Label>{`${entityType}'s name`}</Label>
        <Value>{name}</Value>
      </div>
      <TwoColumns>
        {gender && (
          <Column>
            <Label>Gender</Label>
            <Value>{capitalize(gender)}</Value>
          </Column>
        )}
        {dateOfBirth && (
          <Column>
            <Label>Date of birth</Label>
            <Value>{moment(dateOfBirth).format("ll")}</Value>
          </Column>
        )}
      </TwoColumns>
    </Container>
  )
}

export default SdqClientDetails
