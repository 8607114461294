import {
  faArchive,
  faBoxOpen,
  IconDefinition
} from "@fortawesome/free-solid-svg-icons"
import type { BulkActionType } from "../../state_types"

type IconMap = {
  [_name in BulkActionType]: IconDefinition
}

const iconMap: IconMap = {
  ARCHIVE: faArchive,
  RESTORE: faBoxOpen
}

function actionIcon(action: BulkActionType): IconDefinition | null | undefined {
  return iconMap[action]
}

export { actionIcon }
