import React from "react"
import get from "lodash.get"
import includes from "lodash.includes"
import { connect } from "react-redux"
import Checkbox from "material-ui/Checkbox"
import styled from "@emotion/styled"
import AvatarPreview from "../AvatarPreview"
import AssessmentItemScores from "./AssessmentItemScores"
import NextAssessment from "./NextAssessment"
import colours from "../../themes/colours"
import type { AssessmentWindow, TeamAssessment } from "../../state_types"

type OwnProps = {
  clientAvatar: string
  clientName: string
  uuid: string
  teamAssessment: TeamAssessment
  hasNotification?: boolean
  nextAssessment?: AssessmentWindow
  canBulkEdit: boolean
}

type StateProps = {
  isEditing: boolean
  markedForBulk: (uuid: string) => boolean
}

type Props = OwnProps &
  StateProps & {
    visitRipple: () => any
    toggleBulkAction: () => any
  }

function mapStateToProps(state) {
  return {
    isEditing: get(state, "bulkActions.active", false),
    markedForBulk: (uuid) => includes(get(state, "bulkActions.data"), uuid)
  }
}

function mapDispatchToProps(dispatch) {
  return {
    visit: (uuid) =>
      dispatch({ type: "RIPPLE", payload: { rippleUuid: uuid } }),
    toggle: (markedForBulk, uuid) => {
      const action = markedForBulk
        ? "BULK_RIPPLE_DESELECTED"
        : "BULK_RIPPLE_SELECTED"
      return dispatch({ type: action, payload: uuid })
    }
  }
}

function mergeProps(stateProps, dispatchProps, ownProps): Props {
  return {
    ...ownProps,
    ...stateProps,
    ...dispatchProps,
    visitRipple: () => dispatchProps.visit(ownProps.uuid),
    toggleBulkAction: () => {
      const { uuid, canBulkEdit } = ownProps

      if (canBulkEdit) {
        return dispatchProps.toggle(stateProps.markedForBulk(uuid), uuid)
      }

      return null
    }
  }
}

const Card = styled.div({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  width: "275px",
  padding: "10px 0",
  border: `1px solid ${colours.whiteSilverBorder}`,
  borderRadius: "3px",
  backgroundColor: "white",
  ":hover": {
    border: `1px solid ${colours.blue}`
  },
  boxSizing: "border-box",
  "@media (max-width: 600px)": {
    width: "100%"
  }
})

const CardLink = styled.div<{ isEditing; canBulkEdit }>((props) => ({
  textDecoration: "none",
  color: "inherit",
  cursor: props.isEditing && !props.canBulkEdit ? "not-allowed" : "pointer",
  margin: "10px",
  "@media (max-width: 600px)": {
    width: "100%",
    maxWidth: 360
  }
}))

const Header = styled.div({
  display: "flex",
  width: "100%",
  flexDirection: "row-reverse",
  justifyContent: "space-between",
  alignItems: "center",
  paddingBottom: "10px",
  paddingLeft: 10,
  paddingRight: 10,
  boxSizing: "border-box",
  minHeight: "18px"
})

type BulkActionStatusProps = {
  checked: boolean
}

const BulkActionStatus = ({ checked }: BulkActionStatusProps) => {
  const color = checked ? colours.blue : colours.deemphasisedText
  const styleOverrides = {
    width: "18px",
    height: "18px",
    color,
    fill: color,
    overflow: "hidden"
  }
  const rootStyles = {
    margin: "0",
    width: "18px",
    height: "18px",
    maxWidth: "18px"
  }
  return (
    <Checkbox
      iconStyle={styleOverrides}
      style={rootStyles}
      inputStyle={styleOverrides}
      checked={checked}
    />
  )
}

const Notification = styled.div<{ active; size }>((props) => ({
  color: "black",
  height: `${props.size}px`,
  width: `${props.size}px`,
  minWidth: `${props.size}px`,
  borderWidth: "1.5px",
  borderStyle: "solid",
  borderColor: props.active ? colours.blue : colours.deemphasisedText,
  borderRadius: "50%",
  backgroundColor: props.active ? colours.blue : "#FFFFFF",
  margin: 0
}))

const ClientName = styled.span({
  color: "black",
  fontFamily: "Poppins",
  fontSize: "20px",
  fontWeight: "bold",
  lineHeight: "40px",
  padding: "10px",
  boxSizing: "border-box",
  whiteSpace: "nowrap",
  overflow: "hidden",
  textOverflow: "ellipsis",
  width: "100%",
  textAlign: "center"
})

const CalendarContainer = styled.span({
  padding: "15px 0"
})

const ScoresContainer = styled.div({
  width: "260px",
  paddingTop: "10px"
})

const blankTeamAssessment = {
  score: null,
  range: [50, 50],
  thresholds: [20, 70],
  date: null,
  outOf: 100
}

const DisconnectedRippleCard = ({
  clientAvatar,
  clientName,
  uuid,
  teamAssessment,
  nextAssessment,
  hasNotification,
  toggleBulkAction,
  visitRipple,
  markedForBulk,
  isEditing,
  canBulkEdit
}: Props) => {
  const assessment = teamAssessment || blankTeamAssessment

  const linkTarget = isEditing ? toggleBulkAction : visitRipple

  const marked = markedForBulk(uuid)

  return (
    <CardLink
      isEditing={isEditing}
      canBulkEdit={canBulkEdit}
      onClick={linkTarget}
      className="ripple-card"
    >
      <Card>
        <Header>
          <Notification size={12} active={hasNotification} />
          {canBulkEdit && isEditing && <BulkActionStatus checked={marked} />}
        </Header>
        <AvatarPreview size={93} value={clientAvatar} name={clientName} />
        <ClientName>{clientName}</ClientName>
        <CalendarContainer>
          <NextAssessment assessmentWindow={nextAssessment} />
        </CalendarContainer>
        <ScoresContainer>
          <AssessmentItemScores
            teamAssessment={assessment}
            outOf={assessment.outOf}
            width={260}
            radius={15}
            height={39}
          />
        </ScoresContainer>
      </Card>
    </CardLink>
  )
}

const RippleCard = connect(
  mapStateToProps,
  mapDispatchToProps,
  mergeProps
)(DisconnectedRippleCard)

export default RippleCard
