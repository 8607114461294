import React from "react"
import { bindActionCreators } from "redux"
import { connect } from "react-redux"
import styled from "@emotion/styled"
import Theme from "../themes/White"
import { requestNewPasswordLink } from "../api_actions"
import ForgotPasswordForm from "../components/ForgotPasswordForm"
import GoBackHeader from "../components/GoBackHeader"
import type { DispatchFunction } from "../state_types"

const EditPasswordContainer = styled.div({
  display: "flex",
  flexDirection: "column",
  flexGrow: 1
})

type Props = {
  requestNewPasswordLink: DispatchFunction
}

type FormData = {
  email: string
}

function handleCancel(event) {
  event.preventDefault()

  window.history.back()
}

class ForgotPasswordPage extends React.Component<Props> {
  handleSubmit = (data: FormData) => {
    this.props.requestNewPasswordLink(data.email)
  }

  render() {
    return (
      <Theme>
        <EditPasswordContainer>
          <GoBackHeader handleCancel={handleCancel} />
          <ForgotPasswordForm onSubmit={this.handleSubmit} syncing={false} />
        </EditPasswordContainer>
      </Theme>
    )
  }
}

const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ requestNewPasswordLink }, dispatch)

export default connect(null, mapDispatchToProps)(ForgotPasswordPage)
