import React from "react"
import styled from "@emotion/styled"

import startCase from "lodash.startcase"
import filter from "lodash.filter"
import FontAwesome from "react-fontawesome"
import AssessmentItemScores from "../AssessmentItemScores"
import SdqUserSection from "./SdqUserSection"
import colours from "../../../themes/colours"
import { User } from "../../../state_types"

type Props = {
  teamSection
  titleWidth: number
  width: number
  mobile?: boolean
}

type State = {
  showUserScores: boolean
}

const Container = styled.div<{ addUnderline? }>((props) => ({
  borderBottom: props.addUnderline
    ? `2px solid ${colours.boldLineOnSilver}`
    : "none",
  marginBottom: props.addUnderline ? "10px" : ""
}))

const Row = styled.div<{ padding? }>((props) => ({
  display: "flex",
  flexDirection: "row",
  paddingBottom: props.padding ? "10px" : ""
}))

const Title = styled.div<{ width; largerText }>((props) => ({
  width: props.width,
  fontSize: props.largerText ? "110%" : "80%",
  fontWeight: props.largerText ? 600 : "normal"
}))

const ShowUsers = styled.a({
  textDecoration: "none",
  display: "flex",
  alignItems: "center"
})

type StyledComponentsCustom = {
  width?: number
  wrapText?: boolean
}

const ScoreContainer = styled.div<StyledComponentsCustom>(({ width }) => ({
  width
}))

const ChildrenContainer = styled.div({})

const SectionText = styled.div<StyledComponentsCustom>(({ wrapText }) => ({
  width: wrapText ? 1 : "100%"
}))

const ONE_THIRD = 1 / 3
const TWO_THIRDS = 2 / 3

class TeamSdqSection extends React.Component<Props, State> {
  static defaultProps = {
    mobile: false
  }

  state = { showUserScores: false }

  handleClick = () => {
    this.setState((state) => ({ showUserScores: !state.showUserScores }))
  }

  hasScore = (user: User) => {
    return user.score !== null
  }

  render() {
    const { teamSection, titleWidth, width, mobile } = this.props

    const tableWidth = width - titleWidth
    const totalDiffSection = teamSection.id === "total_difficulty"
    const radius = totalDiffSection ? 36 : 17
    const barHeight = totalDiffSection ? 80 : 36
    const scoreText = totalDiffSection ? " Score" : ""
    const sectionText = startCase(teamSection.id) + scoreText
    const assessorCount = filter(teamSection.userBreakdown, (user) =>
      this.hasScore(user)
    )
    const hideScore = !(assessorCount.length === 1)

    const assessment = {
      range: teamSection.normalisedRange,
      thresholds: [100 * ONE_THIRD, 100 * TWO_THIRDS],
      score: teamSection.normalisedScore,
      displayScore: teamSection.score,
      id: teamSection.id
    }

    return (
      <Container addUnderline={totalDiffSection}>
        <Row padding={totalDiffSection}>
          <Title width={titleWidth} largerText={totalDiffSection}>
            <ShowUsers
              style={{ color: "black" }}
              href="#"
              onClick={this.handleClick}
            >
              <FontAwesome
                style={{ color: colours.blue, paddingRight: 10 }}
                name={
                  this.state.showUserScores ? "chevron-down" : "chevron-right"
                }
              />
              <SectionText wrapText={mobile || totalDiffSection}>
                {sectionText}
              </SectionText>
            </ShowUsers>
          </Title>
          <ScoreContainer width={tableWidth}>
            <AssessmentItemScores
              users={[]}
              teamAssessment={assessment}
              width={tableWidth}
              radius={radius}
              height={barHeight}
              dialog={null}
              disableRule
              hideScore={hideScore}
              largeScoreCircle={totalDiffSection}
              outOf={100}
              gradientOverride
            />
          </ScoreContainer>
        </Row>
        {this.state.showUserScores && (
          <ChildrenContainer>
            {teamSection.attributes.map((section) => (
              <SdqUserSection
                key={section.id}
                section={section}
                titleWidth={titleWidth}
                width={width}
                mobile={mobile}
              />
            ))}
          </ChildrenContainer>
        )}
      </Container>
    )
  }
}

export default TeamSdqSection
