import unlerp from "unlerp"
import clamp from "clamp"
import lerp from "lerp"

export function interpolateInteger(outputRange, inputRange, input) {
  const normalisedInput = clamp(
    unlerp(inputRange[0], inputRange[1], input),
    0.0,
    1.0
  )
  const result = lerp(outputRange[0], outputRange[1], normalisedInput)
  return Math.round(result)
}
