import React from "react"
import styled from "@emotion/styled"
import { mediaQueries } from "../../Layout"
import type { SectionSummary } from "../../../contexts/assessment/types"
import SimpleRadioButton from "./SimpleRadioButton"
import cat from "../../../images/pedsql/cat.png"
import couch from "../../../images/pedsql/couch.png"
import plant from "../../../images/pedsql/plant.png"
import walk from "../../../images/pedsql/walk.png"
import colours from "../../../themes/colours"

const imageSources = {
  cat,
  couch,
  plant,
  walk
}

const Body = styled.div({
  display: "flex",
  flexDirection: "column",
  height: "100%",
  [mediaQueries.desktop]: {
    flexDirection: "row"
  }
})

const SectionLabel = styled.div<{ active?: boolean }>(({ active }) => ({
  boxSizing: "border-box",
  fontWeight: "bold",
  padding: "6px 18px",
  fontSize: "0.75rem",
  boxShadow: `0px 1px 0px 0px ${
    active ? "rgba(80, 227, 194, 0.5)" : colours.lightGrey
  }`,
  width: "100%",
  lineHeight: "16px"
}))

const ActiveBackground = styled.div<{ img?: string }>(({ img }) => {
  const background = "rgba(80, 227, 194, 0.1)"

  return {
    boxShadow: `inset 0px -25px 25px 0px  ${background}`,
    background: `linear-gradient(0deg, ${background}, ${background})`,
    [mediaQueries.desktop]: {
      background: `linear-gradient(0deg, ${background}, ${background}), url(${imageSources[img]}) no-repeat right bottom`,
      backgroundSize: "225%",
      width: "40%"
    }
  }
})

const BackgroundImage = styled.div<{ img?: string }>(({ img }) => {
  const background = "rgba(255, 255, 255, 0.1)"

  return {
    background: `linear-gradient(0deg, ${background}, ${background}), url(${imageSources[img]}) no-repeat right bottom`,
    height: "100%",
    backgroundSize: "100%",
    display: "flex",
    flexDirection: "column",
    [mediaQueries.desktop]: {
      backgroundSize: "150%",
      width: "60%"
    }
  }
})

const SummaryWrapper = styled.div({
  padding: "2rem",
  paddingBottom: "2rem",
  fontSize: "1.5rem",
  lineHeight: "38px",
  fontWeight: 500,
  display: "flex",
  flexDirection: "column",
  flexGrow: 1,
  [mediaQueries.desktop]: {
    paddingTop: "4.5rem"
  },
  p: {
    fontSize: "20px",
    lineHeight: "28px",
    paddingBottom: "1rem"
  }
})

const Notice = styled.span({
  marginTop: "auto",
  padding: "2rem",
  backgroundColor: colours.lightGrey,
  borderRadius: "4px",
  fontSize: "12px",
  lineHeight: "18px"
})

type LastSectionProps = {
  nextPage: () => void
}

const LastSection = ({ nextPage }: LastSectionProps) => {
  return (
    <BackgroundImage>
      <SectionLabel>Finish and submit</SectionLabel>
      <SummaryWrapper>
        <strong>Are you ready to finish and submit your answers?</strong>
        <br />
        <p>
          After you tap the button below, your responses will be submitted to
          Ripple, and the people looking after you will be able to see your
          answers.
        </p>
        <SimpleRadioButton
          onClick={nextPage}
          icon="chevRight"
          text="Finish"
          fullWidth
          large
        />
        <Notice>
          For any information on the use of the PedsQL™, please contact Mapi
          Research Trust, Lyon, France. Internet:&nbsp;
          <b>https://eprovide.mapi-trust.org</b>
        </Notice>
      </SummaryWrapper>
    </BackgroundImage>
  )
}

type Props = {
  step: SectionSummary
  nextPage: () => void
}

const SimpleRadioSectionSummary = ({ step, nextPage }: Props) => {
  const { title, sectionNumber, image, nextSectionTitle, nextSectionImage } =
    step
  return (
    <Body>
      <ActiveBackground img={image}>
        <SectionLabel>
          {sectionNumber}: {title}
        </SectionLabel>
        <SummaryWrapper>
          {nextSectionTitle
            ? "Good job, you've finished this part"
            : "Thank you! You're nearly done!"}
        </SummaryWrapper>
      </ActiveBackground>
      {nextSectionTitle ? (
        <BackgroundImage img={nextSectionImage}>
          <SectionLabel>
            {sectionNumber + 1}: {nextSectionTitle}
          </SectionLabel>
          <SummaryWrapper>
            Next:
            <br />
            <strong>{nextSectionTitle}</strong>
          </SummaryWrapper>
        </BackgroundImage>
      ) : (
        <LastSection nextPage={nextPage} />
      )}
    </Body>
  )
}

export default SimpleRadioSectionSummary
