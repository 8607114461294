import React from "react"
import { connect } from "react-redux"
import styled from "@emotion/styled"
import { reduxForm, formValueSelector, InjectedFormProps } from "redux-form"
import FontAwesome from "react-fontawesome"
import RippleScheduleForm from "./RippleScheduleForm"
import { stepValidator } from "../contexts/ripple_form/wizard"
// import Loading from '../components/Loading'
import SubmitButton from "./SubmitButton"
import { gutter } from "./Layout"

const RippleDetailsContainer = styled.div({
  display: "flex",
  flexDirection: "column",
  flexGrow: 1
})

const Header = styled.div({})

const GoBack = styled.a({
  textDecoration: "none"
})

const FormContainer = styled.div({
  padding: gutter
})

const ArrowText = styled.div({
  display: "flex",
  padding: "30px 0 0 35px",
  alignItems: "baseline"
})

const Text = styled.div({
  color: "#000000",
  fontFamily: "Poppins",
  fontSize: "1em",
  lineHeight: "23px"
})

const SubmitButtonWrapper = styled.div({
  display: "flex",
  justifyContent: "center",
  paddingTop: "45px"
})

type OnSubmitFunc = () => any

interface IProps extends InjectedFormProps {
  startOn: string
  periodType: string
  onSubmit: OnSubmitFunc
}

function handleCancel(event) {
  event.preventDefault()

  window.history.back()
}

const EditRippleScheduleForm = ({
  startOn,
  periodType,
  handleSubmit,
  onSubmit
}: IProps) => (
  <RippleDetailsContainer>
    <Header>
      <GoBack href="#" onClick={handleCancel}>
        <ArrowText>
          <FontAwesome
            name="long-arrow-left"
            style={{
              paddingRight: "10px",
              textDecoration: "none",
              color: "black"
            }}
          />
          <Text>Cancel</Text>
        </ArrowText>
      </GoBack>
    </Header>
    <FormContainer>
      <form onSubmit={handleSubmit(onSubmit)} id="ripple-schedule-form">
        <RippleScheduleForm startOn={startOn} periodType={periodType} edit />
        <SubmitButtonWrapper>
          {/* { isSubmitting ? <Loading /> : <SubmitButton /> } */}
          <SubmitButton />
        </SubmitButtonWrapper>
      </form>
    </FormContainer>
  </RippleDetailsContainer>
)

const NewEditRippleScheduleForm = reduxForm({
  form: "userEditRipple",
  validate: stepValidator("schedule")
})(EditRippleScheduleForm)

const selector = formValueSelector("userEditRipple")

const mapStateToProps = (state) => {
  const ripple = state.rippleDetails.data.ripple
  const schedule = ripple.schedule
  return {
    periodType: selector(state, "periodType"),
    startOn: selector(state, "startOn"),
    //   syncing,
    initialValues: {
      startOn: schedule.startOn,
      daysOpen: schedule.daysOpen,
      periodType: schedule.periodType,
      period: schedule.period,
      startHour: schedule.startHour,
      endHour: schedule.endHour,
      wdays: schedule.wdays
    }
  }
}

export default connect(mapStateToProps)(NewEditRippleScheduleForm)
