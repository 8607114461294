import React from "react"
import { reduxForm, Field, InjectedFormProps } from "redux-form"
import { connect } from "react-redux"
import { RadioButton } from "material-ui"
import FontAwesome from "react-fontawesome"
import styled from "@emotion/styled"
import pluralize from "pluralize"
import Theme from "../../themes/White"
import { submitOrgArchiveRipplesForm } from "../../api_actions"
import { fieldsRequired } from "../../contexts/shared/validation"
import Loading from "../../components/Loading"
import SubmitButton from "../../components/SubmitButton"
import { gutter } from "../../components/Layout"
import LoadablePage from "../../components/LoadablePage"
import colours from "../../themes/colours"
import MuiRadioButtonGroup from "../../components/MuiExtensions/components/MuiRadioButtonGroup"

const ArchiveRipplesFormContainer = styled.div({})

const Header = styled.div({
  display: "flex",
  justifyContent: "flex-start"
})

const FormContainer = styled.div({
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  padding: `40px  ${gutter}`,
  alignItems: "center"
})

const ArrowText = styled.div({
  display: "flex",
  padding: "30px 0 0 35px",
  alignItems: "baseline"
})

const Form = styled.form({})

const FieldsContainer = styled.div({
  display: "flex",
  flex: 1,
  flexDirection: "column",
  justifyContent: "flex-start"
})

const FieldContainer = styled.div({
  minHeight: "4.5em",
  display: "flex"
})

const Text = styled.div({
  color: "#000000",
  fontFamily: "Poppins",
  fontSize: "1em",
  lineHeight: "23px"
})

const FieldHeading = styled.div({
  color: "#000000",
  fontFamily: "Poppins",
  fontSize: "1em",
  lineHeight: "23px",
  marginBottom: "18px"
})

const GoBack = styled.a({
  textDecoration: "none"
})

const SubmitButtonWrapper = styled.div({
  display: "flex",
  justifyContent: "center",
  paddingTop: "45px"
})

function validate(values) {
  const errors = {}
  fieldsRequired(values, errors, "framework_id")
  return errors
}

function handleCancel(event) {
  event.preventDefault()

  window.history.back()
}

const RadioOption = styled.div({
  marginBottom: "10px",
  minWidth: "300px"
})

const RippleCount = styled.div({
  fontWeight: 300,
  color: colours.grey
})

const radioLabel = ({
  title,
  activeRippleCount
}: {
  title
  activeRippleCount: number
}) => (
  <RadioOption>
    <div>{title}</div>
    <RippleCount>{pluralize("ripple", activeRippleCount, true)}</RippleCount>
  </RadioOption>
)

const renderRadioButtonGroup = ({
  frameworks,
  orgName
}: {
  frameworks
  orgName: string
}) => {
  return (props: { input: { value }; meta: { error; submitFailed } }) => {
    const {
      input,
      meta: { error, submitFailed }
    } = props

    const styles: {
      root: React.CSSProperties
      focus: React.CSSProperties
      error: React.CSSProperties
    } = {
      root: {
        borderTop: "none",
        borderLeft: "none",
        borderRight: "none",
        borderBottomStyle: "solid",
        borderBottomWidth: 1,
        borderColor: "aaa",
        boxSizing: "content-box",
        margin: 0,
        width: "100%"
      },
      focus: {
        borderBottomStyle: "solid",
        borderBottomWidth: 2,
        transform: "scaleX(0)"
      },
      error: {
        borderColor: "red",
        transform: "scaleX(1)"
      }
    }

    let style: React.CSSProperties = { ...styles.root, ...styles.focus }
    if (error && submitFailed) style = { ...style, ...styles.error }

    const frameworkRadioButtons = frameworks
      .sort((a, b) => a.id.localeCompare(b.id))
      .map(({ id, title, activeRippleCount }) => {
        const label = radioLabel({ title, activeRippleCount })
        return <RadioButton key={id} value={id} label={label} />
      })

    return (
      <div>
        <FieldHeading>
          Choose which Ripples for <strong>{orgName}</strong> will be archived
          <hr aria-hidden="true" style={style} />
        </FieldHeading>
        <Field
          {...input}
          component={MuiRadioButtonGroup}
          valueSelected={input.value}
        >
          {frameworkRadioButtons}
        </Field>
      </div>
    )
  }
}

interface IProps {
  frameworks?
  orgName?
  syncing?
  loaded?: boolean // eslint-disable-line react/no-unused-prop-types
  onSubmit: React.FormEventHandler<HTMLFormElement>
}

const OrgArchiveRipplesForm = (
  props: IProps & InjectedFormProps<{}, IProps>
) => {
  const { frameworks, orgName, syncing, onSubmit, handleSubmit } = props

  const isSubmitting = syncing === "sending"

  const handleClick = (event) => {
    if (!window.confirm("Are you sure you want to archive these Ripples?")) {
      event.preventDefault()
    }
  }

  return (
    <ArchiveRipplesFormContainer>
      <Header>
        <GoBack href="#" onClick={handleCancel}>
          <ArrowText>
            <FontAwesome
              name="long-arrow-left"
              style={{
                paddingRight: "10px",
                textDecoration: "none",
                color: "black"
              }}
            />
            <Text>Cancel</Text>
          </ArrowText>
        </GoBack>
      </Header>
      <FormContainer>
        <Form onSubmit={handleSubmit(onSubmit)} id="edit-user-form">
          <FieldsContainer>
            <FieldContainer>
              <Field
                name="framework_id"
                component={renderRadioButtonGroup({ frameworks, orgName })}
              />
            </FieldContainer>
          </FieldsContainer>
          <SubmitButtonWrapper>
            {isSubmitting ? (
              <Loading />
            ) : (
              <SubmitButton onClick={handleClick}>Archive ripples</SubmitButton>
            )}
          </SubmitButtonWrapper>
        </Form>
      </FormContainer>
    </ArchiveRipplesFormContainer>
  )
}

const ReduxOrgArchiveRipplesForm = reduxForm<{}, IProps>({
  form: "orgArchiveRipples",
  validate
})(OrgArchiveRipplesForm)

const EditPageContainer = styled.div({
  display: "flex",
  flexDirection: "column",
  flexGrow: 1
})

const OrgArchiveRipplesPage = (props: IProps) => {
  return (
    <Theme>
      <EditPageContainer>
        <LoadablePage loaded={props.loaded}>
          {() => (
            <ReduxOrgArchiveRipplesForm
              onSubmit={props.onSubmit}
              frameworks={props.frameworks}
              syncing={props.syncing}
              orgName={props.orgName}
            />
          )}
        </LoadablePage>
      </EditPageContainer>
    </Theme>
  )
}

const mapStateToProps = (state) => {
  const frameworks = state.org && state.org.frameworks
  const syncing = state.org && state.org.syncing
  const orgName = state.org && state.org.name

  return {
    loaded: !!frameworks,
    frameworks,
    syncing,
    orgName
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    onSubmit: () => dispatch(submitOrgArchiveRipplesForm())
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(OrgArchiveRipplesPage)
