import React from "react"
import styled from "@emotion/styled"
import moment from "moment"
import TeamSdqSection from "./TeamSdqSection"
import TeamAssessmentComments from "../TeamAssessmentComments"
import type { TeamAssessment as TeamAssessmentType } from "../../../state_types"
import colours from "../../../themes/colours"

type Props = {
  teamAssessment: TeamAssessmentType
  width: number
}
const Container = styled.div({
  paddingBottom: "50px"
})

const Sections = styled.div({
  paddingTop: "10px"
})

const CommentsContainer = styled.div({})

const Header = styled.div({
  display: "flex",
  fontSize: "0.8em",
  borderBottom: `2px solid ${colours.boldLineOnSilver}`,
  paddingBottom: "5px",
  paddingTop: "10px"
})

const Date = styled.div<{ width }>((props) => ({
  width: props.width
}))

const Threshold = styled.div({
  display: "flex"
})

const ThreshHoldRow = styled.div({
  display: "flex",
  justifyContent: "space-around",
  flexGrow: 1
})

const useMobileView = (windowWidth: number) => windowWidth < 670

const TeamSdqAssessment = ({ teamAssessment, width }: Props) => {
  const sections = teamAssessment.attributes
  const mobile = useMobileView(width)
  const titleWidth = mobile ? 100 : 170
  const assessDate = moment(teamAssessment.date)

  return (
    <Container>
      <Header>
        <Date width={titleWidth}>{assessDate.format("MMM DD")}</Date>
        <ThreshHoldRow>
          <Threshold>Abnormal</Threshold>
          <Threshold>Borderline</Threshold>
          <Threshold>Normal</Threshold>
        </ThreshHoldRow>
      </Header>
      <Sections>
        {sections.map((section) => (
          <TeamSdqSection
            key={section.id}
            teamSection={section}
            titleWidth={titleWidth}
            width={width}
            mobile={mobile}
          />
        ))}
      </Sections>
      <CommentsContainer>
        <TeamAssessmentComments
          titleWidth={titleWidth}
          width={width}
          comments={teamAssessment.comments}
          mobile={mobile}
        />
      </CommentsContainer>
    </Container>
  )
}

export default TeamSdqAssessment
