import React from "react"
import SchemeLink from "./SchemeLink"

type Props = {
  value?: string
  maxWidth?: string
  color?: string
}

const EmailLink = (props: Props) => <SchemeLink {...props} scheme="mailto" />

export default EmailLink
