import React from "react"
import styled from "@emotion/styled"
import { formatRating } from "./helpers/scores"
import type { QuestionInput, Question } from "../contexts/assessment/types"

const Row = styled.tr<{ completed }>((props) => ({
  color: props.completed ? "inherit" : "#777777",
  "> td": {
    paddingTop: 10
  }
}))

const LabelColumn = styled.td({
  width: "100%"
})

const ScoreColumn = styled.td({
  width: "4em",
  minWidth: "4em",
  textAlign: "right",
  verticalAlign: "top",
  fontWeight: "bold"
})

const QuestionTitle = styled.div({
  fontWeight: "bold"
})

const Note = styled.div({})

type Props = {
  input?: QuestionInput
  question: Question
}

const QuestionAnswerRow = ({ input, question }: Props) => {
  const hasRating = input && typeof input.rating === "number"
  return (
    <Row completed={hasRating}>
      <LabelColumn>
        <QuestionTitle>{question.title}</QuestionTitle>
        {input && input.comment && <Note>{input.comment}</Note>}
      </LabelColumn>
      <ScoreColumn>{hasRating && formatRating(input.rating)}</ScoreColumn>
    </Row>
  )
}

export default QuestionAnswerRow
