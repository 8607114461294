import React from "react"
import { MuiThemeProvider as V0MuiThemeProvider } from "material-ui/styles"
import getMuiTheme from "material-ui/styles/getMuiTheme"
import baseTheme from "material-ui/styles/baseThemes/lightBaseTheme"
import { MuiThemeProvider, createTheme } from "@material-ui/core/styles"
import colours from "./colours"

const themeV0 = {
  ...baseTheme,
  palette: {
    ...baseTheme.palette,
    primaryTextColor: colours.blue,
    primary1Color: colours.blue,
    pickerHeaderColor: colours.blue
  },
  fontFamily: "Poppins, sans-serif"
}

const theme = {
  overrides: {
    MuiButton: {
      root: {
        borderRadius: 500,
        textTransform: "none" as const,
        "&.toggle-button-group__button": {
          backgroundColor: colours.white,
          fontWeight: 300,
          color: colours.grey
        },
        "&.toggle-button-group__button--selected": {
          color: colours.white,
          "&.toggle-button-group__button--blue": {
            backgroundColor: colours.blue
          },
          "&.toggle-button-group__button--green": {
            backgroundColor: colours.green
          }
        },
        "&.toggle-button-group__button--green:hover": {
          backgroundColor: colours.green
        },
        "&.toggle-button-group__button--blue:hover": {
          backgroundColor: colours.blue
        }
      },
      containedPrimary: {
        padding: "3px 5px",
        fontSize: "1rem",
        fontWeight: 400,
        color: colours.white
      },
      label: {
        padding: "0 10px"
      },
      outlinedPrimary: {
        border: `1px solid ${colours.blue}`,
        "&.Mui-disabled": {
          color: colours.deemphasisedText,
          borderColor: colours.deemphasisedText
        }
      },
      iconSizeMedium: {
        "& > *:first-child": {
          fontSize: "16px"
        }
      }
    },
    MuiInput: {
      underline: {
        "&:hover:not(.Mui-disabled):before": {
          borderBottomColor: colours.blue
        },
        "&:before": {
          borderBottom: `1px solid ${colours.textFieldBorderGrey}`
        },
        "&.Mui-disabled:before": {
          borderBottom: `2px dotted ${colours.textFieldPlaceholderGrey}`
        },
        input: {
          borderColor: colours.textFieldBorderGrey
        }
      }
    },
    MuiFormLabel: {
      root: {
        color: colours.textFieldPlaceholderGrey,
        "&.text-field--small-label": {
          fontSize: ".8rem"
        }
      }
    },
    MuiLinearProgress: {
      root: {
        height: "8px"
      },
      bar1Determinate: {
        borderRadius: "100px"
      },
      colorPrimary: {
        backgroundColor: "#DDD"
      }
    },
    MuiDialogActions: {
      root: {
        padding: "0 24px 24px 24px"
      }
    },
    MuiDialogContent: {
      root: {
        color: colours.grey
      }
    }
  },
  palette: {
    primary: {
      main: colours.blue
    }
  },
  typography: {
    fontFamily: "Poppins, sans-serif"
  }
}

const MuiTheme = ({ children }: { children: any }) => {
  return (
    <MuiThemeProvider theme={createTheme(theme)}>
      <V0MuiThemeProvider muiTheme={getMuiTheme(themeV0)}>
        {children}
      </V0MuiThemeProvider>
    </MuiThemeProvider>
  )
}

export default MuiTheme
