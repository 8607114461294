import React from "react"
import { connect } from "react-redux"
import styled from "@emotion/styled"
import { InjectedFormProps, reduxForm } from "redux-form"
import FontAwesome from "react-fontawesome"
import get from "lodash.get"
import RippleDetailsForm from "./RippleDetailsForm"
import { fieldsRequired } from "../contexts/shared/validation"
import SubmitButton from "./SubmitButton"
import type { OrgMembership } from "../state_types"

const RippleDetailsContainer = styled.div({
  display: "flex",
  flexDirection: "column",
  flexGrow: 1
})

const Header = styled.div({})

const GoBack = styled.a({
  textDecoration: "none"
})

const ArrowText = styled.div({
  display: "flex",
  padding: "30px 0 0 35px",
  alignItems: "baseline"
})

const Text = styled.div({
  color: "#000000",
  fontFamily: "Poppins",
  fontSize: "1em",
  lineHeight: "23px"
})

const SubmitButtonWrapper = styled.div({
  display: "flex",
  justifyContent: "center",
  paddingTop: "45px"
})

type OnSubmitFunc = () => any

interface IProps extends InjectedFormProps {
  orgs: Array<OrgMembership>
  currentOrg: string
  onSubmit: OnSubmitFunc
  defaultValues: {
    clientName?: string
    groupNames: []
  }
}

function validate(values) {
  const errors = {}
  fieldsRequired(values, errors, "clientName", "rippleName")
  return errors
}

function handleCancel(event) {
  event.preventDefault()

  window.history.back()
}

const EditRippleClientForm = ({
  orgs,
  currentOrg,
  handleSubmit,
  onSubmit,
  defaultValues
}: IProps) => (
  <RippleDetailsContainer>
    <Header>
      <GoBack href="#" onClick={handleCancel}>
        <ArrowText>
          <FontAwesome
            name="long-arrow-left"
            style={{
              paddingRight: "10px",
              textDecoration: "none",
              color: "black"
            }}
          />
          <Text>Cancel</Text>
        </ArrowText>
      </GoBack>
    </Header>
    <form onSubmit={handleSubmit(onSubmit)} id="ripple-details-form">
      <RippleDetailsForm
        orgs={orgs}
        currentOrg={currentOrg}
        defaultValues={defaultValues}
        edit
      />
      <SubmitButtonWrapper>
        {/* { isSubmitting ? <Loading /> : <SubmitButton /> } */}
        <SubmitButton />
      </SubmitButtonWrapper>
    </form>
  </RippleDetailsContainer>
)

const NewEditRippleClientForm = reduxForm({
  form: "userEditRipple",
  validate
})(EditRippleClientForm)

const mapStateToProps = (state) => {
  const ripple = state.rippleDetails.data.ripple
  // const syncing = ripple.syncing
  const orgs = get(state, "me.data.user.orgs") || []
  const currentOrg = ripple.orgSlug

  return {
    orgs,
    currentOrg,
    // syncing,
    initialValues: {
      clientAvatar: ripple.clientAvatar,
      clientName: ripple.clientName,
      rippleName: ripple.name,
      clientExternalRef: ripple.clientExternalRef,
      gender: ripple.gender,
      dateOfBirth: ripple.dateOfBirth,
      groupNames: ripple.groupNames
    },
    defaultValues: {
      clientName: ripple.clientName,
      groupNames: ripple.groupNames
    }
  }
}

export default connect(mapStateToProps)(NewEditRippleClientForm)
