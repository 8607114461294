import React from "react"
import { Button as MuiButton } from "@material-ui/core"
import colours from "../themes/colours"

type Props = {
  label: string
  type?: "button" | "reset" | "submit"
  disabled?: boolean
  onClick?
  style?
}

const FlatButton = ({ label, disabled, style, ...rest }: Props) => {
  const color = disabled ? colours.deemphasisedText : colours.blue
  return (
    <MuiButton
      {...rest}
      variant="outlined"
      style={{
        borderColor: `${color}`,
        color,
        ...style
      }}
      disabled={disabled}
    >
      {label}
    </MuiButton>
  )
}

FlatButton.defaultProps = {
  type: "submit",
  disabled: false
}

export default FlatButton
