const settings = {
  users: {
    canSeeImportButton: [
      "gn@ripple.com.au",
      "ed@ripple.com.au",
      "eb2@ripple.com.au",
      "shs@ripple.com.au",
      "ch@ripple.com.au",
      "org_creator@user.com",
      "org_editor@user.com",
      "mcoate@hhacademy.vic.edu.au",
      "lmcgrath@hhacademy.vic.edu.au",
      "srosewarne@hhacademy.vic.edu.au",
      "yai.mario-ring@education.vic.gov.au",
      "scotts@bylc.qld.edu.au",
      "mindis@bylc.qld.edu.au",
      "lzeeng@sfbrightoneast.catholic.edu.au",
      "louise.oteri@education.vic.gov.au",
      "fiona.king6@education.vic.gov.au",
      "Maggie.Scott@education.vic.gov.au",
      "Jennifer.Driessen@education.vic.gov.au",
      "Euplio.Mazzeo@education.vic.gov.au",
      "Shaun.Olsson@education.vic.gov.au",
      "rhys.barri@education.vic.gov.au",
      "gmcnamara@stjamesbrighton.catholic.edu.au",
      "principal@mic.org.au",
      "m.kirk@mic.org.au",
      "r.wood@mic.org.au",
      "lorraine.stevenson@slweirviews.catholic.edu.au",
      "courtney.daly@slweirviews.catholic.edu.au",
      "sean.walsh@theactgroup.com.au"
    ],
    canSeeOrgAdminButton: [
      "gn@ripple.com.au",
      "ed@ripple.com.au",
      "shs@ripple.com.au",
      "org_admin@user.com"
    ]
  }
}

export default settings
