import React from "react"
import styled from "@emotion/styled"
import colours from "../../themes/colours"
import RoundedGradientBar from "../RoundedGradientBar"
import ScoreCircle from "../ScoreCircle"
import Threshold from "../Threshold"
import ScoresByUser from "./ScoresByUser"
import ScoreOverride from "./ScoreOverride"
import type { TeamAssessment, UserScore, UsersHash } from "../../state_types"
import type { DialogFunctions } from "../BubbleDialogContainer"

const Container = styled.div(() => ({
  overflow: "hidden"
}))

const OVERRIDE_THRESHOLDS = [0.0, 100.0]

const linearGradientBetweenThresholds = ({
  badScore,
  goodScore,
  lowerThreshold,
  upperThreshold
}) =>
  `linear-gradient(to right, ${badScore} ${
    lowerThreshold || 0
  }%, ${goodScore} ${upperThreshold || 100}%)`

const GradientRule = styled.hr<{ lowerThreshold; upperThreshold; disableRule }>(
  ({ lowerThreshold, upperThreshold, disableRule }) => ({
    background: disableRule
      ? colours.boldLineOnSilver
      : linearGradientBetweenThresholds({
          ...colours,
          lowerThreshold,
          upperThreshold
        }),
    height: disableRule ? 1 : 3,
    borderWidth: 0
  })
)

const GradientBarContainer = styled.div<{ height; indent }>((props) => ({
  position: "relative",
  height: props.height,
  marginLeft: props.indent
}))

type Props = {
  users?: UsersHash
  teamAssessment: TeamAssessment
  width: number
  radius: number
  height: number
  scoreOverride?: {
    text: string
    colour?: string
  }
  dialog?: DialogFunctions
  disableRule?: boolean
  hideScore?: boolean
  largeScoreCircle?: boolean
  gradientOverride?: boolean
  outOf: number
  suffix?: string
}

const percentageOf = (percentage: number, amount: number) =>
  (percentage * amount) / 100

const scalePercentages = (percentages: Array<number>, max: number) =>
  percentages.map((percentage) => percentage && percentageOf(percentage, max))

const convertToPercentage = (value: number, outOf: number) =>
  (value / outOf) * 100.0

const convertArrayToPercentages = (array: Array<number>, outOf: number) =>
  array.map((value) => value && convertToPercentage(value, outOf))

const convertAssessmentToPercentage = (
  teamAssessment: TeamAssessment & { displayScore? },
  outOf: number
) => ({
  ...teamAssessment,
  score: convertToPercentage(teamAssessment.score, outOf),
  displayScore: teamAssessment.displayScore ?? teamAssessment.score,
  range: convertArrayToPercentages(teamAssessment.range, outOf),
  thresholds: convertArrayToPercentages(teamAssessment.thresholds, outOf)
})

function onBarClicked(
  dialog: DialogFunctions,
  key,
  users,
  thresholds,
  userBreakdown: Array<UserScore>
) {
  return (event) => {
    if (event) {
      event.preventDefault()
      const target: HTMLElement = event.currentTarget
      const contents = (
        <ScoresByUser
          userBreakdown={userBreakdown}
          thresholds={thresholds}
          users={users || {}}
        />
      )
      dialog.toggle(key, target, contents)
    }
  }
}

const AssessmentItemScores = ({
  users,
  teamAssessment,
  scoreOverride,
  width,
  radius,
  height,
  dialog,
  disableRule,
  hideScore,
  largeScoreCircle,
  gradientOverride,
  outOf = 100,
  suffix
}: Props) => {
  const { range, thresholds, score, displayScore } =
    convertAssessmentToPercentage(teamAssessment, outOf)

  const diameter = radius * 2
  const widgetWidth = width - diameter
  const pixelRange = scalePercentages(range, widgetWidth)
  const pixelThresholds = scalePercentages(thresholds, widgetWidth)
  const overridePixelThresholds = scalePercentages(
    OVERRIDE_THRESHOLDS,
    widgetWidth
  )
  const pixelScore = percentageOf(score, widgetWidth)
  const rangeExists = range[0] !== range[1]
  const scoreExists = score !== null
  const key = teamAssessment.number || teamAssessment.id
  const topOffset = (height - diameter) / 2
  const leftOffset = scoreExists ? Math.round(pixelScore - radius) : undefined

  return (
    <Container>
      <GradientBarContainer height={height} indent={radius}>
        {scoreExists && rangeExists && (
          <RoundedGradientBar
            onClick={
              dialog &&
              onBarClicked(
                dialog,
                key,
                users,
                teamAssessment.thresholds,
                teamAssessment.userBreakdown
              )
            }
            top={topOffset}
            radius={radius}
            barRange={pixelRange}
            gradientStops={
              gradientOverride ? overridePixelThresholds : pixelThresholds
            }
          />
        )}
        {scoreOverride ? (
          <ScoreOverride {...scoreOverride} top={topOffset} height={diameter} />
        ) : (
          <ScoreCircle
            onClick={
              dialog &&
              onBarClicked(
                dialog,
                key,
                users,
                teamAssessment.thresholds,
                teamAssessment.userBreakdown
              )
            }
            top={topOffset}
            left={leftOffset}
            radius={radius}
            score={score}
            displayScore={displayScore}
            thresholds={gradientOverride ? OVERRIDE_THRESHOLDS : thresholds}
            hideScore={hideScore}
            suffix={suffix}
          />
        )}
        {!largeScoreCircle && (
          <Threshold
            threshold={pixelThresholds[0]}
            colour={colours.badScore}
            height={height}
          />
        )}
        {!largeScoreCircle && (
          <Threshold
            threshold={pixelThresholds[1]}
            colour={colours.goodScore}
            height={height}
          />
        )}
      </GradientBarContainer>
      {!largeScoreCircle && (
        <GradientRule
          lowerThreshold={thresholds[0]}
          upperThreshold={thresholds[1]}
          disableRule={disableRule}
        />
      )}
    </Container>
  )
}

AssessmentItemScores.defaultProps = {
  dialog: null,
  users: {},
  scoreOverride: null,
  disableRule: false,
  hideScore: false,
  largeScoreCircle: false,
  gradientOverride: false
}

export default AssessmentItemScores
