import React from "react"
import styled from "@emotion/styled"
import pluralize from "pluralize"
import isEmpty from "lodash.isempty"
import startCase from "lodash.startcase"
import ContentContainer from "./RippleInfoContentContainer"
import colours from "../themes/colours"
import { RippleFile, RippleFilesDetails } from "../state_types"
import FileExtensionIcon from "./FileExtensionIcon"

const Header = styled.div({
  display: "flex",
  justifyContent: "space-between",
  width: "100%"
})

type Props = {
  files: RippleFilesDetails
}

const FileList = styled.div({})

const RoleAndFileContainer = styled.div({
  marginBottom: 10,
  paddingBottom: 10,
  borderBottom: `1px solid ${colours.whiteSilverBorder}`
})

const FileLink = styled.a({
  display: "block",
  marginBottom: 10,
  color: colours.darkGrey,
  whiteSpace: "nowrap",
  overflow: "hidden",
  lineHeight: "140%",
  textOverflow: "ellipsis",
  ":last-child": {
    marginBottom: 0
  },
  ":hover": {
    color: colours.blue,
    textDecoration: "underline"
  }
})

const RoleLabel = styled.div({
  textDecoration: "none",
  color: "#333",
  fontWeight: "bold"
})

const iconStyles = {
  marginRight: 10
}

const NoData = styled.div({
  lineHeight: "140%",
  color: colours.grey
})

const MemberHeader = ({ files }: Props) => {
  return <Header>{pluralize("file", files.total, true)}</Header>
}

const NoFiles = () => (
  <NoData>
    You can upload files using the &quot;Add a comment or file&quot; panel in
    the feed section below.
  </NoData>
)

const RoleFileLink = ({ file }: { file: RippleFile }) => (
  <RoleAndFileContainer>
    <RoleLabel>{startCase(file.role)}</RoleLabel>
    <FileLink key={file.path} href={`/ripple_files/${file.path}`}>
      <FileExtensionIcon extension={file.extension} style={iconStyles} />
      {file.filename}
    </FileLink>
  </RoleAndFileContainer>
)

const RegularFileLink = ({ file }: { file: RippleFile }) => (
  <FileLink key={file.path} href={`/ripple_files/${file.path}`}>
    <FileExtensionIcon extension={file.extension} style={iconStyles} />
    {file.filename}
  </FileLink>
)

const MemberBody = ({ files }: Props) => {
  const topFiles = files.top
  return (
    <FileList>
      {isEmpty(topFiles) ? (
        <NoFiles />
      ) : (
        topFiles.map((file) =>
          file.role ? (
            <RoleFileLink file={file} />
          ) : (
            <RegularFileLink file={file} />
          )
        )
      )}
    </FileList>
  )
}

const FileDetails = (props: Props) => {
  return (
    <ContentContainer
      headerContent={<MemberHeader {...props} />}
      bodyContent={<MemberBody {...props} />}
    />
  )
}

export default FileDetails
