import React from "react"
import FontAwesome from "react-fontawesome"
import colours from "../themes/colours"

type CloseButtonProps = {
  onClick: () => void
  style?: {
    color: string
    paddingBottom: number
    fontSize: string
  }
}

const defaultStyle = {
  color: colours.deemphasisedText,
  fontSize: "2em",
  cursor: "pointer",
  paddingBottom: "9px"
}

const CloseButton = ({ onClick, style }: CloseButtonProps) => (
  <FontAwesome
    onClick={onClick}
    name="times"
    style={{
      ...defaultStyle,
      ...style
    }}
  />
)

CloseButton.defaultProps = {
  style: {}
}

export default CloseButton
