import React from "react"
import { Power3 } from "gsap"
import { Vector2 } from "./types"

type Props = {
  name: string
  radius: number
  centre: Vector2
  colour: string
  angleDegrees: number
  maxOpacity?: number
}

const GradientCircle = ({
  name,
  radius,
  centre,
  colour,
  angleDegrees,
  maxOpacity
}: Props) => {
  const angle = angleDegrees
  const anglePI = -angle * (Math.PI / 180)
  const angleCoords = {
    x1: `${Math.round(50 + Math.sin(anglePI) * 50)}%`,
    y1: `${Math.round(50 + Math.cos(anglePI) * 50)}%`,
    x2: `${Math.round(50 + Math.sin(anglePI + Math.PI) * 50)}%`,
    y2: `${Math.round(50 + Math.cos(anglePI + Math.PI) * 50)}%`
  }

  const gradientName = `${name}-circle-gradient-def`
  const gradientUrl = `url(#${gradientName})`

  const keys = [0.0, 0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1.0]
  const steps = keys.map((key) => [
    `${key * 100}%`,
    Power3.easeIn.getRatio(key) * maxOpacity
  ])

  return (
    <>
      <defs key={`${gradientName}-def`}>
        <linearGradient id={gradientName} {...angleCoords}>
          {steps.map((row) => (
            <stop
              key={row[0]}
              stopColor={colour}
              stopOpacity={row[1]}
              offset={row[0]}
            />
          ))}
        </linearGradient>
      </defs>
      <circle
        key={`${name}-circle-back`}
        fill="white"
        r={radius}
        cx={centre.x}
        cy={centre.y}
      />
      <circle
        key={`${name}-circle`}
        fill={gradientUrl}
        r={radius}
        cx={centre.x}
        cy={centre.y}
      />
    </>
  )
}

GradientCircle.defaultProps = {
  maxOpacity: 1.0
}

export default GradientCircle
