import React from "react"
import find from "lodash.find"
import findIndex from "lodash.findindex"
import { Transition, TransitionGroup } from "react-transition-group"
import { TweenMax, Power3 } from "gsap"
import styled from "@emotion/styled"

type SlideDirection = "left" | "right"

type Props = {
  currentKey: string
  children
  defaultDirection?: SlideDirection
}

type State = {
  previousKey?: string
}

type ExitInstructions = {
  exitDirection: SlideDirection
}

const buildEnterTransition = (
  contentKey: string,
  child,
  getSlideDirection: () => SlideDirection
) => {
  const durationMilliseconds = 800
  const durationSeconds = durationMilliseconds / 1000
  const ease = Power3.easeInOut

  return (
    <Transition
      key={contentKey}
      timeout={durationMilliseconds}
      onEntering={(htmlElement) => {
        const startX = getSlideDirection() === "left" ? "110%" : "-110%"
        TweenMax.fromTo(
          htmlElement,
          durationSeconds,
          { x: startX },
          { x: "0%", ease }
        )
      }}
      onExiting={(htmlElement?: HTMLElement) => {
        const targetX = getSlideDirection() === "left" ? "-110%" : "110%"
        TweenMax.to(htmlElement, durationSeconds, {
          x: targetX,
          ease
        })
      }}
    >
      {child}
    </Transition>
  )
}

const SliderWindow = styled.div({
  height: "100%",
  width: "100%",
  position: "relative"
})

const SliderPage = styled.div({
  height: "100%",
  width: "100%",
  position: "absolute",
  display: "flex"
})

export default class SlideablePageGroup extends React.Component<Props, State> {
  static defaultProps = {
    defaultDirection: "left"
  }

  constructor(props: Props) {
    super(props)
    this.state = { previousKey: null }
  }

  UNSAFE_componentWillReceiveProps(props: Props) {
    if (props.currentKey !== this.props.currentKey) {
      this.setState({ previousKey: this.props.currentKey })
    }
  }

  getChild(key?: string) {
    return find(this.props.children, ["key", key])
  }

  getChildIndex(key?: string): number {
    if (key === null) return 0
    return findIndex(this.props.children, ["key", key])
  }

  getSlideDirection(): SlideDirection {
    const previousIndex = this.getChildIndex(this.state.previousKey)
    const currentIndex = this.getChildIndex(this.props.currentKey)

    if (previousIndex < 0) {
      return this.props.defaultDirection || "left"
    }

    const indexDiff = currentIndex - previousIndex
    return indexDiff > 0 ? "left" : "right"
  }

  getExitInstructions(): ExitInstructions {
    return {
      exitDirection: this.getSlideDirection()
    }
  }

  render() {
    const current = this.getChild(this.props.currentKey)

    return (
      <SliderWindow>
        <TransitionGroup>
          {buildEnterTransition(
            this.props.currentKey,
            <SliderPage>{current}</SliderPage>,
            () => this.getSlideDirection()
          )}
        </TransitionGroup>
      </SliderWindow>
    )
  }
}
