import React from "react"
import Loading from "./Loading"

type Props = {
  loaded: boolean
  children: () => any
}

const LoadablePage = ({ loaded, children }: Props) => {
  return loaded ? children() : <Loading />
}

export default LoadablePage
