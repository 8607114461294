import React from "react"
import styled from "@emotion/styled"
import RippleClientDetails from "./RippleClientDetails"
import CloseButton from "./CloseButton"
import Fade from "./Fade"
import colours from "../themes/colours"
import { PaddedByGutter, gutter } from "./Layout"
import RippleInfoPanel from "./RippleInfoPanel"
import type { Ripple } from "../state_types"

type RippleDetailHeaderProps = {
  collapseDetails: boolean
  ripple: Ripple
  infoPanelWidth: number
}

type State = {
  showDetails: boolean
}

const Container = styled.div({
  backgroundColor: "white",
  borderBottom: `1px solid ${colours.whiteSilverBorder}`,
  "@media (max-width: 768px)": {
    flexDirection: "column",
    width: "100%",
    paddingRight: 0
  }
})

const DetailsButton = styled.div({
  paddingBottom: "9px",
  color: colours.blue,
  cursor: "pointer",
  fontFamily: "Poppins",
  fontSize: "12px",
  lineHeight: "19px"
})

const ClientDetailContainer = styled.div({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "flex-end"
})

const InfoPanelContainer = styled.div({
  paddingLeft: gutter,
  paddingRight: gutter,
  boxSizing: "border-box",
  flex: 1
})

class RippleDetailHeader extends React.Component<
  RippleDetailHeaderProps,
  State
> {
  constructor(props) {
    super(props)
    this.state = {
      showDetails: false
    }
  }

  toggleInfoPanel = () => {
    this.setState((state) => ({ showDetails: !state.showDetails }))
  }

  render() {
    const { ripple, collapseDetails } = this.props
    const { showDetails } = this.state

    return (
      <Container className="ripple-detail-header">
        <PaddedByGutter>
          <ClientDetailContainer>
            {ripple && <RippleClientDetails ripple={ripple} />}
            {collapseDetails &&
              (showDetails ? (
                <CloseButton onClick={this.toggleInfoPanel} />
              ) : (
                <DetailsButton onClick={this.toggleInfoPanel}>
                  Details
                </DetailsButton>
              ))}
          </ClientDetailContainer>
        </PaddedByGutter>
        {collapseDetails ? (
          <Fade show={showDetails}>
            <PaddedByGutter>
              <RippleInfoPanel ripple={ripple} />
            </PaddedByGutter>
          </Fade>
        ) : (
          <InfoPanelContainer className="info-container">
            <RippleInfoPanel ripple={ripple} />
          </InfoPanelContainer>
        )}
      </Container>
    )
  }
}

export default RippleDetailHeader
