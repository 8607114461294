import React from "react"
import styled from "@emotion/styled"
import { gutter } from "./Layout"

const triangleWidth = 14
const borderWidth = triangleWidth + 2

const TriangleContainer = styled.div<{ targetX }>((props) => ({
  position: "absolute",
  display: "block",
  verticalAlign: "top",
  top: 0,
  bottom: "initial",
  left: props.targetX - borderWidth / 2 - 1,
  right: "initial"
}))

const TriangleShadow = styled.div<{ borderColor }>((props) => ({
  position: "absolute",
  border: `${borderWidth / 2}px solid transparent`,
  borderBottomColor: props.borderColor,
  borderTopColor: "transparent",
  borderRightColor: "transparent",
  borderLeftColor: "transparent",
  top: -borderWidth,
  left: 0
}))

const Triangle = styled.div<{ color }>((props) => ({
  position: "absolute",
  border: `${triangleWidth / 2}px solid transparent`,
  borderBottomColor: props.color,
  borderTopColor: "transparent",
  borderRightColor: "transparent",
  borderLeftColor: "transparent",
  top: -triangleWidth,
  left: "1px"
}))

const Box = styled.div<{ color; borderColor }>((props) => ({
  position: "relative",
  width: "100%",
  backgroundColor: props.color,
  borderWidth: "1px",
  borderStyle: "solid",
  borderColor: props.borderColor,
  boxShadow: "0 3px 12px rgba(0, 0, 0, 0.15)",
  borderRadius: "4px"
}))

const Contents = styled.div({
  margin: gutter
})

type Props = {
  children: unknown
  targetX: number
}

const TriangleFrame = ({ children, targetX }: Props) => {
  const color = "#FFFFFF"
  const borderColor = "rgba(0, 0, 0, 0.15)"

  return (
    <Box color={color} borderColor={borderColor}>
      <TriangleContainer targetX={targetX}>
        <TriangleShadow borderColor={borderColor} />
        <Triangle color={color} />
      </TriangleContainer>
      <Contents>{children}</Contents>
    </Box>
  )
}

export default TriangleFrame
