import React from "react"
import styled from "@emotion/styled"
import ReportCard from "./ReportCard"
import type { ReportSummary as ReportSummaryType } from "../../state_types"
import ScoreCircle from "../ScoreCircle"

const CenterVertically = styled.div({
  flex: 1,
  display: "flex",
  flexDirection: "column",
  justifyContent: "flex-start",
  paddingBottom: 20,
  "& > *": {
    marginTop: 10
  }
})

type ReportSummaryCardProps = {
  width: number
  height: number
  report: ReportSummaryType
  onClick: () => {}
}

const ScoreRow = styled.div({
  display: "flex",
  paddingTop: "5px",
  paddingLeft: "20px"
})

const ScoreTitle = styled.div({
  paddingLeft: "10px",
  alignSelf: "center",
  overflow: "hidden",
  textOverflow: "ellipsis"
})

const TopReportSummaryCard = ({
  width,
  height,
  report,
  onClick
}: ReportSummaryCardProps) => {
  const { reportData } = report
  if (!reportData) return null

  const { thresholds, rows } = reportData
  const radius = 15
  const left = radius * 2

  const scoresCicles = rows.map((row) => {
    const { id, title, score } = row

    return (
      <ScoreRow key={`score-${id}`}>
        <ScoreCircle
          left={left}
          radius={radius}
          score={score}
          displayScore={score}
          thresholds={thresholds}
        />
        <ScoreTitle>{title}</ScoreTitle>
      </ScoreRow>
    )
  })

  return (
    <ReportCard
      width={width}
      height={height}
      title={report.title}
      onClick={onClick}
    >
      <CenterVertically>{scoresCicles}</CenterVertically>
    </ReportCard>
  )
}

export default TopReportSummaryCard
