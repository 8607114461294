import React from "react"
import styled from "@emotion/styled"
import SubmitButton from "./SubmitButton"
import chevronRightIcon from "../images/icons/chevron-right.svg"

const RightChevronIcon = styled.img({
  marginRight: ".5em"
})

type Props = {
  disabled?: boolean
  children?: React.ReactNode
  onClick?: (event?) => void
  style?
}

const NextButton = ({ disabled, children, onClick, style }: Props) => (
  <SubmitButton
    disabled={disabled}
    style={{
      ...style
    }}
    {...(onClick && { onClick })}
  >
    <>
      {children || "Next"}
      <RightChevronIcon src={chevronRightIcon} alt="" />
    </>
  </SubmitButton>
)

export default NextButton
