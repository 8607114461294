import React from "react"
import styled from "@emotion/styled"
import map from "lodash.map"
import type { ReactNode } from "react"

type ColumnType = {
  name: string
  render: () => ReactNode
  maxWidth?: number
}

type TwoColumnScrollingProps = {
  columns: Array<ColumnType>
}

const Container = styled.div({
  flex: 1,
  display: "flex",
  flexDirection: "row"
})

const Column = styled.div<{ maxWidth }>((props) => ({
  flex: 1,
  overflowX: "hidden",
  overflowY: "scroll",
  maxWidth: props.maxWidth
}))

const TwoColumnScrolling = ({ columns }: TwoColumnScrollingProps) => {
  return (
    <Container className="two-columns">
      {map(columns, (column) => (
        <Column key={column.name} className="column" maxWidth={column.maxWidth}>
          {column.render()}
        </Column>
      ))}
    </Container>
  )
}

export default TwoColumnScrolling
