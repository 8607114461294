import React from "react"
import styled from "@emotion/styled"
import FontAwesome from "react-fontawesome"
import colours from "../themes/colours"

const Card = styled.div({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  width: "275px",
  padding: "10px 0",
  border: `1px solid ${colours.blue}`,
  borderRadius: "3px",
  backgroundColor: "white",
  height: "343.797px",
  boxSizing: "border-box",
  "@media (max-width: 600px)": {
    width: "100%"
  }
})

const CardLink = styled.a({
  textDecoration: "none",
  color: "inherit",
  margin: "10px",
  "@media (max-width: 600px)": {
    width: "100%",
    maxWidth: 360
  }
})

const UserIcon = styled.div({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  border: `1px solid ${colours.blue}`,
  borderRadius: "50%",
  width: "93px",
  height: "93px",
  marginTop: "80px"
})

const CreateRippleText = styled.span({
  color: colours.blue,
  fontFamily: "Poppins",
  fontSize: "14px",
  fontWeight: 600,
  lineHeight: "23px",
  paddingTop: "26px"
})

type Props = {
  dispatchNewRipple
}

const CreateRippleCard = ({ dispatchNewRipple }: Props) => {
  return (
    <CardLink href="#" onClick={dispatchNewRipple}>
      <Card>
        <UserIcon>
          <FontAwesome
            name="user-plus"
            style={{ color: colours.blue, fontSize: "3em" }}
          />
        </UserIcon>
        <CreateRippleText>Create a ripple</CreateRippleText>
      </Card>
    </CardLink>
  )
}

export default CreateRippleCard
