import React from "react"
import styled from "@emotion/styled"
import compact from "lodash.compact"
import { containerWidth } from "./Layout"

export const actionButtonHeight = "3em"

const Actions = styled.div({
  display: "flex",
  height: actionButtonHeight,
  flexShrink: 0,
  flexDirection: "row",
  alignItems: "center",
  justifyContent: "center",
  paddingLeft: "1em",
  paddingRight: "1em",
  zIndex: 2
})

type ActionContainerProps = {
  alignRight: boolean
}

const ActionContainer = styled.div<ActionContainerProps>((props) => ({
  maxWidth: containerWidth,
  flex: 1,
  height: actionButtonHeight,
  display: "flex",
  flexDirection: "row",
  justifyContent: props.alignRight ? "flex-end" : "space-between",
  alignItems: "center",
  overflow: "hidden"
}))

type Props = {
  children
}

const PageBottomActions = ({ children }: Props) => {
  const visibleChildren = Array.isArray(children)
    ? compact(children)
    : [children]
  const numChildren = visibleChildren.length
  const singleSide = numChildren === 1
  const alignRight = singleSide ? visibleChildren[0].key === "right" : false

  return (
    <Actions>
      <ActionContainer alignRight={alignRight}>
        {visibleChildren}
      </ActionContainer>
    </Actions>
  )
}

export default PageBottomActions
