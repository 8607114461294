export function getRedirectUrlFromQueryValue(paramName: string): string | null {
  const value = new URL(window.location.href).searchParams.get(paramName)
  if (!value) {
    return null
  }

  // the value will have been url escaped
  const decodedValue = decodeURI(value)
  // ensure it begins with a slash
  const cleanedValue = decodedValue.replace(/^\/*(.*$)/, "/$1")
  // ensure its origin is unchanged
  const valueRelativeToOrigin = `${window.location.origin}${cleanedValue}`

  return valueRelativeToOrigin
}
