import type { State, Action } from "./state_types"

type SubReducerFunc = (state: any, action: Action) => any

type ReducerHash = {
  [id: string]: SubReducerFunc
}

export function applyReducerHash(
  reducers: ReducerHash,
  statePart,
  action: Action
) {
  const reducer = reducers[action.type]
  if (reducer) {
    return reducer(statePart, action)
  }
  return statePart
}

export function applySubReducer(
  subReducer: SubReducerFunc,
  key: string,
  state: State,
  action: Action
) {
  const subState = subReducer(state[key], action)
  return { ...state, [key]: subState }
}

export function reduceApiResult(
  apiResult,
  dataReducer: (resultData: any, action?: Action) => any
) {
  if (apiResult) {
    if (apiResult.data) {
      return { ...apiResult, data: dataReducer(apiResult.data) }
    }
  }
  return apiResult
}
