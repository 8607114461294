import React from "react"
import { TextField } from "@material-ui/core"
import { AuthForm, Header, Inputs, PrimaryActions } from "../AuthFormStyles"
import SubmitButton from "../SubmitButton"
import AuthErrorMessages from "../AuthErrorMessages"
import type { Errors } from "../../state_types"

type Props = {
  submit: () => void
  onChange: (value: string) => void
  errors?: Errors
  syncing?: boolean
  password?: string
}

const UserPassword = ({
  submit,
  onChange,
  errors,
  syncing,
  password
}: Props) => {
  const handleSubmitForm = (event: React.SyntheticEvent<HTMLFormElement>) => {
    event.preventDefault()
    submit()
  }

  const handleChangePassword = (event: React.ChangeEvent<HTMLInputElement>) => {
    onChange(event.target.value)
  }

  return (
    <>
      <Header>Please confirm your password before continuing</Header>
      <AuthErrorMessages errors={errors} />

      <AuthForm onSubmit={handleSubmitForm}>
        <Inputs>
          <TextField
            id="password"
            type="password"
            label="Password"
            onChange={handleChangePassword}
            value={password}
          />
        </Inputs>

        <PrimaryActions>
          <SubmitButton disabled={syncing}>Continue</SubmitButton>
        </PrimaryActions>
      </AuthForm>
    </>
  )
}

UserPassword.defaultProps = {
  syncing: false,
  password: ""
}

export default UserPassword
