import React from "react"
import compact from "lodash.compact"
import map from "lodash.map"
import type { UserScore } from "../state_types"

type Props = {
  userBreakdown: Array<UserScore>
}

const NumUserResponses = ({ userBreakdown }: Props) => {
  if (!userBreakdown) return null

  const scores = userBreakdown
  const totalUsers = scores.length
  const usersWithScores = compact(map(scores, "score")).length

  return <>{`Responses: ${usersWithScores}/${totalUsers}`}</>
}

export default NumUserResponses
