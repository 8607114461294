import React from "react"
import styled from "@emotion/styled"
import ReportCard from "./ReportCard"
import colours from "../../themes/colours"
import AssessmentItemScores from "../team_assessment/AssessmentItemScores"
import type {
  TeamAssessment as TeamAssessmentType,
  ReportSummary as ReportSummaryType
} from "../../state_types"
import ReportUserSummary from "./ReportUserSummary"

const CenterVertically = styled.div({
  flex: 1,
  display: "flex",
  flexDirection: "column",
  justifyContent: "flex-end",
  paddingBottom: 20,
  "& > *": {
    marginTop: 10
  }
})

const indicatorColours = {
  higher_risk: colours.badScore,
  medium_risk: colours.mediumScore,
  lower_risk: colours.goodScore
}

const teamAssessmentFromReport = (report: ReportSummaryType) => {
  const { reportData } = report
  if (!reportData) return null

  const { outOf, score, thresholds, range } = reportData

  const teamAssessment: TeamAssessmentType = {
    date: "",
    outOf,
    thresholds,
    score,
    range,
    userBreakdown: null
  }
  return teamAssessment
}

type ReportSummaryCardProps = {
  width: number
  height: number
  report: ReportSummaryType
  onClick: () => {}
}

const RiskReportSummaryCard = ({
  width,
  height,
  report,
  onClick
}: ReportSummaryCardProps) => {
  const { reportData } = report
  if (!reportData) return null

  const teamAssessment: TeamAssessmentType = teamAssessmentFromReport(report)

  return (
    <ReportCard
      width={width}
      height={height}
      title={report.title}
      indicatorColour={indicatorColours[report.reportId]}
      onClick={onClick}
    >
      <CenterVertically>
        <ReportUserSummary
          total={report.clients.total}
          users={report.clients.preview}
          avatarSize={30}
        />
        {teamAssessment && (
          <AssessmentItemScores
            teamAssessment={teamAssessment}
            outOf={teamAssessment.outOf}
            width={255}
            radius={15}
            height={39}
          />
        )}
      </CenterVertically>
    </ReportCard>
  )
}

export default RiskReportSummaryCard
