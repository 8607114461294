import React from "react"
import isEmpty from "lodash.isempty"
import last from "lodash.last"
import BubbleDialogContainer from "../BubbleDialogContainer"
import AssessmentPartDetails from "./AssessmentPartDetails"
import AssessmentReportSummary from "./AssessmentReportSummary"
import SlideablePageGroup from "../SlidablePageGroup"
import AssessmentsReport from "../../contexts/assessment/AssessmentsReport"
import AssessmentHeatmap from "../AssessmentHeatmap"
import type { Ripple, UsersHash, TeamAssessment } from "../../state_types"

const dialogWidth = 400

const AssessmentDetailsWithDialog = (props) => (
  <BubbleDialogContainer maxWidth={dialogWidth} outsideClickCloses>
    {(dialogCallbacks) => (
      <AssessmentPartDetails {...props} dialog={dialogCallbacks} />
    )}
  </BubbleDialogContainer>
)

const AssessmentReportSummaryWithDialog = (props) => (
  <BubbleDialogContainer maxWidth={dialogWidth} outsideClickCloses>
    {(dialogCallbacks) => (
      <AssessmentReportSummary {...props} dialog={dialogCallbacks} />
    )}
  </BubbleDialogContainer>
)

type SlidableReportProps = {
  ripple: Ripple // eslint-disable-line react/no-unused-prop-types
  users: UsersHash // eslint-disable-line react/no-unused-prop-types
  assessmentNumber: number // eslint-disable-line react/no-unused-prop-types
  assessment: TeamAssessment // eslint-disable-line react/no-unused-prop-types
  sectionId?: string // eslint-disable-line react/no-unused-prop-types
  section?: TeamAssessment // eslint-disable-line react/no-unused-prop-types
  questionId?: string // eslint-disable-line react/no-unused-prop-types
  question?: TeamAssessment // eslint-disable-line react/no-unused-prop-types
  report: AssessmentsReport // eslint-disable-line react/no-unused-prop-types
  outOf: number // eslint-disable-line react/no-unused-prop-types
  showHeatmap: boolean // eslint-disable-line react/no-unused-prop-types
  // eslint-disable-next-line react/no-unused-prop-types
  returnTo?: {
    url: string
    name: string
  }
}

const reportHierarchy = ({
  ripple,
  users,
  assessmentNumber,
  sectionId,
  section,
  questionId,
  question,
  assessment,
  report,
  outOf,
  showHeatmap,
  returnTo
}: SlidableReportProps) => {
  const result = []
  const queryParams = returnTo ? { returnTo } : undefined

  if (ripple) {
    result.push(
      <AssessmentReportSummaryWithDialog
        key={`ripple-${ripple.uuid}`}
        users={users}
        ripple={ripple}
        report={report}
        outOf={outOf}
        returnTo={returnTo}
      />
    )
    if (showHeatmap) {
      result.push(
        <AssessmentHeatmap
          key={`assessment-${assessmentNumber}`}
          ripple={ripple}
          report={report}
          returnTo={returnTo}
        />
      )
    } else {
      if (assessmentNumber) {
        result.push(
          <AssessmentDetailsWithDialog
            thresholdClassifiers={ripple.frameworkInfo.thresholdClassifiers}
            key={`assessment-${assessmentNumber}`}
            users={users}
            assessmentPart={assessment}
            rippleUuid={ripple.uuid}
            rippleName={ripple.name}
            assessDate={assessment.date}
            outOf={outOf}
            queryParams={queryParams}
          />
        )
      }
      if (sectionId) {
        result.push(
          <AssessmentDetailsWithDialog
            key={`section-${sectionId}`}
            users={users}
            assessmentPart={section}
            rippleUuid={ripple.uuid}
            rippleName={ripple.name}
            assessNum={assessmentNumber}
            assessDate={assessment.date}
            sectionId={sectionId}
            outOf={outOf}
            queryParams={queryParams}
          />
        )
      }
      if (questionId) {
        result.push(
          <AssessmentDetailsWithDialog
            key={`question-${questionId}`}
            users={users}
            assessmentPart={question}
            rippleUuid={ripple.uuid}
            rippleName={ripple.name}
            assessNum={assessmentNumber}
            assessDate={assessment.date}
            sectionId={sectionId}
            sectionTitle={section && section.title}
            outOf={outOf}
            queryParams={queryParams}
          />
        )
      }
    }
  }
  return result
}

const RippleSlidableReport = (props: SlidableReportProps) => {
  const hierarchy = reportHierarchy(props)
  if (isEmpty(hierarchy)) return null

  const currentKey = last(hierarchy).key

  return (
    <SlideablePageGroup currentKey={currentKey} defaultDirection="right">
      {hierarchy}
    </SlideablePageGroup>
  )
}

export default RippleSlidableReport
