import React from "react"
import get from "lodash.get"
import { connect } from "react-redux"
import styled from "@emotion/styled"
import Theme from "../themes/White"
import { submitEditRippleForm } from "../api_actions"
import EditRippleClientForm from "../components/EditRippleClientForm"
import LoadablePage from "../components/LoadablePage"
import type { DispatchFunction, State } from "../state_types"

const EditPageContainer = styled.div({
  display: "flex",
  flexDirection: "column",
  flexGrow: 1
})

type Props = {
  submitEditRippleForm: () => any
  onSubmit: DispatchFunction
  hasRipple: boolean
}

class EditRippleClientPage extends React.Component<Props> {
  handleSubmit = () => {
    this.props.onSubmit(null)
  }

  renderPageBody = () => {
    return (
      <EditPageContainer>
        <EditRippleClientForm onSubmit={this.handleSubmit} />
      </EditPageContainer>
    )
  }

  render() {
    const { hasRipple } = this.props
    return (
      <Theme>
        <LoadablePage loaded={hasRipple}>{this.renderPageBody}</LoadablePage>
      </Theme>
    )
  }
}

const mapStateToProps = (state: State) => {
  const hasRipple = !!get(state, "rippleDetails.data.ripple")
  return { hasRipple }
}

const mapDispatchToProps = (dispatch) => {
  return {
    onSubmit: () => {
      dispatch(submitEditRippleForm())
    }
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EditRippleClientPage)
