import React from "react"
import styled from "@emotion/styled"
import GradientBar from "./GradientBar"
import { widenRange } from "./helpers/number_array"

type Props = {
  radius: number
  barRange: Array<number>
  gradientStops: Array<number>
  top?: number
  onClick?: React.MouseEventHandler
}

const StyledBar = styled(GradientBar)<{ top; radius }>((props) => ({
  borderRadius: props.radius,
  height: 2 * props.radius,
  top: props.top,
  zIndex: 1
}))

const RoundedGradientBar = ({
  top,
  radius,
  barRange,
  gradientStops,
  onClick
}: Props) => {
  const outerRange = widenRange(barRange, radius)
  return (
    <StyledBar
      onClick={onClick}
      top={top}
      radius={radius}
      barRange={outerRange}
      gradientStops={gradientStops}
    />
  )
}

RoundedGradientBar.defaultProps = {
  top: null,
  onClick: null
}

export default RoundedGradientBar
