import React from "react"

type Props = {
  name: string
  invisible?: boolean
  disabled?: boolean
  value?: File
  onChange: (event: React.SyntheticEvent<HTMLInputElement>) => any
}

const invisibleInputStyles = {
  width: "0.1px",
  height: "0.1px",
  opacity: 0,
  overflow: "hidden",
  position: "absolute",
  zIndex: -1
}

class ControlledFileInput extends React.Component<Props> {
  static defaultProps = {
    invisible: false,
    disabled: false
  }

  UNSAFE_componentWillReceiveProps(nextProps: Props) {
    if (!nextProps.value && this.input) {
      this.input.current.value = ""
    }
  }

  input: React.RefObject<HTMLInputElement>

  constructor(props) {
    super(props)
    this.input = React.createRef()
  }

  render() {
    const { name, onChange, invisible, disabled } = this.props
    const style = invisible ? invisibleInputStyles : {}
    return (
      <input
        style={style}
        ref={this.input}
        type="file"
        name={name}
        id={name}
        onChange={onChange}
        disabled={disabled}
      />
    )
  }
}

export default ControlledFileInput
