import React from "react"
import {
  Accordion,
  AccordionSummary,
  AccordionDetails
} from "@material-ui/core"
import styled from "@emotion/styled"
import {
  Header,
  SubHeader,
  PrimaryActions,
  Instructions
} from "../AuthFormStyles"
import { Anchor } from "../TextStyles"
import NextButton from "../NextButton"
import chevronDownIcon from "../../images/icons/chevron-down.svg"
import appleAppStore from "../../images/apple-app-store.svg"
import googlePlayStore from "../../images/google-play-store.svg"
import helpIcon from "../../images/icons/help.svg"
import FlashMessageWithinPage from "../FlashMessageWithinPage"

const HelpIcon = styled.img({
  marginRight: ".5em"
})

const StoreBadge = styled.a({
  cursor: "pointer",
  verticalAlign: "bottom",
  textAlign: "center"
})

const StyledAccordion = styled.div`
  .MuiAccordion-root {
    box-shadow: none;
    margin-bottom: 1em;
    width: min(500px, 100%);
    margin-left: auto;
    margin-right: auto;
  }
  .MuiAccordion-root.Mui-expanded {
    margin: 16px auto;
  }
  .MuiAccordionSummary-root {
    font-weight: bold;
    background: #EEF4FA;
  }
  .MuiAccordionSummary-content {
    margin: 12px auto;
    align-items: center;
  }
  .MuiAccordionSummary-content.Mui-expanded {
    margin: 12px auto;
  }
  .MuiAccordionSummary-root.Mui-expanded {
    min-height: 0;
  }
  .MuiAccordionDetails-root {
    background: white;
    border: 1px solid #eef4fa;
    border-top-width: 0;
    padding-top: 20px;
    padding-bottom: 20px;
    margin-bottom: 1em;
  }
  .MuiAccordionDetails-root p:first-of-type {
    margin-top: 0;
  }
  .MuiAccordionDetails-root p:last-of-type {
    margin-bottom: 0;
  }
  .MuiAccordionDetails-root {
    display: block;
  }
)`

const Link = styled(Anchor)({
  fontWeight: "bold",
  textDecoration: "underline"
})

type State = { submit: () => void; hideFirstTimeInstructions?: boolean }

const DownloadMFAApp = ({ submit, hideFirstTimeInstructions }: State) => {
  return (
    <>
      {!hideFirstTimeInstructions && (
        <FlashMessageWithinPage type="error">
          <p>
            You do not currently have two-factor authentication enabled. You
            will need to set it up before you can use see user data
          </p>
        </FlashMessageWithinPage>
      )}
      <Header>Set up two-factor authentication (2FA)</Header>
      {!hideFirstTimeInstructions && (
        <Instructions>
          As this is the first time you’re signing in, you’ll need to set up
          two-factor authentication (2FA) before using Ripple to view any data.
          This helps keep data within the Ripple secure.
        </Instructions>
      )}
      <StyledAccordion>
        <Accordion square>
          <AccordionSummary
            expandIcon={<img src={chevronDownIcon} alt="More" />}
          >
            <HelpIcon src={helpIcon} alt="" />
            What is two-factor authentication?
          </AccordionSummary>
          <AccordionDetails>
            <p>
              Two-factor authentication (2FA) is a way of adding security to
              your account, and preventing people from accessing your account –
              even if they have access to your email and password.
            </p>
            <p>
              You’ll need an app on your phone or a similar device that can
              generate a 6-digit “One Time Password” for you each time you sign
              in. We also recommend generating backup codes, which can each be
              used once to access your account if you aren’t able to use an
              authenticator app.
            </p>
            <p>
              If you do think someone has access to your email and password,{" "}
              <Link href="mailto:help@ripple.com.au">contact us</Link>{" "}
              immediately.
            </p>
          </AccordionDetails>
        </Accordion>
      </StyledAccordion>
      <SubHeader>Step 1</SubHeader>
      <Instructions>
        <p>
          First, download a free app to generate One Time Passwords. We
          recommend the Google Authenticator app for Apple iOS if you’re on an
          iPhone, or for Android if you have an Android phone.
        </p>
      </Instructions>
      <PrimaryActions>
        <StoreBadge href="https://itunes.apple.com/au/app/google-authenticator/id388497605?mt=8">
          <img
            src={appleAppStore}
            alt="Download on the Apple App Store"
            width={180}
            height={60}
          />
        </StoreBadge>

        <StoreBadge href="https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2&hl=en">
          <img
            src={googlePlayStore}
            alt="Get it on Google Play"
            width={200}
            height={60}
          />
        </StoreBadge>
      </PrimaryActions>
      <PrimaryActions>
        <NextButton onClick={submit}>
          I have downloaded an authenticator app
        </NextButton>
      </PrimaryActions>
    </>
  )
}

export default DownloadMFAApp
