import React from "react"
import { ThemeProvider } from "emotion-theming"
import colours from "./colours"
import ColouredBackground from "../components/ColouredBackground"

const theme = {
  headings: { color: "black" },
  buttons: {
    backgroundColor: "white",
    color: colours.teal
  }
}
type Props = {
  children: React.ReactNode
}

const Background = ({ children }: Props) => {
  return (
    <ThemeProvider theme={theme}>
      <ColouredBackground colour={colours.teal}>{children}</ColouredBackground>
    </ThemeProvider>
  )
}

export default Background
