import React from "react"
import type { ReactNode } from "react"
import TwoColumnTabbing from "./TwoColumnTabbing"
import TwoColumnScrolling from "./TwoColumnScrolling"

type Column = {
  name: string
  render: () => ReactNode
  maxWidth?: number
}

type TwoColumnResponsiveProps = {
  mobileView: boolean
  columns: Array<Column>
}

const TwoColumnResponsive = ({
  mobileView,
  columns
}: TwoColumnResponsiveProps) => {
  if (mobileView) {
    return <TwoColumnTabbing columns={columns} />
  }

  return <TwoColumnScrolling columns={columns} />
}

export default TwoColumnResponsive
