import React from "react"
import styled from "@emotion/styled"
import AvatarPreview from "../AvatarPreview"

const AvatarListContainer = styled.div<{ overlap }>((props) => ({
  display: "flex",
  flexDirection: "row",
  paddingLeft: props.overlap
}))

const baseIndex = 10

const AvatarPreviewContainer = styled.div<{ total; index; overlap }>(
  (props) => ({
    zIndex: baseIndex + props.total - props.index,
    marginLeft: -props.overlap
  })
)

type SummaryUser = {
  id: string
  avatar: string
  name: string
}

type AvatarListProps = {
  users: Array<SummaryUser>
  avatarSize: number
}

const AvatarList = ({ users, avatarSize }: AvatarListProps) => {
  const overlap = avatarSize / 3

  return (
    <AvatarListContainer overlap={overlap}>
      {users.map((user: SummaryUser, index: number) => (
        <AvatarPreviewContainer
          key={user.id}
          total={users.length}
          index={index}
          overlap={overlap}
        >
          <AvatarPreview
            value={user.avatar}
            name={user.name}
            size={avatarSize}
          />
        </AvatarPreviewContainer>
      ))}
    </AvatarListContainer>
  )
}

export default AvatarList
