import React from "react"
import styled from "@emotion/styled"
import colours from "../../themes/colours"
import { gutter } from "../Layout"
import { HeadingTwo } from "../TextStyles"

const Container = styled.div({
  backgroundColor: colours.whiteSilverBorder,
  borderWidth: 1,
  borderStyle: "solid",
  borderColor: colours.boldLineOnSilver,
  padding: `calc(${gutter} * 2) ${gutter}`,
  borderRadius: 3,
  marginTop: gutter
})

const Heading = styled(HeadingTwo)({
  color: "#444444",
  textAlign: "center"
})

const P = styled.p({
  color: "#444444",
  marginBottom: 0
})

type Props = {
  clientName?: string
}

const NoAssessmentsYet = ({ clientName }: Props) => (
  <Container>
    <Heading>No assessments completed yet</Heading>
    <P>
      Once an assessment deadline has occurred, you&apos;ll see a report here
      showing how {clientName || "the client"} is going.
    </P>
  </Container>
)

export default NoAssessmentsYet
