import React from "react"
import styled from "@emotion/styled"

const Container = styled.div<{ top; height }>((props) => ({
  top: props.top,
  height: props.height,
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  position: "absolute",
  left: 0,
  right: "24px",
  zIndex: 3
}))

const TextDisplay = styled.div<{ backgroundColor; height }>((props) => ({
  backgroundColor: props.backgroundColor,
  flex: 1,
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  padding: "0 1.5em",
  borderRadius: props.height / 2,
  fontSize: "0.85em",
  color: "#666666"
}))

type Props = {
  text: string
  colour?: string
  top: number | string
  height: number
}

const ScoreOverride = ({ text, colour, top, height }: Props) => {
  return (
    <Container className="score-override" top={top} height={height}>
      <TextDisplay backgroundColor={colour} height={height}>
        {text}
      </TextDisplay>
    </Container>
  )
}

ScoreOverride.defaultProps = {
  colour: "#DEDEDE"
}

export default ScoreOverride
