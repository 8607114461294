import React from "react"
import { Button as MuiButton } from "@material-ui/core/"
import styled from "@emotion/styled"
import chevronLeftIcon from "../images/icons/chevron-left.svg"

const LeftChevronIcon = styled.img({
  marginRight: ".5em"
})

type Props = {
  disabled?: boolean
  children?: React.ReactNode
  onClick?: (event?) => void
  style?
}

const BackButton = ({ disabled, children, onClick, style }: Props) => (
  <MuiButton
    type="button"
    disabled={disabled}
    variant="outlined"
    color="primary"
    style={{
      ...style
    }}
    {...(onClick && { onClick })}
  >
    <>
      <LeftChevronIcon src={chevronLeftIcon} alt="" />
      {children || "Back"}
    </>
  </MuiButton>
)

export default BackButton
