import React from "react"
import styled from "@emotion/styled"
import FontAwesome from "react-fontawesome"

type IconName = "warn" | "ok" | "error"
type Props = {
  type: IconName
  children: React.ReactNode
}

type IconCircleProps = {
  backgroundColor: string
  textColor?: string
  name: string
}

const Container = styled.div({
  flex: "0 0",
  display: "flex",
  flexDirection: "row",
  justifyContent: "flex-start",
  alignItems: "flex-start",
  background: "#eee",
  borderRadius: "1em",
  padding: "20px 24px 20px 18px",
  marginBottom: "1em"
})

const IconColumn = styled.div({
  flex: "0 0",
  paddingRight: "14px"
})

const TextColumn = styled.div({
  flex: "1 0",
  lineHeight: 1.5,
  " > :first-child": { marginTop: 0 },
  " > :last-child": { marginBottom: 0 },
  " a": {
    color: "black",
    fontWeight: "bold"
  }
})

const IconCircleBackground = styled.div<{
  backgroundColor: string
  textColor?: string
}>(({ backgroundColor, textColor }) => ({
  width: "20px",
  height: "20px",
  borderRadius: "50%",
  textAlign: "center",
  lineHeight: "20px",
  verticalAlign: "middle",
  fontSize: "16px",
  backgroundColor,
  color: textColor || "white"
}))

const IconCircle = ({ name, backgroundColor, textColor }: IconCircleProps) => (
  <IconCircleBackground textColor={textColor} backgroundColor={backgroundColor}>
    <FontAwesome name={name} />
  </IconCircleBackground>
)

const iconForType = (type: IconName) => {
  switch (type) {
    case "warn":
      return (
        <IconCircle
          backgroundColor="#ebb64f"
          textColor="black"
          name="exclamation"
        />
      )
    case "error":
      return <IconCircle backgroundColor="#BA2F2F" name="exclamation" />
    case "ok":
    default:
      return <IconCircle backgroundColor="#2F8348" name="check" />
  }
}

const InformationBox = ({ type, children }: Props) => (
  <Container>
    <IconColumn>{iconForType(type)}</IconColumn>
    <TextColumn>{children}</TextColumn>
  </Container>
)

export default InformationBox
