import React from "react"
import styled from "@emotion/styled"
import FontAwesome from "react-fontawesome"
import startCase from "lodash.startcase"
import colours from "../themes/colours"
import type { FeedItemAttachment as FeedItemAttachmentType } from "../state_types"

const Container = styled.div({
  display: "flex",
  flexDirection: "row"
})

const FileDetails = styled.div({
  flexGrow: 1,
  marginLeft: 10
})

const FilenameLink = styled.a({
  display: "inline-block",
  overflow: "hidden",
  textOverflow: "ellipsis",
  color: colours.grey,
  textDecoration: "none",
  ":hover": {
    textDecoration: "underline",
    color: colours.blue
  }
})

const iconStyles = {
  fontSize: "28px",
  width: 30
}

const RoleIndicator = styled.div({
  fontSize: "90%"
})

type Props = {
  attachment: FeedItemAttachmentType
}

const FeedItemAttachment = ({ attachment }: Props) => {
  return (
    <Container>
      <FontAwesome name="paperclip" style={iconStyles} />
      <FileDetails>
        <FilenameLink href={`/ripple_files/${attachment.path}`}>
          {attachment.filename}
        </FilenameLink>
        {attachment.role && (
          <RoleIndicator>{startCase(attachment.role)}</RoleIndicator>
        )}
      </FileDetails>
    </Container>
  )
}

export default FeedItemAttachment
