import React from "react"
import styled from "@emotion/styled"
import type { ImageScale } from "../contexts/assessment/types"

type Props = {
  imageScale: ImageScale
  value: number
  width: number
  topClip: number
}

const Container = styled.div<{ topClip }>((props) => ({
  flex: 1,
  display: "flex",
  flexDirection: "column",
  justifyContent: "flex-end",
  alignItems: "center",
  marginTop: -props.topClip,
  marginBottom: props.topClip
}))

const HorizontalBackgroundSprite = styled.div<{
  spritesCount
  spriteIndex
  spriteWidth
  width
  src
  alt: string
}>((props) => {
  const scale = props.width / props.spriteWidth
  return {
    backgroundSize: `${props.spritesCount * 100}% 100%`,
    backgroundImage: `url('${props.src}')`,
    backgroundPosition: `-${
      scale * props.spriteIndex * props.spriteWidth
    }px 0px`,
    overflow: "hidden",
    width: props.width,
    height: props.width
  }
})

const ImageScaleDisplay = ({ imageScale, value, width, topClip }: Props) => (
  <Container topClip={topClip}>
    <HorizontalBackgroundSprite
      src={imageScale.url}
      alt="Displays your scale"
      width={width}
      spritesCount={9}
      spriteWidth={420}
      spriteIndex={Math.round(value)}
    />
  </Container>
)

export default ImageScaleDisplay
