import React from "react"
import styled from "@emotion/styled"
import blueImg from "../images/ripple_logo_blue.svg"
import whiteImg from "../images/ripple_logo_white.svg"

const imageSize = "3.5em"

const LogoImg = styled.img({
  width: imageSize,
  height: imageSize
})

type Props = {
  colour: "white" | "blue"
}

const RippleLogo = ({ colour }: Props) => {
  if (colour === "blue") {
    return <LogoImg src={blueImg} />
  }
  if (colour === "white") {
    return <LogoImg src={whiteImg} />
  }
  return null
}

export default RippleLogo
