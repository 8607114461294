import React from "react"
import { connect } from "react-redux"
import styled from "@emotion/styled"
import SubmitButton from "../SubmitButton"
import type { Errors } from "../../state_types"

const Centered = styled.div({
  textAlign: "center"
})

type Props = {
  errors: Errors
  logIn: () => void
}

const NoAssessmentInvitation = ({ errors, logIn }: Props) => {
  return (
    <Centered>
      <h2>{errors.title}</h2>
      <p>To see all your current ripples, please</p>
      <SubmitButton onClick={logIn}>Login</SubmitButton>
    </Centered>
  )
}

const mapDispatchToProps = (dispatch) => ({
  logIn: () => dispatch({ type: "LOGIN" })
})

export default connect(null, mapDispatchToProps)(NoAssessmentInvitation)
