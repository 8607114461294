import React from "react"
import styled from "@emotion/styled"
import FontAwesome from "react-fontawesome"
import type {
  StepStatus,
  TransitionToStepFunc
} from "../contexts/shared/step_types"
import colours from "../themes/colours"
import { pageTransitionDuration } from "../themes/animations"

const StepLabelRow = styled.li<{ type: "active" | "inactive" }>({
  whiteSpace: "nowrap",
  minHeight: "3em"
})

const styleOverrides = {
  active: {
    fontSize: "1.2em",
    color: "black",
    position: "relative",
    "&::before": {
      transform: "scaleX(1)",
      transition: `transform ${pageTransitionDuration}s ease`
    }
  }
}

const StyledLink = styled.a(
  {
    display: "inline-block",
    textDecoration: "none",
    color: colours.deemphasisedText,
    transition: `fontSize ${pageTransitionDuration}s ease, color ${pageTransitionDuration}s ease`,
    marginRight: 5,
    "&::before": {
      content: "''",
      position: "absolute",
      width: "100%",
      height: 0,
      bottom: -6,
      borderBottomWidth: 4,
      borderBottomColor: colours.blue,
      borderBottomStyle: "solid",
      transform: "scaleX(0)"
    }
  },
  ({ type }) => styleOverrides[type]
)

const Tick = () => (
  <FontAwesome name="check" style={{ width: "1.2em", color: colours.blue }} />
)
// const Cross = () => <FontAwesome name="times" style={{ width: '1em', color: 'red' }} />
const Blank = () => (
  <FontAwesome name="check" style={{ width: "1.2em", color: "transparent" }} />
)

const stepIndicator = (step, key) => {
  if (!step.attempted) return <Blank key={key} />
  return step.valid ? <Tick key={key} /> : <Blank key={key} />
}

type Props = {
  transitionToStep: TransitionToStepFunc
  isCurrent?: boolean
  step: StepStatus
  iconSide?: "left" | "right"
}

const clickHandler = (onClick) => {
  return (event) => {
    event.preventDefault()
    onClick()
  }
}

const WizardStepLabel = ({
  step,
  isCurrent,
  transitionToStep,
  iconSide
}: Props) => {
  const type = isCurrent ? "active" : "inactive"
  const indicator = stepIndicator(step, "indicator")
  const link = (
    <StyledLink
      key="link"
      type={type}
      onClick={clickHandler(() => transitionToStep(step.id))}
      href="#"
    >
      {step.title}
    </StyledLink>
  )
  const parts = [indicator, " ", link]

  return (
    <StepLabelRow key={step.id} type={type}>
      {iconSide === "left" ? parts : parts.reverse()}
    </StepLabelRow>
  )
}

WizardStepLabel.defaultProps = {
  isCurrent: false,
  iconSide: "left"
}

export default WizardStepLabel
