import React from "react"
import map from "lodash.map"
import find from "lodash.find"
import Autocomplete, {
  createFilterOptions
} from "@material-ui/lab/Autocomplete"
import TextField from "@material-ui/core/TextField"
import { makeStyles } from "@material-ui/core/styles"
import naturalCompare from "natural-compare"
import { Field } from "redux-form"
import styled from "@emotion/styled"
import type { OrgMembership } from "../state_types"
import AvatarUploader from "./AvatarUploader"
import DatePickerField from "./DatePickerField"
import StringSelectField from "./StringSelectField"
import TextFieldForReduxForm from "./MuiExtensions/components/TextFieldForReduxForm"

type Props = {
  orgs: Array<OrgMembership>
  defaultValues: {
    clientName?: string
    groupNames: []
  }
  edit?: boolean
  currentOrg?: string
  clientSelected?: boolean
}

const FormContainer = styled.div({
  display: "flex",
  flexDirection: "column",
  alignItems: "center"
})

const FieldsContainer = styled.div({
  display: "flex",
  flex: 1,
  flexDirection: "column",
  justifyContent: "flex-start"
})

const FieldContainer = styled.div({
  minHeight: "4.5em",
  display: "flex",
  justifyContent: "flex-start",
  width: "320px",
  "@media(max-width: 320px)": {
    width: "100%"
  }
})

const useStyles = makeStyles({
  root: {
    padding: "15px 0 0"
  }
})

// Really need to understand how to apply existing v0.2 theme to the
// latest material-ui, as these styles below were rather tedious to figure out.
const StyledAutocomplete = styled(Autocomplete)`
  .MuiFormLabel-root {
    color: rgba(0, 0, 0, 0.3);
  }
  .MuiFormLabel-root.Mui-focused {
    color: rgba(0, 174, 236);
  }
  .MuiInput-underline:before {
    border-bottom: 1px solid rgb(224, 224, 224)
  }
  .MuiInput-underline:after {
    border-bottom: 1px solid rgb(224, 224, 224)
  }
  .MuiInput-underline.Mui-focused:before {
    border-bottom: 1px solid rgb(0, 174, 239);
  }
  .MuiInput-underline.Mui-focused:after {
    border-bottom: 1px solid rgb(0, 174, 239);
  }
  .MuiInput-underline:hover:not(.Mui-disabled):before {
    border-bottom: 1px solid rgb(224, 224, 224)
  }
)`

const filter = createFilterOptions()

const filterOptions = (options, params) => {
  const filtered = filter(options, params)

  // Suggest the creation of a new value
  if (params.inputValue !== "" && !filtered.includes(params.inputValue)) {
    filtered.push(params.inputValue)
  }

  return filtered
}

const renderAutocomplete = ({
  ...custom
}: {
  input?
  renderInput
  options
}) => {
  return (
    <StyledAutocomplete
      {...custom}
      onChange={(_event, value) => custom.input.onChange(value)}
      filterOptions={filterOptions}
    />
  )
}

const HiddenInput = styled.input({
  width: "0.1px",
  height: "0.1px",
  opacity: 0,
  overflow: "hidden",
  position: "absolute",
  zIndex: -1
})

const RippleDetailsForm = (props: Props) => {
  const { orgs, edit, currentOrg, defaultValues, clientSelected } = props

  const classes = useStyles()

  const getGroupNames = () => {
    if (!currentOrg) return []

    const orgDetails = find(orgs, (org) => {
      return org.slug === currentOrg
    })

    return orgDetails.groupNames.sort(naturalCompare)
  }

  if (clientSelected) {
    return (
      <FormContainer>
        <FieldsContainer>
          <Field name="clientId" component={HiddenInput} />
        </FieldsContainer>
      </FormContainer>
    )
  }

  return (
    <FormContainer>
      <Field
        name="clientAvatar"
        component={AvatarUploader}
        personName={defaultValues.clientName}
      />
      <FieldsContainer>
        {!edit && orgs.length > 1 && (
          <FieldContainer>
            <Field
              name="organisation"
              component={StringSelectField}
              floatingLabelText="Organisation"
              fullWidth
              options={map(orgs, (org) => [org.slug, org.name])}
            />
          </FieldContainer>
        )}
        <FieldContainer>
          <Field
            name="clientName"
            component={TextFieldForReduxForm}
            label="Client name"
            fullWidth
          />
        </FieldContainer>
        <FieldContainer>
          <Field
            name="rippleName"
            component={TextFieldForReduxForm}
            label="Name, nickname or case ID"
            fullWidth
          />
        </FieldContainer>
        <FieldContainer>
          <Field
            name="clientExternalRef"
            component={TextFieldForReduxForm}
            label="Unique ID (e.g. student ID)"
            fullWidth
          />
        </FieldContainer>
        <FieldContainer>
          <Field
            name="gender"
            component={StringSelectField}
            floatingLabelText="Gender"
            options={[
              ["female", "Female"],
              ["male", "Male"],
              ["diverse", "Diverse"],
              ["other", "Other"]
            ]}
          />
        </FieldContainer>
        <FieldContainer>
          <Field
            name="dateOfBirth"
            maxDate={new Date()}
            component={DatePickerField}
            floatingLabelText="Date of Birth"
            openToYearSelection
            fullWidth
          />
        </FieldContainer>
        <FieldContainer>
          <Field
            name="groupNames"
            component={renderAutocomplete}
            className={classes.root}
            options={getGroupNames()}
            multiple
            freeSolo
            selectOnFocus
            clearOnBlur
            fullWidth
            defaultValue={defaultValues.groupNames}
            renderInput={(params) => {
              return <TextField {...params} variant="standard" label="Groups" />
            }}
          />
        </FieldContainer>
      </FieldsContainer>
    </FormContainer>
  )
}

RippleDetailsForm.defaultProps = {
  edit: false
}

export default RippleDetailsForm
