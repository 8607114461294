import React from "react"
import evPos from "ev-pos/src/index"
import { Vector2 } from "./types"

export const pauseEvent = (e: Event) => {
  e.stopPropagation()
  if (e.cancelable) {
    e.preventDefault()
  }
}

type Props = {
  onChange: (relativeMousePosition: Vector2) => void
}

class Draggable extends React.Component<Props> {
  onPosChange(posInSVG) {
    this.props.onChange(posInSVG)
  }

  onMove(e) {
    const svg = document.getElementById("scoring-widget")
    const relative = evPos(e, svg)
    this.onPosChange(relative)
  }

  moveListenerArgs = (
    isTouch: boolean
  ): [string, (event: Event) => void, { passive: boolean }] => [
    isTouch ? "touchmove" : "mousemove",
    isTouch ? this.handleTouchMove : this.handleMouseMove,
    { passive: false }
  ]

  endListenerArgs = (
    isTouch: boolean
  ): [string, (event: Event) => void, { passive: boolean }] => [
    isTouch ? "touchend" : "mouseup",
    isTouch ? this.handleTouchEnd : this.handleMouseUp,
    { passive: false }
  ]

  addEventListeners = (isTouch: boolean) => {
    document.addEventListener(...this.moveListenerArgs(isTouch))
    document.addEventListener(...this.endListenerArgs(isTouch))
  }

  removeEventListeners = (isTouch: boolean) => {
    const [moveEventType, moveEventListenerFunction] =
      this.moveListenerArgs(isTouch)
    const [endEventType, endEventListenerFunction] =
      this.endListenerArgs(isTouch)
    document.removeEventListener(moveEventType, moveEventListenerFunction)
    document.removeEventListener(endEventType, endEventListenerFunction)
  }

  handleMouseDown = (e) => {
    pauseEvent(e)
    this.addEventListeners(false)
  }

  handleTouchStart = (e) => {
    pauseEvent(e)
    this.addEventListeners(true)
  }

  handleMouseUp = (e) => {
    pauseEvent(e)
    this.removeEventListeners(false)
  }

  handleTouchEnd = (e) => {
    pauseEvent(e)
    this.removeEventListeners(true)
  }

  handleMouseMove = (e) => {
    pauseEvent(e)
    this.onMove(e)
  }

  handleTouchMove = (e) => {
    pauseEvent(e)
    this.onMove(e)
  }

  render() {
    const timeStamp = Date.now()
    const filterId = `slider-circle-shadow-${timeStamp}`

    return (
      <React.Fragment>
        <defs key="slider-circle-def">
          <filter id={filterId} x="-20%" y="-20%" width="200%" height="200%">
            <feDropShadow dx="3" dy="4" stdDeviation="3" floodOpacity="0.5" />
          </filter>
        </defs>
        ,
        <g
          onMouseDown={this.handleMouseDown}
          onTouchStart={this.handleTouchStart}
          filter={`url(#${filterId})`}
        >
          {this.props.children}
        </g>
      </React.Fragment>
    )
  }
}

export default Draggable
