import React from "react"
import { SelectField } from "material-ui"

interface IProps {
  input: {
    onChange: (value: string) => {}
    value: string
    onBlur: (value: string) => {}
    onSubmit: (value: string) => {}
  }
  meta: { touched: boolean; error: string }
  onChange: (value: string) => {}
  name: string
  onSubmit: (event: React.ChangeEvent, index: number, value: string) => {}
}

const MuiSelectField = (props: IProps) => {
  const {
    input: { onChange, value, onBlur, ...inputProps },
    meta: { touched, error },
    onChange: onChangeFromField,
    ...rest
  } = props
  return (
    <SelectField
      {...inputProps}
      value={value}
      errorText={touched && error}
      onChange={(_event, _index, onChangeValue) => {
        onChange(onChangeValue)
        if (onChangeFromField) {
          onChangeFromField(onChangeValue)
        }
      }}
      onBlur={() => onBlur(value)}
      {...rest}
    />
  )
}

export default MuiSelectField
