import React from "react"
import isempty from "lodash.isempty"
import isnumber from "lodash.isnumber"
import styled from "@emotion/styled"
import Link from "redux-first-router-link"
import { Ripple } from "../state_types"
import AvatarPreview from "./AvatarPreview"
import { gutter } from "./Layout"
import { HeadingOne } from "./TextStyles"
import RippleDetails from "../contexts/ripple_details/StateDecorator"
import colours from "../themes/colours"

const AvatarContainer = styled.div({
  paddingRight: gutter
})

const DetailsContainer = styled.div({
  display: "flex",
  flexDirection: "column",
  justifyContent: "center"
})

const RippleName = styled(HeadingOne)({
  fontSize: "1.6em",
  fontWeight: 600,
  marginBottom: 0
})

const Container = styled.div({
  display: "flex"
})

const FurtherDetails = styled.div({})

const DetailContainer = styled.div({
  display: "inline-block",
  whiteSpace: "nowrap",
  marginRight: "1em",
  ":last-child": {
    marginRight: 0
  }
})

const Detail = ({ label, value }: { label: string; value: unknown }) => {
  if (isempty(value) && !isnumber(value)) return null

  return (
    <DetailContainer>
      <span>{label}:</span> <span>{value}</span>
    </DetailContainer>
  )
}

const Edit = styled.span({
  paddingTop: "5px"
})

type Props = {
  ripple: Ripple
}

const RippleClientDetails = ({ ripple: rippleData }: Props) => {
  const ripple = new RippleDetails(rippleData)

  return (
    <Container>
      <AvatarContainer>
        <AvatarPreview
          value={ripple.data.clientAvatar}
          size={60}
          name={ripple.data.clientName}
        />
      </AvatarContainer>
      <DetailsContainer>
        <RippleName>{ripple.data.clientName}</RippleName>
        <FurtherDetails>
          <Detail label="Age" value={ripple.computeAge()} />
          <Detail
            label="Groups"
            value={ripple.groupNamesFormatted().toString()}
          />
        </FurtherDetails>
        {ripple.data.canEdit ? (
          <Edit>
            <Link
              to={{
                type: "EDIT_RIPPLE_CLIENT",
                payload: { rippleUuid: ripple.data.uuid }
              }}
              style={{
                textDecoration: "none",
                color: colours.blue,
                cursor: "pointer"
              }}
            >
              edit
            </Link>
          </Edit>
        ) : null}
      </DetailsContainer>
    </Container>
  )
}

export default RippleClientDetails
