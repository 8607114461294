import styled from "@emotion/styled"
import { CardContainer } from "./CardContainer"

const CalloutContainer = styled(CardContainer)<{ flex?: string }>({
  width: "100%",
  alignSelf: "center",
  borderRadius: "3px",
  boxShadow: "0 2px 10px 0 rgba(0,0,0,0.1)"
})

export default CalloutContainer
