import type { FrameworkSteps, Question } from "../contexts/assessment/types"

const SPLITTER = "question"

const splitFrameworkIntoQuestionSets = (list: FrameworkSteps): Question[][] => {
  return list.reduce((acc: Question[][], curr) => {
    if (curr.type !== SPLITTER) {
      // If the accumulator is empty or the existing subarray already has questions
      // create a new "section" array to receive the next batch of questions
      if (acc.length === 0 || acc[acc.length - 1].length > 1) {
        return [...acc, []]
      }

      return acc
    }

    if (acc.length === 0) {
      acc.push([])
    }

    acc[acc.length - 1].push(curr)

    return acc
  }, [])
}

export default splitFrameworkIntoQuestionSets
