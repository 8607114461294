import React from "react"
import { TextField } from "@material-ui/core"
import styled from "@emotion/styled"
import SubmitButton from "../SubmitButton"
import type { User } from "../../state_types"

type Props = {
  user: User
  updateUser: (values) => void
}

const Container = styled.div({
  fontSize: "0.8em"
})

const Title = styled.h2({
  paddingBottom: "10px"
})

const Fields = styled.div({
  paddingBottom: "40px"
})

const FieldContainer = styled.div({
  paddingBottom: "20px"
})

const Footer = styled.div({})

type State = {
  name: string
  email: string
}

class EditUserDialog extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props)

    this.state = {
      name: props.user.name,
      email: props.user.email
    }
  }

  handleChange = (field: string) => {
    return (event: React.ChangeEvent<HTMLInputElement>) => {
      const value = event.target.value

      this.setState((prevState) => ({
        ...prevState,
        [field]: value
      }))
    }
  }

  saveChanges = () => {
    const { updateUser } = this.props

    updateUser(this.state)
  }

  render() {
    return (
      <Container>
        <Title>Edit user</Title>
        <Fields>
          <FieldContainer>
            <TextField
              style={{ fontSize: "1em", width: "100%" }}
              value={this.state.name}
              label="Name"
              id="name"
              onChange={this.handleChange("name")}
            />
          </FieldContainer>
          <FieldContainer>
            <TextField
              style={{ fontSize: "1em", width: "100%" }}
              value={this.state.email}
              label="Email"
              id="email"
              type="email"
              onChange={this.handleChange("email")}
            />
          </FieldContainer>
        </Fields>
        <Footer>
          <SubmitButton onClick={this.saveChanges}>Update</SubmitButton>
        </Footer>
      </Container>
    )
  }
}

export default EditUserDialog
