import React from "react"
import { Button as MuiButton } from "@material-ui/core/"

type Props = {
  disabled?: boolean
  children?: React.ReactNode
  onClick?: (event?) => void
  style?
}

const SubmitButton = ({ disabled, children, onClick, style }: Props) => (
  <MuiButton
    type="submit"
    disabled={disabled}
    variant="contained"
    color="primary"
    style={{
      ...style
    }}
    {...(onClick && { onClick })}
  >
    {children || "Submit"}
  </MuiButton>
)

export default SubmitButton
