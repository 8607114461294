import React, { Component } from "react"
import { connect } from "react-redux"
import styled from "@emotion/styled"
import { FunctionLink } from "../Link"
import api from "../../api"
import { submitDevLogin, logout } from "../../api_actions"

const showDevLogin =
  process.env.RAILS_ENV === "development" || process.env.RAILS_ENV === "staging"

const DevNav = styled.nav({
  color: "white",
  backgroundColor: "#505",
  borderBottom: "1px solid black",
  textAlign: "center"
})

interface IProps {
  onClick?
}

interface IState {
  logins?
}

class DevLogin extends Component<IProps, IState> {
  constructor(props) {
    super(props)
    this.state = { logins: [] }
  }

  componentDidMount() {
    if (showDevLogin) {
      api.dev
        .getQuickLogins()
        .then((json: { errors? }) => {
          if (json.errors) {
            console.error(json.errors) // eslint-disable-line no-console
          } else {
            this.setState({ logins: json })
          }
        })
        .catch((error) => {
          console.error(error) // eslint-disable-line no-console
        })
    }
  }

  render() {
    if (showDevLogin) {
      const logins = this.state.logins
        .map((data) => (
          <FunctionLink
            key={data.email}
            onClick={() => this.props.onClick(data.email)}
          >
            {data.name}
          </FunctionLink>
        ))
        .reduce((acc, x) => (acc === null ? [x] : [acc, " | ", x]), null)

      return (
        <DevNav className="hide-from-printer">
          Login &nbsp;
          {logins}
        </DevNav>
      )
    }

    return ""
  }
}
const mapStateToProps = () => {
  return {}
}

const mapDispatchToProps = (dispatch) => {
  return {
    onClick: (emailOrUsername) => {
      logout()(dispatch).then(() => dispatch(submitDevLogin(emailOrUsername)))
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(DevLogin)
