import React from "react"
import styled from "@emotion/styled"
import map from "lodash.map"
import AvatarPreview from "../AvatarPreview"
import ScoreCircle from "../ScoreCircle"
import { UserScore } from "../../state_types"
import type { UserHash } from "../../state_types"

const Container = styled.div({
  display: "flex",
  flexDirection: "column",
  justifyContent: "flex-start"
})

const Row = styled.div({
  display: "flex",
  flexDirection: "row",
  justifyContent: "flex-start",
  marginBottom: 15,
  ":last-child": {
    marginBottom: 0
  }
})

const AvatarContainer = styled.div({
  marginRight: 15
})

const Details = styled.div({
  display: "flex",
  flexDirection: "column",
  flex: 1
})

const Name = styled.div({
  fontSize: "1.15em"
})

const ScoreContainer = styled.div({
  paddingLeft: 15
})

type Props = {
  userBreakdown: Array<UserScore>
  thresholds: Array<number>
  users: UserHash
}

const anonymousUser = (index) => {
  return {
    name: `Anonymous ${index}`,
    avatar: null
  }
}

const ScoresByUser = ({ userBreakdown, thresholds, users }: Props) => {
  return (
    <Container>
      {map(userBreakdown, (userScore) => {
        const { id, score, index } = userScore
        const user = users[id] || anonymousUser(index)
        return (
          <Row key={id}>
            <AvatarContainer>
              <AvatarPreview name={user.name} value={user.avatar} size={37} />
            </AvatarContainer>
            <Details>
              <Name>{user.name}</Name>
            </Details>
            <ScoreContainer>
              {score && (
                <ScoreCircle
                  score={score}
                  radius={15}
                  thresholds={thresholds}
                />
              )}
            </ScoreContainer>
          </Row>
        )
      })}
    </Container>
  )
}

export default ScoresByUser
