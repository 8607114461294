import React from "react"
import styled from "@emotion/styled"
import type { Errors } from "../../state_types"
import { HeadingTwo } from "../TextStyles"

const ErrorMessage = styled.div({
  color: "red"
})

const Narrow = styled.div({
  maxWidth: 400
})

const CenteredColumn = styled.div({
  display: "flex",
  flexDirection: "column",
  alignItems: "center"
})

const Header = styled(HeadingTwo)({
  marginBottom: "0.3em",
  color: "black"
})

type Props = {
  errors: Errors
}

const NoUserInvitation = ({ errors }: Props) => {
  return (
    <CenteredColumn>
      <Narrow>
        <Header>Sorry,</Header>
        <ErrorMessage>{errors.title}</ErrorMessage>
        <p>
          If you were expecting this link to work, please contact your Ripple
          liaison officer within your Organisation or contact Team Ripple at{" "}
          <a href="mailto:help@ripple.com.au">help@ripple.com.au</a>
        </p>
      </Narrow>
    </CenteredColumn>
  )
}

export default NoUserInvitation
