import React from "react"
import { connect } from "react-redux"
import includes from "lodash.includes"
import { updateOrgUserRole } from "../api_actions"
import ToggleButton from "./ToggleButton"

type Props = {
  roleName: string
  label: string
  orgRoles: Array<string>
  email: string
  isDisabled: boolean
  small: boolean
  faIcon?: string
  updateRole: (email: string, roleName: string, roleValue: boolean) => any
}

class ToggleOrgRoleButton extends React.Component<Props> {
  handleUpdate = (value) => {
    this.props.updateRole(this.props.email, this.props.roleName, value)
  }

  render() {
    const { orgRoles, roleName, label, isDisabled, small, faIcon } = this.props
    const value = includes(orgRoles || [], roleName)
    return (
      <ToggleButton
        label={label}
        value={value}
        onChange={this.handleUpdate}
        isDisabled={isDisabled}
        small={small}
        faIcon={faIcon}
      />
    )
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    updateRole: (email: string, roleName: string, roleValue: boolean) => {
      dispatch(updateOrgUserRole(email, roleName, roleValue))
    }
  }
}

export default connect(null, mapDispatchToProps)(ToggleOrgRoleButton)
