import { connect } from "react-redux"
import windowDimensions from "react-window-dimensions"
import debounce from "lodash.debounce"
import OrgDashboardDisconnected from "./OrgDashboardDisconnected"

const OrgDashboardWithWidth = windowDimensions({
  take: () => ({
    windowWidth: window.innerWidth
  }),
  debounce: (onResize) => debounce(onResize, 100)
})(OrgDashboardDisconnected)

const mapStateToProps = (state) => ({
  loaded: !!state.org.data || !!state.org.page,
  org: state.org
})

export default connect(mapStateToProps)(OrgDashboardWithWidth)
