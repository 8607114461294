import map from "lodash.map"
import includes from "lodash.includes"
import flatten from "lodash.flatten"
import filter from "lodash.filter"
import type {
  State,
  User,
  OrgMembership,
  RoleNames,
  Action,
  AuthDetailsApiResult
} from "../../state_types"
import RippleStateDecorator from "../ripple_details/StateDecorator"

type UserDecoratorType = {
  loggedIn: () => boolean
  canCreateRipples: () => boolean
  canImportRipples: () => boolean
  defaultRippleUuid?: () => string
  canArchiveRipple: (rippleState: RippleStateDecorator) => boolean
  orgsAdministering: () => Array<OrgMembership>
}

class NullUserDecorator {
  loggedIn(): boolean {
    return false
  }

  canCreateRipples(): boolean {
    return false
  }

  defaultRippleUuid(): string | null | undefined {
    return null
  }

  orgsAdministering() {
    return []
  }

  canArchiveRipple(_rippleState: RippleStateDecorator): boolean {
    return false
  }
}

class UserDecorator extends NullUserDecorator {
  user: User

  constructor(user: User) {
    super()
    this.user = user
  }

  loggedIn(): boolean {
    return true
  }

  canCreateRipples(): boolean {
    return includes(this.orgRoleNames, "ripple_creator")
  }

  canImportRipples(): boolean {
    return this.canCreateRipples() || includes(this.orgRoleNames, "admin")
  }

  canArchiveRipple(rippleState: RippleStateDecorator): boolean {
    const rippleAdminOrgSlugs = map(this.orgsAdministering(), "slug")
    const canAdminAllRipples =
      rippleState && includes(rippleAdminOrgSlugs, rippleState.orgSlug())
    const isRippleAdmin =
      rippleState && includes(rippleState.adminEmails(), this.user.email)

    return canAdminAllRipples || isRippleAdmin
  }

  defaultRippleUuid(): string | null | undefined {
    return this.user.defaultRippleUuid
  }

  orgsAdministering() {
    return filter(this.orgs, (org) => includes(org.roles, "admin"))
  }

  get orgRoleNames(): RoleNames {
    return flatten(map(this.orgs, "roles"))
  }

  get orgs(): Array<OrgMembership> {
    return this.user.orgs || []
  }
}

export default class UserStateDecorator {
  me: AuthDetailsApiResult

  userDecorator: unknown

  constructor(state: State) {
    const me = state.me || {}
    if (me.data && me.data.token && me.data.user) {
      this.userDecorator = new UserDecorator(me.data.user)
    } else {
      this.userDecorator = new NullUserDecorator()
    }
    this.me = me
  }

  loggedIn(): boolean {
    return this.user.loggedIn()
  }

  afterLoginAction(): Action {
    return this.me.afterLogin
  }

  afterLoginRedirect(): string {
    return this.me.afterLoginRedirect
  }

  canCreateRipples(): boolean {
    return this.user.canCreateRipples()
  }

  canImportRipples(): boolean {
    return this.user.canImportRipples()
  }

  canArchiveRipple(rippleState: RippleStateDecorator): boolean {
    return this.user.canArchiveRipple(rippleState)
  }

  defaultRippleUuid(): string | null | undefined {
    return this.user.defaultRippleUuid()
  }

  orgsAdministering() {
    return this.user.orgsAdministering()
  }

  get user(): UserDecoratorType {
    return this.userDecorator as any
  }
}
