import get from "lodash.get"
import React from "react"
import { connect } from "react-redux"
import styled from "@emotion/styled"
import naturalCompare from "natural-compare"
import { faPrint, faTimes } from "@fortawesome/free-solid-svg-icons"
import Theme from "../../themes/White"
import Filters from "../../components/qr/Filters"
import QrRippleTable from "../../components/qr/RippleTable"
import Header from "../../components/Header"
import LoadablePage from "../../components/LoadablePage"
import { largePageWidth, gutter } from "../../components/Layout"
import QrCodes from "../../components/qr/QrCodes"
import { TallButton } from "../../components/TallButton"

const Container = styled.div({
  width: "100%",
  maxWidth: largePageWidth,
  margin: "0 auto",
  padding: `0 ${gutter}`
})

const Contents = styled.div({})

const Overlay = styled.div({
  backgroundColor: "white",
  width: "100%",
  margin: "0 !important",
  padding: "0 !important",
  overflow: "visible !important"
})

const OverlayContainer = styled.div({
  maxWidth: "1200px",
  margin: "0 auto",
  padding: "20px 0"
})

const LinkContainer = styled.div({
  display: "flex",
  justifyContent: "space-around"
})

const Links = (props: { hideQrCodes?: React.MouseEventHandler }) => {
  return (
    <LinkContainer className="hide-from-printer">
      <TallButton label="Print" icon={faPrint} onClick={() => window.print()} />
      <TallButton label="close" icon={faTimes} onClick={props.hideQrCodes} />
    </LinkContainer>
  )
}

const QrCodesModal = (props: {
  hideQrCodes?: React.MouseEventHandler
  assessors?: Array<any>
  ripples?: Array<any>
}) => {
  const linkProps = { hideQrCodes: props.hideQrCodes }
  const qrProps = { assessors: props.assessors, ripples: props.ripples }

  return (
    <Overlay>
      <OverlayContainer>
        <Links {...linkProps} />
        <QrCodes {...qrProps} />
        <Links {...linkProps} />
      </OverlayContainer>
    </Overlay>
  )
}

interface IProps {
  loaded?
  ripples?
  assessors?
  activeAssessors?
  showPrintPage?
  hideQrCodes?: React.MouseEventHandler
}

const QrManagePage = (props: IProps) => {
  const {
    loaded,
    ripples,
    assessors,
    activeAssessors,
    showPrintPage,
    hideQrCodes
  } = props

  if (showPrintPage) {
    return (
      <QrCodesModal
        assessors={activeAssessors}
        ripples={ripples}
        hideQrCodes={hideQrCodes}
      />
    )
  }

  return (
    <Theme>
      <Header />
      <Container>
        <Contents>
          <h1>Manage QR codes</h1>

          <Filters />
          <LoadablePage loaded={loaded}>
            {() => (
              <QrRippleTable
                ripples={ripples}
                assessors={assessors}
                activeAssessors={activeAssessors}
              />
            )}
          </LoadablePage>
        </Contents>
      </Container>
    </Theme>
  )
}

const sortAssessors = (assessors) =>
  assessors.sort((a, b) => naturalCompare(a.name, b.name))

const mapStateToProps = (state) => {
  const loaded = !!get(state, "qrCodes.ripples")
  const ripples = get(state, "qrCodes.ripples")
  const assessors = get(state, "qrCodes.assessors")
  const activeAssessors =
    assessors && sortAssessors(assessors.filter((a) => a.qrActive))
  const showPrintPage = !!get(state, "qrCodes.showPrintPage")

  return { loaded, ripples, assessors, activeAssessors, showPrintPage }
}

const mapDispatchToProps = (dispatch) => {
  return {
    hideQrCodes: () => dispatch({ type: "HIDE_QR_CODES" })
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(QrManagePage)
