import React from "react"
import styled from "@emotion/styled"
import moment from "moment"
import map from "lodash.map"
import includes from "lodash.includes"
import isEmpty from "lodash.isempty"
import pluralize from "pluralize"
import { connect } from "react-redux"
import Link from "redux-first-router-link"
import { Ripple } from "../../state_types"
import NextAssessment from "./NextAssessment"
import ContentContainer from "../RippleInfoContentContainer"
import type { AssessmentWindow, Schedule } from "../../state_types"
import colours from "../../themes/colours"

const Header = styled.div({
  display: "flex",
  justifyContent: "space-between",
  width: "100%"
})

const Body = styled.div()

const CircleContainer = styled.div({
  display: "flex",
  justifyContent: "space-between"
})

const Circle = styled.div<{ active }>((props) => {
  return {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "37px",
    width: "37px",
    borderRadius: "50%",
    color: props.active ? "white" : "#565656",
    border: `1px solid ${props.active ? "#4AE2B2" : "#D7D7D7"}`,
    backgroundColor: props.active ? "#4AE2B2" : "#FEFEFE"
  }
})

const CircleText = styled.div({
  fontFamily: "Poppins",
  fontSize: "17px",
  lineHeight: "28px",
  textAlign: "center"
})

const SchedulePeriodInfo = styled.div({
  display: "flex",
  justifyContent: "space-between",
  paddingBottom: "25px"
})

const ScheduleText = styled.div({
  color: "#000000",
  fontFamily: "Poppins",
  fontSize: "16px",
  fontWeight: 500,
  lineHeight: "23px"
})

const AssessmentText = styled.div({
  color: "#000000",
  fontFamily: "Poppins",
  fontSize: "12px",
  lineHeight: "16px",
  paddingTop: "25px"
})

type Props = {
  nextAssessment: AssessmentWindow
  schedule: Schedule
  ripple: Ripple
}

type WeekDayProps = {
  enabledDays: Array<number>
}

const dayNames = ["Su", "M", "Tu", "W", "Th", "F", "Sa"]
const dayOrder = [1, 2, 3, 4, 5, 6, 0]

const WeekDays = ({ enabledDays }: WeekDayProps) => {
  return (
    <CircleContainer>
      {map(dayOrder, (index) => (
        <Circle
          key={index}
          active={isEmpty(enabledDays) || includes(enabledDays, index)}
        >
          <CircleText>{dayNames[index]}</CircleText>
        </Circle>
      ))}
    </CircleContainer>
  )
}

const CirclePanel = ({ startOn }: { startOn: moment.MomentInput }) => {
  const startDay = startOn && moment(startOn).day()

  return <WeekDays enabledDays={[startDay]} />
}

const WeekDetails = ({ startOn }: { startOn: moment.MomentInput }) => (
  <CirclePanel startOn={startOn} />
)
const MonthDetails = ({ startOn }: { startOn: moment.MomentInput }) => (
  <CirclePanel startOn={startOn} />
)
const DayDetails = ({ enabledDays }: { enabledDays: number[] }) =>
  enabledDays ? <WeekDays enabledDays={enabledDays} /> : null

const ScheduleHeader = ({
  nextAssessment,
  ripple
}: {
  nextAssessment: AssessmentWindow
  ripple
}) => {
  return (
    <Header>
      <NextAssessment
        iconWidth={40}
        paddingWidth={0}
        assessmentWindow={nextAssessment}
      />
      {ripple.canEdit ? (
        <Link
          to={{
            type: "EDIT_RIPPLE_SCHEDULE",
            payload: { rippleUuid: ripple.uuid }
          }}
          style={{
            textDecoration: "none",
            color: colours.blue,
            cursor: "pointer"
          }}
        >
          edit
        </Link>
      ) : null}
    </Header>
  )
}

const periodDetails = {
  month: MonthDetails,
  week: WeekDetails,
  day: DayDetails
}

const ScheduleBody = ({
  periodType,
  period,
  startOn,
  daysOpen,
  wdays
}: Schedule) => {
  const repeats = periodType !== "none"
  const PeriodDetails = periodDetails[periodType]

  return (
    <Body>
      {repeats ? (
        <SchedulePeriodInfo>
          <ScheduleText>Repeat: {periodType}</ScheduleText>
          <ScheduleText>
            Every:
            {period}
          </ScheduleText>
        </SchedulePeriodInfo>
      ) : (
        "Doesn't repeat"
      )}
      {repeats && <PeriodDetails startOn={startOn} enabledDays={wdays} />}
      <AssessmentText>
        {`Assessments remain open for ${pluralize("day", daysOpen, true)}`}
      </AssessmentText>
    </Body>
  )
}

const ScheduleDetails = ({ nextAssessment, schedule, ripple }: Props) => {
  if (!schedule) return null

  const { periodType, period, startOn, daysOpen, wdays } = schedule

  return (
    <ContentContainer
      headerContent={
        <ScheduleHeader nextAssessment={nextAssessment} ripple={ripple} />
      }
      bodyContent={
        <ScheduleBody
          periodType={periodType}
          period={period}
          startOn={startOn}
          daysOpen={daysOpen}
          wdays={wdays}
        />
      }
    />
  )
}

const mapDispatchToProps = (_dispatch) => ({})

export default connect(null, mapDispatchToProps)(ScheduleDetails)
