import React from "react"
import { Button as MuiButton } from "@material-ui/core"
import FontAwesome from "react-fontawesome"
import colours from "../themes/colours"

type Props = {
  label: string
  faIcon?: string
  value: boolean
  isDisabled?: boolean
  small?: boolean
  onChange: (value: boolean) => any
}

class ToggleButton extends React.Component<Props> {
  static defaultProps = {
    isDisabled: false,
    faIcon: null
  }

  handleChange = () => {
    this.props.onChange(!this.props.value)
  }

  render() {
    const { value, label, faIcon, isDisabled, small } = this.props

    return (
      <MuiButton
        style={{
          height: small ? "26px" : "40px",
          lineHeight: "20px",
          border: `1px solid ${colours.green}`,
          color: value ? "white" : colours.grey,
          whiteSpace: "nowrap",
          minWidth: small ? "4em" : "6em",
          paddingLeft: small ? "0" : "1em",
          paddingRight: small ? "0" : "1em",
          backgroundColor: value ? colours.green : "white",
          fontSize: small ? "0.8em" : "1em",
          fontWeight: 400
        }}
        startIcon={
          faIcon && (
            <FontAwesome
              name={faIcon}
              style={{
                width: "1em",
                height: "1em",
                margin: "0",
                color: value ? "white" : colours.green
              }}
            />
          )
        }
        onClick={this.handleChange}
        disabled={isDisabled}
      >
        {label}
      </MuiButton>
    )
  }
}

export default ToggleButton
