import immutable from "object-path-immutable"
import FrameworkModel from "./FrameworkModel"
import { applyReducerHash, reduceApiResult } from "../../redux_helpers"
import RippleGA from "../../ripple_ga"
import type { Action, AssessmentApiResult } from "../../state_types"

type UpdateQuestionInputAction = {
  type: "UPDATE_QUESTION_INPUT"
  payload: {
    id: string
    rating: number
  }
}

type UpdateCommentAction = {
  type: "UPDATE_QUESTION_COMMENT"
  payload: {
    id: string
    comment: string
  }
}

type UpdateAssessmentDataAction = {
  type: "UPDATE_ASSESSMENT_DATA"
  payload: {
    id: string
    value: string
  }
}

type ClearQuestionInputAction = {
  type: "CLEAR_QUESTION_INPUT"
  payload: {
    id: string
  }
}

type ReceivedOpenAssessementAction = {
  type: "RECEIVED_OPEN_ASSESSMENT"
  payload: {
    assessment: AssessmentApiResult
  }
}

function nextStage(frameworkModel, currentStage) {
  return frameworkModel.nextStepID(currentStage)
}

function previousStage(frameworkModel, currentStage) {
  return frameworkModel.previousStepID(currentStage)
}

const assessmentReducers = {
  RECEIVED_OPEN_ASSESSMENT: (
    assessmentApiResult: AssessmentApiResult | null | undefined,
    { payload }: ReceivedOpenAssessementAction
  ) => {
    if (!(payload.assessment && payload.assessment.errors)) {
      RippleGA.configUserOrganisation(
        payload.assessment.data.ripple.organisationSlug
      )
    }
    return payload.assessment
  },

  PREVIOUS_ASSESSMENT_PAGE: (assessmentApiResult?: AssessmentApiResult) => {
    return reduceApiResult(assessmentApiResult, (assessment) => {
      const frameworkModel = new FrameworkModel(assessment.framework.steps)
      return {
        ...assessment,
        currentStage: previousStage(frameworkModel, assessment.currentStage)
      }
    })
  },

  NEXT_ASSESSMENT_PAGE: (assessmentApiResult?: AssessmentApiResult) => {
    return reduceApiResult(assessmentApiResult, (assessment) => {
      const frameworkModel = new FrameworkModel(assessment.framework.steps)
      return {
        ...assessment,
        currentStage: nextStage(frameworkModel, assessment.currentStage)
      }
    })
  },

  UPDATE_QUESTION_INPUT: (
    assessmentApiResult: AssessmentApiResult | null | undefined,
    { payload }: UpdateQuestionInputAction
  ) => {
    return reduceApiResult(assessmentApiResult, (assessment) => {
      let newState = immutable.set(
        assessment,
        ["input", "attributes", payload.id, "rating"],
        payload.rating
      )

      newState = immutable.set(
        newState,
        ["input", "attributes", payload.id, "response_type"],
        "rating"
      )

      return newState
    })
  },

  UPDATE_QUESTION_COMMENT: (
    assessmentApiResult: AssessmentApiResult | null | undefined,
    { payload }: UpdateCommentAction
  ) => {
    return reduceApiResult(assessmentApiResult, (assessment) => {
      const { id, comment } = payload
      if (id) {
        let newState = immutable.set(
          assessment,
          ["input", "attributes", id, "comment"],
          comment
        )

        newState = immutable.set(
          newState,
          ["input", "attributes", payload.id, "response_type"],
          "rating"
        )

        return newState
      }
      return immutable.set(assessment, ["input", "comment"], comment)
    })
  },

  UPDATE_COMMENT_ONLY: (
    assessmentApiResult: AssessmentApiResult | null | undefined,
    { payload }: UpdateCommentAction
  ) => {
    return reduceApiResult(assessmentApiResult, (assessment) => {
      const { id, comment } = payload
      if (id) {
        let newState = immutable.set(
          assessment,
          ["input", "attributes", id, "comment"],
          comment
        )

        newState = immutable.set(
          newState,
          ["input", "attributes", payload.id, "response_type"],
          "comment_only"
        )

        return newState
      }
      return immutable.set(assessment, ["input", "comment"], comment)
    })
  },

  UPDATE_ASSESSMENT_DATA: (
    assessmentApiResult: AssessmentApiResult | null | undefined,
    { payload }: UpdateAssessmentDataAction
  ) => {
    return reduceApiResult(assessmentApiResult, (assessment) => {
      const { id, value } = payload
      return immutable.set(assessment, ["input", "data", id], value)
    })
  },

  CLEAR_QUESTION_INPUT: (
    assessmentApiResult: AssessmentApiResult | null | undefined,
    { payload }: ClearQuestionInputAction
  ) => {
    return reduceApiResult(assessmentApiResult, (assessment) => {
      return immutable.del(assessment, [
        "input",
        "attributes",
        payload.id,
        "rating"
      ])
    })
  },

  SENDING_ASSESSMENT_STARTED: (
    assessmentApiResult?: AssessmentApiResult
  ): AssessmentApiResult => {
    return { ...assessmentApiResult, syncing: "sending" }
  }
}

export default function assessmentReducer(
  assessmentApiResult: AssessmentApiResult = null,
  action: Action
): AssessmentApiResult {
  return applyReducerHash(assessmentReducers, assessmentApiResult, action)
}
