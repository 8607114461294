import React from "react"
import range from "lodash.range"
import MenuItem from "material-ui/MenuItem"
import { Field } from "redux-form"
import MuiSelectField from "./MuiExtensions/components/MuiSelectField"

// type Option = [string, string]

type Props = {
  floatingLabelText: string
  input: { name: string }
  blankOption?: string
}

const values = range(1, 24)

const numberToTimeString = (hour) => {
  if (hour < 12) {
    return `${hour} am`
  }

  if (hour === 12) {
    return `${hour} pm`
  }

  return `${hour - 12} pm`
}

const TimeSelectHtmlField = ({ floatingLabelText, input }: Props) => {
  return (
    <div>
      <label htmlFor={input.name}>{floatingLabelText}</label>
      <select id={input.name} {...input}>
        <option value="" />
        {values.map((value) => (
          <option key={value} value={value}>
            {numberToTimeString(value)}
          </option>
        ))}
      </select>
    </div>
  )
}

TimeSelectHtmlField.defaultProps = {
  blankOption: null
}

const TimeSelectMuiField = ({ blankOption, input, ...rest }: Props) => {
  return (
    <Field
      name={input.name}
      component={MuiSelectField}
      floatingLabelStyle={{ fontFamily: "Poppins" }}
      fullWidth
      {...rest}
    >
      {blankOption && (
        <MenuItem key="null" value={null} primaryText={blankOption} />
      )}
      {values.map((value) => (
        <MenuItem
          key={value}
          value={value}
          primaryText={numberToTimeString(value)}
        />
      ))}
    </Field>
  )
}

TimeSelectMuiField.defaultProps = {
  blankOption: null
}

export default window.gon.rails_env === "test"
  ? TimeSelectHtmlField
  : TimeSelectMuiField
