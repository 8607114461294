import withQuery from "with-query"
import {
  fetchJson,
  fetchWithHeaders,
  fetchLink,
  jwtFromState
} from "./api_helpers"
import type { Link, GetStateFunc } from "./state_types"
import type { AssessmentInput } from "./contexts/assessment/types"

const host = window.location.origin

type BulkAction = {
  ripple_ids: Array<string>
  organisation?: string
}

const api = {
  assessments: {
    new: (token: string) =>
      fetchJson(withQuery(`${host}/api/assessments/new`, { token })),
    create: (link: Link, data: AssessmentInput) =>
      fetchLink(link, null, { data })
  },
  dev: {
    getQuickLogins: () => {
      return fetchJson(`${host}/api/dev/quick_logins`)
    },
    login: (data: {}) => {
      return fetchJson(`${host}/api/dev/quick_logins`, null, {
        method: "POST",
        data
      })
    }
  },
  user: {
    login: (data: {}) => {
      return fetchJson(`${host}/api/sessions`, null, { method: "POST", data })
    },
    logout: () => {
      return fetchJson(`${host}/api/sessions`, null, { method: "DELETE" })
    },
    update: (data: unknown, getState: GetStateFunc, userEmail: string) => {
      return fetchJson(
        `${host}/api/users/update/?requester_email=${encodeURIComponent(
          userEmail
        )}`,
        jwtFromState(getState()),
        { method: "PUT", data }
      )
    },
    updateOTP: (data: unknown, getState: GetStateFunc) => {
      return fetchJson(`${host}/api/users/otp`, jwtFromState(getState()), {
        method: "PUT",
        data
      })
    },
    disableOTP: (data: unknown, getState: GetStateFunc) => {
      return fetchJson(`${host}/api/users/otp`, jwtFromState(getState()), {
        method: "DELETE",
        data
      })
    },
    createBackupCodes: (data: unknown, getState: GetStateFunc) => {
      return fetchJson(
        `${host}/api/users/backup_codes`,
        jwtFromState(getState()),
        {
          method: "POST",
          data
        }
      )
    },
    removeBackupCodes: (data: unknown, getState: GetStateFunc) => {
      return fetchJson(
        `${host}/api/users/backup_codes`,
        jwtFromState(getState()),
        {
          method: "DELETE",
          data
        }
      )
    },
    updatePassword: (
      data: unknown,
      getState: GetStateFunc,
      userEmail: string
    ) => {
      return fetchWithHeaders(
        `${host}/api/users/update_password/?requester_email=${encodeURIComponent(
          userEmail
        )}`,
        jwtFromState(getState()),
        { method: "PUT", data }
      )
    },
    newPassword: (email: string) => {
      const data = { email }
      return fetchJson(`${host}/api/passwords/request_new`, null, {
        method: "POST",
        data
      })
    },
    resetPassword: (data: unknown) => {
      return fetchJson(`${host}/api/passwords`, null, { method: "POST", data })
    },
    validateFields: (data: {}): any => {
      return fetchJson(`${host}/api/users/validate`, null, {
        method: "POST",
        data
      })
    }
  },
  userInvite: {
    show: (token: string) =>
      fetchJson(`${host}/api/user_invitations/${token}`, null, {
        method: "GET"
      }),
    accept: (token: string, values: {}) =>
      fetchJson(`${host}/api/user_invitations/${token}`, null, {
        method: "PUT",
        data: values
      })
  },
  qrCodes: {
    ripples: (query: {}, getState: GetStateFunc) =>
      fetchJson(
        withQuery(`${host}/api/qr_codes/ripples`, query),
        jwtFromState(getState()),
        { method: "GET" }
      ),
    setStatus: (
      rippleRoleId: number,
      activeStatus: boolean,
      getState: GetStateFunc
    ) =>
      fetchJson(
        `${host}/api/ripple_roles/toggle_qr_status`,
        jwtFromState(getState()),
        {
          method: "PUT",
          data: { active: activeStatus, ripple_role_ids: rippleRoleId }
        }
      ),
    url: (token: string) => `${host}/assess/${token}`
  },
  ripples: {
    new: (query: {}, getState: GetStateFunc) =>
      fetchJson(
        withQuery(`${host}/api/ripples/new`, query),
        jwtFromState(getState()),
        { method: "GET" }
      ),
    create: (data: unknown, getState: GetStateFunc) =>
      fetchWithHeaders(`${host}/api/ripples`, jwtFromState(getState()), {
        method: "POST",
        data
      }),
    index: (query: {}, getState: GetStateFunc) =>
      fetchJson(
        withQuery(`${host}/api/ripples`, query),
        jwtFromState(getState()),
        { method: "GET" }
      ),
    archived: (query: {}, getState: GetStateFunc) =>
      fetchJson(
        withQuery(`${host}/api/ripples/archived`, query),
        jwtFromState(getState()),
        { method: "GET" }
      ),
    show: (uuid: string, getState: GetStateFunc) =>
      fetchJson(`${host}/api/ripples/${uuid}`, jwtFromState(getState()), {
        method: "GET"
      }),
    update: (data: unknown, getState: GetStateFunc, uuid: string) =>
      fetchJson(`${host}/api/ripples/${uuid}`, jwtFromState(getState()), {
        method: "PUT",
        data
      }),
    closeAssessment: (uuid: string, getState: GetStateFunc) =>
      fetchJson(
        `${host}/api/ripples/${uuid}/close_assessment`,
        jwtFromState(getState()),
        {
          method: "PUT"
        }
      ),
    sendReminder: (uuid: string, userId: number, getState: GetStateFunc) =>
      fetchJson(
        `${host}/api/ripples/${uuid}/send_reminder`,
        jwtFromState(getState()),
        {
          method: "PUT",
          data: { user_id: userId }
        }
      ),
    archive: (uuid: string, getState: GetStateFunc) =>
      fetchJson(
        `${host}/api/ripples/${uuid}/archive`,
        jwtFromState(getState()),
        {
          method: "PUT"
        }
      ),
    restore: (uuid: string, getState: GetStateFunc) =>
      fetchJson(
        `${host}/api/ripples/${uuid}/restore`,
        jwtFromState(getState()),
        {
          method: "PUT"
        }
      ),
    bulk: {
      import: (data: {}, getState: GetStateFunc) =>
        fetchJson(`${host}/api/bulk/ripples/import`, jwtFromState(getState()), {
          method: "POST",
          data
        }),
      archive: (data: BulkAction, getState: GetStateFunc) =>
        fetchJson(
          `${host}/api/bulk/ripples/archive`,
          jwtFromState(getState()),
          {
            method: "POST",
            data
          }
        ),
      restore: (data: BulkAction, getState: GetStateFunc) =>
        fetchJson(
          `${host}/api/bulk/ripples/restore`,
          jwtFromState(getState()),
          {
            method: "POST",
            data
          }
        )
    }
  },
  feed: {
    addComment: (data: unknown, getState: GetStateFunc) =>
      fetchJson(
        `${host}/api/feed_items`,
        jwtFromState(getState()),
        { method: "POST", data },
        { multipart: true }
      )
  },
  org: {
    show: (slug: string, getState: GetStateFunc) =>
      fetchJson(`${host}/api/orgs/${slug}`, jwtFromState(getState()), {
        method: "GET"
      }),
    archiveRipples: (slug: string, data: {}, getState: GetStateFunc) =>
      fetchJson(
        `${host}/api/orgs/${slug}/archive_ripples`,
        jwtFromState(getState()),
        { method: "PUT", data }
      )
  },
  orgFrameworks: {
    index: (slug: string, getState: GetStateFunc) =>
      fetchJson(
        `${host}/api/orgs/${slug}/frameworks`,
        jwtFromState(getState()),
        {
          method: "GET"
        }
      )
  },
  orgUsers: {
    index: (slug: string, query: {}, getState: GetStateFunc) =>
      fetchJson(
        withQuery(`${host}/api/orgs/${slug}/users`, query),
        jwtFromState(getState()),
        {
          method: "GET"
        }
      ),
    create: (slug: string, data: unknown, getState: GetStateFunc) =>
      fetchJson(`${host}/api/orgs/${slug}/users`, jwtFromState(getState()), {
        method: "POST",
        data
      }),
    updateUser: (
      slug: string,
      userId: number,
      values: {},
      getState: GetStateFunc
    ) =>
      fetchJson(
        `${host}/api/orgs/${slug}/users/${userId}`,
        jwtFromState(getState()),
        { method: "PUT", data: values }
      ),
    update_role: (
      slug: string,
      userEmail: string,
      data: unknown,
      getState: GetStateFunc
    ) =>
      fetchJson(
        `${host}/api/orgs/${slug}/users/update_role/?email=${encodeURIComponent(
          userEmail
        )}`,
        jwtFromState(getState()),
        { method: "POST", data }
      ),
    remove_org_roles: (
      slug: string,
      userEmail: string,
      getState: GetStateFunc
    ) =>
      fetchJson(
        `${host}/api/orgs/${slug}/users/remove_org_roles/?email=${encodeURIComponent(
          userEmail
        )}`,
        jwtFromState(getState()),
        { method: "POST" }
      ),
    resend_invite: (slug: string, userEmail: string, getState: GetStateFunc) =>
      fetchJson(
        `${host}/api/orgs/${slug}/users/resend_invite/?email=${encodeURIComponent(
          userEmail
        )}`,
        jwtFromState(getState()),
        { method: "POST" }
      ),
    update_tags: (
      slug: string,
      userEmail: string,
      data: unknown,
      getState: GetStateFunc
    ) =>
      fetchJson(
        `${host}/api/orgs/${slug}/users/tags/?email=${encodeURIComponent(
          userEmail
        )}`,
        jwtFromState(getState()),
        { method: "PUT", data }
      )
  },
  report: {
    show: (reportId: string, query: {}, getState: GetStateFunc) =>
      fetchJson(
        withQuery(`${host}/api/reports/${reportId}`, query),
        jwtFromState(getState()),
        { method: "GET" }
      ),
    index: (query: {}, getState: GetStateFunc) =>
      fetchJson(
        withQuery(`${host}/api/reports`, query),
        jwtFromState(getState()),
        {
          method: "GET"
        }
      )
  }
}

export default api
