import React from "react"
import { Button as MuiButton } from "@material-ui/core"
import colours from "../themes/colours"

type Props = {
  label: string
  type?: "button" | "reset" | "submit"
  disabled?: boolean
  onClick?
  style?
}

const TextButton = ({ label, disabled, style, type, ...rest }: Props) => {
  const color = disabled ? colours.deemphasisedText : colours.blue

  return (
    <MuiButton
      {...rest}
      variant="text"
      style={{
        color,
        ...style
      }}
      type={type}
      disabled={disabled}
    >
      {label}
    </MuiButton>
  )
}

TextButton.defaultProps = {
  type: "button",
  disabled: false
}

export default TextButton
