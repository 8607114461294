import React from "react"
import { connect } from "react-redux"
import styled from "@emotion/styled"
import {
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent
} from "@material-ui/core"
import FlatButton from "./FlatButton"
import AvatarPreview from "./AvatarPreview"
import { restoreRipple } from "../api_actions"
import colours from "../themes/colours"
import { gutter } from "./Layout"
import type { Ripple } from "../state_types"

type Props = {
  ripple: Ripple
  submitRestore: (uuid: string) => {}
}

type State = {
  modalOpen: boolean
  processing: boolean
}

const TR = styled.tr({
  fontSize: "0.9em"
})

const TD = styled.td({
  borderBottom: `1px solid ${colours.boldLineOnSilver}`,
  paddingRight: gutter,
  textAlign: "left",
  whiteSpace: "nowrap",
  height: 37,
  paddingTop: 10,
  paddingBottom: 10,
  ":last-child": {
    paddingRight: 0
  }
})

const renderPrettyButton = (onClick, label, options = { disabled: false }) => (
  <FlatButton
    style={{
      height: "32px",
      lineHeight: "30px",
      padding: "10px 5px",
      marginLeft: "10px"
    }}
    label={label}
    disabled={options.disabled}
    onClick={() => onClick()}
  />
)

const AvatarCell = styled(TD)({
  width: "1%"
})

const StyledDialogContent = styled(DialogContent)({
  maxWidth: "500px"
})

class RippleTableRow extends React.Component<Props, State> {
  state = { modalOpen: false, processing: false }

  handleOpen() {
    this.setState({ modalOpen: true })
  }

  handleClose() {
    this.setState({ modalOpen: false })
  }

  confirmRestore = () => {
    this.setState({ processing: true })
    this.props.submitRestore(this.props.ripple.uuid)
  }

  render() {
    const ripple = this.props.ripple

    const dialogActions = [
      renderPrettyButton(() => this.handleClose(), "Cancel", {
        disabled: this.state.processing
      }),
      renderPrettyButton(() => this.confirmRestore(), "Restore", {
        disabled: this.state.processing
      })
    ]

    return (
      <TR className="ripple">
        <AvatarCell key="avatar">
          <AvatarPreview value={null} name={ripple.clientAvatar} size={37} />
        </AvatarCell>
        <TD key="name">{ripple.clientName}</TD>
        <TD key="org">{ripple.organisation.name}</TD>
        <TD key="lastAssessmentDate">{ripple.latestAssessmentCompletedAt}</TD>
        <TD key="actions">
          {renderPrettyButton(() => this.handleOpen(), "Restore Ripple")}
          <Dialog open={this.state.modalOpen}>
            <DialogTitle id="simple-dialog-title">
              {`Restoring ${ripple.clientName} ripple`}
            </DialogTitle>
            <StyledDialogContent>
              <p>
                Would you like to restore {ripple.clientName} ripple? All
                previous users will have access to it. The schedule will not be
                restored.
              </p>
              <p>
                <strong>
                  Please review the ripple settings immediately after restoring
                  it.
                </strong>
              </p>
            </StyledDialogContent>
            <DialogActions>{dialogActions}</DialogActions>
          </Dialog>
        </TD>
      </TR>
    )
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    submitRestore: (rippleUuid) => {
      dispatch(restoreRipple(rippleUuid))
    }
  }
}

const mapStateToProps = (_) => {
  return {}
}

export default connect(mapStateToProps, mapDispatchToProps)(RippleTableRow)
