import React, { Component } from "react"
import { connect } from "react-redux"

import { submitLogin } from "../api_actions"
import LoginLayout from "../layouts/LoginLayout"
import UserPassword from "../components/login_steps/UserPassword"
import DownloadMFAApp from "../components/login_steps/DownloadMFAApp"
import ScanQRCode from "../components/login_steps/ScanQRCode"
import EnterCodeNormal from "../components/login_steps/EnterCodeNormal"
import EnterCodeSetup from "../components/login_steps/EnterCodeSetup"
import type { Errors } from "../state_types"

type SubmitLoginFun = (
  userName: string,
  password: string,
  otpCode: string,
  newOtpCode: string
) => unknown

type Props = {
  clientOutdated?: boolean
  onSubmit: SubmitLoginFun
  errors?: Errors
  syncing?: boolean
  loginStep: string
  mfaSetupUrl?: string
  onNoUserOrPass: () => unknown
}

type State = {
  emailOrUsername: string
  password: string
  otpCode: string
  otpCodeForUnconfirmedSecret: string
  otpSetupStep: string
}

class LoginPage extends Component<Props, State> {
  state = {
    emailOrUsername: "",
    password: "",
    otpCode: "",
    otpCodeForUnconfirmedSecret: "",
    otpSetupStep: "download_authenticator"
  }

  static getDerivedStateFromProps(props: Props, state: State): State {
    if (props.loginStep === "enter_password") {
      // if you were sent to the beginning, be at the beginning
      return {
        ...state,
        otpCode: "", // without this the controller will be confused where in the process you are
        otpCodeForUnconfirmedSecret: "",
        otpSetupStep: "download_authenticator" // be at the first step of setup if you subsequently have to do setup
      }
    }

    // if you've skipped past have the beginning (e.g. by reloading the page)
    // be sent to the beginning
    if (!state.password || !state.emailOrUsername) {
      props.onNoUserOrPass()
    }

    return state
  }

  updateValueFn = (key: string) => {
    return (value: string) => {
      this.setState({ [key]: value } as Pick<State, keyof State>)
    }
  }

  updateToValueFn = (key: string, value: string) => {
    return () => {
      this.setState({ [key]: value } as Pick<State, keyof State>)
    }
  }

  handleSubmit = () => {
    this.props.onSubmit(
      this.state.emailOrUsername,
      this.state.password,
      this.state.otpCode,
      this.state.otpCodeForUnconfirmedSecret
    )
  }

  render() {
    const { loginStep, errors, syncing, clientOutdated, mfaSetupUrl } =
      this.props
    const {
      emailOrUsername,
      password,
      otpCode,
      otpCodeForUnconfirmedSecret,
      otpSetupStep
    } = this.state

    return (
      <LoginLayout>
        {loginStep === "enter_password" && (
          <UserPassword
            onChangeEmailOrUsername={this.updateValueFn("emailOrUsername")}
            onChangePassword={this.updateValueFn("password")}
            submit={this.handleSubmit}
            errors={errors}
            syncing={syncing}
            clientOutdated={clientOutdated}
            emailOrUsername={emailOrUsername}
            password={password}
          />
        )}

        {loginStep === "enter_otp" && (
          <EnterCodeNormal
            onChange={this.updateValueFn("otpCode")}
            submit={this.handleSubmit}
            errors={errors}
            syncing={syncing}
            otpCode={otpCode}
          />
        )}

        {loginStep === "setup_otp" &&
          otpSetupStep === "download_authenticator" && (
            <DownloadMFAApp
              submit={this.updateToValueFn(
                "otpSetupStep",
                "setup_authenticator"
              )}
            />
          )}
        {loginStep === "setup_otp" &&
          otpSetupStep === "setup_authenticator" && (
            <ScanQRCode
              submit={this.updateToValueFn("otpSetupStep", "confirm_code")}
              back={this.updateToValueFn(
                "otpSetupStep",
                "download_authenticator"
              )}
              mfaSetupUrl={mfaSetupUrl}
            />
          )}
        {loginStep === "setup_otp" && otpSetupStep === "confirm_code" && (
          <EnterCodeSetup
            onChange={this.updateValueFn("otpCodeForUnconfirmedSecret")}
            back={this.updateToValueFn("otpSetupStep", "setup_authenticator")}
            submit={this.handleSubmit}
            errors={errors}
            syncing={syncing}
            otpCode={otpCodeForUnconfirmedSecret}
          />
        )}
      </LoginLayout>
    )
  }
}

const mapStateToProps = (state) => {
  const me = state.me || {}
  const clientOutdated = state.location.type === "CLIENT_OUTDATED"

  return {
    clientOutdated,
    errors: me.errors,
    syncing: !!me.syncing,
    loginStep: me.loginStep,
    mfaSetupUrl: me.mfaSetupUrl
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    onSubmit: (
      emailOrUsername: string,
      password: string,
      otpCode: string,
      otpCodeForUnconfirmedSecret: string
    ) =>
      // eslint-disable-next-line @typescript-eslint/no-unsafe-return
      dispatch(
        submitLogin(
          emailOrUsername,
          password,
          otpCode,
          otpCodeForUnconfirmedSecret
        )
      ),

    onNoUserOrPass: () => dispatch({ type: "LOGIN" })
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(LoginPage)
