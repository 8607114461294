import React from "react"
import styled from "@emotion/styled"
import { Vector2 } from "./svg/types"
import TriangleFrame from "./TriangleFrame"

const Container = styled.div<{
  top: number
  left: number
  right: number
  centre: number
}>((props) => ({
  position: "absolute",
  top: props.top,
  left: props.left,
  right: props.right,
  // padding: 20,
  zIndex: 100
}))

type Props = {
  target: Vector2
  left: number
  right: number
  children: unknown
}

const BubbleDialog = ({ target, left, right, children }: Props) => {
  return (
    <Container
      top={target.y}
      centre={target.x}
      left={left}
      right={right}
      className="bubble-dialog"
    >
      <TriangleFrame targetX={target.x - left}>{children}</TriangleFrame>
    </Container>
  )
}

export default BubbleDialog
