import React from "react"
import styled from "@emotion/styled"
import ArrowButton from "./ArrowButton"
import SubmitButton from "./SubmitButton"
import Actions from "./PageBottomActions"

type Props = {
  previousPage?: () => unknown
  nextPage?: () => unknown
  submit: () => unknown
}

const Container = styled.div({
  marginTop: 15
})

const SdqPageActions = ({ previousPage, nextPage, submit }: Props) => {
  return (
    <Container>
      <Actions>
        {previousPage && (
          <ArrowButton
            key="left"
            onClick={previousPage}
            direction="left"
            text="Prev"
          />
        )}
        {nextPage && (
          <ArrowButton
            key="right"
            onClick={nextPage}
            direction="right"
            text="Next"
          />
        )}
        {!nextPage && submit && <SubmitButton key="submit" onClick={submit} />}
      </Actions>
    </Container>
  )
}

export default SdqPageActions
