import React from "react"
import { HeadingOne, HeadingTwo, HeadingThree } from "../components/TextStyles"
import ArrowButton from "../components/ArrowButton"
import { Container } from "../components/Layout"
import Theme from "../themes/White"
import ScoringWidget from "../components/ScoringWidget"
import ToggleButton from "../components/ToggleButton"
import ToggleButtonGroup from "../components/ToggleButtonGroup"

const clicked = () => alert("someone clicked me")

class StatefulScoringWidget extends React.Component<{}, { value: number }> {
  state = {
    value: 50
  }

  updateRating = (value) => {
    this.setState({ value })
  }

  render() {
    return (
      <ScoringWidget
        id="foobar"
        onUpdate={this.updateRating}
        value={this.state.value}
        previousValue={30}
      />
    )
  }
}

class StatefulToggleButton extends React.Component<
  { value?: boolean },
  { value: boolean }
> {
  constructor(props) {
    super(props)
    this.state = { value: props.value }
  }

  onChange = (_value) => {
    this.setState((state) => ({ value: !state.value }))
  }

  render() {
    return (
      <ToggleButton
        onChange={this.onChange}
        value={this.state.value}
        label="Toggle Me"
      />
    )
  }
}

class StatefulToggleButtonGroup extends React.Component<
  { value: string },
  { value: string }
> {
  constructor(props) {
    super(props)
    this.state = { value: props.value }
  }

  onChange = (value) => {
    this.setState({ value })
  }

  render() {
    return (
      <ToggleButtonGroup
        onChange={this.onChange}
        value={this.state.value}
        primaryColour="green"
        buttonNames={["click one", "click two"]}
      />
    )
  }
}

const StyleBoard = () => {
  return (
    <Theme>
      <Container>
        <StatefulScoringWidget />

        <HeadingOne>This is heading one</HeadingOne>
        <p>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse
          feugiat nunc a varius molestie. Donec ac libero nec tellus vehicula
          vehicula at nec velit. Nulla sagittis volutpat felis nec porttitor.
          Pellentesque rutrum nulla ultricies, cursus nulla ac, mattis nisi. Sed
          convallis felis et urna tincidunt pretium. Vestibulum in aliquet nisl,
          vitae consectetur nibh. Aenean libero augue, dictum vitae neque ut,
          fringilla maximus libero. Etiam eu urna ac nunc facilisis imperdiet.
          Sed eget fringilla dui. Duis faucibus viverra varius. Nam sit amet
          tristique urna. Phasellus tempor purus eget urna gravida cursus eget
          sit amet massa. Sed vehicula egestas turpis at laoreet. Mauris odio
          nisl, blandit dapibus tellus vel, laoreet condimentum arcu.
        </p>
        <HeadingTwo>This is a heading two</HeadingTwo>
        <p>
          Suspendisse augue quam, facilisis ac maximus quis, tristique eget leo.
          Suspendisse potenti. Vestibulum vestibulum, tellus tristique pulvinar
          pellentesque, velit sapien faucibus risus, sit amet suscipit velit
          tortor ac nisi. Curabitur dignissim pretium auctor. Vestibulum vitae
          elit tortor. Integer at dignissim felis. Sed dapibus nibh in mi ornare
          efficitur. Quisque cursus commodo enim eget maximus. Pellentesque eget
          nunc vitae sem imperdiet tincidunt. In nec consectetur lorem.
        </p>

        <HeadingThree>This is a heading three </HeadingThree>
        <p>
          Suspendisse augue quam, facilisis ac maximus quis, tristique eget leo.
          Suspendisse potenti. Vestibulum vestibulum, tellus tristique pulvinar
          pellentesque, velit sapien faucibus risus, sit amet suscipit velit
          tortor ac nisi. Curabitur dignissim pretium auctor. Vestibulum vitae
          elit tortor. Integer at dignissim felis. Sed dapibus nibh in mi ornare
          efficitur. Quisque cursus commodo enim eget maximus. Pellentesque eget
          nunc vitae sem imperdiet tincidunt. In nec consectetur lorem.
        </p>
        <div>
          <HeadingTwo>Here are some buttons</HeadingTwo>
          <div>
            <ArrowButton onClick={clicked} direction="right" text="Next" />
          </div>
          <div>
            <ArrowButton onClick={clicked} direction="left" text="Previous" />
          </div>
          <div>
            <StatefulToggleButton value />
          </div>
          <div>
            <StatefulToggleButton value={false} />
          </div>
          <div>
            <StatefulToggleButtonGroup value="assessor" />
          </div>
        </div>
      </Container>
    </Theme>
  )
}

export default StyleBoard
