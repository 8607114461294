const padZero = (number: number, digits: number) =>
  Array(Math.max(digits - String(number).length + 1, 0)).join("0") + number

export const isoStringWithoutZone = (date: Date): string =>
  `${date.getFullYear()}-${padZero(date.getMonth() + 1, 2)}-${padZero(
    date.getDate(),
    2
  )}`

export const todayIsoDate = (): string => isoStringWithoutZone(new Date())
