import React from "react"
import styled from "@emotion/styled"
import Vec2 from "vec2"
import type { ReactNode } from "react"
import ContainerDimensions from "react-container-dimensions"
import BubbleDialog from "./BubbleDialog"

const bubbleGutter = 7

const Container = styled.div({
  position: "relative",
  width: "100%"
})

export type ToggleFunc = (
  _key: string,
  _targetElement: HTMLElement,
  _contents: ReactNode
) => void
export type DialogFunctions = {
  toggle: ToggleFunc
}
type ChildrenFunc = (_callbacks: DialogFunctions) => unknown

type BubbleProps = {
  key: string
  targetElement: HTMLElement
  contents: ReactNode
}

type Props = {
  children: ChildrenFunc
  maxWidth: number
  outsideClickCloses?: boolean
  forceRight?: boolean
  className?: string
}

type PropsWithWidth = Props & {
  width: number
}

type State = {
  current?: BubbleProps
}

class BubbleDialogContainer extends React.Component<PropsWithWidth, State> {
  static defaultProps = { outsideClickCloses: false }

  state = { current: null }

  componentDidMount() {
    if (this.props.outsideClickCloses) {
      document.addEventListener("click", this.outsideClickListener)
    }
  }

  container: React.RefObject<any>

  constructor(props) {
    super(props)
    this.container = React.createRef()
  }

  componentWillUnmount() {
    if (this.props.outsideClickCloses) {
      document.removeEventListener("click", this.outsideClickListener)
    }
  }

  getBestLeft(targetX: number): number {
    const { maxWidth } = this.props
    const candidate = targetX - maxWidth / 2
    return Math.max(candidate, bubbleGutter)
  }

  getBestRight(targetX: number): number {
    const { maxWidth, width } = this.props
    const inverseTargetX = width - targetX
    const candidate = inverseTargetX - maxWidth / 2
    return Math.max(candidate, bubbleGutter)
  }

  getRemainingRight(targetX: number, used: number): number {
    const { maxWidth, width } = this.props
    const remaining = maxWidth - used
    const candidate = targetX + remaining
    const inverseCandidate = width - candidate
    return Math.max(inverseCandidate, bubbleGutter)
  }

  getRemainingLeft(targetX: number, used: number): number {
    const { maxWidth } = this.props
    const remaining = maxWidth - used
    const candidate = targetX - remaining
    return Math.max(candidate, bubbleGutter)
  }

  getBestDimensions(targetX: number): [number, number] {
    const { width, forceRight } = this.props
    const closestToLeft = targetX < width / 2

    if (closestToLeft && !forceRight) {
      const left = this.getBestLeft(targetX)
      const used = targetX - left
      const right = this.getRemainingRight(targetX, used)
      return [left, right]
    }

    const inverseTargetX = width - targetX
    const right = this.getBestRight(targetX)
    const used = inverseTargetX - right
    const left = this.getRemainingLeft(targetX, used)
    return [left, right]
  }

  outsideClickListener = (e: Event) => {
    this.closeOnOutsideClick(e)
  }

  closeOnOutsideClick(e: Event) {
    const isOpen = this.state.current
    const insideClick = this.container.current?.contains(e.target)

    if (isOpen && !insideClick) {
      this.closeDialog()
    }
  }

  closeDialog() {
    this.setState({ current: null })
  }

  openDialog(key: string, targetElement: HTMLElement, contents: ReactNode) {
    this.setState({ current: { key, targetElement, contents } })
  }

  calculatePosition(targetElement: HTMLElement) {
    if (!this.container.current) return null

    const targetRect = targetElement.getBoundingClientRect()
    const containerRect = this.container.current.getBoundingClientRect()
    const absoluteTarget = Vec2(
      (targetRect.left + targetRect.right) / 2.0,
      targetRect.bottom + 8
    )
    const containerTopLeft = Vec2(containerRect.left, containerRect.top)
    const relativeTarget = absoluteTarget.subtract(containerTopLeft, true)

    const [left, right] = this.getBestDimensions(relativeTarget.x)

    return {
      target: relativeTarget,
      left,
      right
    }
  }

  toggleDialog = (
    key: string,
    targetElement: HTMLElement,
    contents: ReactNode
  ) => {
    const { current } = this.state

    if (current && current.key === key) {
      this.closeDialog()
    } else {
      this.openDialog(key, targetElement, contents)
    }
  }

  render() {
    const { children, className } = this.props
    const { current } = this.state

    return (
      <Container ref={this.container} className={className}>
        {current && this.container.current ? (
          <BubbleDialog {...this.calculatePosition(current.targetElement)}>
            {current.contents}
          </BubbleDialog>
        ) : null}
        {children({ toggle: this.toggleDialog })}
      </Container>
    )
  }
}

const MeasuredBubbleDialogContainer = (props: Props) => {
  return (
    <ContainerDimensions>
      {({ width }) => {
        return <BubbleDialogContainer {...props} width={width} />
      }}
    </ContainerDimensions>
  )
}

MeasuredBubbleDialogContainer.defaultProps = { outsideClickCloses: false }

export default MeasuredBubbleDialogContainer
