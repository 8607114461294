import React from "react"
import styled from "@emotion/styled"
import Theme from "../themes/UnderConstruction"
import { HeadingOne } from "../components/TextStyles"
import Header from "../components/Header"

const ContentContainer = styled.div({
  paddingTop: "40%",
  maxWidth: 600,
  marginLeft: "auto",
  marginRight: "auto"
})

const NotFoundPage = () => {
  return (
    <Theme>
      <Header />
      <ContentContainer>
        <HeadingOne>Page Not Found (404)</HeadingOne>
        <p>Oh no! We couldn&apos;t find what you were looking for.</p>
      </ContentContainer>
    </Theme>
  )
}

export default NotFoundPage
