import React from "react"
import moment from "moment"
import { AnyAction } from "redux"
import { ThunkDispatch } from "redux-thunk"
import { connect } from "react-redux"
import styled from "@emotion/styled"
import FontAwesome from "react-fontawesome"
import { AssessorStatus, UsersHash } from "../state_types"
import colours from "../themes/colours"
import AvatarPreview from "./AvatarPreview"
import { sendAssessmentReminder } from "../api_actions"
import LoadingLink from "./LoadingLink"
import Card from "./Card"
import { gutter } from "./Layout"

type Props = {
  assessors: Array<AssessorStatus>
  users: UsersHash
  rippleUuid: string // eslint-disable-line react/no-unused-prop-types
  onSendReminder?: (userId: number, onComplete: () => {}) => void
}

type SingleAssessorProps = {
  user: {
    id: number
    name: string
    avatar?: string
  }
  assessedAt: string
  onSendReminder: (userId: number, onComplete: () => {}) => void
}

type SendReminderProps = {
  onClick: (onComplete) => void
}

const internalCardGutter = 10
const cardWidth = 300

const AssessmentStatusBox = styled.div({
  // backgroundColor: "#EEEEEE",
  // borderBottom: "1px solid #CCCCCC",
  // marginTop: -11,
  display: "flex",
  flexDirection: "row",
  flexWrap: "wrap",
  marginBottom: `-${gutter}`,
  marginRight: `-${gutter}`
})

const AssessorCard = styled(Card)({
  width: cardWidth,
  marginRight: gutter,
  marginBottom: gutter,
  display: "flex",
  flexDirection: "row",
  [`@media (max-width: ${cardWidth * 2}px)`]: {
    width: "100%"
  }
})

const CardAvatar = styled.div({
  flex: 0,
  paddingRight: internalCardGutter
})

const CardDetails = styled.div({
  flex: 1,
  display: "flex",
  flexDirection: "column"
})

const CardIcons = styled.div({
  flex: 0,
  paddingLeft: internalCardGutter,
  fontSize: "18px"
})

const AssessedAtOrLink = styled.span({
  fontSize: "0.85em"
})

const Complete = () => (
  <FontAwesome name="check" style={{ color: colours.goodScore }} />
)

const SendReminder = ({ onClick }: SendReminderProps) => (
  <LoadingLink onClick={onClick}>send reminder</LoadingLink>
)

const SingleAssessorStatus = ({
  user,
  assessedAt,
  onSendReminder
}: SingleAssessorProps) => {
  const completed = !!assessedAt

  return (
    <AssessorCard>
      <CardAvatar>
        <AvatarPreview size={37} value={user.avatar} name={user.name} />
      </CardAvatar>
      <CardDetails>
        <span>{user.name}</span>
        <AssessedAtOrLink>
          {completed ? (
            moment(assessedAt).format("D MMM YYYY")
          ) : (
            <SendReminder
              onClick={(onComplete) => {
                onSendReminder(user.id, onComplete)
              }}
            />
          )}
        </AssessedAtOrLink>
      </CardDetails>
      {completed && (
        <CardIcons>
          <Complete />
        </CardIcons>
      )}
    </AssessorCard>
  )
}

const CurrentAssessmentStatus = ({
  assessors,
  users,
  onSendReminder
}: Props) => (
  <AssessmentStatusBox>
    {assessors.map(({ assessedAt, userId }) => {
      const user = users[userId] || { name: "Unknown user" }
      if (!user) return null
      return (
        <SingleAssessorStatus
          key={userId}
          user={{ ...user, id: userId }}
          assessedAt={assessedAt}
          onSendReminder={onSendReminder}
        />
      )
    })}
  </AssessmentStatusBox>
)

const mapDispatchToProps = (
  dispatch: ThunkDispatch<any, any, AnyAction>,
  ownProps: Props
) => ({
  onSendReminder: (userId: number, onComplete: () => void) => {
    dispatch(sendAssessmentReminder(ownProps.rippleUuid, userId, onComplete))
  }
})

export default connect(null, mapDispatchToProps)(CurrentAssessmentStatus)
