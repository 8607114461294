import React from "react"
import styled from "@emotion/styled"
import isEmpty from "lodash.isempty"
import { SubHead } from "./TextStyles"
import type { Errors } from "../state_types"

const ErrorMessages = styled.div({
  display: "flex",
  justifyContent: "center",
  flexShrink: 0,
  textAlign: "center"
})

const Message = styled(SubHead)({
  color: "red",
  fontWeight: 300
})

type Props = {
  errors?: Errors
}

const AuthErrorMessages = ({ errors }: Props) => {
  if (isEmpty(errors)) {
    return null
  }

  return (
    <ErrorMessages>
      <Message>{errors.title}</Message>
    </ErrorMessages>
  )
}

export default AuthErrorMessages
