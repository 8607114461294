import React from "react"
import { connect } from "react-redux"
import styled from "@emotion/styled"
import moment from "moment"
import { FunctionLink, Anchor } from "../Link"
import { closeRippleAssessment } from "../../api_actions"
import type {
  AssessmentWindow,
  AssessmentWindowWithOpenAndClosing,
  AssessmentWindowWithStartAndEnd
} from "../../state_types"
import colours from "../../themes/colours"
import calendar from "../../images/calendar.svg"

type Props = {
  rippleUuid?: string
  iconWidth?: number | string
  paddingWidth?: number
  assessmentWindow?: AssessmentWindow
  canAdmin?: boolean
  assessmentLink?: string
  onClose: (_rippleUuid: string) => any
}

const Container = styled.span({
  display: "flex",
  flexDirection: "row",
  alignItems: "center"
})

const CalendarImg = styled.img<{ width; paddingRight }>((props) => ({
  width: props.width,
  height: props.width,
  paddingRight: props.paddingRight,
  paddingTop: "2px"
}))

const CalendarText = styled.div({
  display: "flex",
  flexDirection: "column",
  alignItems: "flex-start",
  justifyContent: "center",
  color: "black",
  fontFamily: "Poppins",
  fontSize: "12px",
  lineHeight: "16px",
  padding: "0 5px"
})

const Action = styled.span({
  marginRight: "0.5em",
  paddingRight: "0.5em",
  borderRight: `1px solid ${colours.boldLineOnSilver}`,
  "&:last-child": {
    borderRightWidth: 0,
    paddingRight: 0,
    marginRight: 0
  }
})

const ActionsContainer = styled.div({
  display: "flex",
  flexDirection: "row"
})

const momentOrNull = (value) => (value ? moment(value) : null)

const assessmentDateStrings = (assessmentWindow: AssessmentWindow) => {
  const startOn = momentOrNull(
    (assessmentWindow as AssessmentWindowWithStartAndEnd).startOn
  )
  const endOn = momentOrNull(
    (assessmentWindow as AssessmentWindowWithStartAndEnd).endOn
  )
  const justDay = "D"
  const withMonth = "D MMM"
  const withMonthAndYear = "D MMM YYYY"

  if (!startOn) {
    return ["none"]
  }

  if (!endOn) {
    return [startOn.format(withMonth), "until complete"]
  }

  if (startOn.isSame(endOn)) {
    return [startOn.format(withMonth)]
  }

  const sameYear = startOn.year() === endOn.year()
  const sameMonth = sameYear && startOn.month() === endOn.month()
  const thisYear = endOn.year() === moment().year()

  return [
    startOn.format(sameMonth ? justDay : withMonth),
    endOn.format(thisYear ? withMonth : withMonthAndYear)
  ]
}

interface NextAssessmentTextProps {
  rippleUuid: string
  assessmentWindow: AssessmentWindow
  canAdmin: boolean
  assessmentLink: string
  onClose: (_rippleUuid?: string) => void
}

const NextAssessmentText = ({
  rippleUuid,
  assessmentWindow,
  canAdmin,
  assessmentLink,
  onClose
}: NextAssessmentTextProps) => {
  const actions = []

  if ((assessmentWindow as AssessmentWindowWithOpenAndClosing).closing) {
    return (
      <CalendarText>
        <span>Closing</span>
        <span>Assessments</span>
      </CalendarText>
    )
  }

  if ((assessmentWindow as AssessmentWindowWithOpenAndClosing).open) {
    if (assessmentLink) {
      actions.push(
        <Action key="start-assessment">
          <Anchor to={assessmentLink}>Start assessment</Anchor>
        </Action>
      )
    }
    if (canAdmin) {
      actions.push(
        <Action key="close-assessment">
          <FunctionLink
            onClick={() => onClose(rippleUuid)}
            confirm="This will prevent further assessments this time around, are you sure?"
          >
            Close now
          </FunctionLink>
        </Action>
      )
    }

    if (actions.length > 0) {
      return (
        <CalendarText>
          <span>Open For Assessment</span>
          <ActionsContainer>{actions}</ActionsContainer>
        </CalendarText>
      )
    }

    return (
      <CalendarText>
        <span>Open For</span>
        <span>Assessment</span>
      </CalendarText>
    )
  }

  return (
    <CalendarText>
      <span>Next Assessment</span>
      <span>{assessmentDateStrings(assessmentWindow).join(" - ")}</span>
    </CalendarText>
  )
}

const NoAssessmentText = () => (
  <CalendarText>
    <span>No Assessment</span>
    <span>Scheduled</span>
  </CalendarText>
)

export const PureNextAssessment = ({
  rippleUuid,
  iconWidth,
  paddingWidth,
  assessmentWindow,
  canAdmin,
  assessmentLink,
  onClose
}: Props) => (
  <Container className="next-assessment">
    <CalendarImg src={calendar} width={iconWidth} paddingRight={paddingWidth} />
    {assessmentWindow ? (
      <NextAssessmentText
        rippleUuid={rippleUuid}
        assessmentWindow={assessmentWindow}
        canAdmin={canAdmin}
        assessmentLink={assessmentLink}
        onClose={onClose}
      />
    ) : (
      <NoAssessmentText />
    )}
  </Container>
)

PureNextAssessment.defaultProps = {
  iconWidth: "2.6em",
  paddingWidth: 5,
  assessmentWindow: null,
  canAdmin: false,
  assessmentLink: null
}

const mapDispatchToProps = (dispatch) => {
  return {
    onClose: (rippleUuid: string) => {
      dispatch(closeRippleAssessment(rippleUuid))
    }
  }
}

export default connect(null, mapDispatchToProps)(PureNextAssessment)
