import React, { Component, ReactNode } from "react"
import RouterLink from "redux-first-router-link"
import styled from "@emotion/styled"
import colours from "../../themes/colours"

const Wrapper = styled.ul({
  textAlign: "center",
  marginBottom: "10px"
})

const ListItem = styled.li({
  listStyleType: "none",
  display: "inline-block",
  paddingRight: "10px",
  textAlign: "center"
})

const lightGrey = "rgba(157, 157, 157, 0.2)"

const RoundLink = styled(RouterLink)(({ disabled, selected }) => ({
  backgroundColor: selected ? lightGrey : "none",
  fontWeight: selected ? "bold" : "normal",
  padding: "0",
  display: "inline-block",
  width: "40px",
  height: "40px",
  lineHeight: "40px",
  color: colours.blue,
  // textDecoration: selected ? "underline" : "none",
  textDecoration: "none",
  borderRadius: "50%",
  opacity: disabled ? 0.5 : 1.0,
  cursor: disabled ? "default" : "pointer",
  userSelect: "none",
  "&:hover": {
    backgroundColor: disabled ? "none" : lightGrey
  }
}))

const PageLink = (props: {
  disabled?: boolean
  to?: string
  children?: ReactNode
  selected?: boolean
}) => {
  let linkProps: { disabled?: boolean; shouldDispatch?: boolean } = props

  if (props.disabled) {
    linkProps = { ...linkProps, shouldDispatch: false }
  }

  return (
    <ListItem>
      <RoundLink {...linkProps} />
    </ListItem>
  )
}
type PaginationProps = {
  currentPage
  totalPages
  pagesToShow
  to
}

export default class Pagination extends Component<PaginationProps> {
  onPageChange(page) {
    this.props.to(page)
  }

  getPager() {
    const { currentPage, totalPages } = this.props
    let { pagesToShow } = this.props
    const pages = []
    let startFromNumber

    if (totalPages <= pagesToShow) {
      startFromNumber = 1
      pagesToShow = totalPages
    } else if (currentPage <= Math.ceil(pagesToShow / 2)) {
      startFromNumber = 1
    } else if (currentPage + Math.floor((pagesToShow - 1) / 2) >= totalPages) {
      startFromNumber = totalPages - (pagesToShow - 1)
    } else {
      startFromNumber = currentPage - Math.floor(pagesToShow / 2)
    }

    for (let i = 1; i <= pagesToShow; i += 1) {
      pages.push(startFromNumber)
      startFromNumber += 1
    }

    return {
      currentPage,
      totalPages,
      pages
    }
  }

  render() {
    if (this.props.totalPages <= 1) return null

    const pager = this.getPager()

    return (
      <Wrapper>
        <PageLink disabled={pager.currentPage === 1} to={this.props.to(1)}>
          &lt;&lt;
        </PageLink>
        <PageLink
          disabled={pager.currentPage === 1}
          to={this.props.to(pager.currentPage - 1)}
        >
          &lt;
        </PageLink>
        {pager.pages.map((page) => (
          <PageLink
            key={page}
            selected={pager.currentPage === page}
            to={this.props.to(page)}
          >
            {page}
          </PageLink>
        ))}
        <PageLink
          disabled={pager.currentPage === pager.totalPages}
          to={this.props.to(pager.currentPage + 1)}
        >
          &gt;
        </PageLink>
        <PageLink
          disabled={pager.currentPage === pager.totalPages}
          to={this.props.to(pager.totalPages)}
        >
          &gt;&gt;
        </PageLink>
      </Wrapper>
    )
  }
}
