import React from "react"
import moment from "moment"
import styled from "@emotion/styled"

type Props = {
  date?: Date | string
}

const DateDetail = styled.span({
  display: "block",
  fontSize: "0.78em",
  marginBottom: "0.5em",
  lineHeight: "130%",
  color: "black"
})

const Dom = styled.span({})

const AssessmentDate = ({ date }: Props) => {
  if (!date) return <DateDetail>Open</DateDetail>

  const momdate = moment(date)

  return (
    <DateDetail key="date">
      <div>{momdate.format("MMM")}</div>
      <Dom>{momdate.format("D")}</Dom>
    </DateDetail>
  )
}

export default AssessmentDate
