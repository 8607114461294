import React from "react"
import { connect } from "react-redux"
import { touch } from "redux-form"
import isEmpty from "lodash.isempty"
import get from "lodash.get"
import find from "lodash.find"
import Wizard from "../components/Wizard"
import LayoutWithHeader from "../components/LayoutWithHeader"
import Header from "../components/Header"
import WizardProgressBar from "../components/WizardProgressBar"
import Theme from "../themes/Silver"
import newRippleWizard, {
  steps,
  stepValidator
} from "../contexts/ripple_form/wizard"
import NewRippleDetails from "./NewRippleDetails"
import NewRippleAssessmentDetails from "./NewRippleAssessmentDetails"
import NewRippleInviteParticipants from "./NewRippleInviteParticipants"
import NewSchedule from "./NewSchedule"
import { submitRippleForm } from "../api_actions"
import Loading from "../components/Loading"
import { flatFieldNamesFromErrors } from "../uiHelpers/formHelpers"
import type { TransitionToStepFunc } from "../contexts/shared/step_types"
import type { RippleFormApiResult } from "../state_types"

type Props = {
  rippleFormResult: RippleFormApiResult
  onSubmit: () => any
  formData: {
    values?: unknown
    anyTouched?: boolean
  }
  transitionToStep: TransitionToStepFunc
  touchFields: (fieldNames: Array<string>) => any
}

class NewRipple extends React.Component<Props> {
  currentStepID = () => this.props.rippleFormResult.currentStepID

  stepIsValid(stepID) {
    const errors = this.validationErrors(stepID)
    return isEmpty(errors)
  }

  currentFramework() {
    const frameworkId = get(this.props, "formData.values.framework")
    const frameworks = this.frameworks()
    return frameworkId && frameworks && find(frameworks, { id: frameworkId })
  }

  frameworks() {
    return get(this.props, "rippleFormResult.data.frameworks")
  }

  currentRoles() {
    const framework = this.currentFramework()
    return framework && framework.roles
  }

  currentStepIsValid() {
    return this.stepIsValid(this.currentStepID())
  }

  validationErrors(stepID) {
    const validator = stepValidator(stepID)
    const validationProps = { frameworks: this.frameworks() }
    const validationValues = this.props.formData.values || {}
    return validator(validationValues, validationProps)
  }

  touchAllErrorFieldsInStep(stepID) {
    const errors = this.validationErrors(stepID)
    const fields = flatFieldNamesFromErrors(errors)
    this.props.touchFields(fields)
  }

  requestStepTransition(clientId) {
    return (stepID) => {
      const transitionToStep = this.props.transitionToStep
      if (this.currentStepIsValid()) {
        transitionToStep(stepID, clientId)
      } else {
        this.touchAllErrorFieldsInStep(this.currentStepID())
      }
    }
  }

  renderLoading() {
    return (
      <Theme>
        <LayoutWithHeader
          header={
            <div>
              <Header />
            </div>
          }
        >
          <Loading />
        </LayoutWithHeader>
      </Theme>
    )
  }

  render() {
    const { rippleFormResult, onSubmit } = this.props

    if (!rippleFormResult.data) return this.renderLoading()

    const { currentStepID, initialValues } = rippleFormResult
    const clientId = initialValues && initialValues.clientId
    const submitting = !!rippleFormResult.syncing
    const currentStepNumber = newRippleWizard.stepNumber(currentStepID)
    const stepStates = steps.map((step) => {
      const stepNumber = newRippleWizard.stepNumber(step.id)
      const valid = this.stepIsValid(step.id)
      const attempted =
        this.props.formData.anyTouched && stepNumber <= currentStepNumber
      return { ...step, valid, attempted }
    })
    const rippleForm = rippleFormResult.data

    return (
      <Theme>
        <LayoutWithHeader
          header={
            <div>
              <Header />
              <WizardProgressBar steps={steps} currentStepID={currentStepID} />
            </div>
          }
        >
          <Wizard
            currentStepID={currentStepID}
            steps={stepStates}
            transitionToStep={this.requestStepTransition(clientId)}
            onSubmit={onSubmit}
            submitting={submitting}
          >
            <NewRippleDetails key="ripple-details" />
            <NewRippleAssessmentDetails
              key="assessment"
              frameworks={rippleForm.frameworks}
              formSettings={rippleForm.settings || {}}
            />
            <NewSchedule key="schedule" />
            <NewRippleInviteParticipants
              key="participants"
              roles={this.currentRoles()}
            />
          </Wizard>
        </LayoutWithHeader>
      </Theme>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    rippleFormResult: state.rippleForm || {} || {},
    formData: state.form.ripple || {}
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    onSubmit: () => {
      dispatch(submitRippleForm())
    },
    transitionToStep: (stepID, clientId) => {
      if (clientId) {
        dispatch({
          type: "NEW_RIPPLE_STEP_FOR_CLIENT",
          payload: { step: stepID, clientId }
        })
      } else {
        dispatch({ type: "NEW_RIPPLE_STEP", payload: { step: stepID } })
      }
    },
    touchFields: (fields) => {
      dispatch(touch("ripple", ...fields))
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(NewRipple)
