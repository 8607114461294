import React, { useEffect } from "react"

const baseStyles = {
  height: "100%",
  width: "100%",
  display: "flex",
  flex: 1,
  flexDirection: "column" as const
}

type Props = {
  colour: string
  children
}

const ColouredBackground = (props: Props) => {
  useEffect(() => {
    window.document.body.style.backgroundColor = props.colour
    return () => {
      window.document.body.style.backgroundColor = null
    }
  }, [])

  const customStyles = {
    backgroundColor: props.colour
  }

  return <div style={{ ...baseStyles, ...customStyles }}>{props.children}</div>
}

export default ColouredBackground
