import React from "react"
import map from "lodash.map"
import styled from "@emotion/styled"
import ContainerDimensions from "react-container-dimensions"
import TeamAssessment from "./TeamAssessment"
import NumUserNotes from "./NumUserNotes"
import type {
  TeamAssessment as TeamAssessmentType,
  UserHash
} from "../../state_types"
import type { DialogFunctions } from "../BubbleDialogContainer"
import ThresholdClassifiers from "./ThresholdClassifiers"

type Props = {
  users: UserHash
  sections: TeamAssessmentType
  rippleUuid: string
  dialog: DialogFunctions
  assessmentNumber: number
  sectionId?: string
  outOf: number
  queryParams?: {}
  thresholdClassifiers: Array<string> | null
}

const Notes = styled.div({
  paddingBottom: "1em",
  fontSize: "0.8em"
})

const SectionTitle = styled.div<{ noPadding }>((props) => ({
  paddingTop: "1em",
  paddingBottom: props.noPadding ? 0 : "1em",
  fontWeight: 600
}))

const SectionList = ({
  users,
  sections,
  rippleUuid,
  dialog,
  assessmentNumber,
  sectionId,
  outOf,
  queryParams,
  thresholdClassifiers
}: Props) => {
  return (
    <ContainerDimensions>
      {({ width }) => (
        <div style={{ flex: 1 }}>
          <ThresholdClassifiers thresholdClassifiers={thresholdClassifiers} />

          {map(sections, (section) => {
            let path = `/ripple/${rippleUuid}/assessments/${assessmentNumber}`
            if (sectionId) path += `/${sectionId}`
            path += `/${section.id}`

            const showNotes =
              section.type === "question" &&
              section.responseType !== "comment_only"

            return (
              <div key={section.id}>
                <SectionTitle noPadding={showNotes}>
                  {section.title}
                </SectionTitle>
                {showNotes ? (
                  <Notes>
                    <NumUserNotes userBreakdown={section.userBreakdown} />
                  </Notes>
                ) : null}
                <TeamAssessment
                  users={users}
                  width={width}
                  teamAssessment={section}
                  dialog={dialog}
                  outOf={outOf}
                  linkTo={path}
                  queryParams={queryParams}
                />
              </div>
            )
          })}
        </div>
      )}
    </ContainerDimensions>
  )
}

export default SectionList
