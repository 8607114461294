import React from "react"
import { Image } from "cloudinary-react"
import AvatarContainer from "./AvatarContainer"
import { cloudName } from "./helpers/cloudinary"

type Props = {
  size: number
  publicId: string
}

const CloudinaryUrlAvatarPreview = ({ size, publicId }: Props) => (
  <AvatarContainer size={size}>
    <Image
      cloudName={cloudName}
      publicId={publicId}
      height={size}
      width={size}
      crop="fill"
    />
  </AvatarContainer>
)

export default CloudinaryUrlAvatarPreview
