import React from "react"
import { connect } from "react-redux"
import FilterState from "./FilterState"
import { DropDownFilter, FilterGridContainer } from "../Filters"
import type { DropDownFilterProps } from "../Filters"
import type { State } from "../../state_types"

type FilterParams = {
  organisation?: DropDownFilterProps
  group?: DropDownFilterProps
  filters?
  onChange?: (_newFilters?) => void
}

const Filters = ({ organisation, group, filters, onChange }: FilterParams) => {
  const buildChangeHandler = (key: string) => (newValue: string) =>
    onChange({ ...filters, group: null, [key]: newValue })

  return (
    <FilterGridContainer>
      {organisation && (
        <DropDownFilter
          {...organisation}
          onChange={buildChangeHandler("organisation")}
        />
      )}
      {group && (
        <DropDownFilter {...group} onChange={buildChangeHandler("group")} />
      )}
    </FilterGridContainer>
  )
}

const orgsItems = (org) => ({
  value: org.slug,
  text: org.name
})

const mapStateToProps = (state: State) => {
  const filterState = new FilterState(state)

  const result: any = { filters: filterState.filters }

  result.organisation = {
    label: "Organisation",
    value: filterState.filters.organisation,
    items: filterState.availableOrgs.map(orgsItems)
  }

  result.group = {
    label: "Group",
    value: filterState.filters.group,
    items: filterState.availableGroups
  }

  return result
}

const mapDispatchToProps = (dispatch) => ({
  onChange: (newFilters: { organisation: string }) => {
    dispatch({ type: "EDIT_QR_CODES", filters: newFilters })
    dispatch({
      type: "REMEMBER_ORG_SLUG",
      payload: newFilters.organisation
    })
  }
})

export default connect(mapStateToProps, mapDispatchToProps)(Filters)
