import React from "react"
import { Select, SelectProps, MenuItem } from "@material-ui/core"
import map from "lodash.map"

interface DropDownProps extends SelectProps {
  value: string
  items: Array<{
    value: string
    text: string
  }>
  disabled: boolean
  open?: boolean
  className?: string
  onChange: (any) => void
}

const DropDownMenu = ({ value, items, ...props }: DropDownProps) => {
  return (
    <Select
      value={value ?? ""}
      displayEmpty
      style={{ marginLeft: "24px", marginTop: "12px", minWidth: "220px" }}
      {...props}
    >
      {items &&
        map(items, (item) => (
          <MenuItem key={item.value} value={item.value}>
            {item.text}
          </MenuItem>
        ))}
    </Select>
  )
}

export default DropDownMenu
