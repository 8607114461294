import React from "react"
import styled from "@emotion/styled"
import isEmpty from "lodash.isempty"
import colours from "../../themes/colours"
import { Anchor } from "../TextStyles"
import CommentCard from "../CommentCard"
import AvatarPreview from "../AvatarPreview"

type Comment = {
  id: string | number
  text: string
  user: {
    id: string
    name: string
    avatar: string
  }
}

type Props = {
  titleWidth: number
  width: number
  comments: Array<Comment>
  mobile?: boolean
}

type State = {
  showUserComments: boolean
}

const Container = styled.div({
  marginBottom: 15
})

const Row = styled.div<{ mobile }>((props) => ({
  display: "flex",
  flexDirection: props.mobile ? "column" : "row"
}))

const Title = styled.div<{ width; mobile }>((props) => ({
  display: "flex",
  flexDirection: "column",
  width: props.width,
  fontSize: "80%",
  paddingBottom: props.mobile ? "10px" : "0px"
  // border: '1px solid red'
}))

const CommentsContainer = styled.div<{ width }>((props) => ({
  width: props.width
}))

const CommentAvatars = styled.div({
  display: "flex"
})

const AvatarContainer = styled.div({
  display: "flex",
  marginLeft: -5
})

const Heading = styled.div({
  paddingRight: "10px"
})

const NoComments = styled.div({
  color: colours.darkGrey
})

const CommentContainer = styled.div({
  paddingBottom: "10px"
})

class TeamAssessmentComments extends React.Component<Props, State> {
  static defaultProps = {
    mobile: false
  }

  state = { showUserComments: false }

  handleClick = () => {
    this.setState((state) => ({ showUserComments: !state.showUserComments }))
  }

  render() {
    const { titleWidth, width, comments, mobile } = this.props
    const commentWidth = mobile ? width : width - titleWidth - 10
    return (
      <Container>
        <Row mobile={mobile}>
          <Title mobile={mobile} width={titleWidth}>
            <Heading>Comments</Heading>
            {isEmpty(comments) ? (
              <NoComments>no comments</NoComments>
            ) : (
              <Anchor href="#" onClick={this.handleClick}>
                {this.state.showUserComments ? "hide" : "view all"}
              </Anchor>
            )}
          </Title>
          {this.state.showUserComments ? (
            <CommentsContainer width={commentWidth}>
              {comments.map((comment) => (
                <CommentContainer key={comment.id}>
                  <CommentCard
                    commentText={comment.text}
                    detailText=""
                    userName={comment.user.name}
                    userAvatar={comment.user.avatar}
                  />
                </CommentContainer>
              ))}
            </CommentsContainer>
          ) : (
            <CommentAvatars>
              {comments.map((comment) => (
                <AvatarContainer key={comment.id}>
                  <AvatarPreview
                    size={37}
                    name={comment.user.name}
                    value={comment.user.avatar}
                  />
                </AvatarContainer>
              ))}
            </CommentAvatars>
          )}
        </Row>
      </Container>
    )
  }
}

export default TeamAssessmentComments
