import React from "react"
import styled from "@emotion/styled"
import AvatarPreview from "../../AvatarPreview"
import colours from "../../../themes/colours"
import AssessmentItemScores from "../AssessmentItemScores"

const Container = styled.div({
  marginBottom: 8,
  ":last-child": {
    marginBottom: 0
  }
})

const Row = styled.div({
  display: "flex",
  flexDirection: "row"
})

const Title = styled.div<{ width; indent }>((props) => ({
  width: props.width,
  marginLeft: props.indent,
  fontSize: "80%",
  display: "flex",
  flexShrink: 1,
  flexDirection: "row"
}))

const UserAvatar = styled.div<{ width }>((props) => ({
  width: props.width
}))

const UserDetails = styled.div<{ width; gutter }>((props) => ({
  width: props.width,
  display: "flex",
  flexDirection: "column",
  marginLeft: props.gutter,
  lineHeight: "120%"
}))

const UserName = styled.div({
  whiteSpace: "nowrap",
  overflow: "hidden",
  textOverflow: "ellipsis"
})

const UserRole = styled.div({
  whiteSpace: "nowrap",
  overflow: "hidden",
  textOverflow: "ellipsis",
  color: colours.darkGrey,
  lineHeight: "130%"
})

const ScoreContainer = styled.div<{ width }>((props) => ({
  width: props.width
}))

const ONE_THIRD = 1 / 3
const TWO_THIRDS = 2 / 3

type Props = {
  section
  titleWidth: number
  width: number
  mobile?: boolean
}

const SdqUserSection = ({ section, titleWidth, width, mobile }: Props) => {
  const tableWidth = width - titleWidth - 4
  const avatarWidth = 28
  const indent = mobile ? 0 : 15
  const gutter = 8
  const userDetailsWidth = titleWidth - indent - avatarWidth - 2 * gutter
  const { user } = section
  const radius = 17
  const barHeight = 40

  const assessment = {
    range: [section.score, section.score],
    thresholds: [100 * ONE_THIRD, 100 * TWO_THIRDS],
    score: section.normalisedScore,
    displayScore: section.score,
    id: section.id
  }

  return (
    <Container>
      <Row>
        <Title width={titleWidth - indent} indent={indent}>
          <UserAvatar width={avatarWidth}>
            <AvatarPreview
              value={user.avatar}
              name={user.name}
              size={avatarWidth}
            />
          </UserAvatar>
          <UserDetails width={userDetailsWidth} gutter={gutter}>
            <UserName>{user.name}</UserName>
            <UserRole>{user.roleName}</UserRole>
          </UserDetails>
        </Title>
        <ScoreContainer width={tableWidth}>
          <AssessmentItemScores
            users={[]}
            teamAssessment={assessment}
            width={tableWidth}
            radius={radius}
            height={barHeight}
            dialog={null}
            outOf={100}
            disableRule
          />
        </ScoreContainer>
      </Row>
    </Container>
  )
}

SdqUserSection.defaultProps = {
  mobile: false
}

export default SdqUserSection
