import React from "react"
import styled from "@emotion/styled"
import map from "lodash.map"
import AddComment from "../containers/AddComment"
import DiscussionCommentCard from "./DiscussionCommentCard"
import { gutter } from "./Layout"

const Container = styled.div({
  display: "flex",
  flexDirection: "column",
  height: "100%",
  zIndex: 1,
  padding: gutter
})

const CommentEntryContainer = styled.div({})

const CommentFeedContainer = styled.div({
  paddingTop: gutter
})

const Row = styled.div({
  paddingBottom: "0.8em"
})

type Props = {
  rippleUuid: string
  rippleFeed: Array<any>
}

const CommentsContainer = ({ rippleUuid, rippleFeed }: Props) => {
  return (
    <Container>
      <CommentEntryContainer>
        <AddComment rippleUuid={rippleUuid} />
      </CommentEntryContainer>
      <CommentFeedContainer>
        {map(rippleFeed, (feedItem) => (
          <Row key={feedItem.id}>
            <DiscussionCommentCard feedItem={feedItem} />
          </Row>
        ))}
      </CommentFeedContainer>
    </Container>
  )
}

export default CommentsContainer
