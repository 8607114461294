import React from "react"
import Linkify from "react-linkify"
import styled from "@emotion/styled"
import AvatarPreview from "./AvatarPreview"

type Props = {
  commentText: string
  detailText: string
  userName: string
  userAvatar: string
}

const Container = styled.div({
  display: "flex",
  flexDirection: "column",
  backgroundColor: "white",
  justifyContent: "center",
  padding: "10px",
  border: "1px solid #DCDCDC"
})

const UserDetail = styled.div({
  display: "flex",
  alignItems: "center"
})

const Note = styled.div({
  color: "#6C727C",
  fontSize: "0.8em",
  paddingTop: "10px",
  whiteSpace: "pre-wrap",
  wordWrap: "break-word"
})

const Detail = styled.div({
  display: "flex",
  flexDirection: "column",
  paddingLeft: "10px"
})

const Avatar = styled.div({})

const Name = styled.div({
  fontWeight: 600
})

const FurtherDetail = styled.div({
  fontSize: "0.8em"
})

const CommentCard = ({
  userName,
  userAvatar,
  commentText,
  detailText
}: Props) => {
  return (
    <Container>
      <UserDetail>
        <Avatar>
          <AvatarPreview size={37} name={userName} value={userAvatar} />
        </Avatar>
        <Detail>
          <Name>{userName}</Name>
          <FurtherDetail>{detailText}</FurtherDetail>
        </Detail>
      </UserDetail>
      <Note>
        <Linkify properties={{ target: "_blank", rel: "noopener noreferrer" }}>
          {commentText}
        </Linkify>
      </Note>
    </Container>
  )
}

export default CommentCard
