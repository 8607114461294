import React from "react"
import styled from "@emotion/styled"
import colours from "../themes/colours"
import { actionButtonHeight } from "./PageBottomActions"

const ButtonLink = styled.a<{ disabled?; theme?: { buttons? } }>(
  ({ disabled }) => ({
    padding: "0 25px",
    borderRadius: "500px",
    textDecoration: "none",
    backgroundColor: colours.blue,
    color: "white",
    opacity: disabled ? 0.5 : 1.0,
    cursor: disabled ? "default" : "pointer",
    height: actionButtonHeight,
    lineHeight: actionButtonHeight,
    whiteSpace: "nowrap",
    userSelect: "none"
  }),
  ({ theme }) => theme.buttons
)

interface IProps {
  disabled?: boolean
  onClick?: React.MouseEventHandler
  children?: React.ReactNode
  theme?: Record<string, unknown>
}

const Button = (props: IProps) => {
  let linkProps: {} = props

  if (props.disabled) {
    linkProps = { ...linkProps, onClick: null, href: null }
  } else {
    linkProps = { href: "#", ...linkProps }
  }
  return <ButtonLink {...linkProps} />
}

export default Button
