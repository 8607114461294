import React from "react"
import map from "lodash.map"
import { Field } from "redux-form"
import styled from "@emotion/styled"
import CsvUploader from "../../components/CsvUploader"
import StringSelectField from "../../components/StringSelectField"

type Props = {
  orgs: []
}

const FormContainer = styled.div({
  display: "flex",
  flexDirection: "column",
  alignItems: "center"
})

const FieldsContainer = styled.div({
  display: "flex",
  flex: 1,
  flexDirection: "column",
  justifyContent: "flex-start"
})

const FieldContainer = styled.div({
  minHeight: "4.5em",
  display: "flex",
  justifyContent: "flex-start",
  width: "320px",
  "@media(max-width: 320px)": {
    width: "100%"
  }
})

const ImportRipplesDetailsForm = ({ orgs }: Props) => {
  return (
    <FormContainer>
      <FieldsContainer>
        <FieldContainer>
          <Field name="csvFile" component={CsvUploader} />
        </FieldContainer>
        <FieldContainer>
          <Field
            name="organisation"
            component={StringSelectField}
            floatingLabelText="Organisation"
            fullWidth
            options={map(orgs, (org) => [org.slug, org.name])}
          />
        </FieldContainer>
      </FieldsContainer>
    </FormContainer>
  )
}

ImportRipplesDetailsForm.defaultProps = {}

export default ImportRipplesDetailsForm
