import React from "react"
import styled from "@emotion/styled"
import Overlay from "./Overlay"
import type { DispatchFunction } from "../state_types"
import Panel, { ButtonGroup, PanelAction } from "./Panel"
import { TallButton } from "./TallButton"
import CalloutContainer from "./CalloutContainer"

export type ConfirmationModalProps = {
  title: string
  question: Array<string>
  onConfirm: DispatchFunction
  onCancel: DispatchFunction
  display: boolean
}

const Container = styled(CalloutContainer)<{ responsive: boolean }>({
  maxWidth: "445px"
})

const ConfirmationPanel = styled(Panel)({
  flexDirection: "column",
  minHeight: "250px"
})

const Sentence = styled.p({
  marginBlockEnd: 0
})

const Text = styled.div({
  marginBottom: "auto"
})

function makeButton(label, onClick): JSX.Element {
  return <TallButton label={label} onClick={onClick} />
}
const ConfirmationModal = ({
  title,
  question,
  onConfirm,
  onCancel,
  display
}: ConfirmationModalProps) =>
  display ? (
    <Overlay background="translucent" fullScreen>
      <Container responsive={false}>
        <ConfirmationPanel topPadding>
          <PanelAction>{title}</PanelAction>
          <Text>
            {question.map((q) => (
              <Sentence key={q}>{q}</Sentence>
            ))}
          </Text>
          <ButtonGroup
            width="100%"
            group="bulk-confirmation"
            buttons={[
              makeButton("Confirm", onConfirm),
              makeButton("Cancel", onCancel)
            ]}
          />
        </ConfirmationPanel>
      </Container>
    </Overlay>
  ) : null

export default ConfirmationModal
