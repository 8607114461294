import React from "react"
import styled from "@emotion/styled"
import Ellipsis from "../Ellipsis"
import AvatarPreview from "../AvatarPreview"
import colours from "../../themes/colours"
import { gutter } from "../Layout"
import ToggleButton from "../ToggleButton"
import { Assessor, Ripple } from "../../state_types"

export const HEIGHT_OF_ROW = 57

type Props = {
  ripple: Ripple
  assessors: Array<Assessor>
  toggleQrStatus: (ids: Array<any>, qrActive: boolean) => {}
}

const TR = styled.tr({
  fontSize: "0.9em"
})

const TD = styled.td({
  borderBottom: `1px solid ${colours.boldLineOnSilver}`,
  paddingRight: gutter,
  textAlign: "left",
  whiteSpace: "nowrap",
  height: 30,
  paddingTop: 10,
  paddingBottom: 10,
  ":last-child": {
    paddingRight: 0
  }
})

const StretchingCell = styled(TD)({
  width: "99%"
})

const ButtonsContainer = styled.div({
  display: "flex",
  flexDirection: "row",
  flexWrap: "nowrap",
  marginBottom: -10
})

const ButtonContainer = styled.div({
  display: "inline-block",
  marginRight: 10,
  marginBottom: 10,
  ":last-child": {
    marginRight: 0
  }
})

const assessorButtons = (assessor, toggleQrStatus) => (
  <ButtonsContainer>
    <ButtonContainer>
      <ToggleButton
        label="QR code"
        value={assessor.qrActive}
        small
        faIcon="qrcode"
        onChange={() => toggleQrStatus([assessor.id], !assessor.qrActive)}
      />
    </ButtonContainer>
  </ButtonsContainer>
)

const AssessorDetails = styled.div({
  display: "flex",
  flexDirection: "row",
  flexWrap: "wrap",
  marginTop: "5px",
  paddingBottom: "5px"
})

const AssessorName = styled.div({
  flex: 2
})

const AssessorButtons = styled.div({
  flex: 1
})

const renderAssessors = (assessors, toggleQrStatus) =>
  assessors.map((assessor) => (
    <AssessorDetails key={assessor.id}>
      <AssessorName>{assessor.name}</AssessorName>
      <AssessorButtons>
        {assessorButtons(assessor, toggleQrStatus)}
      </AssessorButtons>
    </AssessorDetails>
  ))

const RippleTableRow = ({ ripple, assessors, toggleQrStatus }: Props) => {
  const { clientName, clientAvatar } = ripple

  return (
    <TR>
      {[
        <TD key="avatar">
          <AvatarPreview value={clientAvatar} name={clientName} size={37} />
        </TD>,
        <TD key="name">
          <Ellipsis maxWidth="16em">
            <strong>{clientName}</strong>
          </Ellipsis>
        </TD>,
        <StretchingCell key="assessors">
          {renderAssessors(assessors, toggleQrStatus)}
        </StretchingCell>
      ]}
    </TR>
  )
}

export default React.memo(RippleTableRow)
