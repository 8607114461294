import React from "react"
import styled from "@emotion/styled"
import ReportCard from "./ReportCard"
import type { ReportSummary as ReportSummaryType } from "../../state_types"
import { CircleStyle } from "../ScoreCircle"
import { scoreColour } from "../../utils/scoreColour"

const ScoreContainer = styled.div({
  flex: 1,
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  paddingBottom: 20,
  "& > *": {
    marginTop: 10
  }
})

type ReportSummaryCardProps = {
  width: number
  height: number
  report: ReportSummaryType
  onClick: () => {}
}

const CircleContents = styled.div({
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  textAlign: "center",
  color: "white"
})

const Count = styled.div({
  fontSize: "40px",
  lineHeight: "110%"
})

const ScoreSubtitle = styled.div({
  fontSize: "10px",
  lineHeight: "110%"
})

const Subtitle = styled.div({
  paddingLeft: "10px",
  alignSelf: "center",
  overflow: "hidden",
  textOverflow: "ellipsis"
})

const CompletionRatesSummaryCard = ({
  width,
  height,
  report,
  onClick
}: ReportSummaryCardProps) => {
  const { reportData } = report
  if (!reportData) return null

  const { threshold, ripplesUnderThreshold, ripplesTotal } = reportData
  const radius = 55
  const score =
    ripplesTotal > 0 ? 100 - (100 * ripplesUnderThreshold) / ripplesTotal : null

  return (
    <ReportCard
      width={width}
      height={height}
      title={report.title}
      onClick={onClick}
    >
      <ScoreContainer>
        <CircleStyle
          radius={radius}
          gradientColor={scoreColour(score, [30, 70])}
        >
          <CircleContents>
            <Count>{ripplesUnderThreshold}</Count>
            <ScoreSubtitle>Ripples</ScoreSubtitle>
          </CircleContents>
        </CircleStyle>
        <Subtitle>With assessors under {threshold * 100}%</Subtitle>
      </ScoreContainer>
    </ReportCard>
  )
}

export default CompletionRatesSummaryCard
