import React from "react"
import MuiPopover from "@material-ui/core/Popover"

interface IProps {
  open?: boolean
  anchorEl?: Element
  handleRequestClose?(reason: string): void
  children: React.ReactNode
}

const Popover = ({ open, anchorEl, handleRequestClose, children }: IProps) => {
  const maxHeight = (window.innerHeight / 3) * 2

  return (
    <MuiPopover
      open={open}
      anchorEl={anchorEl}
      anchorOrigin={{ horizontal: "left", vertical: "bottom" }}
      transformOrigin={{ horizontal: "left", vertical: "top" }}
      onClose={handleRequestClose}
      style={{
        marginTop: "20px",
        borderRadius: "6px"
      }}
    >
      <div
        style={{
          padding: "10px 0",
          minWidth: "325px",
          maxHeight: `${maxHeight}px`
        }}
      >
        {children}
      </div>
    </MuiPopover>
  )
}

export default Popover
