import React from "react"
import { connect } from "react-redux"
import { reduxForm, formValueSelector, InjectedFormProps } from "redux-form"
import { stepValidator } from "../../contexts/import_ripples_form/wizard"
import type {
  FrameworkInfo,
  RippleFormSettings
} from "../../contexts/ripple_form/types"
import RippleAssessmentForm from "../../components/RippleAssessmentForm"

interface IProps {
  frameworks: Array<FrameworkInfo>
  formSettings: RippleFormSettings
  currentFrameworkId?: string
}

class NewAssessmentDetails extends React.Component<
  InjectedFormProps<IProps> & IProps
> {
  handleSubmit = () => {}

  render() {
    const noFramework = !this.props.currentFrameworkId
    const { frameworks, formSettings, currentFrameworkId } = this.props

    return (
      <form onSubmit={this.handleSubmit}>
        <RippleAssessmentForm
          noFramework={noFramework}
          frameworks={frameworks}
          formSettings={formSettings}
          currentFrameworkId={currentFrameworkId}
        />
      </form>
    )
  }
}

const AssessmentDetailsForm = reduxForm({
  form: "importRipples",
  destroyOnUnmount: false,
  forceUnregisterOnUnmount: true,
  validate: stepValidator("assessment")
})(NewAssessmentDetails)

const selector = formValueSelector("importRipples")

const mapStateToProps = (state) => {
  return { currentFrameworkId: selector(state, "framework") }
}

export default connect<any, any, IProps>(mapStateToProps)(AssessmentDetailsForm)
