import React from "react"
import styled from "@emotion/styled"
import { capitalize } from "underscore.string"
import { NavLink } from "redux-first-router-link"
import BubbleDialogContainer from "./BubbleDialogContainer"
import { Ripple } from "../state_types"
import type { DialogFunctions } from "./BubbleDialogContainer"
import FrameworkDetails from "./FrameworkDetails"
import MemberDetails from "./MemberDetails"
import ScheduleDetails from "./team_assessment/ScheduleDetails"
import FileDetails from "./FileDetails"
import colours from "../themes/colours"

const InfoPanel = styled.div({
  display: "flex",
  height: "100%",
  justifyContent: "space-between",
  lineHeight: "30px"
})

const RipplesContainer = styled.div({
  display: "flex",
  justifyContent: "flex-start",
  alignItems: "baseline",
  flexWrap: "wrap",
  alignContent: "flex-start"
})

const baseStyles = {
  marginRight: "20px",
  marginBottom: "5px",
  cursor: "pointer",
  color: colours.deemphasisedText,
  textDecoration: "none",
  fontSize: "14px",
  ":hover": {
    color: colours.blue
  }
}

const linkStyles = {
  ...baseStyles,
  color: colours.blue
}

const activeStyles = {
  color: "inherit",
  borderBottom: `3px solid ${colours.blue}`
}

const Item = styled.a({
  padding: "0 10px",
  cursor: "pointer",
  color: "inherit",
  textDecoration: "none",
  fontSize: "14px"
})

const PanelContainer = styled.div({
  flexWrap: "wrap",
  display: "flex",
  alignContent: "flex-start",
  "@media (max-width: 475px)": {
    width: "100%",
    padding: "0"
  }
})

const InfoContainer = styled.div({
  flexBasis: "content"
})

function onClick(dialog: DialogFunctions, key, contents) {
  return (event) => {
    if (event) {
      const target: HTMLElement = event.currentTarget
      dialog.toggle(key, target, contents)
    }
  }
}

const PanelItem = ({ dialog, name, content }: { dialog; name; content }) => {
  return (
    <Item href="#" onClick={onClick(dialog, name, content)}>
      {capitalize(name)}
    </Item>
  )
}

const Link = styled.a({})

export type RippleInfoPanelProps = {
  ripple: Ripple
  clientsRipples?
  permissions?: {
    userCanCreateRipples?: boolean
  }
  dispatchAddRippleToClient?
}

const RippleInfoPanelDisconnected = ({
  ripple,
  clientsRipples,
  permissions,
  dispatchAddRippleToClient
}: RippleInfoPanelProps) => {
  const { nextAssessment, schedule, users, frameworkInfo, files } = ripple

  return (
    <BubbleDialogContainer maxWidth={400} outsideClickCloses>
      {(dialogCallbacks) => (
        <InfoPanel>
          <RipplesContainer className="ripplesContainer">
            {clientsRipples &&
              clientsRipples.map((data) => (
                <NavLink
                  className="rippleLink"
                  key={data.rippleUuid}
                  activeStyle={activeStyles}
                  style={baseStyles}
                  to={{
                    type: "RIPPLE",
                    payload: { rippleUuid: data.rippleUuid }
                  }}
                >
                  {data.frameworkTitle}
                </NavLink>
              ))}
            {permissions.userCanCreateRipples && (
              <Link
                href="#"
                style={linkStyles}
                onClick={() => dispatchAddRippleToClient(ripple.clientId)}
              >
                Add +
              </Link>
            )}
          </RipplesContainer>
          <InfoContainer>
            <PanelContainer>
              <PanelItem
                dialog={dialogCallbacks}
                name="framework"
                content={
                  <FrameworkDetails
                    frameworkInfo={frameworkInfo}
                    ripple={ripple}
                  />
                }
              />
              <PanelItem
                dialog={dialogCallbacks}
                name="schedule"
                content={
                  <ScheduleDetails
                    nextAssessment={nextAssessment}
                    schedule={schedule}
                    ripple={ripple}
                  />
                }
              />
              <PanelItem
                dialog={dialogCallbacks}
                name="members"
                content={<MemberDetails users={users} ripple={ripple} />}
              />
              <PanelItem
                dialog={dialogCallbacks}
                name="files"
                content={<FileDetails files={files} />}
              />
            </PanelContainer>
          </InfoContainer>
        </InfoPanel>
      )}
    </BubbleDialogContainer>
  )
}

export default RippleInfoPanelDisconnected
