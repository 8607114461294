type OnSuccessFunc = (_fileName: string, _txt: string | ArrayBuffer) => void
type OnFailureFunc = (_fileName: string, _error: string) => void

export function decodeTxtFile(
  file: File,
  onSuccess: OnSuccessFunc,
  onFailure: OnFailureFunc
) {
  const reader = new FileReader()

  reader.onload = (e) => {
    if (file.type === "text/csv") {
      const txt = e.target.result
      onSuccess(file.name, txt)
    } else {
      onFailure(file.name, `File must be a CSV (comma-separated-values)`)
    }
  }

  reader.readAsText(file)
}
