import immutable from "object-path-immutable"
import { applyReducerHash } from "../../redux_helpers"
import type { RippleFormApiResult, Action } from "../../state_types"
import newImportRipplesWizard, { initialStepID } from "./wizard"

const reducers = {
  IMPORT_RIPPLES_STEP: (
    state: (RippleFormApiResult & { org_slug }) | null | undefined,
    action: Action
  ) => {
    const payload = action.payload || {}
    const payloadStep =
      payload.step && newImportRipplesWizard.containsStep(payload.step)
        ? payload.step
        : null
    const desiredStep = payloadStep || initialStepID

    const newState = immutable.set(state, "currentStepID", desiredStep)
    if (payload.org_slug) newState.org_slug = payload.org_slug

    return newState
  },

  IMPORT_RIPPLES_OPTIONS: (
    state: RippleFormApiResult | null | undefined,
    action: Action
  ) => {
    const payload = action.payload || {}
    return immutable.set(state, ["data"], payload.data)
  },

  SUBMITTING_IMPORT_RIPPLES_FORM: (
    state: RippleFormApiResult | null | undefined,
    _action: Action
  ) => {
    return { ...state, syncing: "sending" }
  },

  SUBMITTED_IMPORT_RIPPLES_FORM: () => {
    return null
  }
}

export default function reducer(
  state: RippleFormApiResult = null,
  action: Action
): RippleFormApiResult | null | undefined {
  return applyReducerHash(reducers, state, action)
}
