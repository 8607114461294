import React from "react"
import get from "lodash.get"
import Link from "redux-first-router-link"
import { bindActionCreators } from "redux"
import { connect } from "react-redux"
import styled from "@emotion/styled"
import Theme from "../themes/White"
import { submitResetPassword } from "../api_actions"
import EditPasswordForm from "../components/EditPasswordForm"
import type { State } from "../state_types"

const EditPasswordContainer = styled.div({
  display: "flex",
  flexDirection: "column",
  flexGrow: 1,
  paddingTop: "3em"
})

const CenteredColumn = styled.div({
  display: "flex",
  flexDirection: "column",
  alignItems: "center"
})

const Contents = styled.div({
  maxWidth: 500,
  textAlign: "center"
})

const ErrorMessage = styled.div({
  maxWidth: 500,
  textAlign: "center",
  color: "red",
  fontWeight: "bold",
  marginBottom: "1em"
})

type ResetError = null | "invalid_token"

type Props = {
  submitResetPassword: (
    password: string,
    token: string,
    onFailure: (error: ResetError) => void
  ) => any
  token?: string
}

type TState = {
  error: ResetError
}

class NewPasswordPage extends React.Component<Props, TState> {
  constructor(props) {
    super(props)
    this.state = { error: null }
  }

  handleSubmit = (data) => {
    const { password } = data
    const { token } = this.props
    this.props.submitResetPassword(password, token || "", this.handleFailure)
  }

  handleFailure = (error: ResetError) => {
    this.setState({ error })
  }

  renderError() {
    const { error } = this.state
    switch (error) {
      case "invalid_token":
        return (
          <CenteredColumn>
            <ErrorMessage>
              Sorry, this reset password link is invalid, has already been used,
              or has expired. You can{" "}
              <Link to={{ type: "FORGOT_PASSWORD" }}>Send another</Link>
            </ErrorMessage>
          </CenteredColumn>
        )

      default:
        return null
    }
  }

  render() {
    const { token } = this.props
    return (
      <Theme>
        <EditPasswordContainer>
          {this.renderError()}
          {token ? (
            <EditPasswordForm onSubmit={this.handleSubmit} syncing={false} />
          ) : (
            <CenteredColumn>
              <Contents>
                <p>No token specified.</p>
              </Contents>
            </CenteredColumn>
          )}
        </EditPasswordContainer>
      </Theme>
    )
  }
}

const mapStateToProps = (state: State) => {
  const token = get(state.location, "query.token")
  return { token }
}

const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ submitResetPassword }, dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(NewPasswordPage)
