import styled from "@emotion/styled"
import React from "react"
import { containerWidth, mediaQueries } from "../../Layout"

type Props = {
  children: any
  id?: string
}

const OuterCardContainer = styled.div({
  marginLeft: "auto",
  marginRight: "auto",
  display: "flex",
  flex: 1,
  flexDirection: "column",
  justifyContent: "center",
  backgroundColor: "white",
  minHeight: 500,
  [mediaQueries.desktop]: {
    width: containerWidth,
    borderRadius: 10,
    backgroundColor: "transparent"
  }
})

const VisibleCard = styled.div({
  backgroundColor: "white",
  display: "flex",
  flex: 1,
  flexDirection: "column",
  maxHeight: "100%",
  [mediaQueries.desktop]: {
    position: "relative",
    borderRadius: 10,
    marginTop: 35,
    marginBottom: 35,
    boxShadow: "0 10px 20px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23)"
  }
})

const SimpleRadioCard = ({ children, ...rest }: Props) => {
  return (
    <OuterCardContainer {...rest}>
      <VisibleCard>{children}</VisibleCard>
    </OuterCardContainer>
  )
}

export default SimpleRadioCard
