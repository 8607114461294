import React from "react"
import MuiLinearProgress from "@material-ui/core/LinearProgress"

interface IProps {
  stepCount: number
  currentStep: number
}

const LinearProgress = ({ stepCount, currentStep }: IProps) => {
  const progress = (currentStep / stepCount) * 100
  return <MuiLinearProgress variant="determinate" value={progress} />
}

export default LinearProgress
