import React from "react"
import styled from "@emotion/styled"
import { connect } from "react-redux"
import { InjectedFormProps, reduxForm } from "redux-form"
import FontAwesome from "react-fontawesome"
import RippleAssessmentForm from "./RippleAssessmentForm"
import {
  fieldsRequired,
  fieldMustBeGreaterThanOtherField
} from "../contexts/shared/validation"
import SubmitButton from "./SubmitButton"
import { gutter } from "./Layout"
import type { FrameworkInfo } from "../contexts/ripple_form/types"

const RippleDetailsContainer = styled.div({
  display: "flex",
  flexDirection: "column",
  flexGrow: 1
})

const Header = styled.div({
  display: "flex",
  justifyContent: "flex-start"
})

const GoBack = styled.a({
  textDecoration: "none"
})

const BodyContents = styled.div({
  display: "flex",
  justifyContent: "center"
})

const FormContainer = styled.div({
  padding: gutter,
  width: "800px"
})

const ArrowText = styled.div({
  display: "flex",
  padding: "30px 0 0 35px",
  alignItems: "baseline"
})

const Text = styled.div({
  color: "#000000",
  fontFamily: "Poppins",
  fontSize: "1em",
  lineHeight: "23px"
})

const SubmitButtonWrapper = styled.div({
  display: "flex",
  justifyContent: "center",
  paddingTop: "45px"
})

function validate(values) {
  const errors = {}
  fieldsRequired(values, errors, "lowerThreshold", "upperThreshold")
  fieldMustBeGreaterThanOtherField(
    values,
    errors,
    "upperThreshold",
    "lowerThreshold",
    2
  )
  return errors
}

function handleCancel(event) {
  event.preventDefault()

  window.history.back()
}

type OnSubmitFunc = () => any

interface IProps extends InjectedFormProps {
  onSubmit: OnSubmitFunc
  framework: FrameworkInfo
}

const EditRippleAssessmentForm = ({
  onSubmit,
  framework,
  handleSubmit
}: IProps) => {
  return (
    <RippleDetailsContainer>
      <Header>
        <GoBack href="#" onClick={handleCancel}>
          <ArrowText>
            <FontAwesome
              name="long-arrow-left"
              style={{
                paddingRight: "10px",
                textDecoration: "none",
                color: "black"
              }}
            />
            <Text>Cancel</Text>
          </ArrowText>
        </GoBack>
      </Header>
      <BodyContents>
        <FormContainer>
          <form onSubmit={handleSubmit(onSubmit)} id="ripple-details-form">
            <RippleAssessmentForm
              edit
              frameworks={[framework]}
              formSettings={{}}
              currentFrameworkId={framework.id}
            />
            <SubmitButtonWrapper>
              <SubmitButton />
            </SubmitButtonWrapper>
          </form>
        </FormContainer>
      </BodyContents>
    </RippleDetailsContainer>
  )
}

const NewEditRippleAssessmentForm = reduxForm({
  form: "userEditRipple",
  validate
})(EditRippleAssessmentForm)

const mapStateToProps = (state) => {
  const ripple = state.rippleDetails.data.ripple
  return {
    initialValues: {
      lowerThreshold: ripple.lowerThreshold,
      upperThreshold: ripple.upperThreshold
    },
    framework: ripple.frameworkInfo
  }
}

export default connect(mapStateToProps)(NewEditRippleAssessmentForm)
