import styled from "@emotion/styled"
import React from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  faChevronRight,
  faChevronLeft,
  faXmark
} from "@fortawesome/free-solid-svg-icons"
import colours from "../../../themes/colours"
import { actionButtonHeight } from "../../PageBottomActions"

const iconMap = {
  chevRight: faChevronRight,
  chevLeft: faChevronLeft,
  xMark: faXmark
}

const Button = styled.a<{
  disabled?: boolean
  secondary?: boolean
  fullWidth?: boolean
  large?: boolean
}>(
  ({ disabled }) => ({
    width: "fit-content",
    padding: "0 25px",
    borderRadius: "50px",
    textDecoration: "none",
    whiteSpace: "nowrap",
    cursor: "pointer",
    fontWeight: 600,
    fontSize: "1rem",
    height: actionButtonHeight,
    lineHeight: actionButtonHeight,
    backgroundColor: colours.blue,
    color: colours.white,
    display: "flex",
    alignItems: "center",
    boxSizing: "border-box",
    ":hover, :focus": {
      backgroundColor: disabled ? colours.blue : colours.activeBlue
    }
  }),
  ({ fullWidth }) => {
    return fullWidth
      ? {
          width: "100%",
          justifyContent: "center",
          margin: "0"
        }
      : {}
  },
  ({ large }) => {
    return large
      ? {
          fontSize: "22px",
          height: "2.25em"
        }
      : {}
  },
  ({ secondary, disabled }) => {
    return secondary
      ? {
          color: colours.black,
          backgroundColor: colours.white,
          fontWeight: 400,
          ":hover, :focus": {
            backgroundColor: disabled ? colours.white : colours.boldLineOnSilver
          }
        }
      : {}
  },
  ({ disabled }) => {
    return disabled
      ? {
          opacity: 0.5,
          cursor: "not-allowed"
        }
      : {}
  }
)

const ButtonText = styled.span({
  margin: "0 .5rem"
})

type Props = {
  icon?: "chevLeft" | "chevRight" | "xMark" | undefined
  prefixIcon?: boolean
  text: string
  secondary?: boolean
  disabled?: boolean
  fullWidth?: boolean
  large?: boolean
  onClick: () => void
}

const FAIcon = (icon: string) => {
  return <FontAwesomeIcon icon={iconMap[icon]} />
}

const clickHandler = (onClick: () => void, disabled: boolean | undefined) => {
  return (event) => {
    event.preventDefault()

    if (!disabled) {
      onClick()
    }
  }
}

const SimpleRadioButton = ({
  icon,
  prefixIcon,
  text,
  secondary,
  onClick,
  fullWidth,
  large,
  disabled
}: Props) => {
  return (
    <Button
      onClick={clickHandler(onClick, disabled)}
      secondary={secondary}
      id={text}
      href={disabled ? undefined : "#"}
      disabled={disabled}
      fullWidth={fullWidth}
      large={large}
    >
      {icon && prefixIcon && FAIcon(icon)}
      <ButtonText>{text}</ButtonText>
      {icon && !prefixIcon && FAIcon(icon)}
    </Button>
  )
}

export default SimpleRadioButton
