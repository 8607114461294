import React, { Component } from "react"
import { connect } from "react-redux"
import { Button } from "@material-ui/core/"
import styled from "@emotion/styled"
import {
  Header,
  SubHeader,
  PrimaryActions,
  Instructions
} from "../components/AuthFormStyles"
import LayoutWithHeader from "../components/LayoutWithHeader"
import PageHeader from "../components/Header"
import Theme from "../themes/Silver"
import LoginLayout from "../layouts/LoginLayout"

type Props = {
  backupCodes: string[]
  syncing: boolean
  onSubmit: () => {}
  onNoBackupCodes: () => {}
}

type State = {}

// Monospace text block
const MonospaceTextBlock = styled.div({
  background: "#EEEEEE",
  padding: "10px",
  border: "1px solid #CCCCCC",
  borderRadius: "4px",
  fontFamily: '"Roboto Mono", Monospace',
  width: "min(500px, 100%)",
  marginBottom: "1em",
  whiteSpace: "pre"
})

class DisplayBackupCodes extends Component<Props> {
  state = {}

  static getDerivedStateFromProps(props: Props, state: State): State {
    if (!props.backupCodes) {
      props.onNoBackupCodes()
    }
    return state
  }

  render() {
    if (!this.props.backupCodes) {
      return null
    }

    return (
      <Theme>
        <LayoutWithHeader
          header={
            <div>
              <PageHeader />
            </div>
          }
        >
          <LoginLayout hideLogo>
            <Header>Generate backup codes</Header>
            <Instructions>
              <p>
                If you lose access to your authentication app, you can use these
                codes instead of the 6-digit One Time Passwords from your
                device.
              </p>
              <p>
                Each code can only be used once, and we recommend you keep them
                in a safe, secure place.
              </p>

              <p>
                For security reasons, these codes are only able to be displayed
                once. Please make sure you have recorded them somewhere secure
                before leaving this page.
              </p>
            </Instructions>
            <SubHeader>Backup codes:</SubHeader>
            <MonospaceTextBlock id="backup_codes">
              {this.props.backupCodes.join("\n")}
            </MonospaceTextBlock>
            <PrimaryActions>
              <Button
                type="button"
                variant="contained"
                color="primary"
                disabled={this.props.syncing}
                onClick={this.props.onSubmit}
              >
                I have saved these codes
              </Button>
            </PrimaryActions>
          </LoginLayout>
        </LayoutWithHeader>
      </Theme>
    )
  }
}

const mapStateToProps = (state) => {
  const me = state.me || {}

  return { backupCodes: me.backupCodes, syncing: me.syncing }
}

const mapDispatchToProps = (dispatch) => {
  return {
    onSubmit: () => dispatch({ type: "MANAGE_MFA" }),
    onNoBackupCodes: () => dispatch({ type: "MANAGE_MFA" })
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(DisplayBackupCodes)
