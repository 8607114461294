import React from "react"
import isEmpty from "lodash.isempty"
import ContainerDimensions from "react-container-dimensions"
import styled from "@emotion/styled"
import TeamAssessment from "../team_assessment/TeamAssessment"
import type {
  TeamAssessment as TeamAssessmentType,
  UsersHash,
  ScoreReport as ScoreReportType
} from "../../state_types"
import ReportFilters from "./ConnectedReportFilters"
import colours from "../../themes/colours"
import ReportLayout from "./ReportLayout"
import ReportDownloadButton from "./ReportDownloadButton"
import Pagination from "../shared/Pagination"

const NoData = styled.div({
  color: colours.grey,
  textAlign: "center",
  marginTop: 30
})

const ReportRowsContainer = styled.div({
  overflowY: "scroll"
})

type ScoreReportParams = ScoreReportType & {
  title: string
  reportId: string
  subReportId?: string
  reportUrl: string
  returnTo?: {
    to: string
    name: string
  }
  customTitle?
  linkTo?
  showDate?: boolean
  filtersReadonly?: boolean
  showTimeframe?: boolean
  showThreshold?: boolean
  scoreSuffix?: string
}

const ScoreReport = (params: ScoreReportParams) => {
  const {
    title,
    reportId,
    subReportId,
    reportData,
    filters,
    returnTo,
    customTitle,
    linkTo,
    showDate,
    filtersReadonly,
    scoreSuffix,
    showTimeframe,
    showThreshold
  } = params

  const users: UsersHash = {}
  const titleWidth = 150

  const to = (page) => {
    const query = { ...filters, page }

    if (subReportId) {
      return { type: "SUB_REPORT", query, payload: { reportId, subReportId } }
    }

    return { type: "REPORT", query, payload: { reportId } }
  }

  const ReportPagination = () => (
    <Pagination
      currentPage={filters.page}
      pagesToShow={4}
      totalPages={reportData.totalPages}
      to={to}
    />
  )

  const reportFilterParams = {
    reportId,
    disabled: filtersReadonly,
    showTextSearch: true,
    showTimeframe,
    showThreshold
  }

  return (
    <ReportLayout
      title={title}
      breadcrumb={returnTo}
      header={<ReportFilters {...reportFilterParams} />}
      buttons={<ReportDownloadButton />}
    >
      <ContainerDimensions>
        {({ width }) => (
          <ReportRowsContainer>
            {reportData.rows.map((row) => {
              const teamAssessment: TeamAssessmentType = {
                date: row.date,
                outOf: row.outOf,
                thresholds: row.thresholds || reportData.defaultThresholds,
                score: row.score,
                range: row.range,
                userBreakdown: null
              }

              return (
                <TeamAssessment
                  key={row.id}
                  users={users}
                  width={width - titleWidth}
                  teamAssessment={teamAssessment}
                  customTitle={customTitle && customTitle(row)}
                  linkTo={linkTo && linkTo(row)}
                  titleWidth={titleWidth}
                  outOf={row.outOf}
                  showDate={row.date && showDate}
                  scoreSuffix={scoreSuffix}
                />
              )
            })}

            {!isEmpty(reportData.rows) && <ReportPagination />}

            {isEmpty(reportData.rows) && (
              <NoData>
                There are no results for the current set of filters.
              </NoData>
            )}
          </ReportRowsContainer>
        )}
      </ContainerDimensions>
    </ReportLayout>
  )
}

export default ScoreReport
