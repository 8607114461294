import immutable from "object-path-immutable"
import isequal from "lodash.isequal"
import { applyReducerHash } from "../../redux_helpers"
import type { RipplesApiResult, Action } from "../../state_types"

export const standardizeFilters = (filters) => {
  const f = filters || {}

  return {
    page: (f.page && f.page.toString()) || "1",
    organisation: f.organisation || null,
    searchTerm: f.searchTerm || "",
    order: f.order || "lowscore",
    group: f.group || null
  }
}

const reducers = {
  RIPPLES: (state: RipplesApiResult | null | undefined, action: Action) => {
    const filters = action.query || action.meta.query || {}

    return immutable.set(state, ["filters"], standardizeFilters(filters))
  },
  RECEIVED_MY_RIPPLE_LIST: (
    state: RipplesApiResult | null | undefined,
    action: Action
  ) => {
    const dataFilters = standardizeFilters(action.payload.filters)
    const stateFilters = standardizeFilters(state && state.filters)

    // Don't update unless the filters for the data returned matches
    // what the current filters (in state)
    if (isequal(dataFilters, stateFilters)) {
      return action.payload
    }

    return state
  },
  SUBMITTING_RIPPLE_FORM: () => {
    return {}
  },
  RIPPLE_RESTORED: () => {
    return {}
  },
  RIPPLE_ARCHIVED: () => {
    return {}
  }
}

export default function myRipplesReducer(
  state: RipplesApiResult = null,
  action: Action
): RipplesApiResult | null | undefined {
  return applyReducerHash(reducers, state, action)
}
