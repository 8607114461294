import React from "react"
import { connect } from "react-redux"
import get from "lodash.get"
import find from "lodash.find"
import includes from "lodash.includes"
import styled from "@emotion/styled"
import { NavLink } from "redux-first-router-link"
import debounce from "lodash.debounce"
import windowDimensions from "react-window-dimensions"
import colours from "../themes/colours"
import type { OrgMembership } from "../state_types"

const HeaderLinksContainer = styled.div<{ showMobile }>((props) => ({
  backgroundColor: "white",
  display: "flex",
  flexDirection: "row",
  justifyContent: "center",
  fontSize: props.showMobile ? "14px" : "inherit"
}))

const HeaderLinks = ({
  showMobile,
  children
}: {
  showMobile: boolean
  children: React.ReactNode
}) => (
  <HeaderLinksContainer showMobile={showMobile}>
    {children}
  </HeaderLinksContainer>
)

type OrgAdminNavProps = {
  orgs: Array<OrgMembership>
  windowWidth: number
  mostRecentOrgSlug: string
}

function getOrgWithRole(orgs, role, defaultSlug) {
  return (
    find(
      orgs,
      (org) => includes(org.roles, role) && org.slug === defaultSlug
    ) || find(orgs, (org) => includes(org.roles, role))
  )
}

function getOrgWithPermission(orgs, permission, defaultSlug) {
  return (
    find(
      orgs,
      (org) =>
        org.permissions &&
        org.permissions[permission] &&
        org.slug === defaultSlug
    ) || find(orgs, (org) => org.permissions && org.permissions[permission])
  )
}

const styles = ({ showMobile }) => {
  return {
    marginRight: "3%",
    whiteSpace: "nowrap",
    margin: showMobile ? "0 5px" : "0 10px",
    display: "inline-block",
    color: "black",
    textDecoration: "none",
    ":hover": {
      color: colours.blue
    }
  }
}

const activeStyles = {
  borderBottom: `3px solid ${colours.blue}`
}

const OrgAdminLink = ({
  showMobile,
  org
}: {
  showMobile: boolean
  org: OrgMembership
}) => (
  <NavLink
    activeStyle={activeStyles}
    style={styles({ showMobile })}
    to={{ type: "ORG", payload: { slug: org.slug } }}
  >
    {showMobile ? "Org" : "Org Management"}
  </NavLink>
)

const RipplesLink = ({
  showMobile,
  orgSlug
}: {
  showMobile: boolean
  orgSlug
}) => (
  <NavLink
    activeStyle={activeStyles}
    style={styles({ showMobile })}
    to={{ type: "RIPPLES", query: { organisation: orgSlug } }}
  >
    {showMobile ? "Ripples" : "Ripple Dashboard"}
  </NavLink>
)

const ReportsLink = ({
  showMobile,
  org
}: {
  showMobile: boolean
  org: OrgMembership
}) => (
  <NavLink
    activeStyle={activeStyles}
    style={styles({ showMobile })}
    to={{
      type: "REPORT_INDEX",
      query: { organisation: org.slug }
    }}
  >
    Reports
  </NavLink>
)

const ArchiveLink = ({
  showMobile,
  orgSlug
}: {
  showMobile: boolean
  orgSlug: string
}) => (
  <NavLink
    activeStyle={activeStyles}
    style={styles({ showMobile })}
    to={{ type: "ARCHIVED_RIPPLES", query: { organisation: orgSlug } }}
  >
    Archive
  </NavLink>
)

const OrgAdminNav = ({
  orgs,
  windowWidth,
  mostRecentOrgSlug
}: OrgAdminNavProps) => {
  const adminOrg = getOrgWithRole(orgs, "admin", mostRecentOrgSlug)
  const reportsOrg = getOrgWithPermission(orgs, "reports", mostRecentOrgSlug)
  const archiveOrg = getOrgWithPermission(orgs, "archive", mostRecentOrgSlug)
  const showMobile = windowWidth < 768

  return (
    <HeaderLinks showMobile={showMobile}>
      {adminOrg && <OrgAdminLink showMobile={showMobile} org={adminOrg} />}
      <RipplesLink showMobile={showMobile} orgSlug={mostRecentOrgSlug} />
      {reportsOrg && <ReportsLink showMobile={showMobile} org={reportsOrg} />}
      {archiveOrg && (
        <ArchiveLink showMobile={showMobile} orgSlug={mostRecentOrgSlug} />
      )}
    </HeaderLinks>
  )
}

const OrgAdminNavWithWidth = windowDimensions({
  take: () => ({ windowWidth: window.innerWidth }),
  debounce: (onResize) => debounce(onResize, 100)
})(OrgAdminNav)

const mapStateToProps = (state) => ({
  orgs: get(state, "me.data.user.orgs") || [],
  mostRecentOrgSlug: get(state, "memory.mostRecent.organisationSlug")
})

export default connect(mapStateToProps)(OrgAdminNavWithWidth)
