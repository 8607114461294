/* eslint-env browser */
import React from "react"
import styled from "@emotion/styled"
import UserTable from "./user_table/UserTable"
import AddUser from "./AddUser"
import { gutter } from "./Layout"

const TableContainer = styled.div({
  backgroundColor: "white",
  padding: gutter,
  borderRadius: 3,
  boxShadow: "0 2px 10px 0 rgba(0,0,0,0.1)",
  marginBottom: 50
})

const AddUserContainer = styled.div({
  backgroundColor: "white"
})

const OrgDashboardUsers = ({ orgSlug }: { orgSlug: string }) => (
  <div>
    <TableContainer>
      <AddUserContainer>
        <AddUser />
      </AddUserContainer>
      <UserTable orgSlug={orgSlug} />
    </TableContainer>
  </div>
)

export default OrgDashboardUsers
