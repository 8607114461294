import React from "react"
import DatePicker from "material-ui/DatePicker"
import { isoStringWithoutZone } from "./helpers/date"

const mapError = ({ touched, error, warning }) =>
  touched && (error || warning) ? { errorText: error || warning } : {}

interface IProps {
  input?: { onChange?(_: string): void; value?: Date | undefined }
  meta: { touched; error; warning }
  defaultValue?: Date | string | undefined
}

const DatePickerField = ({ input, meta, defaultValue, ...rest }: IProps) => {
  const value = input.value ? new Date(input.value) : defaultValue || null

  const onChange = (event, newValue: Date) => {
    input.onChange(isoStringWithoutZone(newValue))
  }
  return (
    <DatePicker
      {...input}
      {...mapError(meta)}
      style={{ width: "100%" }}
      {...rest}
      value={value as Date}
      onChange={onChange}
    />
  )
}

export default DatePickerField
