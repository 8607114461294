import React from "react"
import styled from "@emotion/styled"
import FontAwesome from "react-fontawesome"

const GoBack = styled.a({
  textDecoration: "none"
})

const Header = styled.div({
  display: "flex",
  justifyContent: "flex-start"
})

const ArrowText = styled.div({
  display: "flex",
  padding: "30px 0 0 35px",
  alignItems: "baseline"
})

const Text = styled.div({
  color: "#000000",
  fontFamily: "Poppins",
  fontSize: "1em",
  lineHeight: "23px"
})

type Props = {
  handleCancel: React.MouseEventHandler<HTMLAnchorElement>
  label?: string
}

const GoBackHeader = ({ handleCancel, label }: Props) => (
  <Header>
    <GoBack href="#" onClick={handleCancel}>
      <ArrowText>
        <FontAwesome
          name="long-arrow-left"
          style={{
            paddingRight: "10px",
            textDecoration: "none",
            color: "black"
          }}
        />
        <Text>{label}</Text>
      </ArrowText>
    </GoBack>
  </Header>
)

GoBackHeader.defaultProps = {
  label: "Cancel"
}

export default GoBackHeader
