import React from "react"
import styled from "@emotion/styled"
import isEmpty from "lodash.isempty"
import YearlyAssessmentLists from "./YearlyAssessmentLists"
import AssessmentsReport from "../../contexts/assessment/AssessmentsReport"
import { Ripple, UsersHash } from "../../state_types"
import type { DialogFunctions } from "../BubbleDialogContainer"
import NextAssessment from "./NextAssessment"
import NoAssessmentsYet from "./NoAssessmentsYet"
import AssessmentResultsHidden from "../AssessmentResultsHidden"
import CurrentAssessmentStatus from "../CurrentAssessmentStatus"
import colours from "../../themes/colours"
import { interpolateInteger } from "../helpers/math"
import { gutter } from "../Layout"
import Breadcrumb from "../Breadcrumb"
import ThresholdClassifiers from "./ThresholdClassifiers"

const AssessmentsReportContainer = styled.div({
  flex: 1,
  display: "flex"
})

const AssessmentReportInnerContainer = styled.div({
  flex: 1
})

const NextAssessmentContainer = styled.div({
  borderBottom: `1px solid ${colours.boldLineOnSilver}`,
  padding: "10px 0",
  marginBottom: 10
})

const CurrentAssessmentStatusContainer = styled.div({
  marginTop: gutter,
  marginBottom: `calc(${gutter} - 10px)`
})

type Props = {
  users: UsersHash
  ripple: Ripple
  report: AssessmentsReport
  dialog: DialogFunctions
  outOf: number
  returnTo?: {
    name: string
    url: string
  }
}

const renderAssessments = (
  users,
  ripple,
  report,
  dialog,
  titleWidth,
  outOf,
  queryParams
) => {
  if (ripple && ripple.teamAssessments) {
    if (ripple.teamAssessments === "access_denied") {
      return (
        <AssessmentResultsHidden
          clientName={ripple.name}
          message={ripple.hiddenResultsMessage}
        />
      )
    }

    if (!isEmpty(ripple.teamAssessments)) {
      return (
        <YearlyAssessmentLists
          users={users}
          rippleUuid={ripple.uuid}
          report={report}
          dialog={dialog}
          titleWidth={titleWidth}
          outOf={outOf}
          queryParams={queryParams}
        />
      )
    }
  }
  return <NoAssessmentsYet clientName={ripple.name} />
}

const AssessmentReportSummary = ({
  users,
  ripple,
  report,
  dialog,
  outOf,
  returnTo
}: Props) => {
  const { frameworkInfo, uuid, nextAssessment, assessmentLink, canEdit } =
    ripple
  const { thresholdClassifiers } = frameworkInfo
  const titleWidth = interpolateInteger([30, 50], [360, 640], window.innerWidth)
  const paddingWidth = 5
  const queryParams = returnTo ? { returnTo } : undefined

  return (
    <AssessmentsReportContainer>
      <AssessmentReportInnerContainer>
        {returnTo && <Breadcrumb to={returnTo.url} name={returnTo.name} />}
        <NextAssessmentContainer>
          <NextAssessment
            rippleUuid={uuid}
            iconWidth={titleWidth - paddingWidth}
            paddingWidth={paddingWidth}
            assessmentWindow={nextAssessment}
            canAdmin={canEdit}
            assessmentLink={assessmentLink}
          />
          {nextAssessment && nextAssessment.assessors && (
            <CurrentAssessmentStatusContainer>
              <CurrentAssessmentStatus
                assessors={nextAssessment.assessors}
                users={users}
                rippleUuid={uuid}
              />
            </CurrentAssessmentStatusContainer>
          )}
          <ThresholdClassifiers thresholdClassifiers={thresholdClassifiers} />
        </NextAssessmentContainer>

        {renderAssessments(
          users,
          ripple,
          report,
          dialog,
          titleWidth,
          outOf,
          queryParams
        )}
      </AssessmentReportInnerContainer>
    </AssessmentsReportContainer>
  )
}

export default AssessmentReportSummary
