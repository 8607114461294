import React from "react"
import styled from "@emotion/styled"
import Rainbow from "rainbowvis.js"
import { radians } from "radians"
import ArcSlider from "./ArcSlider"
import DoubleGradientCircle from "./DoubleGradientCircle"
import WidgetScore from "./WidgetScore"
import { getAngleToHandle } from "./arc_calculations"

const Svg = styled.svg({
  width: "100%",
  flexGrow: 1,
  overflow: "visible"
})

type Props = {
  id: string
  radius: number
  width: number
  centre
  arcThickness: number
  value?: number
  controlValue: number
  previousValue?: number
  outOf: number
  startColour: string
  endColour: string
  handleChange: (value: number) => void
  showCircle: boolean
  arcAtTop: boolean
}

function currentColour(
  startColour: string,
  endColour: string,
  value: number,
  outOf: number
) {
  const rainbow = new Rainbow()
  rainbow.setSpectrum(startColour, endColour)
  rainbow.setNumberRange(0, outOf)

  return `#${rainbow.colourAt(value)}`
}

const SvgScoringWidget = ({
  id,
  radius,
  width,
  centre,
  arcThickness,
  value,
  controlValue,
  previousValue,
  outOf,
  startColour,
  endColour,
  handleChange,
  showCircle,
  arcAtTop
}: Props) => {
  const arcRadius = radius
  const outerCircleRadius = arcRadius - arcThickness * 1.25
  const arcAngleDegrees = 100
  const colour = currentColour(startColour, endColour, controlValue, outOf)

  const angleToHandle = getAngleToHandle(value, outOf, arcAngleDegrees, radius)

  return (
    <Svg id="scoring-widget">
      {showCircle && (
        <DoubleGradientCircle
          id={id}
          radius={outerCircleRadius}
          centre={centre}
          colour={colour}
          outerThickness={arcThickness}
          angleDegrees={radians(angleToHandle)}
        />
      )}
      {showCircle && (
        <WidgetScore
          value={value}
          centre={centre}
          colour={colour}
          radius={outerCircleRadius - arcThickness}
          previousValue={previousValue}
        />
      )}
      <ArcSlider
        onChange={handleChange}
        arcAngleDegrees={arcAngleDegrees}
        value={controlValue}
        outOf={outOf}
        centre={centre}
        radius={arcRadius}
        svgWidth={width}
        startColour={startColour as any}
        endColour={endColour as any}
        thickness={arcThickness}
        atTop={arcAtTop}
      />
    </Svg>
  )
}

export default SvgScoringWidget
