import React from "react"
import type { ReactNode } from "react"
import type {
  TeamAssessment as TeamAssessmentType,
  UsersHash
} from "../../state_types"
import type { DialogFunctions } from "../BubbleDialogContainer"
import TeamRippleAssessment from "./TeamRippleAssessment"
import TeamSdqAssessment from "./SDQ/TeamSdqAssessment"

type Props = {
  users: UsersHash
  teamAssessment: TeamAssessmentType
  width: number
  dialog?: DialogFunctions
  showDate?: boolean
  customTitle?: ReactNode
  linkTo?: string
  titleWidth?: number
  outOf: number
  queryParams?: {}
  scoreSuffix?: string
}

const TeamAssessment = (props: Props) => {
  const assessmentType = props.teamAssessment.type
  if (assessmentType === "sdq") {
    return <TeamSdqAssessment {...props} />
  }

  return <TeamRippleAssessment {...props} />
}

TeamAssessment.defaultProps = {
  showDate: false,
  linkTo: null,
  titleWidth: 50
}

export default TeamAssessment
