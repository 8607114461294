import React from "react"
import styled from "@emotion/styled"
import map from "lodash.map"
import withQuery from "with-query"
import TeamAssessment from "./TeamAssessment"
import type {
  TeamAssessment as TeamAssessmentType,
  UsersHash
} from "../../state_types"
import type { DialogFunctions } from "../BubbleDialogContainer"

type Props = {
  users: UsersHash
  teamAssessments: Array<TeamAssessmentType>
  year: number
  showYear: boolean
  width: number
  rippleUuid: string
  dialog: DialogFunctions
  titleWidth?: number
  outOf: number
  queryParams?: {}
}

const YearTitleRow = styled.div({
  paddingTop: "1.5em",
  paddingBottom: "0.7em"
})

const YearTitle = styled.h3({
  fontSize: "1.5em",
  fontWeight: "bold",
  margin: 0
})

const MeasuredAssessmentList = ({
  users,
  teamAssessments,
  width,
  year,
  showYear,
  rippleUuid,
  dialog,
  titleWidth,
  outOf,
  queryParams
}: Props) => {
  return (
    <div>
      {showYear && (
        <YearTitleRow>
          <YearTitle>{year}</YearTitle>
        </YearTitleRow>
      )}
      {map(teamAssessments, (teamAssessment) => (
        <TeamAssessment
          users={users}
          width={width}
          key={teamAssessment.id}
          teamAssessment={teamAssessment}
          dialog={dialog}
          outOf={outOf}
          showDate
          linkTo={withQuery(
            `/ripple/${rippleUuid}/assessments/${teamAssessment.number}`,
            queryParams
          )}
          titleWidth={titleWidth}
        />
      ))}
    </div>
  )
}

MeasuredAssessmentList.defaultProps = {
  titleWidth: 50
}

export default MeasuredAssessmentList
