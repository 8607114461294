import React from "react"
import ToggleButton from "./ToggleButton"

type Props = {
  label: string
  input: {
    value: string
    onChange: (hasNoValue: boolean) => any
  }
  faIcon?: string
}

const stringToBool = (stringValue): boolean => !!stringValue

const ToggleButtonField = ({ input, label, faIcon }: Props) => {
  const { onChange, value, ...rest } = input
  const boolValue: boolean = stringToBool(value)
  return (
    <ToggleButton
      {...rest}
      label={label}
      value={boolValue}
      faIcon={faIcon}
      onChange={onChange}
    />
  )
}

ToggleButtonField.defaultProps = {
  faIcon: null
}

export default ToggleButtonField
