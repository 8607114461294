import React from "react"
import styled from "@emotion/styled"
import { reduxForm, Field, InjectedFormProps } from "redux-form"
import { connect } from "react-redux"
import FontAwesome from "react-fontawesome"
import { fieldsRequired } from "../contexts/shared/validation"
import Loading from "./Loading"
import AvatarUploader from "./AvatarUploader"
import SubmitButton from "./SubmitButton"
import type { User } from "../state_types"
import { gutter } from "./Layout"
import TextFieldForReduxForm from "./MuiExtensions/components/TextFieldForReduxForm"

const UserEditFormContainer = styled.div({})

const Header = styled.div({
  display: "flex",
  justifyContent: "flex-start"
})

const FormContainer = styled.div({
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  padding: `40px  ${gutter}`,
  alignItems: "center"
})

const ArrowText = styled.div({
  display: "flex",
  padding: "30px 0 0 35px",
  alignItems: "baseline"
})

const Form = styled.form({})

const FieldsContainer = styled.div({
  display: "flex",
  flex: 1,
  flexDirection: "column",
  justifyContent: "flex-start"
})

const FieldContainer = styled.div({
  minHeight: "4.5em",
  display: "flex"
})

const Text = styled.div({
  color: "#000000",
  fontFamily: "Poppins",
  fontSize: "1em",
  lineHeight: "23px"
})

const GoBack = styled.a({
  textDecoration: "none"
})

const SubmitButtonWrapper = styled.div({
  display: "flex",
  justifyContent: "center",
  paddingTop: "45px"
})

function validate(values) {
  const errors = {}
  fieldsRequired(values, errors, "name")
  return errors
}
type OnSubmitFunc = () => any

interface IProps extends InjectedFormProps {
  user: User
  syncing: unknown
  onSubmit: OnSubmitFunc
}

function handleCancel(event) {
  event.preventDefault()

  window.history.back()
}

const UserEditForm = ({ user, syncing, onSubmit, handleSubmit }: IProps) => {
  const { email, avatar } = user
  const isSubmitting = syncing === "sending"
  const textFieldStyle = { height: "100px" }

  return (
    <UserEditFormContainer>
      <Header>
        <GoBack href="#" onClick={handleCancel}>
          <ArrowText>
            <FontAwesome
              name="long-arrow-left"
              style={{
                paddingRight: "10px",
                textDecoration: "none",
                color: "black"
              }}
            />
            <Text>Cancel</Text>
          </ArrowText>
        </GoBack>
      </Header>
      <FormContainer>
        <Form onSubmit={handleSubmit(onSubmit)} id="edit-user-form">
          <FieldsContainer>
            <Field
              name="avatar"
              component={AvatarUploader}
              personName={email}
              defaultValue={avatar}
            />
            <FieldContainer>
              <Field
                name="name"
                component={TextFieldForReduxForm}
                label="Preferred name"
                fullWidth
                style={textFieldStyle}
                helperText="This name will be used throughout the app and will be visible to other users"
              />
            </FieldContainer>
            <FieldContainer>
              <Field
                name="fullName"
                component={TextFieldForReduxForm}
                label="Full name"
                fullWidth
                style={textFieldStyle}
                helperText=" This name will be used for record keeping and is visible only to you "
              />
            </FieldContainer>
            <FieldContainer>
              <Field
                name="phone"
                component={TextFieldForReduxForm}
                label="Phone incl country"
                style={textFieldStyle}
                fullWidth
              />
            </FieldContainer>
          </FieldsContainer>
          <SubmitButtonWrapper>
            {isSubmitting ? <Loading /> : <SubmitButton />}
          </SubmitButtonWrapper>
        </Form>
      </FormContainer>
    </UserEditFormContainer>
  )
}

const NewUserEditForm = reduxForm({
  form: "userEdit",
  validate
})(UserEditForm)

const mapStateToProps = (state) => {
  const me = state.me
  const user = me.data.user
  const syncing = me.syncing

  return {
    user,
    syncing,
    initialValues: {
      fullName: user.fullName,
      name: user.name,
      phone: user.phone
    }
  }
}

export default connect(mapStateToProps)(NewUserEditForm)
