import React from "react"
import styled from "@emotion/styled"
import Theme from "../themes/White"
import GoBackHeader from "../components/GoBackHeader"
import { HeadingTwo } from "../components/TextStyles"

const Container = styled.div({
  display: "flex",
  flexDirection: "column",
  flexGrow: 1
})

const CenteredColumn = styled.div({
  display: "flex",
  flexDirection: "column",
  alignItems: "center"
})

const Contents = styled.div({
  maxWidth: 500,
  textAlign: "center"
})

function handleCancel(event) {
  event.preventDefault()
  window.history.back()
}

const ForgotPasswordSentPage = () => (
  <Theme>
    <Container>
      <GoBackHeader handleCancel={handleCancel} label="Try again" />
      <CenteredColumn>
        <Contents>
          <HeadingTwo>Email Sent</HeadingTwo>
          <p>
            We&apos;ve email you a link to change your password. If you
            haven&apos;t received anything, please{" "}
            <a href="mailto:help@ripple.com.au">contact us</a> for assistance.
          </p>
        </Contents>
      </CenteredColumn>
    </Container>
  </Theme>
)

export default ForgotPasswordSentPage
