import React from "react"
import { TextField } from "@material-ui/core"

type Props = {
  updateQuestionComment: (
    attributeId: string | undefined | null,
    comment: string
  ) => unknown
}

const SdqAssessmentCommentInput = ({ updateQuestionComment }: Props) => {
  const handleChange = (
    event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    updateQuestionComment(null, event.target.value)
  }

  return (
    <div>
      <TextField
        label="Do you have any other comments or concerns?"
        multiline
        rows={5}
        style={{ width: "100%" }}
        onChange={handleChange}
      />
    </div>
  )
}

export default SdqAssessmentCommentInput
