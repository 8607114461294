import React from "react"
import CircularProgressbar from "react-circular-progressbar"
import AvatarContainer from "./AvatarContainer"

type Props = {
  size: number
  percentComplete: number
}

const AvatarProgress = ({ size, percentComplete }: Props) => (
  <AvatarContainer size={size}>
    <CircularProgressbar percentage={percentComplete} />
  </AvatarContainer>
)

export default AvatarProgress
