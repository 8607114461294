import React from "react"
import find from "lodash.find"
import findIndex from "lodash.findindex"
import { Transition, TransitionGroup } from "react-transition-group"
import { TweenMax, Power3 } from "gsap"
import styled from "@emotion/styled"
import { pageTransitionDuration } from "../themes/animations"

type Props = {
  currentKey: string
  children
}

type State = {
  previousKey?: string
}

type SlideDirection = "left" | "right"

type ExitInstructions = {
  exitDirection: SlideDirection
}

const buildEnterTransition = (
  contentKey: string,
  child,
  getSlideDirection: () => SlideDirection
) => {
  const ease = Power3.easeOut
  const delay = pageTransitionDuration / 3
  const remainingDuration = pageTransitionDuration - delay

  return (
    <Transition
      key={contentKey}
      timeout={pageTransitionDuration * 1000}
      onEntering={(htmlElement) => {
        if (getSlideDirection() === "left") {
          const startX = getSlideDirection() === "left" ? "100%" : "-100%"
          TweenMax.fromTo(
            htmlElement,
            remainingDuration,
            { x: startX, opacity: 0.0 },
            { x: "0%", opacity: 1.0, ease }
          ).delay(delay)
        } else {
          TweenMax.fromTo(
            htmlElement,
            remainingDuration,
            { opacity: 0.0 },
            { opacity: 1.0, ease }
          ).delay(delay)
        }
      }}
      onExiting={(htmlElement?: HTMLElement) => {
        if (getSlideDirection() === "left") {
          TweenMax.fromTo(
            htmlElement,
            pageTransitionDuration,
            { opacity: 1.0 },
            { opacity: 0.0, ease }
          )
        } else {
          const targetX = getSlideDirection() === "left" ? "-100%" : "100%"
          TweenMax.fromTo(
            htmlElement,
            pageTransitionDuration,
            { x: "0%", opacity: 1.0 },
            { x: targetX, opacity: 0.0, ease }
          )
        }
      }}
    >
      {child}
    </Transition>
  )
}

const SliderWindow = styled.div({
  width: "100%",
  position: "relative"
})

const SliderPage = styled.div({
  width: "100%",
  position: "absolute",
  display: "flex"
})

export default class FadeSlidePageGroup extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props)
    this.state = { previousKey: null }
  }

  UNSAFE_componentWillReceiveProps(props: Props) {
    if (props.currentKey !== this.props.currentKey) {
      this.setState({ previousKey: this.props.currentKey })
    }
  }

  getChild(key?: string) {
    return find(this.props.children, ["key", key])
  }

  getChildIndex(key?: string): number {
    if (key === null) return 0
    return findIndex(this.props.children, ["key", key])
  }

  getIndexDiff() {
    return (
      this.getChildIndex(this.props.currentKey) -
      this.getChildIndex(this.state.previousKey)
    )
  }

  getSlideDirection(): SlideDirection {
    return this.getIndexDiff() > 0 ? "left" : "right"
  }

  getExitInstructions(): ExitInstructions {
    return {
      exitDirection: this.getSlideDirection()
    }
  }

  render() {
    const current = this.getChild(this.props.currentKey)

    return (
      <SliderWindow>
        <TransitionGroup>
          {buildEnterTransition(
            this.props.currentKey,
            <SliderPage>{current}</SliderPage>,
            () => this.getSlideDirection()
          )}
        </TransitionGroup>
      </SliderWindow>
    )
  }
}
