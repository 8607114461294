import React from "react"
import styled from "@emotion/styled"
import Link from "redux-first-router-link"
import FontAwesome from "react-fontawesome"
import { gutter } from "./Layout"
import colours from "../themes/colours"

const BreadcrumbContainer = styled.div({
  border: "none",
  paddingTop: gutter
})

const BreadcrumbContents = styled.div({
  display: "flex",
  alignItems: "center"
})

const BreadcrumbName = styled.div({
  paddingLeft: "1em",
  color: "black"
})

interface IProps {
  to: string
  name: React.ReactNode
}

const Breadcrumb = (props: IProps) => {
  return (
    <BreadcrumbContainer>
      <Link style={{ textDecoration: "none" }} to={props.to}>
        <BreadcrumbContents>
          <FontAwesome style={{ color: colours.blue }} name="chevron-left" />
          <BreadcrumbName>{props.name}</BreadcrumbName>
        </BreadcrumbContents>
      </Link>
    </BreadcrumbContainer>
  )
}

export default Breadcrumb
