import React from "react"
import styled from "@emotion/styled"
import { gutter, mediaQueries } from "../../Layout"
import RippleLogo from "../../RippleLogo"
import type { AssessmentIntro } from "../../../contexts/assessment/types"
import GenericImage from "../../svg/GenericImage"
import Cat from "../../../images/pedsql/cat.png"
import SimpleRadioButton from "./SimpleRadioButton"

const imageSources = {
  cat: Cat
}

const LogoContainer = styled.div({
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-between",
  padding: "3.5rem 1.25rem 0 1.25rem",
  boxSizing: "border-box",
  alignItems: "center",
  marginBottom: gutter,
  [mediaQueries.desktop]: {
    padding: "3.5rem 3rem 0 3rem "
  }
})

const Body = styled.div({
  flex: 1
})

const FrameworkDescription = styled.div({
  boxSizing: "border-box",
  width: "100%",
  padding: ".5rem 1.25rem 1.25rem 1.25rem",
  whiteSpace: "pre-wrap",
  [mediaQueries.desktop]: {
    width: "75%",
    padding: ".5rem 3rem 1rem 3rem "
  }
})

const BackgroundImage = styled.div<{ src?: string }>(({ src }) => ({
  background: `url(${imageSources[src]}) no-repeat bottom right`,
  backgroundSize: "contain",
  height: "100%",
  width: "100%",
  boxSizing: "border-box",
  padding: "0 1.25rem 0 1.25rem",
  [mediaQueries.desktop]: {
    padding: "1rem 3rem 1rem 3rem"
  }
}))

type Props = {
  nextPage: () => unknown
  step: AssessmentIntro
}

const SimpleRadioTitleSlide = ({ nextPage, step }: Props) => {
  return (
    <>
      <LogoContainer>
        <GenericImage width="10em" src={step.titleLogo} />
        <RippleLogo colour="blue" />
      </LogoContainer>
      <Body>
        <FrameworkDescription>
          <h2>{step.subtitle}</h2>
          <p>{step.introMessage}</p>
        </FrameworkDescription>
      </Body>
      <BackgroundImage src={step.backgroundImage}>
        <SimpleRadioButton
          onClick={nextPage}
          large
          icon="chevRight"
          text="Get started"
        />
      </BackgroundImage>
    </>
  )
}

export default SimpleRadioTitleSlide
