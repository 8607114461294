import React from "react"
import { AnyAction } from "redux"
import { ThunkDispatch } from "redux-thunk"
import { connect } from "react-redux"
import { reset } from "redux-form"
import styled from "@emotion/styled"
import map from "lodash.map"
import isEmpty from "lodash.isempty"
import Theme from "../themes/Silver"
import Header from "../components/Header"
import RippleCard from "../components/team_assessment/RippleCard"
import CreateRippleCard from "../components/CreateRippleCard"
import NoRipplesCard from "../components/NoRipplesCard"
import RippleCardGrid from "../components/RippleCardGrid"
import UserStateDecorator from "../contexts/user/UserStateDecorator"
import LoadablePage from "../components/LoadablePage"
import RipplesFilterGrid from "../components/ripples/RipplesFilterGrid"
import EditModePanel from "../components/EditModePanel"
import Pagination from "../components/shared/Pagination"

const AppBarContainer = styled.div()

const MainContainer = styled.div({
  flex: 1,
  display: "flex"
})

const ContentContainer = styled.div({
  display: "flex",
  justifyContent: "center",
  flex: 1
})

const Contents = styled.div({
  flex: 1,
  display: "flex",
  flexDirection: "column"
})

const CardGridContainer = styled.div({
  flex: 1,
  display: "flex",
  flexDirection: "row",
  justifyContent: "center",
  paddingTop: "3%"
})

interface IProps {
  canCreate: boolean
  myRipples
  isEditing: boolean
  dispatchNewRipple
}

const RipplesIndex = (props: IProps) => {
  const { canCreate, myRipples, isEditing, dispatchNewRipple } = props
  const loaded = !!(myRipples && myRipples.data)
  const { filters } = myRipples || {}
  const { ripples, totalPages } = myRipples.data || {}

  const to = (page) => {
    const query = { ...filters, page }
    return { type: "RIPPLES", query }
  }

  const RipplesPagination = () => (
    <Pagination
      currentPage={filters.page}
      pagesToShow={5}
      totalPages={totalPages}
      to={to}
    />
  )

  return (
    <Theme>
      <AppBarContainer>
        <Header />
      </AppBarContainer>
      <MainContainer>
        <ContentContainer>
          <Contents>
            <LoadablePage loaded={loaded}>
              {() => (
                <Contents>
                  <RipplesFilterGrid ripples={ripples} />
                  {isEditing && <EditModePanel ripples={ripples} />}
                  <CardGridContainer>
                    <RippleCardGrid>
                      {canCreate && (
                        <CreateRippleCard
                          dispatchNewRipple={dispatchNewRipple}
                        />
                      )}
                      {isEmpty(ripples) && <NoRipplesCard />}
                      {map(ripples, (ripple) => (
                        <RippleCard key={ripple.uuid} {...ripple} />
                      ))}
                    </RippleCardGrid>
                  </CardGridContainer>
                  <RipplesPagination />
                </Contents>
              )}
            </LoadablePage>
          </Contents>
        </ContentContainer>
      </MainContainer>
    </Theme>
  )
}

const mapStateToProps = (state) => {
  const userState = new UserStateDecorator(state)

  return {
    myRipples: state.myRipples,
    canCreate: userState.canCreateRipples(),
    isEditing: state.bulkActions.active
  }
}

const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, AnyAction>) => ({
  dispatchNewRipple: () => {
    dispatch({ type: "NEW_RIPPLE_STEP" })
    dispatch(reset("ripple"))
  }
})

export default connect(mapStateToProps, mapDispatchToProps)(RipplesIndex)
