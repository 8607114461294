import React from "react"

// Copied from https://reactjs.org/docs/error-boundaries.html

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface IProps {}
interface IState {
  hasError?: boolean
}

export default class ErrorBoundary extends React.Component<IProps, IState> {
  constructor(props) {
    super(props)
    this.state = { hasError: false }
  }

  static getDerivedStateFromError(_error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true }
  }

  componentDidCatch(error, errorInfo) {
    // You can also log the error to an error reporting service
    // logErrorToMyService(error, errorInfo)
    console.error(error) // eslint-disable-line no-console
    console.error(errorInfo) // eslint-disable-line no-console
  }

  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return <h1>Something went wrong.</h1>
    }

    return this.props.children
  }
}
