import React from "react"
import RouterLink from "redux-first-router-link"
import colours from "../themes/colours"

const linkStyle = {
  textDecoration: "none",
  color: colours.blue,
  cursor: "pointer"
}

type LinkProps = {
  to: Record<string, unknown>
  children
  confirm?: string
}

const Link = ({ to, children, confirm }: LinkProps) => (
  <RouterLink
    to={to}
    style={linkStyle}
    onClick={confirm ? () => window.confirm(confirm) : null}
  >
    {children}
  </RouterLink>
)

Link.defaultProps = {
  confirm: null
}

type AnchorProps = {
  to: string
  children
  confirm?: string
}

export const Anchor = ({ to, children, confirm }: AnchorProps) => (
  <a
    href={to}
    style={linkStyle}
    onClick={confirm ? () => window.confirm(confirm) : null}
  >
    {children}
  </a>
)

Anchor.defaultProps = {
  confirm: null
}

type FunctionLinkProps = {
  onClick: React.MouseEventHandler
  children
  confirm?: string
}

const wrapOnClickWithConfirm = (
  onClick: React.MouseEventHandler,
  confirm?: string
) => {
  if (confirm) {
    return (event) => window.confirm(confirm) && onClick(event)
  }

  return onClick
}

export const FunctionLink = ({
  onClick,
  children,
  confirm
}: FunctionLinkProps) => (
  <a
    style={linkStyle}
    href="#"
    onClick={wrapOnClickWithConfirm(onClick, confirm)}
  >
    {children}
  </a>
)

type LinkState = {
  clicked: boolean
}

type StatefulLinkProps = FunctionLinkProps & {
  afterClicked: string
}

export class StatefulFunctionLink extends React.Component<
  StatefulLinkProps,
  LinkState
> {
  constructor(props: StatefulLinkProps) {
    super(props)
    this.state = { clicked: false }
  }

  render() {
    const { afterClicked, onClick, ...linkProps } = this.props
    const { clicked } = this.state

    if (clicked) {
      return <span>{afterClicked}</span>
    }

    return (
      <FunctionLink
        {...linkProps}
        onClick={(e) => {
          this.setState({ clicked: true })
          onClick(e)
        }}
      />
    )
  }
}

export default Link
