import HashMap from "hashmap"
import { TeamAssessment } from "../../state_types"

export default class AssessmentsReport {
  teamAssessments: Array<TeamAssessment>

  constructor(teamAssessments) {
    this.teamAssessments = teamAssessments
  }

  allTeamAssessments() {
    return this.teamAssessments
  }

  mapYears(callback) {
    const hash = this.byYear()
    return hash
      .keys()
      .sort()
      .reverse()
      .map((year) => callback(year, hash.get(year)))
  }

  byYear() {
    return this.teamAssessments.reduce((accumulator, teamAssessment) => {
      const date = teamAssessment.date
        ? new Date(teamAssessment.date)
        : new Date()
      const year = date.getFullYear()
      const array = accumulator.get(year) || accumulator.set(year, []).get(year)
      array.push(teamAssessment)
      return accumulator
    }, new HashMap())
  }
}
