import React from "react"
import styled from "@emotion/styled"
import FontAwesome from "react-fontawesome"
import isEmpty from "lodash.isempty"
import colours from "../themes/colours"

const HiddenInput = styled.input({
  width: "0.1px",
  height: "0.1px",
  opacity: 0,
  overflow: "hidden",
  position: "absolute",
  zIndex: -1
})

const buttonSize = 36

const RoundLabel = styled.label({
  width: buttonSize,
  height: buttonSize,
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  color: "white",
  backgroundColor: colours.blue,
  borderRadius: 500,
  cursor: "pointer"
})

const plusStyles = {
  fontSize: 24
}

type Props = {
  name: string
  icon: string
  onChange: React.ChangeEventHandler<HTMLInputElement>
  onBlur: () => void
}

const changeHandler =
  (handler?: React.ChangeEventHandler<HTMLInputElement>) =>
  ({ target: { files } }) => {
    if (!isEmpty(files) && handler) {
      handler(files[0])
    }
  }

const IconFileInput = ({ name, icon, onBlur, onChange, ...props }: Props) => (
  <span>
    <RoundLabel htmlFor={name}>
      <FontAwesome style={plusStyles} name={icon} />
    </RoundLabel>
    <HiddenInput
      type="file"
      id={name}
      {...props}
      onBlur={() => onBlur()}
      onChange={changeHandler(onChange)}
    />
  </span>
)

export default IconFileInput
