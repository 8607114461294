import React from "react"
import pluralize from "pluralize"
import styled from "@emotion/styled"
import colours from "../themes/colours"

type NumericStatisticProps = {
  value: number
  noun: string
  singleLine?: boolean
  headingMode?: boolean
}

const SingleLineContainer = styled.div({
  color: colours.darkGrey
})

const Container = styled.div<{ headingMode }>((props) => ({
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  lineHeight: "120%",
  color: colours.darkGrey,
  alignItems: props.headingMode ? "center" : "flex-start"
}))

const Value = styled.span<{ headingMode }>((props) => ({
  fontWeight: props.headingMode ? "bold" : "normal",
  display: "block"
}))

const Noun = styled.span({
  display: "block"
})

const NumericStatistic = ({
  value,
  noun,
  singleLine,
  headingMode
}: NumericStatisticProps) => {
  if (singleLine) {
    return (
      <SingleLineContainer>
        {value} {pluralize(noun, value)}
      </SingleLineContainer>
    )
  }
  return (
    <Container headingMode={headingMode}>
      <Value headingMode={headingMode}>{value}</Value>
      <Noun>{pluralize(noun, value)}</Noun>
    </Container>
  )
}

NumericStatistic.defaultProps = {
  singleLine: false,
  headingMode: false
}

export default NumericStatistic
