import React from "react"
import styled from "@emotion/styled"
import Checkbox from "material-ui/Checkbox"
import { gutter } from "./Layout"
import colours from "../themes/colours"
import CloseButton from "./CloseButton"
import type { ProposedAttachment } from "../contexts/feed/types"

const AttachmentContainer = styled.div({
  display: "flex",
  alignItems: "flex-start",
  justifyContent: "space-between",
  borderBottom: `1px solid ${colours.whiteSilverBorder}`,
  borderTop: `1px solid ${colours.whiteSilverBorder}`,
  // backgroundColor: colours.blue,
  // color: 'white',
  padding: `10px ${gutter}`
})

const FileDetails = styled.div({
  flexGrow: 1,
  marginRight: gutter
})

const FileName = styled.div({
  fontWeight: 600,
  marginBottom: 5
})

const FileOptions = styled.div({
  fontSize: "90%"
})

type Props = {
  attachment?: ProposedAttachment
  onChange: (attachment?: ProposedAttachment) => any
}

const treatmentPlanRole = "treatment_plan"

const AttachmentDetails = ({ attachment, onChange }: Props) => (
  <AttachmentContainer>
    <FileDetails>
      <FileName>{attachment.file.name}</FileName>
      <FileOptions>
        <Checkbox
          checked={attachment.role === treatmentPlanRole}
          label="Set as current treatment plan"
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore onCheck is whats used, rather than onChange, despite typescript complaining, onCheck does change things whereas onChange does not.
          onCheck={(event: React.ChangeEvent<HTMLInputElement>) => {
            const role = event.target.checked ? treatmentPlanRole : null
            const newAttachment = { ...attachment, role }
            onChange(newAttachment)
          }}
        />
      </FileOptions>
    </FileDetails>
    <CloseButton
      style={{ color: colours.white, paddingBottom: 0, fontSize: "1.5em" }}
      onClick={() => onChange(null)}
    />
  </AttachmentContainer>
)

export default AttachmentDetails
