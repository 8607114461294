import React from "react"
import styled from "@emotion/styled"
import get from "lodash.get"
import QuestionTableRow from "./QuestionTableRow"
import { gutter } from "./Layout"
import colours from "../themes/colours"
import type { AssessmentAttributesInput } from "../contexts/assessment/types"

type RadioQuestion = {
  id: string
  question?: string
  hasError?: boolean
}

type Option = [number, string]

type Props = {
  options?: Array<Option>
  questions?: Array<RadioQuestion>
  preamble?: string
  indented?: boolean
  disabled?: boolean
  updateRating: (questionId: string, newValue?: number) => unknown
  input: AssessmentAttributesInput
  mobile: boolean
}

const Table = styled.table({
  marginLeft: -20,
  paddingLeft: 20
})

const Container = styled.div({
  paddingTop: gutter
})

const Preamble = styled.p({
  fontSize: "0.9em",
  borderBottom: `2px solid ${colours.boldLineOnSilver}`,
  paddingBottom: "15px"
})

const HeadingCell = styled.th({
  borderBottom: `2px solid ${colours.boldLineOnSilver}`
})

const Heading = styled.span({
  fontSize: "0.7em",
  fontWeight: "normal",
  display: "block",
  lineHeight: "120%",
  width: "7em",
  wordSpacing: "100px",
  marginBottom: 5
})

const QuestionContainer = styled.div({})

const DesktopRadioQuestionTable = ({
  questions,
  options,
  preamble,
  indented,
  disabled,
  updateRating,
  mobile,
  input
}: Props) => {
  return (
    <Container>
      {preamble && <Preamble>{preamble}</Preamble>}
      <Table cellPadding={0} cellSpacing={0} id="org-users-table">
        <thead style={{ borderBottom: "2px solid blue" }}>
          <tr>
            <HeadingCell />
            {options &&
              options.map(([id, name]) => (
                <HeadingCell key={id}>
                  <Heading>{name}</Heading>
                </HeadingCell>
              ))}
          </tr>
        </thead>
        <tbody>
          {questions &&
            questions.map((question) => (
              <QuestionTableRow
                key={get(question, "id")}
                questionData={question}
                options={options}
                indented={indented || false}
                disabled={disabled}
                updateRating={updateRating}
                mobile={mobile}
                value={input[question.id]}
                hasError={question.hasError}
              />
            ))}
        </tbody>
      </Table>
    </Container>
  )
}

const MobileRadioQuestionTable = ({
  questions,
  options,
  preamble,
  mobile,
  indented,
  disabled,
  updateRating,
  input
}: Props) => {
  return (
    <QuestionContainer>
      {preamble && <Preamble>{preamble}</Preamble>}
      {questions &&
        questions.map((question) => (
          <QuestionTableRow
            key={get(question, "id")}
            questionData={question}
            options={options}
            indented={indented || false}
            disabled={disabled}
            updateRating={updateRating}
            mobile={mobile}
            value={input[question.id]}
            hasError={question.hasError}
          />
        ))}
    </QuestionContainer>
  )
}

const RadioQuestionTable = (props: Props) => {
  const { mobile } = props

  return mobile ? (
    <MobileRadioQuestionTable {...props} />
  ) : (
    <DesktopRadioQuestionTable {...props} />
  )
}

const defaultBools = {
  disabled: false
}

RadioQuestionTable.defaultProps = defaultBools
DesktopRadioQuestionTable.defaultProps = defaultBools
MobileRadioQuestionTable.defaultProps = defaultBools

export default RadioQuestionTable
