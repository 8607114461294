import React from "react"
import { TextField } from "@material-ui/core"
import {
  AuthForm,
  Header,
  SubHeader,
  PrimaryActions,
  Instructions,
  Inputs
} from "../AuthFormStyles"
import type { Errors } from "../../state_types"
import BackButton from "../BackButton"
import NextButton from "../NextButton"
import AuthErrorMessages from "../AuthErrorMessages"

type Props = {
  submit: () => void
  onChange: (value: string) => void
  back: () => void
  errors?: Errors
  syncing?: boolean
  otpCode?: string
}

const EnterCodeSetup = ({
  submit,
  onChange,
  errors,
  syncing,
  otpCode,
  back
}: Props) => {
  const handleChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    onChange(event.target.value)
  }

  const handleSubmitForm = (event: React.SyntheticEvent<HTMLFormElement>) => {
    event.preventDefault()
    submit()
  }

  return (
    <>
      <Header>Set up two-factor authentication (2FA)</Header>
      <SubHeader>Step 3</SubHeader>
      <Instructions>
        Next enter the code the app shows on your screen below. This will
        confirm that the app has been set up correctly.
      </Instructions>

      <AuthErrorMessages errors={errors} />

      <AuthForm onSubmit={handleSubmitForm}>
        <Inputs>
          <TextField
            id="otpCode"
            label="One-time password (OTP)"
            onChange={handleChange}
            value={otpCode}
          />
        </Inputs>

        <PrimaryActions>
          <BackButton onClick={back} />
          <NextButton disabled={syncing}>
            Confirm code to finish 2FA set up
          </NextButton>
        </PrimaryActions>
      </AuthForm>
    </>
  )
}

export default EnterCodeSetup
