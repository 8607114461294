import React from "react"
import styled from "@emotion/styled"
import { reduxForm, Field, InjectedFormProps } from "redux-form"
import {
  fieldsRequired,
  fieldMustBeSameAsOtherField,
  minLengthRequired
} from "../contexts/shared/validation"
import SubmitButton from "./SubmitButton"
import Loading from "./Loading"
import TextFieldForReduxForm from "./MuiExtensions/components/TextFieldForReduxForm"

const CenteredColumn = styled.div({
  display: "flex",
  flexDirection: "column",
  alignItems: "center"
})

const FormActions = styled.div({
  marginTop: 50
})

function validate(values) {
  const errors = {}
  fieldsRequired(values, errors, "password", "passwordConfirmation")
  minLengthRequired(values, errors, 8, "password")
  fieldMustBeSameAsOtherField(
    values,
    errors,
    "passwordConfirmation",
    "password"
  )
  return errors
}

interface IProps {
  syncing?: unknown
  onSubmit: React.FormEventHandler
}

const UserPasswordForm = ({
  syncing,
  onSubmit,
  handleSubmit
}: IProps & InjectedFormProps<{}, IProps>) => {
  const isSubmitting = syncing === "sending"
  const textFieldStyle = {
    width: "256px",
    height: "72px"
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <CenteredColumn>
        <Field
          name="password"
          component={TextFieldForReduxForm}
          label="Type your new password"
          type="password"
          style={textFieldStyle}
        />
        <Field
          name="passwordConfirmation"
          component={TextFieldForReduxForm}
          label="And type it again"
          type="password"
          style={textFieldStyle}
        />
        <FormActions>
          {isSubmitting ? <Loading /> : <SubmitButton>Submit</SubmitButton>}
        </FormActions>
      </CenteredColumn>
    </form>
  )
}

export default reduxForm<{}, IProps>({
  form: "userPassword",
  validate
})(UserPasswordForm)
