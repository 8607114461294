import React from "react"
import { Vector2 } from "./types"

type Props = {
  radius: number
  centre: Vector2
}

const BottomFadeOutCircle = ({ radius, centre }: Props) => {
  return (
    <>
      <defs key="bottom-fade-circle-def">
        <linearGradient
          id="bottom-fade-circle-gradient"
          x1="0%"
          x2="0%"
          y1="0%"
          y2="100%"
        >
          <stop stopColor="white" stopOpacity="0.0" offset="40%" />
          <stop stopColor="white" stopOpacity="0.3" offset="45%" />
          <stop stopColor="white" stopOpacity="0.5" offset="50%" />
          <stop stopColor="white" stopOpacity="0.56" offset="55%" />
          <stop stopColor="white" stopOpacity="0.68" offset="60%" />
          <stop stopColor="white" stopOpacity="0.8" offset="65%" />
          <stop stopColor="white" stopOpacity="0.9" offset="70%" />
          <stop stopColor="white" stopOpacity="0.95" offset="75%" />
          <stop stopColor="white" stopOpacity="1.0" offset="80%" />
        </linearGradient>
      </defs>
      <circle
        key="bottom-fade-circle"
        fill="url(#bottom-fade-circle-gradient)"
        r={radius}
        cx={centre.x}
        cy={centre.y}
      />
    </>
  )
}

export default BottomFadeOutCircle
