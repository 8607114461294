import { applyReducerHash } from "../../redux_helpers"
import type { Action } from "../../state_types"

interface RememberOrgSlugState {
  mostRecent: { organisationSlug: string }
}

const reducers = {
  REMEMBER_ORG_SLUG: (state: RememberOrgSlugState, action: Action) => {
    return {
      ...state,
      mostRecent: {
        organisationSlug: action.payload
      }
    }
  }
}

const INITIAL_STATE: RememberOrgSlugState = {
  mostRecent: {
    organisationSlug: null
  }
}

export default function rememberOrgSlugReducer(
  state: RememberOrgSlugState = INITIAL_STATE,
  action: Action
): string {
  return applyReducerHash(reducers, state, action)
}
