import React from "react"
import styled from "@emotion/styled"
import { connect } from "react-redux"
import { InjectedFormProps, reduxForm } from "redux-form"
import FontAwesome from "react-fontawesome"
import moment from "moment"
import RippleMembersForm from "./RippleMembersForm"
import { stepValidator } from "../contexts/ripple_form/wizard"
import SubmitButton from "./SubmitButton"
import { gutter } from "./Layout"

const RippleDetailsContainer = styled.div({
  display: "flex",
  flexDirection: "column",
  flexGrow: 1
})

const Header = styled.div({})

const GoBack = styled.a({
  textDecoration: "none"
})

const Form = styled.form({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  padding: gutter
})

const ArrowText = styled.div({
  display: "flex",
  padding: "30px 0 0 35px",
  alignItems: "baseline"
})

const Text = styled.div({
  color: "#000000",
  fontFamily: "Poppins",
  fontSize: "1em",
  lineHeight: "23px"
})

type OnSubmitFunc = () => any

interface IProps {
  onSubmit: OnSubmitFunc
  roles: Array<string>
  hasCurrentAssessment?: boolean
  nextAssessment?: string
}

function handleCancel(event) {
  event.preventDefault()

  window.history.back()
}

const EditRippleMembersForm = ({
  onSubmit,
  roles,
  hasCurrentAssessment,
  nextAssessment,
  handleSubmit,
  error
}: InjectedFormProps<IProps> & IProps) => {
  return (
    <RippleDetailsContainer>
      <Header>
        <GoBack href="#" onClick={handleCancel}>
          <ArrowText>
            <FontAwesome
              name="long-arrow-left"
              style={{
                paddingRight: "10px",
                textDecoration: "none",
                color: "black"
              }}
            />
            <Text>Cancel</Text>
          </ArrowText>
        </GoBack>
      </Header>
      <Form onSubmit={handleSubmit(onSubmit)} id="ripple-details-form">
        <RippleMembersForm
          roles={roles}
          error={error}
          hasCurrentAssessment={hasCurrentAssessment}
          nextAssessment={nextAssessment}
        />
        <SubmitButton style={{ marginTop: "45px" }} />
      </Form>
    </RippleDetailsContainer>
  )
}

const validator = (values) =>
  stepValidator("participants")(values, { checkAdmins: true })

const hasCurrentDailyAssessment = (lastStartDate, schedule) => {
  const now = moment()
  if (!lastStartDate.isSame(now, "day")) return false

  const startTime = schedule.startHour
    ? moment(schedule.startHour)
    : now.clone().startOf("day")
  const endTime = schedule.endHour
    ? moment(schedule.endHour)
    : now.clone().endOf("day")

  return now.isBetween(startTime, endTime)
}

const hasCurrentNonDailyAssessment = (lastStartDate, schedule) => {
  // if daysOpen is null, assessment window stays open until all assessors have done assessment
  if (!schedule.daysOpen) return true

  const today = moment()
  const closingDate = lastStartDate.clone().add(schedule.daysOpen, "day")
  return today.isSameOrBefore(closingDate, "day")
}

const hasCurrentAssessment = ({ teamAssessments, schedule }) => {
  // ignore ripples that don't have a recurring schedule
  if (!schedule || schedule.periodType === "none") return false

  // if a ripple doesn't have any team assessments, it can't be open
  if (!teamAssessments || teamAssessments.length === 0) return false

  const lastAssessment = teamAssessments.sort(
    (a, b) => moment(b.date).unix() - moment(a.date).unix()
  )[0]
  const lastStartDate = moment(lastAssessment.date)

  return schedule.periodType === "day"
    ? hasCurrentDailyAssessment(lastStartDate, schedule)
    : hasCurrentNonDailyAssessment(lastStartDate, schedule)
}

const NewEditRippleMembersForm = reduxForm({
  form: "userEditRipple",
  validate: validator
})(EditRippleMembersForm)

const mapStateToProps = (state) => {
  const ripple = state.rippleDetails.data.ripple
  const { adminUsers: users, nextAssessment } = ripple

  const userData = users.map((user) => {
    return {
      name: user.name,
      roleNames: user.roles,
      email: user.email,
      phone: user.phone,
      existing: true,
      phoneEditable: user.phoneEditable
    }
  })
  return {
    initialValues: {
      members: userData
    },
    hasCurrentAssessment:
      (nextAssessment && nextAssessment.open) || hasCurrentAssessment(ripple),
    nextAssessment: nextAssessment && nextAssessment.startOn
  }
}

export default connect<any, any, IProps>(mapStateToProps)(
  NewEditRippleMembersForm
)
