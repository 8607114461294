import React from "react"
import styled from "@emotion/styled"
import FontAwesome from "react-fontawesome"
import Link from "redux-first-router-link"
import Wizard from "../contexts/shared/Wizard"
import { gutter, gutterEm, containerWidth } from "./Layout"
import WizardStepLabels from "./WizardStepLabels"
import FadeSlidePageGroup from "./FadeSlidePageGroup"
import colours from "../themes/colours"
import type { Steps, TransitionToStepFunc } from "../contexts/shared/step_types"

const WizardContainer = styled.div({
  backgroundColor: colours.silver,
  display: "flex",
  flex: "1 1 auto",
  flexDirection: "row",
  justifyContent: "center"
})

const sidebarTextWidthEm = 12
const sidebarWidthEm = sidebarTextWidthEm + 2 * gutterEm
const sidebarWidth = `${sidebarWidthEm}em`

const LeftSide = styled.div({
  flex: 0.25,
  minWidth: sidebarWidth,
  // border: '1px solid green',
  "@media(max-width: 750px)": {
    display: "none"
  }
})

const RightSide = styled.div({
  flex: 0.25,
  minWidth: "1em",
  display: "block",
  textAlign: "right",
  "@media(max-width: 750px)": {
    display: "none"
  }
})

const PageContent = styled.div({
  flex: 1,
  // flexShrink: 0,
  display: "flex",
  flexDirection: "column",
  maxWidth: containerWidth,
  marginTop: "3em",
  // border: '1px solid blue',
  "@media(max-width: 750px)": {
    marginTop: 0
  }
})

const ContentPadding = styled.div<{ right?: any; left?: any; nowrap?: any }>(
  (props) => {
    return {
      flexGrow: 1,
      display: "flex",
      paddingTop: gutter,
      paddingBottom: gutter,
      paddingLeft: props.right ? 0 : gutter,
      paddingRight: props.left ? 0 : gutter,
      whiteSpace: props.nowrap ? "nowrap" : "normal"
    }
  }
)

const MobileHeader = styled.div({
  display: "flex",
  paddingTop: gutter,
  paddingRight: gutter,
  paddingLeft: gutter,
  justifyContent: "space-between",
  "@media(min-width: 750px)": {
    display: "none"
  }
})

const WizardCurrentStep = styled.div({})

type Props = {
  children
  currentStepID: string
  steps: Steps
  transitionToStep: TransitionToStepFunc
}

const WizardPageBody = ({
  children,
  currentStepID,
  steps,
  transitionToStep
}: Props) => {
  const wizard = new Wizard(steps)
  const currentStep = wizard.stepForID(currentStepID)

  return (
    <WizardContainer>
      <LeftSide>
        <ContentPadding nowrap left>
          <WizardStepLabels
            currentStepID={currentStepID}
            steps={steps}
            transitionToStep={transitionToStep}
          />
        </ContentPadding>
      </LeftSide>
      <PageContent>
        <MobileHeader>
          <WizardCurrentStep>
            <WizardStepLabels
              iconSide="right"
              currentStepID={currentStepID}
              steps={[currentStep]}
              transitionToStep={transitionToStep}
            />
          </WizardCurrentStep>
          <Link to={{ type: "RIPPLES" }}>
            <FontAwesome
              name="times"
              style={{ color: colours.deemphasisedText, fontSize: "1.5em" }}
            />
          </Link>
        </MobileHeader>
        <ContentPadding>
          <FadeSlidePageGroup currentKey={currentStepID}>
            {children}
          </FadeSlidePageGroup>
        </ContentPadding>
      </PageContent>
      <RightSide>
        <ContentPadding right style={{ justifyContent: "flex-end" }}>
          <Link to={{ type: "RIPPLES" }}>
            <FontAwesome
              name="times"
              style={{ color: colours.deemphasisedText, fontSize: "2em" }}
            />
          </Link>
        </ContentPadding>
      </RightSide>
    </WizardContainer>
  )
}

export default WizardPageBody
