import React from "react"
import { connect } from "react-redux"
import { touch } from "redux-form"
import isEmpty from "lodash.isempty"
import get from "lodash.get"
import find from "lodash.find"
import Wizard from "../../components/Wizard"
import LayoutWithHeader from "../../components/LayoutWithHeader"
import Header from "../../components/Header"
import WizardProgressBar from "../../components/WizardProgressBar"
import Theme from "../../themes/Silver"
import importRipplesWizard, {
  steps,
  stepValidator
} from "../../contexts/import_ripples_form/wizard"
import ImportDetails from "./ImportDetails"
import AssessmentDetails from "./AssessmentDetails"
import Schedule from "./Schedule"
import { submitImportRipplesForm } from "../../api_actions"
import Loading from "../../components/Loading"
import { flatFieldNamesFromErrors } from "../../uiHelpers/formHelpers"
import type { TransitionToStepFunc } from "../../contexts/shared/step_types"
import type { RippleFormApiResult } from "../../state_types"

type Props = {
  importRipplesFormResult: RippleFormApiResult
  onSubmit: () => any
  formData: {
    values?: unknown
    anyTouched?: boolean
  }
  transitionToStep: TransitionToStepFunc
  touchFields: (fieldNames: Array<string>) => any
}

class ImportRipplesPage extends React.Component<Props> {
  currentStepID = () => this.props.importRipplesFormResult.currentStepID

  stepIsValid(stepID) {
    const errors = this.validationErrors(stepID)
    return isEmpty(errors)
  }

  currentFramework() {
    const frameworkId = get(this.props, "formData.values.framework")
    const frameworks = this.frameworks()
    return frameworkId && frameworks && find(frameworks, { id: frameworkId })
  }

  frameworks() {
    return get(this.props, "importRipplesFormResult.data.frameworks")
  }

  currentRoles() {
    const framework = this.currentFramework()
    return framework && framework.roles
  }

  currentStepIsValid() {
    return this.stepIsValid(this.currentStepID())
  }

  validationErrors(stepID) {
    const validator = stepValidator(stepID)
    const validationProps = { frameworks: this.frameworks() }
    const validationValues = this.props.formData.values || {}
    return validator(validationValues, validationProps)
  }

  touchAllErrorFieldsInStep(stepID) {
    const errors = this.validationErrors(stepID)
    const fields = flatFieldNamesFromErrors(errors)
    this.props.touchFields(fields)
  }

  requestStepTransition = (stepID) => {
    const transitionToStep = this.props.transitionToStep
    if (this.currentStepIsValid()) {
      transitionToStep(stepID)
    } else {
      this.touchAllErrorFieldsInStep(this.currentStepID())
    }
  }

  renderLoading() {
    return (
      <Theme>
        <LayoutWithHeader
          header={
            <div>
              <Header />
            </div>
          }
        >
          <Loading />
        </LayoutWithHeader>
      </Theme>
    )
  }

  render() {
    const { importRipplesFormResult, onSubmit } = this.props

    if (!importRipplesFormResult.data) return this.renderLoading()

    const { currentStepID } = importRipplesFormResult
    const submitting = !!importRipplesFormResult.syncing
    const currentStepNumber = importRipplesWizard.stepNumber(currentStepID)
    const stepStates = steps.map((step) => {
      const stepNumber = importRipplesWizard.stepNumber(step.id)
      const valid = this.stepIsValid(step.id)
      const attempted =
        this.props.formData.anyTouched && stepNumber <= currentStepNumber
      return { ...step, valid, attempted }
    })
    const importRipplesForm = importRipplesFormResult.data

    return (
      <Theme>
        <LayoutWithHeader
          header={
            <div>
              <Header />
              <WizardProgressBar steps={steps} currentStepID={currentStepID} />
            </div>
          }
        >
          <Wizard
            currentStepID={currentStepID}
            steps={stepStates}
            transitionToStep={this.requestStepTransition}
            onSubmit={onSubmit}
            submitting={submitting}
          >
            <ImportDetails key="upload-file" />
            <AssessmentDetails
              key="assessment"
              frameworks={importRipplesForm.frameworks}
              formSettings={importRipplesForm.settings || {}}
            />
            <Schedule key="schedule" />
          </Wizard>
        </LayoutWithHeader>
      </Theme>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    importRipplesFormResult: state.importRipplesForm || {} || {},
    formData: state.form.importRipples || {}
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    onSubmit: () => {
      dispatch(submitImportRipplesForm())
    },
    transitionToStep: (stepID) => {
      dispatch({ type: "IMPORT_RIPPLES_STEP", payload: { step: stepID } })
    },
    touchFields: (fields) => {
      dispatch(touch("importRipples", ...fields))
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ImportRipplesPage)
