import React from "react"
import styled from "@emotion/styled"
import omit from "lodash.omit"
import { WrappedFieldInputProps } from "redux-form"
import { faFileCsv } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { IconProp } from "@fortawesome/fontawesome-svg-core"
import { FormErrorMessage } from "./TextStyles"
import colours from "../themes/colours"
import IconFileInput from "./IconFileInput"
import { decodeTxtFile } from "./helpers/file_processors"

const FileContainer = styled.div({
  width: "100%",
  display: "flex",
  alignItems: "center",
  marginBottom: "1.5em",
  flexDirection: "column"
})

const avatarSize = 120

const FileWidget = styled.div({
  marginRight: "0.5em",
  position: "relative",
  width: avatarSize,
  height: avatarSize
})

const ButtonContainer = styled.div({
  position: "absolute",
  bottom: 0,
  right: 0
})

const FileText = styled.div({
  display: "flex",
  flexDirection: "column",
  alignItems: "flex-start",
  marginLeft: "1em",
  paddingTop: "20px"
})

const FadedText = styled.div({
  display: "flex",
  fontWeight: 300,
  color: colours.deemphasisedText,
  fontSize: "0.7em",
  lineHeight: "140%"
})

const Instruction = styled.div({
  display: "flex"
})

const IconContainer = styled.div({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  color: colours.grey
})

const FileIcon = styled(FontAwesomeIcon)({
  fontSize: 100
})

const FileDetails = ({ fileName }: { fileName: string }) => (
  <div>File: {fileName}</div>
)

type Props = {
  input: WrappedFieldInputProps
  meta: {
    error?: string
  }
}

type State = {
  fileName?: string
}

class CsvUploader extends React.Component<Props, State> {
  state = {
    fileName: null
  }

  onFileSelection = (file?: File) => {
    if (file)
      decodeTxtFile(file, this.onFileDecodeSuccess, this.onFileDecodeFailure)
  }

  onFileDecodeSuccess = (fileName: string, txt: string) => {
    this.setState({ fileName })

    this.props.input.onChange(txt)
  }

  onFileDecodeFailure = (fileName: string, error: string) => {
    this.setState({ fileName })

    this.props.input.onChange({ error: true, message: error })
  }

  render() {
    const { input } = this.props
    const fileInputProps = omit(input, ["onChange", "value"])
    const { error } = this.props.meta
    const { fileName } = this.state

    return (
      <FileContainer>
        <FileWidget>
          <IconContainer>
            <FileIcon icon={faFileCsv as IconProp} />
          </IconContainer>
          <ButtonContainer>
            <IconFileInput
              icon="plus"
              {...fileInputProps}
              onChange={this.onFileSelection}
              accept=".csv"
            />
          </ButtonContainer>
        </FileWidget>
        <FileText>
          <Instruction>
            <FadedText>Choose a CSV file to upload</FadedText>
          </Instruction>
          {fileName && <FileDetails fileName={fileName} />}
          {error && <FormErrorMessage>{error}</FormErrorMessage>}
        </FileText>
      </FileContainer>
    )
  }
}

export default CsvUploader
