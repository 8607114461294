import React from "react"
import type { Props } from "./ScoringWidget"

class TestScoringWidget extends React.Component<Props> {
  handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.props.onUpdate(parseInt(event.target.value, 10))
  }

  render() {
    return (
      <input
        id="score"
        style={{ height: 50 }}
        value={this.props.value || ""}
        onChange={this.handleChange}
      />
    )
  }
}

export default TestScoringWidget
