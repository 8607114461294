import React from "react"
import type { Vector2 } from "./types"

type Props = {
  centre: Vector2
  pressed?: boolean
  radius: number
}

const DragHandleCircle = ({ centre, radius }: Props) => {
  const props = {
    r: radius,
    cx: centre.x,
    cy: centre.y
  }
  // if (pressed) return <PressedCircle highlight={false} {...props} />

  return (
    <circle
      key="slider-circle"
      fill="#F2F2F2"
      strokeWidth={1}
      stroke="#666666"
      {...props}
    />
  )
}

export default DragHandleCircle
