import React, { ReactElement } from "react"
import styled from "@emotion/styled"
import {
  Field,
  FieldArray,
  FieldArrayFieldsProps,
  WrappedFieldProps
} from "redux-form"
import FontAwesome from "react-fontawesome"
import { IconButton, RadioButton, RadioButtonGroup } from "material-ui"
import moment from "moment"
import { HeadingThree, FormHelpText } from "./TextStyles"
import colours from "../themes/colours"
import AddAnotherButton from "./AddAnotherButton"
import RippleRolesField from "./RippleRolesField"
import TextFieldForReduxForm from "./MuiExtensions/components/TextFieldForReduxForm"

// roleNames is a list of admin (optionally) and at most one other role
const getUserRole = (roles: Array<any>) =>
  roles.find((role: string) => role !== "admin")

const FormContainer = styled.div({
  display: "flex",
  flexDirection: "column",
  alignItems: "center"
})

const ParticipantsContainer = styled.div({
  display: "flex",
  flexDirection: "column",
  justifyContent: "flex-start"
})

const AddParticipants = styled.ul({
  paddingLeft: 0,
  listStyle: "none"
})

const ParticipantDetails = styled.li({
  position: "relative",
  paddingBottom: "3em",
  display: "flex"
})

const FieldsContainer = styled.div({
  display: "flex",
  justifyContent: "space-between",
  flexWrap: "wrap"
})

const RolesContainer = styled.div({
  marginTop: "1em"
})

interface RadioButtonProps extends WrappedFieldProps {
  defaultValue?
}

const renderRadioButtonGroup = ({
  input,
  defaultValue,
  ...rest
}: RadioButtonProps) => {
  const valueSelected =
    typeof input.value === "boolean" ? input.value : defaultValue
  return (
    <RadioButtonGroup
      {...input}
      {...rest}
      valueSelected={valueSelected}
      onChange={(_event, value) => input.onChange(value)}
    />
  )
}

const FieldContainer = styled.div({
  display: "flex",
  marginRight: "2em"
})

const ItemDetails = styled.div({
  flex: 1
})

const TrashButton = ({ onClick }: { onClick: React.MouseEventHandler }) => (
  <IconButton
    onClick={onClick}
    style={{
      position: "absolute",
      top: "1em",
      right: "0",
      padding: 0,
      fontSize: "1.5em",
      color: colours.deemphasisedText,
      height: "1.5em",
      width: "1.5em"
    }}
  >
    <FontAwesome name="trash-o" />
  </IconButton>
)

const StartAssessing = styled.fieldset({
  display: "flex",
  flexDirection: "column",
  color: "rgba(0, 0, 0, 0.3)", // this colour matches the redux-form-material-ui textfield colour.
  border: "2px dotted currentColor",
  padding: ".5em 1em",
  borderRadius: "8px",
  marginTop: "1em"
})

// the CSS controlling the colour of the radio buttons is in application.css.
// I'm sorry, but I couldn't figure out how to pass it into the RadioButton element
const radioButtonLabelStyle = {
  color: "inherit",
  marginBottom: ".5em"
}

type MembersProps = {
  fields: FieldArrayFieldsProps<any>
  roles: Array<string>
  hasCurrentAssessment?: boolean
  nextAssessment?: string
}

type State = {
  userRole: string
}

type MemberProps = {
  isExisting: boolean
  phoneEditable: boolean
  member
  number: number
  roles: Array<string>
  userRole: string
  hasCurrentAssessment?: boolean
  nextAssessment?: string
  onRemove: () => void
}

class MemberField extends React.Component<MemberProps, State> {
  static get assessorRoles() {
    return ["assessor", "parent", "teacher", "self", "educator", "student"]
  }

  state = { userRole: this.props.userRole }

  get isAssessor() {
    return MemberField.assessorRoles.includes(this.state.userRole)
  }

  get askWhenToStart() {
    return (
      !this.props.isExisting &&
      this.props.hasCurrentAssessment &&
      this.isAssessor
    )
  }

  render() {
    const {
      isExisting,
      phoneEditable,
      number,
      member,
      roles,
      onRemove,
      nextAssessment
    } = this.props

    const textFieldStyle = { height: "72px", width: "256px" }
    return (
      <ParticipantDetails
        className={isExisting ? "existing-participant" : "new-participant"}
      >
        <HeadingThree style={{ marginTop: 40, paddingRight: 20 }}>
          {number}.
        </HeadingThree>
        <ItemDetails>
          <Field
            name={`${member}.name`}
            type="text"
            component={TextFieldForReduxForm}
            label="Name"
            style={textFieldStyle}
            disabled={isExisting}
          />
          <FieldsContainer>
            <FieldContainer>
              <Field
                name={`${member}.email`}
                type="text"
                component={TextFieldForReduxForm}
                label="Email"
                style={textFieldStyle}
                disabled={isExisting}
              />
            </FieldContainer>
            <FieldContainer>
              <Field
                name={`${member}.phone`}
                type="text"
                component={TextFieldForReduxForm}
                label="Mobile"
                style={textFieldStyle}
                disabled={isExisting && !phoneEditable}
              />
            </FieldContainer>
          </FieldsContainer>
          <RolesContainer>
            <Field
              name={`${member}.roleNames`}
              component={RippleRolesField}
              buttonNames={roles}
              primaryColour={colours.green}
              updateRoles={(newRoles) =>
                this.setState({ userRole: getUserRole(newRoles) })
              }
            />
            {this.askWhenToStart && (
              <StartAssessing className="start-assessing">
                <legend id={`start-assessing-${number}`}>
                  Start assessing
                </legend>
                <Field
                  name={`${member}.addToCurrentAssessment`}
                  component={renderRadioButtonGroup}
                  defaultValue={!!isExisting}
                >
                  <RadioButton
                    label="Now"
                    value
                    labelStyle={radioButtonLabelStyle}
                  />
                  <RadioButton
                    label={`Next assessment window (${moment(
                      nextAssessment
                    ).format("DD MMM")})`}
                    value={false}
                    labelStyle={radioButtonLabelStyle}
                  />
                </Field>
              </StartAssessing>
            )}
          </RolesContainer>
        </ItemDetails>
        <TrashButton onClick={onRemove} />
      </ParticipantDetails>
    )
  }
}

/* eslint-disable react/no-array-index-key */
const MembersFieldList = ({
  fields,
  roles,
  hasCurrentAssessment,
  nextAssessment
}: MembersProps): ReactElement<any, any> => {
  return (
    <ParticipantsContainer>
      <FormHelpText>
        Participants will only have to view and / or assess this Ripple. You can
        invite more participants later.
      </FormHelpText>
      <AddParticipants>
        {fields.map((member, index: number) => {
          const rowData = fields.get(index)
          const { existing, phoneEditable, roleNames } = rowData

          const userRole = roleNames && getUserRole(roleNames)

          return (
            <MemberField
              key={index}
              isExisting={existing}
              phoneEditable={phoneEditable}
              number={index + 1}
              roles={roles}
              userRole={userRole}
              member={member}
              hasCurrentAssessment={hasCurrentAssessment}
              nextAssessment={nextAssessment}
              onRemove={() => fields.remove(index)}
            />
          )
        })}
      </AddParticipants>
      <AddAnotherButton fields={fields} />
    </ParticipantsContainer>
  )
}
/* eslint-enable */

const ErrorMessage = styled.div({
  color: "red",
  fontWeight: "bold",
  marginBottom: "1em"
})

type RippleMembersFormProps = {
  roles: Array<string>
  hasCurrentAssessment?: boolean
  nextAssessment?: string
  error?: string
}

const RippleMembersForm = ({
  roles,
  error,
  hasCurrentAssessment,
  nextAssessment
}: RippleMembersFormProps) => {
  return (
    <FormContainer>
      {error && <ErrorMessage>{error}</ErrorMessage>}
      <FieldArray
        name="members"
        component={MembersFieldList}
        props={{ roles, hasCurrentAssessment, nextAssessment }}
      />
    </FormContainer>
  )
}

RippleMembersForm.defaultProps = {
  error: null
}

export default RippleMembersForm
