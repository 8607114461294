import get from "lodash.get"
import find from "lodash.find"
import map from "lodash.map"
import naturalCompare from "natural-compare"
import type { State } from "../../state_types"

export default class ReportFilterState {
  orgs

  filters

  groups

  constructor(state: State) {
    this.orgs = get(state, "me.data.user.orgs") || []
    this.filters = get(state, "report.data.filters") || {}
    this.groups = get(state, "report.data.groups") || []
  }

  get availableOrgs() {
    return this.orgs
  }

  get availableFrameworks() {
    return get(this.currentOrg, "frameworks") || []
  }

  get availableGroups() {
    const groupSorted = this.groups.sort(naturalCompare)

    return [{ value: "", text: "All" }].concat(
      map(groupSorted, (group) => {
        return {
          value: group,
          text: group
        }
      })
    )
  }

  get currentOrg() {
    return this._orgForSlug() || this.availableOrgs[0]
  }

  // PRIVATE

  _orgForSlug() {
    return find(this.availableOrgs, { slug: this.filters.organisation })
  }
}
