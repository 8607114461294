import React from "react"
import FontAwesome from "react-fontawesome"
import { TextField } from "@material-ui/core"
import map from "lodash.map"
import styled from "@emotion/styled"
import type { User } from "../state_types"
import colours from "../themes/colours"

type Props = {
  user: User
  updateUserTags: (email, tags) => void
}

const TagList = styled.ul({
  listStyle: "none",
  padding: 0,
  width: "100%"
})

const TagItem = styled.li({
  width: "100%",
  display: "flex"
})

const Tag = styled.span({
  flexGrow: 1
})

const Container = styled.div({
  fontSize: "0.8em"
})

type State = {
  newTagValue: string
}

class EditTagsBubbleDialog extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props)

    this.state = {
      newTagValue: ""
    }
  }

  addTag() {
    const { user, updateUserTags } = this.props

    const tags = user.tags ? user.tags : []
    if (tags.indexOf(this.state.newTagValue) < 0) {
      tags.push(this.state.newTagValue)
      tags.sort()
      updateUserTags(user.email, tags)
    }
  }

  tagRemoved(tagToRemove: string) {
    const { user, updateUserTags } = this.props
    const filteredTags = user.tags.filter((tag) => tag !== tagToRemove)

    updateUserTags(user.email, filteredTags)
  }

  inputChanged(value: string) {
    this.setState({ newTagValue: value })
  }

  render() {
    const { user } = this.props

    const iconStyle = {
      fontSize: "1.5em",
      width: "1em",
      height: "1em",
      cursor: "pointer"
    }
    return (
      <Container>
        <TagList>
          {map(user.tags, (tag) => (
            <TagItem key={tag}>
              <Tag>{tag}</Tag>
              <FontAwesome
                data-testid="removeTag"
                name="minus"
                style={{ ...iconStyle, color: colours.blue }}
                onClick={() => this.tagRemoved(tag)}
              />
            </TagItem>
          ))}
        </TagList>
        <TextField
          label="New Tag"
          InputLabelProps={{
            className: "text-field--small-label"
          }}
          id="tags-input"
          style={{ width: "100%" }}
          onChange={(
            event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
          ) => {
            this.inputChanged(event.target.value)
          }}
        />
        <FontAwesome
          data-testid="addTag"
          name="plus"
          style={{
            ...iconStyle,
            color: colours.green,
            position: "relative",
            left: "-1em",
            backgroundColor: "white"
          }}
          onClick={() => {
            this.addTag()
          }}
        />
      </Container>
    )
  }
}

export default EditTagsBubbleDialog
