import React from "react"
import get from "lodash.get"
import find from "lodash.find"
import FontAwesome from "react-fontawesome"
import styled from "@emotion/styled"
import { connect } from "react-redux"
import { deleteOrgUser } from "../../api_actions"
import colours from "../../themes/colours"

const Anchor = styled.span({
  cursor: "pointer",
  color: colours.darkGrey,
  fontSize: "1.4em",
  ":hover": {
    color: colours.blue
  }
})

const DisabledAnchor = styled.span({
  color: colours.deemphasisedText,
  fontSize: "1.4em"
})

type Props = {
  email: string
  isCurrentUser: boolean
  userHasRipples: boolean
  style?: unknown
  removeOrgUser: (email: string) => any
}

class RemoveOrgUserLink extends React.Component<Props> {
  static defaultProps = {
    style: null
  }

  handleRemove = () => {
    if (window.confirm("Are you sure you want to delete this user?")) {
      this.props.removeOrgUser(this.props.email)
    }
  }

  showDisabledReason = () => {
    const { isCurrentUser, userHasRipples } = this.props
    if (isCurrentUser) {
      alert("You cannot delete yourself from this organisation.")
    } else if (userHasRipples) {
      alert(
        "To remove this user from the organisation they have to first be removed from all their ripples."
      )
    }
  }

  canRemove() {
    const { isCurrentUser, userHasRipples } = this.props
    return !isCurrentUser && !userHasRipples
  }

  render() {
    if (this.canRemove()) {
      return (
        <Anchor onClick={this.handleRemove}>
          <FontAwesome name="trash-o" style={this.props.style} />
        </Anchor>
      )
    }

    return (
      <DisabledAnchor onClick={this.showDisabledReason}>
        <FontAwesome name="trash-o" style={this.props.style} />
      </DisabledAnchor>
    )
  }
}

const mapStateToProps = (state, { email }) => {
  const currentUserEmail = get(state, "me.data.user.email")
  const existingUsers = get(state, "org.data.orgUsers")
  const existingUser =
    existingUsers && find(existingUsers, ["user.email", email])
  const rippleCount = existingUser && get(existingUser, "stats.ripples")

  return {
    isCurrentUser: currentUserEmail === email,
    userHasRipples: !!rippleCount
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    removeOrgUser: (email: string) => {
      dispatch(deleteOrgUser(email))
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(RemoveOrgUserLink)
