import React from "react"
import styled from "@emotion/styled"
import type { TeamAssessment } from "../../state_types"

const Container = styled.div(() => ({
  overflow: "hidden"
}))

type Props = {
  teamAssessment: TeamAssessment
}

const AssessmentCommentSummary = ({ teamAssessment }: Props) => {
  const { userBreakdown } = teamAssessment

  return (
    <Container>
      {userBreakdown.map((attributes) => (
        <div key={attributes.id}>{attributes.comment}</div>
      ))}
    </Container>
  )
}

export default AssessmentCommentSummary
