import immutable from "object-path-immutable"
import get from "lodash.get"
import findIndex from "lodash.findindex"
import { applyReducerHash } from "../../redux_helpers"
import type { Action, QrCodesApiResult } from "../../state_types"

const reducers = {
  EDIT_QR_CODES: (state, action) => {
    if (action && action.filters) {
      return { filters: action.filters }
    }

    return state
  },
  RECEIVED_QR_RIPPLES: (_state, action) => {
    return action.payload
  },
  UPDATED_QR_STATUS: (state, action) => {
    let newState = state

    action.payload.forEach((data) => {
      const { rippleRoleId, active, token } = data

      const existingAssessors = get(state, `assessors`)
      const existingAssessorIndex = findIndex(existingAssessors, [
        "id",
        rippleRoleId
      ])

      newState = immutable.set(
        newState,
        ["assessors", existingAssessorIndex, "qrActive"],
        active
      )
      newState = immutable.set(
        newState,
        ["assessors", existingAssessorIndex, "token"],
        token
      )
    })

    return newState
  },
  PRINT_QR_CODES: (state, _action) => {
    return immutable.set(state, ["showPrintPage"], true)
  },
  HIDE_QR_CODES: (state, _action) => {
    return immutable.set(state, ["showPrintPage"], false)
  }
}

export default function qrCodesReducer(
  state: QrCodesApiResult = null,
  action: Action
): QrCodesApiResult | null | undefined {
  return applyReducerHash(reducers, state, action)
}
