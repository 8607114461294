import React from "react"
import styled from "@emotion/styled"
import isNil from "lodash.isnil"
import FontAwesome from "react-fontawesome"
import IconButton from "../components/IconButton"
import ArrowButton from "../components/ArrowButton"
import RealScoringWidget from "../components/ScoringWidget"
import TestScoringWidget from "../components/TestScoringWidget"
import AssessmentHeader from "../components/AssessmentHeader"
import Comment from "../components/Comment"
import { HeadingOne } from "../components/TextStyles"
import { gutter } from "../components/Layout"
import AssessmentCard from "../components/AssessmentCard"
import Actions from "../components/PageBottomActions"
import type {
  Framework,
  Question,
  QuestionInput
} from "../contexts/assessment/types"
import ColouredBackground from "../components/ColouredBackground"
import colours from "../themes/colours"

const ScoringWidget =
  window.gon.rails_env === "test" ? TestScoringWidget : RealScoringWidget

const Background = styled.div({
  flex: 1,
  display: "flex",
  flexDirection: "row"
})

const Attribute = styled.div({
  flexGrow: 1,
  flexShrink: 0.0,
  display: "flex",
  flexDirection: "column",
  justifyContent: "flex-start",
  minHeight: 300
})

const AttributeActions = styled.div({
  display: "flex",
  justifyContent: "center",
  paddingTop: 7,
  height: "2em",
  zIndex: 3,
  position: "absolute",
  bottom: "6em",
  width: "100%",
  left: 0
})

const ScoringWidgetContainer = styled.div({
  flex: 1,
  display: "flex",
  minHeight: 250,
  flexDirection: "row",
  justifyContent: "center"
})

const QuestionDetails = styled.div({
  marginTop: "1.5em"
})

const QuestionTitle = styled(HeadingOne)({
  marginBottom: "0.3em"
})

const QuestionHeader = styled(AssessmentHeader)({
  justifyContent: "flex-start",
  marginBottom: gutter,
  flexGrow: 0,
  flexShrink: 0
})

const QuestionBody = styled.div({
  fontWeight: 600,
  margin: 0,
  lineHeight: "140%"
})

const Instructions = styled.div({
  fontWeight: 300,
  margin: 0,
  lineHeight: "140%"
})

const ClearButton = styled.a({
  ":visited,:hover,:active,:focus,:link": {
    color: "black",
    textDecoration: "none"
  }
})

const defaultRating = null
const defaultComment = ""

const SectionNumber = styled.div({
  textAlign: "center",
  fontSize: "1em",
  fontWeight: "normal",
  color: colours.deemphasisedText
})

const CopyrightFooter = styled.footer({
  fontSize: "0.8em",
  lineHeight: "100%",
  color: "#666666",
  display: "flex",
  justifyContent: "space-between",
  paddingTop: 20
})

type Props = {
  question: Question
  current: boolean
  input?: QuestionInput
  previousInput?: QuestionInput
  previousPage: () => unknown
  nextPage: () => unknown
  updateRating: (rating: number) => unknown
  clearRating: () => unknown
  attributeTotal: number
  attributeIndex: number
  updateQuestionComment: (value: string) => unknown
  canSkip: boolean
  framework: Framework
}

class QuestionPage extends React.Component<Props, { showComment: boolean }> {
  static defaultProps = {
    previousInput: null
  }

  state = { showComment: false }

  onUpdate = () => {
    this.setState((state) => ({ showComment: !state.showComment }))
  }

  render() {
    const { question, current, canSkip, updateRating, framework } = this.props
    const { footer, subtitle, outOf, showPreviousResult } = framework
    const input = this.props.input
    const comment = input?.comment || defaultComment
    const hasRating = !isNil(input?.rating)
    const rating = hasRating ? input?.rating : defaultRating
    const disabled = !hasRating && !canSkip
    const showSkip = canSkip && !hasRating
    const canClear = !!hasRating
    const previousValue: number | null =
      !!showPreviousResult && this.props.previousInput
        ? this.props.previousInput.rating
        : null

    return (
      <ColouredBackground colour={colours.blue}>
        <Background>
          <AssessmentCard
            id={current ? "current-question" : null}
            maxHeight={800}
          >
            <>
              <QuestionHeader>
                {question.progressLabel && (
                  <SectionNumber>{question.progressLabel}</SectionNumber>
                )}
                <QuestionDetails>
                  <QuestionTitle>{question.title}</QuestionTitle>
                  <QuestionBody>{question.question}</QuestionBody>
                  <Instructions>{question.instructions}</Instructions>
                </QuestionDetails>
              </QuestionHeader>
              <Attribute>
                <ScoringWidgetContainer>
                  <ScoringWidget
                    id={question.id}
                    onUpdate={updateRating}
                    value={rating}
                    previousValue={previousValue}
                    outOf={outOf}
                    imageScale={question.imageScale}
                  />
                </ScoringWidgetContainer>
                <AttributeActions>
                  {canClear && (
                    <ClearButton href="#" onClick={this.props.clearRating}>
                      Reset
                    </ClearButton>
                  )}
                </AttributeActions>
              </Attribute>
            </>

            <Comment
              comment={comment}
              onChange={this.props.updateQuestionComment}
              showComment={this.state.showComment}
              onUpdate={this.onUpdate}
            />
            <Actions>
              <ArrowButton
                onClick={this.props.previousPage}
                direction="left"
                text="Prev"
              />
              <IconButton
                onClick={this.onUpdate}
                style={{
                  fontSize: "2.3em",
                  padding: 0,
                  height: "2.3em",
                  width: "3em"
                }}
              >
                {comment ? (
                  <FontAwesome name="commenting" />
                ) : (
                  <FontAwesome name="comment-o" />
                )}
              </IconButton>
              <ArrowButton
                onClick={this.props.nextPage}
                direction="right"
                text={showSkip ? "Skip" : "Next"}
                secondary={showSkip}
                disabled={disabled}
              />
            </Actions>
            <CopyrightFooter>
              <span>{footer}</span>
              <span>{subtitle}</span>
            </CopyrightFooter>
          </AssessmentCard>
        </Background>
      </ColouredBackground>
    )
  }
}

export default QuestionPage
