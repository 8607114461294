import React from "react"
import without from "lodash.without"
import { Button as MuiButton } from "@material-ui/core"

import styled from "@emotion/styled"
import colours from "../themes/colours"

const ToggleContainer = styled.div({
  width: "100%"
})

type ValueType = string | Array<string>

type ToggleProps = {
  value: ValueType
  multiSelect?: boolean
  onChange: (value: any) => any
  buttonNames: Array<string>
  primaryColour?: string
  height?: string
  fontSize?: string
  style?
}

function capitalize(string: string) {
  return string.charAt(0).toUpperCase() + string.slice(1)
}

class ToggleButtonGroup extends React.Component<ToggleProps> {
  static defaultProps = {
    height: "40px",
    fontSize: "16px",
    primaryColour: colours.blue,
    style: {},
    multiSelect: false
  }

  changeHandler = (name: string) => () => {
    const { value, multiSelect } = this.props
    if (multiSelect) {
      const newValue = value.includes(name)
        ? without(value, name)
        : [...value, name]
      this.props.onChange(newValue)
    } else {
      this.props.onChange(name)
    }
  }

  isValueSelected(buttonValue: string) {
    const { value, multiSelect } = this.props

    return multiSelect ? value.includes(buttonValue) : value === buttonValue
  }

  render() {
    const { buttonNames = [], height, fontSize, style } = this.props
    const primaryColour =
      this.props.primaryColour || ToggleButtonGroup.defaultProps.primaryColour

    const SharedStyles = {
      padding: "0 20px 0 20px",
      border: `1px solid ${primaryColour}`,
      height,
      lineHeight: "16px",
      borderLeftWidth: "0px"
    }

    const stylesPerButtonPosition = (first: boolean, last: boolean) => {
      if (first) {
        return {
          ...SharedStyles,
          borderLeftWidth: "1px",
          borderRadius: "500px 0 0 500px"
        }
      }
      if (last) {
        return {
          ...SharedStyles,
          borderRadius: "0 500px 500px 0"
        }
      }
      return { ...SharedStyles, borderRadius: 0 }
    }

    const componentClassName = "toggle-button-group__button"
    return (
      <ToggleContainer>
        {buttonNames.map((name, index) => {
          const selected = this.isValueSelected(name)
          const selectedClassName = selected
            ? `${componentClassName}--selected`
            : ""
          const colourClassName =
            primaryColour === colours.green
              ? `${componentClassName}--green`
              : `${componentClassName}--blue`
          return (
            <MuiButton
              className={`${componentClassName} ${selectedClassName} ${colourClassName}`}
              style={{
                ...stylesPerButtonPosition(
                  index === 0,
                  index === buttonNames.length - 1
                ),
                fontSize,
                ...style
              }}
              key={name}
              onClick={this.changeHandler(name)}
            >
              {capitalize(name)}
            </MuiButton>
          )
        })}
      </ToggleContainer>
    )
  }
}

export default ToggleButtonGroup
