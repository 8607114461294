import React from "react"
import { connect } from "react-redux"
import styled from "@emotion/styled"
import get from "lodash.get"
import Filters, { FiltersType } from "./Filters"
import UserTableRow from "./UserTableRow"
import type { OrgUser } from "../../state_types"
import {
  resendOrgUserInvite as resendAction,
  updateUser as updateUserAction,
  updateUserTags as updateTagsAction
} from "../../api_actions"
import { UserRow, SelectionContainer, StyledCheckbox, Cell } from "./common"
import Pagination from "../shared/Pagination"

type Props = {
  orgSlug: string
  filters: FiltersType
  orgUsers: Array<OrgUser>
  totalPages: number
  resendOrgUserInvite: typeof resendAction
  updateUserTags: typeof updateTagsAction
  updateUser: typeof updateUserAction
  onFilterChange: (newFilters: FiltersType) => any
}

const Container = styled.div({})

const SelectAllRow = styled(UserRow)({
  border: "none",
  display: "none" // Hiding selection stuff for now, remove this line when ready
})

const SelectAllText = styled(Cell)({
  fontWeight: 300
})

const Table = styled.div({})

export const UserTableDisconnected = (props: Props) => {
  const {
    filters,
    orgUsers,
    totalPages,
    orgSlug,
    resendOrgUserInvite,
    updateUserTags,
    updateUser
  } = props

  const to = (page) => {
    const query = { ...filters, page }
    return { type: "ORG", payload: { slug: orgSlug }, query }
  }

  const UserPagination = () => (
    <Pagination
      currentPage={filters.page}
      pagesToShow={5}
      totalPages={totalPages}
      to={to}
    />
  )

  return (
    <Container>
      <Filters onChange={props.onFilterChange} />
      <SelectAllRow>
        <SelectionContainer>
          <StyledCheckbox color="primary" />
        </SelectionContainer>
        <SelectAllText>Select all</SelectAllText>
      </SelectAllRow>
      <Table id="org-users-table">
        {orgUsers.map((orgUser) => {
          return (
            <UserTableRow
              key={orgUser.user.id}
              orgUser={orgUser}
              resendOrgUserInvite={resendOrgUserInvite}
              updateUserTags={updateUserTags}
              updateUser={updateUser}
            />
          )
        })}
      </Table>
      <UserPagination />
    </Container>
  )
}

const mapStateToProps = (state) => ({
  filters: get(state, "org.filters"),
  totalPages: get(state, "org.data.totalPages"),
  orgUsers: get(state, "org.data.orgUsers")
})

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    onFilterChange: (newFilters: {}) => {
      dispatch({
        type: "ORG",
        query: newFilters,
        payload: { slug: ownProps.orgSlug }
      })
    },
    resendOrgUserInvite: (email) => {
      dispatch(resendAction(email))
    },
    updateUserTags: (email, tags) => {
      dispatch(updateTagsAction(email, tags))
    },
    updateUser: (user, values) => {
      dispatch(updateUserAction(user, values))
    }
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(UserTableDisconnected)
