import React from "react"
import styled from "@emotion/styled"
import { gutter } from "./Layout"
import colours from "../themes/colours"

type AppBarProps = {
  children
}

const Background = styled.div({
  borderBottom: `1px solid ${colours.whiteSilverBorder}`,
  backgroundColor: "white",
  paddingLeft: gutter,
  paddingRight: gutter
})

const Container = styled.div({
  height: "3.75em",
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  justifyContent: "space-between"
})

const AppBar = ({ children }: AppBarProps) => {
  return (
    <Background>
      <Container>{children}</Container>
    </Background>
  )
}

export default AppBar
