import styled from "@emotion/styled"
import colours from "../themes/colours"

export const HeadingOne = styled.h1(
  {
    fontSize: "2em",
    fontWeight: 700,
    color: "black",
    margin: 0,
    lineHeight: "140%",
    marginBottom: "0.7em"
  },
  ({ theme }: { theme: { headings? } }) => theme.headings
)

export const HeadingTwo = styled.h2({
  fontSize: "1.4em",
  fontWeight: 700,
  color: "black",
  margin: 0,
  lineHeight: "140%"
})

export const HeadingThree = styled.h3({
  fontSize: "1em",
  fontWeight: "bold"
})

export const SubHead = styled.h4({
  fontSize: "0.7em",
  fontWeight: 700
})

type AnchorProps = {
  maxWidth?: string | number
  color?
}

export const Anchor = styled.a(
  {
    color: colours.blue,
    ":active": { color: colours.blue },
    ":visited": { color: colours.blue }
  },
  ({ maxWidth }: AnchorProps) =>
    maxWidth
      ? {
          maxWidth,
          display: "inline-block",
          overflow: "hidden",
          textOverflow: "ellipsis"
        }
      : {},
  ({ color }: AnchorProps) =>
    color
      ? {
          color,
          textDecoration: "none",
          ":active": { color },
          ":visited": { color },
          ":hover": { color: colours.blue, textDecoration: "underline" }
        }
      : {}
)

export const FormHelpText = styled.div({
  fontWeight: 300,
  color: colours.deemphasisedText
})

export const FormErrorMessage = styled.div({
  color: "red",
  fontSize: "12px",
  borderTop: "2px solid red",
  marginTop: 4
})
