import React from "react"
import isempty from "lodash.isempty"
import styled from "@emotion/styled"
import { reduxForm, Field, InjectedFormProps } from "redux-form"
import {
  fieldsRequired,
  fieldsAnyRequired,
  fieldMustBeSameAsOtherField,
  minLengthRequired,
  usernameFormat
} from "../contexts/shared/validation"
import SubmitButton from "./SubmitButton"
import api from "../api"
import { HeadingTwo } from "./TextStyles"
import TextFieldForReduxForm from "./MuiExtensions/components/TextFieldForReduxForm"
import isFakeEmail from "../utils/isFakeEmail"

const CenteredColumn = styled.div({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  textAlign: "center"
})

const Header = styled(HeadingTwo)({
  marginBottom: "0.3em",
  color: "black"
})

const FormActions = styled.div({
  marginTop: 50
})

function validate(values) {
  const errors = {}
  fieldsRequired(values, errors, "password", "passwordConfirmation")
  fieldsAnyRequired(values, errors, "email", "username")
  minLengthRequired(values, errors, 8, "password")
  minLengthRequired(values, errors, 4, "username")
  usernameFormat(values, errors, "username")
  fieldMustBeSameAsOtherField(
    values,
    errors,
    "passwordConfirmation",
    "password"
  )
  return errors
}

const asyncValidate = (values) => {
  return api.user.validateFields(values).then((json) => {
    if (json.username === "taken") {
      throw Object.create({ username: "That username is already taken" })
    }

    if (json.email === "taken") {
      throw Object.create({ email: "That email is already taken" })
    }
  })
}
interface IProps {
  user?: {
    name?: string
    email?: string
    username?: string
  }
  assessed?: boolean
}

const UserInviteForm = ({
  user,
  handleSubmit,
  assessed = true
}: IProps & InjectedFormProps<{}, IProps>) => {
  const { name, email, username } = user

  const emailMissing = isempty(email)
  const usernameMissing = isempty(username)

  const showEmail = emailMissing && usernameMissing
  const showUsername = usernameMissing && (emailMissing || isFakeEmail(email))
  const textFieldStyle = {
    width: "257px",
    height: "72px"
  }

  return (
    <form onSubmit={handleSubmit}>
      <CenteredColumn>
        <Header>{`Hi ${name || "there"}`}</Header>
        {assessed ? (
          <div>
            <p>Your assessment has been submitted.</p>
            <p>
              If you would like to be able view this Ripple you will need to
              complete your registration.
            </p>
          </div>
        ) : (
          <p>
            You&apos;ve been invited to use Ripple. Please complete your
            registration.
          </p>
        )}
        {showUsername && (
          <Field
            name="username"
            component={TextFieldForReduxForm}
            label="Type your username"
            type="username"
            style={textFieldStyle}
          />
        )}
        {showEmail && (
          <Field
            name="email"
            component={TextFieldForReduxForm}
            label="Type your email"
            type="email"
            style={textFieldStyle}
          />
        )}
        <Field
          name="password"
          component={TextFieldForReduxForm}
          label="Type your new password"
          type="password"
          style={textFieldStyle}
        />
        <Field
          name="passwordConfirmation"
          component={TextFieldForReduxForm}
          label="And type it again"
          type="password"
          style={textFieldStyle}
        />
        <FormActions>
          <SubmitButton>Submit</SubmitButton>
        </FormActions>
      </CenteredColumn>
    </form>
  )
}

export default reduxForm<{}, IProps>({
  form: "userInvite",
  validate,
  asyncValidate,
  asyncBlurFields: ["username", "email"]
})(UserInviteForm)
