import React from "react"
import includes from "lodash.includes"
import { connect } from "react-redux"
import styled from "@emotion/styled"
import get from "lodash.get"
import StatsGroup from "./StatsGroup"
import { HeadingOne } from "./TextStyles"
import { gutter } from "./Layout"
import Popover from "./Popover"
import type { OrgStats as OrgStatsType, DispatchFunction } from "../state_types"
import colours from "../themes/colours"
import settings from "../settings"

type Props = {
  stats: OrgStatsType
  name: string
  slug: string
  dispatch: DispatchFunction
  showAdminButton: boolean
}

const Container = styled.header({
  display: "flex",
  flexDirection: "row"
})

const MainContents = styled.div({
  flex: 2,
  display: "flex",
  flexDirection: "column",
  justifyContent: "flex-start",
  alignItems: "flex-start",
  margin: gutter
})

const SideContents = styled.div({
  flex: 1,
  display: "flex",
  flexDirection: "row",
  justifyContent: "flex-end",
  alignItems: "flex-start",
  margin: gutter
})

const HeadingContainer = styled.div({})

const OrgName = styled(HeadingOne)({
  margin: 0,
  marginBottom: 10
})

const MenuTitle = styled.a({
  color: "#000000",
  fontFamily: "Poppins",
  fontSize: "12px",
  lineHeight: "16px",
  paddingBottom: 0,
  fontWeight: "normal",
  cursor: "pointer"
})

const MenuContainer = styled.div({
  display: "flex",
  width: "110px",
  justifyContent: "flex-end",
  alignItems: "center",
  paddingRight: "0px"
})

const Item = styled.li<{ styles? }>((props) => ({
  display: "grid",
  gridTemplateColumns: "1.5em 1fr",
  alignItems: "center",
  padding: "10px 20px",
  borderBottom: "1px solid #E3E2E2",
  color: colours.blue,
  fontFamily: "Poppins",
  fontSize: "14px",
  fontWeight: "600",
  lineHeight: "23px",
  listStyleType: "none",
  ...props.styles,
  ":hover": {
    color: colours.white,
    backgroundColor: colours.blue
  }
}))

const MenuItem = ({
  to,
  text
}: {
  to: () => unknown
  text: React.ReactNode
}) => {
  const handleClick = (event) => {
    event.preventDefault()
    to()
  }

  return (
    <a
      href=""
      onClick={handleClick}
      style={{
        textDecoration: "none"
      }}
    >
      <Item>
        <span style={{ gridColumn: 2 }}>{text}</span>
      </Item>
    </a>
  )
}

type MenuProps = {
  slug: string
  dispatch: DispatchFunction
}

type MenuState = {
  open: boolean
  anchorEl?: HTMLElement
}

class OrgAdminMenu extends React.Component<MenuProps, MenuState> {
  constructor(props) {
    super(props)

    this.handleClick = this.handleClick.bind(this)
    this.handleRequestClose = this.handleRequestClose.bind(this)

    this.state = {
      open: false,
      anchorEl: null
    }
  }

  handleClick = (event) => {
    event.preventDefault()

    this.setState({
      open: true,
      anchorEl: event.currentTarget
    })
  }

  handleRequestClose = () => {
    this.setState({
      open: false
    })
  }

  render() {
    const { slug, dispatch } = this.props
    const { open, anchorEl } = this.state

    return (
      <MenuContainer>
        <MenuTitle onClick={this.handleClick}>Admin</MenuTitle>
        <Popover
          open={open}
          anchorEl={anchorEl}
          handleRequestClose={this.handleRequestClose}
        >
          <MenuItem
            to={() =>
              dispatch({ type: "ORG_ARCHIVE_RIPPLES", payload: { slug } })
            }
            text="Archive ripples"
          />
        </Popover>
      </MenuContainer>
    )
  }
}

const OrgManagement = ({
  stats,
  name,
  slug,
  dispatch,
  showAdminButton
}: Props) => (
  <Container>
    <MainContents>
      <HeadingContainer>
        <OrgName>{name}</OrgName>
      </HeadingContainer>
      <HeadingContainer>
        <StatsGroup stats={stats} headingMode />
      </HeadingContainer>
    </MainContents>
    <SideContents>
      {showAdminButton && <OrgAdminMenu slug={slug} dispatch={dispatch} />}
    </SideContents>
  </Container>
)

const testing = process.env.RAILS_ENV === "test"
const developing = process.env.RAILS_ENV === "development"

const showAdminButton = (state) => {
  const email = get(state, "me.data.user.email")
  return (
    includes(settings.users.canSeeOrgAdminButton, email) ||
    testing ||
    developing
  )
}

const mapStateToProps = (state) => {
  return {
    stats: get(state, "org.stats"),
    name: get(state, "org.name"),
    slug: get(state, "location.payload.slug"),
    showAdminButton: showAdminButton(state)
  }
}

const mapDispatchToProps = (dispatch) => ({ dispatch })

export default connect(mapStateToProps, mapDispatchToProps)(OrgManagement)
