import React from "react"
import { connect } from "react-redux"
import withQuery from "with-query"
import type {
  State,
  Report as ReportType,
  ReportApiResult
} from "../state_types"
import LoadablePage from "../components/LoadablePage"
import Theme from "../themes/Silver"
import Header from "../components/Header"
import LayoutWithHeader from "../components/LayoutWithHeader"
import RiskReport from "../components/reports/RiskReport"
import TopReport from "../components/reports/TopReport"
import HeatmapReport from "../components/reports/HeatmapReport"
import CompletionRatesReport from "../components/reports/CompletionRatesReport"

type LoadableNewDemoPageProps = {
  loaded: boolean
  report: ReportType
  reportId: string
  reportUrl: string
  parentUrl: string
}

const reports = {
  risk: RiskReport,
  top: TopReport,
  heatmap: HeatmapReport,
  completion_rates: CompletionRatesReport
}

const ReportPage = ({
  loaded,
  report,
  reportId,
  reportUrl,
  parentUrl
}: LoadableNewDemoPageProps) => (
  <Theme>
    <LayoutWithHeader
      header={
        <div>
          <Header />
        </div>
      }
    >
      <LoadablePage loaded={loaded}>
        {() =>
          React.createElement(reports[report.reportType], {
            key: reportId,
            title: report.title,
            subtitle: report.subtitle,
            reportId,
            reportData: report.reportData,
            filters: report.filters,
            reportUrl,
            returnTo: { to: parentUrl, name: "Reports" }
          })
        }
      </LoadablePage>
    </LayoutWithHeader>
  </Theme>
)

const mapStateToProps = (state: State) => {
  const report: ReportApiResult = state?.report
  const location = state.location || {}
  const query = location.query || {}

  return {
    loaded: !report?.syncing,
    report: report?.data,
    reportId: report?.reportId,
    reportUrl: withQuery(location.pathname, location.search),
    parentUrl: withQuery(
      "/reports",
      query && {
        framework: query.framework,
        organisation: query.organisation,
        group: query.group
      }
    )
  }
}

const mapDispatchToProps = () => {
  return {}
}

export default connect(mapStateToProps, mapDispatchToProps)(ReportPage)
