import React, { Component } from "react"
import { connect } from "react-redux"

import { submitUpdateOtp } from "../api_actions"
import LayoutWithHeader from "../components/LayoutWithHeader"
import LoginLayout from "../layouts/LoginLayout"
import Header from "../components/Header"
import Theme from "../themes/Silver"
import ConfirmPassword from "../components/login_steps/ConfirmPassword"
import DownloadMFAApp from "../components/login_steps/DownloadMFAApp"
import ScanQRCode from "../components/login_steps/ScanQRCode"
import EnterCodeNormal from "../components/login_steps/EnterCodeNormal"
import EnterCodeSetup from "../components/login_steps/EnterCodeSetup"
import type { Errors } from "../state_types"

type SubmitLoginFun = (
  userName: string,
  password: string,
  otpCode: string
) => unknown

type Props = {
  onSubmit: SubmitLoginFun
  errors?: Errors
  syncing?: boolean
  loginStep: string
  mfaSetupUrl?: string
  onNoPass: () => unknown
  mfaEnabled?: boolean
}

type State = {
  password: string
  otpCode: string
  otpCodeForUnconfirmedSecret: string
  otpSetupStep: string
}

class UpdateOTPPage extends Component<Props, State> {
  state = {
    password: "",
    otpCode: "",
    otpCodeForUnconfirmedSecret: "",
    otpSetupStep: "download_authenticator"
  }

  static getDerivedStateFromProps(props: Props, state: State): State {
    if (props.loginStep === "enter_password") {
      // if you were sent to the beginning, be at the beginning
      return {
        ...state,
        otpCode: "", // without this the controller will be confused where in the process you are
        otpCodeForUnconfirmedSecret: "",
        otpSetupStep: "download_authenticator" // be at the first step of setup if you subsequently have to do setup
      }
    }

    // if you've skipped past the beginning (e.g. by reloading the page)
    // be sent to the beginning
    if (!state.password) {
      props.onNoPass()
    }

    return state
  }

  updateValueFn = (key: string) => {
    return (value: string) => {
      this.setState({ [key]: value } as Pick<State, keyof State>)
    }
  }

  updateToValueFn = (key: string, value: string) => {
    return () => {
      this.setState({ [key]: value } as Pick<State, keyof State>)
    }
  }

  handleSubmit = () => {
    this.props.onSubmit(
      this.state.password,
      this.state.otpCode,
      this.state.otpCodeForUnconfirmedSecret
    )
  }

  render() {
    const { loginStep, errors, syncing, mfaSetupUrl } = this.props
    const { password, otpCode, otpCodeForUnconfirmedSecret, otpSetupStep } =
      this.state

    return (
      <Theme>
        <LayoutWithHeader
          header={
            <div>
              <Header />
            </div>
          }
        >
          <LoginLayout hideLogo>
            {loginStep === "enter_password" && (
              <ConfirmPassword
                onChange={this.updateValueFn("password")}
                submit={this.handleSubmit}
                errors={errors}
                syncing={syncing}
                password={password}
              />
            )}

            {loginStep === "enter_otp" && (
              <EnterCodeNormal
                onChange={this.updateValueFn("otpCode")}
                submit={this.handleSubmit}
                errors={errors}
                syncing={syncing}
                otpCode={otpCode}
                submitLabel="Continue"
                heading="Please confirm your one-time password before continuing"
              />
            )}

            {loginStep === "setup_otp" &&
              otpSetupStep === "download_authenticator" && (
                <DownloadMFAApp
                  hideFirstTimeInstructions
                  submit={this.updateToValueFn(
                    "otpSetupStep",
                    "setup_authenticator"
                  )}
                />
              )}
            {loginStep === "setup_otp" &&
              otpSetupStep === "setup_authenticator" && (
                <ScanQRCode
                  submit={this.updateToValueFn("otpSetupStep", "confirm_code")}
                  back={this.updateToValueFn(
                    "otpSetupStep",
                    "download_authenticator"
                  )}
                  mfaSetupUrl={mfaSetupUrl}
                />
              )}
            {loginStep === "setup_otp" && otpSetupStep === "confirm_code" && (
              <EnterCodeSetup
                onChange={this.updateValueFn("otpCodeForUnconfirmedSecret")}
                back={this.updateToValueFn(
                  "otpSetupStep",
                  "setup_authenticator"
                )}
                submit={this.handleSubmit}
                errors={errors}
                syncing={syncing}
                otpCode={otpCodeForUnconfirmedSecret}
              />
            )}
          </LoginLayout>
        </LayoutWithHeader>
      </Theme>
    )
  }
}

const mapStateToProps = (state) => {
  const me = state.me || {}

  return {
    errors: me.errors,
    syncing: !!me.syncing,
    loginStep: me.loginStep,
    mfaSetupUrl: me.mfaSetupUrl,
    mfaEnabled: !!me.mfaEnabled
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    onSubmit: (
      password: string,
      otpCode: string,
      otpCodeForUnconfirmedSecret: string
    ) =>
      // eslint-disable-next-line @typescript-eslint/no-unsafe-return
      dispatch(submitUpdateOtp(password, otpCode, otpCodeForUnconfirmedSecret)),

    onNoPass: () => dispatch({ type: "UPDATE_OTP" })
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(UpdateOTPPage)
