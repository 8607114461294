import {
  fetchNewRippleForm,
  fetchMyRippleList,
  fetchArchivedRippleList,
  fetchRipple,
  fetchRipplesForQrManagement,
  fetchUserInvite,
  fetchOrg,
  fetchOrgUsers,
  fetchOrgFrameworks,
  fetchImportRipplesForm,
  fetchReport,
  fetchReportIndex,
  fetchSubReport
} from "./api_actions"
import {
  requiresLoggedOut,
  requiresLoggedIn,
  goToUserHome,
  decorateRoutesWithHelp
} from "./route_helpers"

const routes = {
  HOME: { path: "/", thunk: requiresLoggedOut({ otherwise: goToUserHome() }) },
  STYLEBOARD: "/styleboard",
  ASSESS: { path: "/assess/:token", hideHelp: true },
  NEW_RIPPLE_STEP: {
    path: "/ripples/new/:step?",
    hideHelp: true,
    thunk: requiresLoggedIn({ then: fetchNewRippleForm })
  },
  NEW_RIPPLE_STEP_FOR_CLIENT: {
    path: "/client/:clientId/ripples/new/:step?",
    hideHelp: true,
    thunk: requiresLoggedIn({ then: fetchNewRippleForm })
  },
  IMPORT_RIPPLES_STEP: {
    path: "/ripples/import/:step?",
    hideHelp: true,
    thunk: requiresLoggedIn({ then: fetchImportRipplesForm })
  },
  RIPPLES: {
    path: "/ripples",
    thunk: requiresLoggedIn({ then: fetchMyRippleList })
  },
  ARCHIVED_RIPPLES: {
    path: "/archive",
    thunk: requiresLoggedIn({ then: fetchArchivedRippleList })
  },
  RIPPLE: {
    path: "/ripple/:rippleUuid",
    thunk: requiresLoggedIn({ then: fetchRipple })
  },
  RIPPLE_HEATMAP: {
    path: "/ripple/:rippleUuid/heatmap",
    thunk: requiresLoggedIn({ then: fetchRipple })
  },
  EDIT_RIPPLE_SCHEDULE: {
    path: "/ripple/:rippleUuid/edit/schedule",
    thunk: requiresLoggedIn({ then: fetchRipple })
  },
  EDIT_RIPPLE_ASSESSMENT: {
    path: "/ripple/:rippleUuid/edit/framework",
    thunk: requiresLoggedIn({ then: fetchRipple })
  },
  EDIT_RIPPLE_MEMBERS: {
    path: "/ripple/:rippleUuid/edit/members",
    thunk: requiresLoggedIn({ then: fetchRipple })
  },
  EDIT_RIPPLE_CLIENT: {
    path: "/ripple/:rippleUuid/edit/client_details",
    thunk: requiresLoggedIn({ then: fetchRipple })
  },
  ORG: {
    path: "/org/:slug",
    thunk: fetchOrg({ then: fetchOrgUsers })
  },
  ORG_ARCHIVE_RIPPLES: {
    path: "/org/:slug/archive",
    thunk: fetchOrg({ then: fetchOrgFrameworks })
  },
  EDIT_QR_CODES: {
    path: "/qr_codes/edit",
    thunk: requiresLoggedIn({ then: fetchRipplesForQrManagement })
  },
  LOGOUT: { path: "/logout" },
  LOGIN: "/login",
  LOGIN_FAILED: "/login",
  LOGIN_SETUP_OTP: "/login",
  LOGIN_ENTER_OTP: "/login",
  MANAGE_MFA: { path: "/users/mfa", thunk: requiresLoggedIn() },
  DISABLE_OTP: { path: "/users/mfa/otp/remove", thunk: requiresLoggedIn() },
  DISABLE_OTP_ENTER_OTP: {
    path: "/users/mfa/otp/remove",
    thunk: requiresLoggedIn()
  },
  DISABLE_OTP_FAILED: {
    path: "/users/mfa/otp/remove",
    thunk: requiresLoggedIn()
  },
  UPDATE_OTP: { path: "/users/mfa/otp/edit", thunk: requiresLoggedIn() },
  UPDATE_OTP_FAILED: { path: "/users/mfa/otp/edit", thunk: requiresLoggedIn() },
  UPDATE_OTP_SETUP_OTP: {
    path: "/users/mfa/otp/edit",
    thunk: requiresLoggedIn()
  },
  UPDATE_OTP_ENTER_OTP: {
    path: "/users/mfa/otp/edit",
    thunk: requiresLoggedIn()
  },
  DISPLAY_BACKUP_CODES: {
    path: "/users/mfa/backup_codes",
    thunk: requiresLoggedIn()
  },
  CREATE_BACKUP_CODES_ENTER_OTP: {
    path: "/users/mfa/backup_codes/new",
    thunk: requiresLoggedIn()
  },
  CREATE_BACKUP_CODES_FAILED: {
    path: "/users/mfa/backup_codes/new",
    thunk: requiresLoggedIn()
  },
  CREATE_BACKUP_CODES: {
    path: "/users/mfa/backup_codes/new",
    thunk: requiresLoggedIn()
  },
  REMOVE_BACKUP_CODES_ENTER_OTP: {
    path: "/users/mfa/backup_codes/remove",
    thunk: requiresLoggedIn()
  },
  REMOVE_BACKUP_CODES_FAILED: {
    path: "/users/mfa/backup_codes/remove",
    thunk: requiresLoggedIn()
  },
  REMOVE_BACKUP_CODES: {
    path: "/users/mfa/backup_codes/remove",
    thunk: requiresLoggedIn()
  },
  USER_INVITE: { path: "/user_invite/:token", thunk: fetchUserInvite },
  ACCEPT_USER_INVITE_SETUP_OTP: "/user_invite/:token",
  USER_EDIT: {
    path: "/users/edit",
    thunk: requiresLoggedIn()
  },
  USER_PASSWORD: {
    path: "/users/password/edit",
    thunk: requiresLoggedIn()
  },
  FORGOT_PASSWORD: "/forgot_password",
  FORGOT_PASSWORD_SENT: "/forgot_password_sent",
  NEW_PASSWORD: "/new_password",
  RIPPLE_ASSESSMENT_REPORT: {
    path: "/ripple/:rippleUuid/assessments/:assessmentNumber",
    thunk: fetchRipple
  },
  RIPPLE_SECTION_REPORT: {
    path: "/ripple/:rippleUuid/assessments/:assessmentNumber/:sectionId",
    thunk: fetchRipple
  },
  RIPPLE_QUESTION_REPORT: {
    path: "/ripple/:rippleUuid/assessments/:assessmentNumber/:sectionId/:questionId",
    thunk: fetchRipple
  },
  REPORT_INDEX: {
    path: "/reports",
    thunk: requiresLoggedIn({ then: fetchReportIndex })
  },
  REPORT: {
    path: "/reports/:reportId",
    thunk: requiresLoggedIn({ then: fetchReport })
  },
  SUB_REPORT: {
    path: "/reports/:reportId/:subReportId",
    thunk: requiresLoggedIn({ then: fetchSubReport })
  },
  ACCESS_DENIED: { path: "/access_denied" },
  CLIENT_OUTDATED: { path: "/client_outdated" }
}

export default decorateRoutesWithHelp(routes)
// export default routes
