import React from "react"
import { connect } from "react-redux"
import styled from "@emotion/styled"
import Theme from "../themes/White"
import { submitEditUserForm } from "../api_actions"
import UserEditForm from "../components/UserEditForm"
import type { User, State, DispatchFunction } from "../state_types"

const EditPageContainer = styled.div({
  display: "flex",
  flexDirection: "column",
  flexGrow: 1
})

type Props = {
  user: User
  submitEditUserForm: () => any
  onSubmit: DispatchFunction
}

class UserEditPage extends React.Component<Props> {
  handleSubmit = () => {
    const {
      user: { email }
    } = this.props
    this.props.onSubmit(email)
  }

  renderPageBody() {
    return (
      <EditPageContainer>
        <UserEditForm onSubmit={this.handleSubmit} />
      </EditPageContainer>
    )
  }

  render() {
    return <Theme>{this.renderPageBody()}</Theme>
  }
}

const mapStateToProps = (state: State) => {
  const me = state.me || {}
  const loggedIn = me.data && me.data.token
  const user = loggedIn && me.data ? me.data.user : null
  return {
    user
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    onSubmit: (userEmail: string) => {
      dispatch(submitEditUserForm(userEmail))
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(UserEditPage)
