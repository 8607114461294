import get from "lodash.get"
import immutable from "object-path-immutable"
import { applyReducerHash } from "../../redux_helpers"
import type { RippleDetailApiResult, Action } from "../../state_types"

const reducers = {
  RECEIVED_RIPPLE: (
    state: RippleDetailApiResult | null | undefined,
    action: Action
  ) => {
    return action.payload
  },
  RIPPLE: (state: RippleDetailApiResult | null | undefined, action: Action) => {
    const stateRippleUuid = get(state, "data.ripple.uuid")
    if (stateRippleUuid === action.payload.rippleUuid) {
      return state
    }

    return { syncing: "receiving" }
  },
  SUBMITTED_RIPPLE_FORM: (
    _state: RippleDetailApiResult | null | undefined,
    _action: Action
  ) => {
    return null
  },
  SUBMITTED_RIPPLE_COMMENT: (
    state: RippleDetailApiResult | null | undefined,
    action: Action
  ) => {
    const payloadFeed = action.payload.feedItem

    const stateRippleUuid = get(state, "data.ripple.uuid")
    if (stateRippleUuid === action.payload.rippleUuid) {
      return immutable.insert(state, ["data", "ripple", "feed"], payloadFeed, 0)
    }

    return state
  },
  SUBMITTING_EDIT_RIPPLE_FORM: (state?: RippleDetailApiResult) => {
    return { ...state, syncing: "sending" }
  },
  SUBMITTED_EDIT_RIPPLE_FORM: (
    state: RippleDetailApiResult | null | undefined,
    _action: Action
  ) => {
    return immutable.set(state, "changed", true)
  },
  CLOSING_RIPPLE_ASSESSMENT: (
    state: RippleDetailApiResult | null | undefined,
    action: Action
  ) => {
    const ripple = get(state, "data.ripple")
    const { rippleUuid } = action.payload

    if (ripple.uuid === rippleUuid && ripple.nextAssessment) {
      return immutable.set(
        state,
        ["data", "ripple", "nextAssessment", "closing"],
        true
      )
    }

    return state
  }
}

export default function rippleDetailsReducer(
  state: RippleDetailApiResult = null,
  action: Action
): RippleDetailApiResult | null | undefined {
  return applyReducerHash(reducers, state, action)
}
