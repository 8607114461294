import React from "react"
import { Field } from "redux-form"
import styled from "@emotion/styled"
import moment from "moment"
import DatePickerField from "./DatePickerField"
import StringSelectField from "./StringSelectField"
import TimeSelectField from "./TimeSelectField"
import ToggleWeekdaysField from "./ToggleWeekdaysField"

type Props = {
  startOn: string
  periodType: string
  edit?: boolean
}

const FormContainer = styled.div({
  display: "flex",
  flexDirection: "column",
  alignItems: "center"
})

const FieldsContainer = styled.div({
  display: "flex",
  flex: 0.8,
  flexDirection: "column",
  justifyContent: "flex-start"
})

const FieldContainer = styled.div({
  minHeight: "4.5em",
  display: "flex",
  justifyContent: "flex-start",
  width: "365px",
  "@media(max-width: 475px)": {
    width: "100%"
  }
})

const FieldHeader = styled.div({
  color: "#4A4A4A",
  fontFamily: "Poppins",
  fontSize: "12px",
  lineHeight: "17px",
  paddingBottom: "40px"
})

const pluralisedDays = [
  "Sundays",
  "Mondays",
  "Tuesdays",
  "Wednesdays",
  "Thursdays",
  "Fridays",
  "Saturdays"
]

function dayOfTheWeek(date) {
  const day = moment(date).day()
  return pluralisedDays[day]
}

function selectedDay(date) {
  if (!date) return "Weekly"

  const day = dayOfTheWeek(date)
  return `Weekly ${day}`
}

function getPeriodTypeOptions(periodType) {
  const weekOptions = [
    [1, "Week"],
    [2, "2 Weeks"],
    [3, "3 Weeks"],
    [4, "4 Weeks"]
  ]
  const monthOptions = [
    [1, "Month"],
    [2, "2 Months"],
    [3, "3 Months"],
    [4, "4 Months"],
    [5, "5 Months"],
    [6, "6 Months"]
  ]

  if (periodType === "week") {
    return weekOptions
  }
  if (periodType === "month") {
    return monthOptions
  }
  return []
}

const RippleScheduleForm = ({ startOn, periodType, edit }: Props) => {
  const today = moment().toDate()
  const startOnDate = moment(startOn).toDate()
  const oneYearFromTodaysDate = moment().add(1, "years").toDate()

  return (
    <FormContainer>
      <FieldHeader>
        Ripple participants will recieve an e-mail at the beginning of each
        assessment period
      </FieldHeader>
      <FieldsContainer>
        <FieldContainer>
          <Field
            floatingLabelText="Starts on"
            name="startOn"
            component={DatePickerField}
            defaultValue={startOn}
            minDate={edit ? startOnDate : today}
            maxDate={oneYearFromTodaysDate}
            fullWidth
          />
        </FieldContainer>
        <FieldContainer>
          <Field
            floatingLabelText="Repeats"
            name="periodType"
            component={StringSelectField}
            options={[
              ["none", "Doesn't repeat"],
              ["month", "Monthly"],
              ["week", selectedDay(startOn)],
              ["day", "Daily"]
            ]}
            value="never"
          />
        </FieldContainer>
        {periodType && periodType !== "none" && periodType !== "day" && (
          <FieldContainer>
            <Field
              floatingLabelText="Every"
              name="period"
              component={StringSelectField}
              options={getPeriodTypeOptions(periodType)}
              value={1}
            />
          </FieldContainer>
        )}
        {periodType === "day" && (
          <React.Fragment>
            <FieldContainer>
              <Field
                floatingLabelText="Starting at"
                name="startHour"
                component={TimeSelectField}
              />
            </FieldContainer>
            <FieldContainer>
              <Field
                floatingLabelText="Ending at"
                name="endHour"
                component={TimeSelectField}
              />
            </FieldContainer>
          </React.Fragment>
        )}
        {periodType !== "day" && (
          <FieldContainer>
            <Field
              floatingLabelText="Assessment period"
              name="daysOpen"
              component={StringSelectField}
              options={[
                [1, "1 day"],
                [2, "2 days"],
                [3, "3 days"],
                [4, "4 days"],
                [5, "5 days"],
                [6, "6 days"],
                [7, "7 days"],
                ["until-complete", "Until complete"]
              ]}
              fullWidth
              value={1}
            />
          </FieldContainer>
        )}
        {periodType === "day" && (
          <FieldContainer>
            <Field
              name="wdays"
              component={ToggleWeekdaysField}
              floatingLabelText="On days"
              faIcon="star"
            />
          </FieldContainer>
        )}
      </FieldsContainer>
    </FormContainer>
  )
}

RippleScheduleForm.defaultProps = {
  edit: false
}

export default RippleScheduleForm
