import React from "react"
import { TextField } from "@material-ui/core"
import {
  AuthForm,
  Header,
  PrimaryActions,
  AlternativeActions,
  Instructions,
  Inputs
} from "../AuthFormStyles"
import AuthErrorMessages from "../AuthErrorMessages"
import SubmitButton from "../SubmitButton"
import type { Errors } from "../../state_types"
import InformationBox from "../InformationBox"

const OtpResetLink = ({ children }: { children: React.ReactNode }) => (
  <a href="mailto:help@ripple.com.au?subject=Two-factor%20authentication%20-%20reset%20required">
    {children}
  </a>
)

type Props = {
  submit: () => void
  onChange: (value: string) => void
  errors?: Errors
  syncing?: boolean
  otpCode?: string
  submitLabel?: React.ReactNode
  heading?: string
}

const EnterCodeNormal = ({
  submit,
  onChange,
  errors,
  syncing,
  otpCode,
  submitLabel,
  heading
}: Props) => {
  const handleChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    onChange(event.target.value)
  }

  const handleSubmitForm = (event: React.SyntheticEvent<HTMLFormElement>) => {
    event.preventDefault()
    submit()
  }

  return (
    <>
      <Header>{heading || "Ripple Login"}</Header>

      <Instructions>
        <p>Your account is two-factor authentication enabled.</p>
        <p>
          Please enter your one-time password below. This can be found in the
          authenticator app you installed when you set this&nbsp;up.
        </p>
      </Instructions>

      <AuthErrorMessages errors={errors} />

      <AuthForm onSubmit={handleSubmitForm}>
        <Inputs>
          <TextField
            id="otpCode"
            label="One-time password (OTP)"
            onChange={handleChange}
            value={otpCode}
          />
        </Inputs>

        <PrimaryActions>
          <SubmitButton disabled={syncing}>
            {submitLabel || "Login"}
          </SubmitButton>
        </PrimaryActions>

        <InformationBox type="warn">
          <p>
            If you don&rsquo;t currently have access to this code, you can use
            any backup codes that you&rsquo;ve generated, or{" "}
            <OtpResetLink>get in touch</OtpResetLink> with Ripple directly.
          </p>
        </InformationBox>
      </AuthForm>

      <AlternativeActions>
        <OtpResetLink>
          I don&rsquo;t have access to my authenticator app or backup codes
        </OtpResetLink>
      </AlternativeActions>
    </>
  )
}

EnterCodeNormal.defaultProps = {
  syncing: false,
  otpCode: ""
}

export default EnterCodeNormal
