import React from "react"
import styled from "@emotion/styled"
import { TextField } from "@material-ui/core"
import DropDownMenu from "./DropDownMenu"
import { CardContainer } from "./CardContainer"

export const FilterContainer = styled.div({
  display: "flex",
  justifyContent: "flex-start",
  alignItems: "start",
  margin: "0 10px 25px",
  height: "56px"
})

const FilterLabel = styled.span({
  fontFamily: "Poppins",
  fontSize: "14px",
  fontWeight: 600,
  lineHeight: "56px",
  display: "inline-block"
})

const FilterGridInnerContainer = styled.div({
  display: "flex",
  flexDirection: "row",
  flexGrow: 1,
  flexWrap: "wrap"
})

const OuterContainer = styled.div({
  display: "flex",
  flexGrow: 0,
  flexDirection: "row",
  justifyContent: "center"
})

export interface DropDownFilterProps {
  onChange: (string) => any
  label: string
  value: string
  items: Array<{
    value: string
    text: string
  }>
  className?: string
  disabled?: boolean
}

export const DropDownFilter = ({
  label,
  value,
  items,
  onChange,
  className,
  disabled
}: DropDownFilterProps) => {
  return (
    <FilterContainer>
      <FilterLabel>{label}:</FilterLabel>
      <DropDownMenu
        value={value}
        onChange={(event) => onChange(event.target.value)}
        items={items}
        className={className}
        disabled={disabled}
      />
    </FilterContainer>
  )
}

type FilterSearchProps = {
  value: string
  onChange: (newValue: string) => void
  disabled?: boolean
  inputProps?: {}
}

type FilterSearchState = {
  value: string
}

export class SearchFilter extends React.Component<
  FilterSearchProps,
  FilterSearchState
> {
  constructor(props: FilterSearchProps) {
    super(props)

    this.handleChange = this.handleChange.bind(this)
  }

  handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.props.onChange(event.target.value)
  }

  render() {
    const { disabled, inputProps } = this.props

    const value = this.props.value || ""

    return (
      <FilterContainer>
        <FilterLabel>Search:</FilterLabel>
        <TextField
          name="search"
          placeholder="Start typing..."
          style={{ marginLeft: "24px", marginTop: "12px", width: "150px" }}
          value={value}
          onChange={this.handleChange}
          disabled={disabled}
          inputProps={inputProps}
        />
      </FilterContainer>
    )
  }
}

type FilterGridContainerProps = {
  children
}

export const FilterGridContainer = ({ children }: FilterGridContainerProps) => (
  <OuterContainer>
    <CardContainer>
      <FilterGridInnerContainer className="filter-grid">
        {children}
      </FilterGridInnerContainer>
    </CardContainer>
  </OuterContainer>
)
