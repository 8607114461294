import React from "react"
import styled from "@emotion/styled"
import { ThemeProvider } from "emotion-theming"
import map from "lodash.map"
import colours from "./colours"
import ColouredBackground from "../components/ColouredBackground"
import underConstruction from "../images/under_construction.png"

const theme = {}

const yellow = "#FFEAA0"
const grey = "#DDDDDD"
const thickness = 20

export const ContentContainer = styled.div({
  paddingTop: "5%",
  maxWidth: 600,
  marginLeft: "auto",
  marginRight: "auto"
})

const CustomBackground = styled.div({
  height: "100%",
  background: `repeating-linear-gradient(45deg, ${yellow}, ${yellow} ${thickness}px, ${grey} ${thickness}px, ${grey} ${
    thickness * 2
  }px)`
})

const ConstructionLogo = styled.img({
  opacity: 0.2,
  position: "fixed",
  right: 20,
  bottom: 20
})

const DataDumpContainer = styled.div({
  marginLeft: 20,
  marginBottom: "1em",
  fontSize: "80%"
})

const DataRow = styled.div({
  marginBottom: "0.5em"
})

const DataLabel = styled.div({
  fontWeight: "bold"
})

export const DataDump = ({
  data
}: {
  data: Array<{ value: React.ReactNode; key: React.Key }>
}) => {
  return (
    <DataDumpContainer>
      {map(data, (value: React.ReactNode, key) => {
        return (
          <DataRow key={key}>
            <DataLabel>{key}:</DataLabel>
            <div>{value}</div>
          </DataRow>
        )
      })}
    </DataDumpContainer>
  )
}

type Props = {
  children: React.ReactNode
}

const Background = ({ children }: Props) => {
  return (
    <ThemeProvider theme={theme}>
      <ColouredBackground colour={colours.silver}>
        <CustomBackground>
          {children}
          <ConstructionLogo src={underConstruction} alt="Under construction" />
        </CustomBackground>
      </ColouredBackground>
    </ThemeProvider>
  )
}

export default Background
