import React, { ReactNode } from "react"
import styled from "@emotion/styled"

const Container = styled.div({
  display: "flex",
  flexDirection: "column",
  justifyContent: "flex-start"
})

const Header = styled.div({
  display: "flex",
  color: "#000000",
  fontFamily: "Poppins",
  fontSize: "14px",
  fontWeight: 600,
  lineHeight: "22px",
  paddingBottom: "15px",
  borderBottom: "1px solid #979797",
  justifyContent: "space-between"
})

const Body = styled.div({
  padding: "20px 0 0",
  fontFamily: "Poppins",
  fontSize: "12x",
  lineHeight: "16px"
})

interface IProps {
  headerContent: ReactNode
  bodyContent: ReactNode
}

const ContentContainer = ({ headerContent, bodyContent }: IProps) => {
  return (
    <Container>
      <Header>{headerContent}</Header>
      <Body>{bodyContent}</Body>
    </Container>
  )
}

export default ContentContainer
