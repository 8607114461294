export interface IOrgRole {
  description: string
  name: string
}

export const orgRoles: Array<IOrgRole> = [
  { description: "Org Admin", name: "admin" },
  { description: "Observer", name: "observer" },
  { description: "Ripple Creator", name: "ripple_creator" },
  { description: "Ripple Editor", name: "ripple_editor" },
  { description: "Client", name: "client" }
]
