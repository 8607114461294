import React from "react"
import type { ReactNode } from "react"
import styled from "@emotion/styled"
import colours from "../themes/colours"

type OnCompleteFunc = () => void

type LinkProps = {
  children: ReactNode
  onClick: (onComplete: OnCompleteFunc) => void
}

type LinkState = {
  loading: boolean
}

const Anchor = styled.a({
  textDecoration: "none",
  color: colours.blue,
  cursor: "pointer"
})

const DisabledAnchor = styled.a<React.AnchorHTMLAttributes<HTMLAnchorElement>>({
  textDecoration: "none",
  color: colours.grey,
  marginRight: 10
})

export default class LoadingLink extends React.Component<LinkProps, LinkState> {
  state = { loading: false }

  render() {
    const { loading } = this.state
    const { children, onClick } = this.props

    if (loading) {
      return (
        <span>
          <DisabledAnchor>{children}</DisabledAnchor>
        </span>
      )
    }

    return (
      <Anchor
        onClick={() => {
          this.setLoading()
          onClick(() => this.setNotLoading())
        }}
      >
        {children}
      </Anchor>
    )
  }

  setLoading() {
    this.setState({ loading: true })
  }

  setNotLoading() {
    this.setState({ loading: false })
  }
}
