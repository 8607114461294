import React from "react"
import Vec2 from "vec2"
import Draggable from "./Draggable"
import DragHandleCircle from "./DragHandleCircle"
import { Vector2 } from "./types"
import { getArcBound, getXOnArc, normalizeValue } from "./arc_calculations"

type Props = {
  centre: Vector2
  radius: number
  handleRadius: number
  svgWidth: number
  arcAngleDegrees: number
  value: number
  outOf: number
  onChange: (value: number) => void
  atTop: boolean
}

const xOffsetFromCentre = (svgPos, svgWidth) => {
  return svgPos.x - svgWidth / 2
}

const clampOrd = (x, bound) => Math.max(Math.min(x, bound), -bound)

class ArcDraggable extends React.Component<Props> {
  onChange = (svgPos: Vector2) => {
    const bound = this.getBound()
    const xOffset = xOffsetFromCentre(svgPos, this.props.svgWidth)
    const clampedOffset = clampOrd(xOffset, bound)

    const rating = this.getRating(clampedOffset, bound, this.props.outOf)
    this.props.onChange(rating)
  }

  getBound = () => {
    return getArcBound(this.props.arcAngleDegrees, this.props.radius)
  }

  getArcY(xFromCentre: number) {
    const { radius, atTop } = this.props
    const distance = Math.sqrt(radius * radius - xFromCentre * xFromCentre)

    return atTop ? distance : -distance
  }

  getRating = (clampedOffset: number, bound: number, outOf: number) => {
    const zeroToBound = (clampedOffset + bound) / 2
    const zeroToRating = (zeroToBound / bound) * outOf
    return zeroToRating
  }

  getHandlePos() {
    const { centre, value, outOf } = this.props

    const normalisedValue = normalizeValue(value, outOf)
    const boundedValue = getXOnArc(normalisedValue, this.getBound())

    const handleFromCentre = Vec2(boundedValue, -this.getArcY(boundedValue))
    return handleFromCentre.add(centre, false)
  }

  render() {
    return (
      <Draggable onChange={this.onChange}>
        <DragHandleCircle
          radius={this.props.handleRadius}
          centre={this.getHandlePos()}
        />
      </Draggable>
    )
  }
}

export default ArcDraggable
