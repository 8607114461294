import React from "react"
import styled from "@emotion/styled"
import pluralize from "pluralize"
import Link from "redux-first-router-link"
import { capitalize } from "underscore.string"
import map from "lodash.map"
import reduce from "lodash.reduce"
import flatmap from "lodash.flatmap"
import includes from "lodash.includes"
import FontAwesome from "react-fontawesome"
import colours from "../themes/colours"
import { Ripple } from "../state_types"
import AvatarPreview from "./AvatarPreview"
import ContentContainer from "./RippleInfoContentContainer"

const UsersList = styled.div()

const UserDetails = styled.div({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  paddingBottom: "20px",
  ":last-child": {
    paddingBottom: "0"
  }
})

const Header = styled.div({
  display: "flex",
  justifyContent: "space-between",
  width: "100%"
})

const Name = styled.div({
  color: "#000000",
  fontFamily: "Poppins",
  fontSize: "16px",
  lineHeight: "23px",
  fontWeight: 500,
  textTransform: "uppercase"
})

const Role = styled.div({
  color: "#000000",
  fontFamily: "Poppins",
  fontSize: "12px",
  lineHeight: "16px"
})

const State = styled.div({
  color: "#6C727C",
  fontFamily: "Poppins",
  fontSize: "12px",
  lineHeight: "16px"
})

const NameContainer = styled.div({
  display: "flex",
  flexDirection: "column",
  paddingLeft: "15px"
})

const NameAvatar = styled.div<{ role }>((props) => {
  const isAdmin = props.role === "admin"

  return {
    display: "flex",
    alignItems: "center",
    paddingLeft: isAdmin ? "" : "24px",
    position: "relative"
  }
})

type User = {
  name?: string
  role?: string
  pending: boolean
}

type Props = {
  users: Array<User>
  ripple: Ripple
}

type MemberBodyProps = {
  users: Array<User>
}

function userRoleBreakdown(users) {
  // if multiple roles we will calculate duplicates
  const roles = flatmap(users, "roles")

  const roleCount = reduce(
    roles,
    (accumulator, role) => {
      return { ...accumulator, [role]: (accumulator[role] || 0) + 1 }
    },
    {}
  )

  delete roleCount.null

  return roleCount
}

function stringifyUserRoles(roleCount) {
  const roleDetails = Object.keys(roleCount)
    .sort()
    .map((role) => {
      const count = roleCount[role]
      return pluralize(capitalize(role), count, true)
    })

  return roleDetails.join(", ")
}

const MemberHeader = ({ users, ripple }: Props) => {
  const roleCount = userRoleBreakdown(users)

  return (
    <Header>
      {stringifyUserRoles(roleCount)}
      {ripple.canEdit ? (
        <Link
          to={{
            type: "EDIT_RIPPLE_MEMBERS",
            payload: { rippleUuid: ripple.uuid }
          }}
          style={{
            textDecoration: "none",
            color: colours.blue,
            cursor: "pointer"
          }}
        >
          edit
        </Link>
      ) : null}
    </Header>
  )
}

const MemberBody = ({ users }: MemberBodyProps) => {
  return (
    <UsersList>
      {map(users, (user) => (
        <UserDetails key={user.email}>
          <NameAvatar role={user.role}>
            {includes(user.roles, "admin") && (
              <FontAwesome
                name="star"
                style={{
                  color: colours.green,
                  fontSize: "1em",
                  paddingRight: "8px",
                  position: "absolute",
                  top: 0,
                  left: 10
                }}
              />
            )}
            <AvatarPreview size={37} value={user.avatar} name={user.name} />
            <NameContainer>
              <Name>{user.name}</Name>
              <Role>{capitalize(user.roles.join(", "))}</Role>
            </NameContainer>
          </NameAvatar>
          {user.pending && <State>Pending</State>}
        </UserDetails>
      ))}
    </UsersList>
  )
}

const MemberDetails = ({ users, ripple }: Props) => {
  return (
    <ContentContainer
      headerContent={<MemberHeader users={users} ripple={ripple} />}
      bodyContent={<MemberBody users={users} />}
    />
  )
}

export default MemberDetails
