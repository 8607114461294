import React from "react"
import { connect } from "react-redux"
import get from "lodash.get"
import styled from "@emotion/styled"
import { SearchFilter, DropDownFilter } from "../Filters"
import { orgRoles, IOrgRole } from "../../constants/org_roles"

export type FiltersType = {
  role: string
  searchTerm: string
  page?: undefined | number
}

type Props = {
  filters: FiltersType
  onChange: (newFilters: FiltersType) => any
}

const FilterContainer = styled.div({
  display: "flex",
  flexDirection: "row",
  flexWrap: "wrap"
})

const Filters = (props: Props) => {
  const { filters, onChange } = props

  const buildChangeHandler = (key: string) => (newValue: string) =>
    onChange({ ...filters, [key]: newValue, page: undefined })

  const roles = {
    label: "Role",
    value: filters && filters.role,
    items: [{ value: "", text: "Any" }].concat(
      orgRoles.map((orgRole: IOrgRole) => ({
        text: orgRole.description,
        value: orgRole.name
      }))
    )
  }

  return (
    <FilterContainer>
      <DropDownFilter {...roles} onChange={buildChangeHandler("role")} />

      <SearchFilter
        inputProps={{ "data-testid": "user-search" }}
        value={filters && filters.searchTerm}
        onChange={buildChangeHandler("searchTerm")}
      />
    </FilterContainer>
  )
}

const mapStateToProps = (state) => ({
  filters: get(state, "org.filters")
})

export default connect(mapStateToProps)(Filters)
