import styled from "@emotion/styled"
import React from "react"
import FontAwesome from "react-fontawesome"
import colours from "../themes/colours"

const PrimaryButton = styled.a<{ disabled?: boolean; secondary?: boolean }>(
  (props) => ({
    textDecoration: "none",
    whiteSpace: "nowrap",
    cursor: props.disabled ? "not-allowed" : "pointer",
    color:
      props.secondary || props.disabled ? colours.deemphasisedText : "inherit"
  })
)

const TextContainer = styled.span({
  paddingLeft: "0.4em",
  paddingRight: "0.4em"
})

type Props = {
  direction: "left" | "right"
  text: string
  secondary?: boolean
  disabled?: boolean
  onClick: () => unknown
}

const Arrow = (direction: string) => (
  <FontAwesome name={`long-arrow-${direction}`} />
)

const clickHandler = (onClick, disabled) => {
  return (event) => {
    event.preventDefault()

    if (!disabled) {
      onClick()
    }
  }
}

const NextButton = ({
  direction,
  text,
  secondary,
  onClick,
  disabled
}: Props) => {
  return (
    <PrimaryButton
      onClick={clickHandler(onClick, disabled)}
      secondary={secondary}
      id={text}
      href="#"
      disabled={disabled}
    >
      {direction === "left" && Arrow(direction)}
      <TextContainer>{text}</TextContainer>
      {direction === "right" && Arrow(direction)}
    </PrimaryButton>
  )
}

NextButton.defaultProps = { secondary: false, disabled: false }

export default NextButton
