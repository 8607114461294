import React from "react"
import styled from "@emotion/styled"
import Markdown from "react-markdown"

import ControlledRadioButtonGroup from "./ControlledRadioButtonGroup"
import colours from "../themes/colours"
import emot0 from "../images/pedsql/emot-0.svg"
import emot1 from "../images/pedsql/emot-1.svg"
import emot2 from "../images/pedsql/emot-2.svg"
import emot3 from "../images/pedsql/emot-3.svg"
import emot4 from "../images/pedsql/emot-4.svg"
import check from "../images/check.svg"
import { mediaQueries } from "./Layout"

const imageSources = {
  "emot-0": emot0,
  "emot-1": emot1,
  "emot-2": emot2,
  "emot-3": emot3,
  "emot-4": emot4
}

const Bookmark = styled.div({
  backgroundColor: colours.badScore,
  opacity: 0.8,
  width: 5,
  position: "absolute",
  height: "90%",
  top: 5,
  left: -20
})

type QuestionTypeProps = {
  questionData
  options
  hasError?: boolean
  updateRating: (rating: number) => unknown
  rating: number | null
  disabled?: boolean
}

const SelectedWrapper = styled.span({
  display: "flex",
  justifyContent: "center"
})

const LabelContainer = styled.label<{ disabled?: boolean; checked?: boolean }>(
  ({ disabled }) => ({
    display: "flex",
    cursor: disabled ? "auto" : "pointer",
    borderRadius: "50px",
    width: "100%"
  }),
  ({ disabled }) => {
    return disabled
      ? {}
      : {
          border: `1px solid ${colours.blue}`,
          backgroundColor: colours.blue,
          color: colours.white,
          fontWeight: "bold",
          cursor: "pointer",
          margin: "5px 0",
          flexGrow: 1,
          ":hover": {
            backgroundColor: colours.darkBlue
          },
          [mediaQueries.desktop]: {
            maxWidth: "280px"
          }
        }
  },
  ({ checked }) => {
    return checked
      ? {
          backgroundColor: colours.white,
          color: colours.blue,
          ":before": {},
          ":hover": {
            backgroundColor: colours.white
          }
        }
      : {}
  }
)

const SelectedIndicator = styled.span<{ checked: boolean }>(
  {
    width: "40px",
    aspectRatio: "1",
    margin: "auto 20px auto 0"
  },
  ({ checked }) => {
    return checked
      ? {
          backgroundRepeat: "no-repeat",
          backgroundImage: `url(${check})`,
          backgroundPosition: "center",
          backgroundColor: colours.blue,
          borderRadius: "50px"
        }
      : {}
  }
)
const ButtonContainer = styled.div<{ disabled: boolean }>(
  {
    display: "flex",
    padding: "4px",
    alignItems: "center"
  },
  ({ disabled }) => {
    return disabled
      ? {}
      : {
          flexGrow: 1,
          p: {
            textAlign: "center",
            width: "100%"
          },
          ":hover": {
            borderColor: colours.blue
          }
        }
  }
)

const ValueContainer = styled.span<{ checked: boolean }>(
  {
    display: "flex",
    width: "25px",
    alignItems: "center",
    justifyContent: "center",
    borderLeft: `1px solid ${colours.white}`,
    padding: "10px"
  },
  ({ checked }) => {
    return checked
      ? {
          borderLeft: `1px solid ${colours.blue}`
        }
      : {}
  }
)

const RadioButton = styled.input({
  cursor: "inherit",
  position: "absolute",
  opacity: 0.0
})

const ButtonGroup = styled.div({
  display: "flex",
  flexDirection: "column"
})

const Checkmark = styled.span<{ checked: boolean; icon: string }>`
  background: ${(props) => `url(${imageSources[props.icon]}) no-repeat bottom`};
  background-size: contain;
  border: 4px solid ${colours.white};
  height: 44px;
  min-width: 44px;
  background-color: ${(props) =>
    props.checked ? colours.lightGreen : colours.white};
  border-radius: 50%;
  margin-right: 1rem;
  &:focus: {
  }
`

const RadioQuestion = ({
  questionData,
  options,
  updateRating,
  hasError,
  rating,
  disabled
}: QuestionTypeProps) => {
  const renderButton = ([value, title, icon], checked, handleChange) => {
    const presentationalValue = 4 - value / 25

    return (
      <SelectedWrapper key={`${questionData.id}-${title}`}>
        {!disabled && <SelectedIndicator checked={checked} />}
        <LabelContainer checked={checked} disabled={disabled}>
          <ButtonContainer disabled={disabled}>
            <RadioButton
              type="radio"
              name={questionData.id}
              value={value}
              onChange={handleChange}
              checked={checked && !disabled}
              disabled={disabled}
            />
            <Checkmark icon={icon} checked={checked && !disabled} />
            <Markdown>{title}</Markdown>
          </ButtonContainer>
          {!disabled && (
            <ValueContainer checked={checked}>
              {presentationalValue}
            </ValueContainer>
          )}
        </LabelContainer>
      </SelectedWrapper>
    )
  }

  return (
    <>
      {hasError && <Bookmark />}
      <ButtonGroup>
        <ControlledRadioButtonGroup
          options={options}
          renderButton={renderButton}
          onChange={updateRating}
          value={rating}
        />
      </ButtonGroup>
    </>
  )
}

export default RadioQuestion
