import immutable from "object-path-immutable"
import get from "lodash.get"
import findIndex from "lodash.findindex"
import { applyReducerHash } from "../../redux_helpers"
import RippleGA from "../../ripple_ga"
import { getRedirectUrlFromQueryValue } from "../../utils/urlHelpers"
import type {
  AuthDetailsApiResult,
  UserFormApiResult,
  Action,
  State
} from "../../state_types"

const reducers = {
  SUBMITTED_LOGIN: (state?: AuthDetailsApiResult) => {
    return { ...state, syncing: "sending", errors: {}, messages: null }
  },

  RECEIVED_CURRENT_USER: (
    state: AuthDetailsApiResult | null | undefined,
    action: Action
  ) => {
    RippleGA.configUserOrganisations(action.payload.data.user)
    return {
      ...state,
      message: null,
      errors: {},
      ...action.payload,
      afterLogin: null,
      afterLoginRedirect: null,
      syncing: false
    }
  },

  LOGIN_ENTER_OTP: (
    state: AuthDetailsApiResult | null | undefined,
    action: Action
  ) => {
    return {
      ...state,
      message: null,
      errors: {},
      ...action.payload,
      loginStep: "enter_otp",
      syncing: false
    }
  },

  LOGIN_SETUP_OTP: (
    state: AuthDetailsApiResult | null | undefined,
    action: Action
  ) => {
    return {
      ...state,
      message: null,
      errors: {},
      ...action.payload,
      loginStep: "setup_otp",
      syncing: false
    }
  },

  LOGIN_FAILED: (
    state: AuthDetailsApiResult | null | undefined,
    action: Action
  ) => {
    return {
      ...state,
      message: null,
      errors: {},
      ...action.payload,
      loginStep: "enter_password",
      syncing: false
    }
  },

  HOME: (state?: AuthDetailsApiResult) => {
    if (get(state, "syncing")) {
      return immutable.set(state, "syncing", null)
    }
    return state
  },

  CLEAR_AFTER_LOGIN: (state: AuthDetailsApiResult | null | undefined) => {
    return { ...state, afterLogin: null }
  },

  LOGIN: (state: AuthDetailsApiResult | null | undefined, action: Action) => {
    // this value is the value set in config/initializers/active_admin.rb's before_action.
    const afterLoginRedirect = getRedirectUrlFromQueryValue("then")
    // we only want to set it if it was set in the query string
    const afterLoginRedirectWithKey = afterLoginRedirect
      ? { afterLoginRedirect }
      : {}

    return {
      ...state,
      message: null,
      errors: {},
      ...action.payload,
      ...afterLoginRedirectWithKey,
      loginStep: "enter_password",
      syncing: false
    }
  },

  MANAGE_MFA: (
    state: AuthDetailsApiResult | null | undefined,
    action: Action
  ) => {
    let updatedState = state

    if ("mfaRequired" in action.payload) {
      updatedState = immutable.set(
        state,
        ["data", "user", "mfaRequired"],
        action.payload.mfaRequired
      )
    }
    if ("mfaEnabled" in action.payload) {
      updatedState = immutable.set(
        state,
        ["data", "user", "mfaEnabled"],
        action.payload.mfaEnabled
      )
    }

    if ("backupCodesGenerated" in action.payload) {
      updatedState = immutable.set(
        state,
        ["data", "user", "backupCodesGenerated"],
        action.payload.backupCodesGenerated
      )
    }

    return {
      ...updatedState,
      message: null,
      errors: {},
      ...action.payload,
      syncing: false,
      backupCodes: null
    }
  },

  SUBMITTED_UPDATE_OTP: (state?: AuthDetailsApiResult) => {
    return { ...state, syncing: "sending", errors: {}, messages: null }
  },

  UPDATE_OTP_ENTER_OTP: (
    state: AuthDetailsApiResult | null | undefined,
    action: Action
  ) => {
    return {
      ...state,
      message: null,
      errors: {},
      ...action.payload,
      loginStep: "enter_otp",
      syncing: false
    }
  },

  UPDATE_OTP_SETUP_OTP: (
    state: AuthDetailsApiResult | null | undefined,
    action: Action
  ) => {
    return {
      ...state,
      message: null,
      errors: {},
      ...action.payload,
      loginStep: "setup_otp",
      syncing: false
    }
  },

  UPDATE_OTP_FAILED: (
    state: AuthDetailsApiResult | null | undefined,
    action: Action
  ) => {
    return {
      ...state,
      message: null,
      errors: {},
      ...action.payload,
      loginStep: "enter_password",
      syncing: false
    }
  },

  UPDATE_OTP: (
    state: AuthDetailsApiResult | null | undefined,
    action: Action
  ) => {
    return {
      ...state,
      message: null,
      errors: {},
      ...action.payload,
      loginStep: "enter_password",
      syncing: false
    }
  },

  SUBMITTED_DISABLE_OTP: (state?: AuthDetailsApiResult) => {
    return { ...state, syncing: "sending", errors: {}, messages: null }
  },

  DISABLE_OTP_ENTER_OTP: (
    state: AuthDetailsApiResult | null | undefined,
    action: Action
  ) => {
    return {
      ...state,
      message: null,
      errors: {},
      ...action.payload,
      loginStep: "enter_otp",
      syncing: false
    }
  },

  DISABLE_OTP_FAILED: (
    state: AuthDetailsApiResult | null | undefined,
    action: Action
  ) => {
    return {
      ...state,
      message: null,
      errors: {},
      ...action.payload,
      loginStep: "enter_password",
      syncing: false
    }
  },

  DISABLE_OTP: (
    state: AuthDetailsApiResult | null | undefined,
    action: Action
  ) => {
    return {
      ...state,
      message: null,
      errors: {},
      ...action.payload,
      loginStep: "enter_password",
      syncing: false
    }
  },

  SUBMITTED_CREATE_BACKUP_CODES: (state?: AuthDetailsApiResult) => {
    return { ...state, syncing: "sending", errors: {}, messages: null }
  },

  CREATE_BACKUP_CODES_ENTER_OTP: (
    state: AuthDetailsApiResult | null | undefined,
    action: Action
  ) => {
    return {
      ...state,
      message: null,
      errors: {},
      ...action.payload,
      loginStep: "enter_otp",
      syncing: false
    }
  },

  CREATE_BACKUP_CODES_FAILED: (
    state: AuthDetailsApiResult | null | undefined,
    action: Action
  ) => {
    return {
      ...state,
      message: null,
      errors: {},
      ...action.payload,
      loginStep: "enter_password",
      syncing: false
    }
  },

  CREATE_BACKUP_CODES: (
    state: AuthDetailsApiResult | null | undefined,
    action: Action
  ) => {
    return {
      ...state,
      message: null,
      errors: {},
      ...action.payload,
      loginStep: "enter_password",
      syncing: false
    }
  },

  SUBMITTED_REMOVE_BACKUP_CODES: (state?: AuthDetailsApiResult) => {
    return { ...state, syncing: "sending", errors: {}, messages: null }
  },

  REMOVE_BACKUP_CODES_ENTER_OTP: (
    state: AuthDetailsApiResult | null | undefined,
    action: Action
  ) => {
    return {
      ...state,
      message: null,
      errors: {},
      ...action.payload,
      loginStep: "enter_otp",
      syncing: false
    }
  },

  REMOVE_BACKUP_CODES_FAILED: (
    state: AuthDetailsApiResult | null | undefined,
    action: Action
  ) => {
    return {
      ...state,
      message: null,
      errors: {},
      ...action.payload,
      loginStep: "enter_password",
      syncing: false
    }
  },

  REMOVE_BACKUP_CODES: (
    state: AuthDetailsApiResult | null | undefined,
    action: Action
  ) => {
    return {
      ...state,
      message: null,
      errors: {},
      ...action.payload,
      loginStep: "enter_password",
      syncing: false
    }
  },

  DISPLAY_BACKUP_CODES: (
    state: AuthDetailsApiResult | null | undefined,
    action: Action
  ) => {
    let updatedState = state
    if ("backupCodesGenerated" in action.payload) {
      updatedState = immutable.set(
        state,
        ["data", "user", "backupCodesGenerated"],
        action.payload.backupCodesGenerated
      )
    }
    return {
      ...updatedState,
      message: null,
      errors: {},
      ...action.payload,
      syncing: false
    }
  },

  SUBMITTING_EDIT_USER_FORM: (
    state: UserFormApiResult | null | undefined,
    _action: Action
  ) => {
    return { ...state, syncing: "sending" }
  },

  SUBMITTED_EDIT_USER_FORM: (
    state: UserFormApiResult | null | undefined,
    action: Action
  ) => {
    const updatedState = immutable.set(state, ["data", "user"], action.payload)
    return { ...updatedState, syncing: false }
  },

  SUBMITTING_USER_PASSWORD_FORM: (
    state: UserFormApiResult | null | undefined,
    _action: Action
  ) => {
    return { ...state, syncing: "sending" }
  },

  SUBMITTED_USER_PASSWORD_FORM: (
    state: UserFormApiResult | null | undefined,
    _action: Action
  ) => {
    return { ...state, syncing: false }
  },

  UPDATED_ORG_ROLE: (
    state: UserFormApiResult | null | undefined,
    action: Action
  ) => {
    const { email, roles, orgSlug } = action.payload
    if (get(state, "data.user.email") === email) {
      const orgs = get(state, "data.user.orgs")
      const orgIndex = findIndex(orgs, { slug: orgSlug })
      if (orgIndex >= 0) {
        return immutable.set(
          state,
          ["data", "user", "orgs", orgIndex, "roles"],
          roles
        )
      }
    }
    return state
  }
}

export default function meReducer(
  state: AuthDetailsApiResult = null,
  action: Action
): AuthDetailsApiResult {
  return applyReducerHash(reducers, state, action)
}

const globalReducers = {
  LOGOUT: (state: State) => {
    RippleGA.clearUserProperties()
    return {
      flash: { messages: [] },
      location: state.location,
      _persist: state._persist,
      me: { data: {}, _persist: state.me && state.me._persist }
    }
  }
}

export function userGlobalReducer(state: State, action: Action): State {
  return applyReducerHash(globalReducers, state, action)
}
