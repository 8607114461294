import React from "react"
import styled from "@emotion/styled"

export const gutterEm = 1.3
export const gutter = `${gutterEm}em`
export const containerWidth = 720
export const largePageWidth = 1200

export const mediaQueries = {
  desktop: `@media only screen and (min-width: ${containerWidth}px) and (min-height: 650px)`
}

const InnerContainer = styled.div({
  margin: gutter,
  display: "flex",
  flex: 1,
  flexDirection: "column",
  position: "relative"
})

const OuterContainer = styled.div({
  maxWidth: containerWidth,
  marginLeft: "auto",
  marginRight: "auto",
  display: "flex",
  flex: 1,
  flexDirection: "column"
})

const InvisibleCard = styled.div({
  padding: gutter,
  maxHeight: 800,
  display: "flex",
  flex: 1,
  flexDirection: "column"
})

const OuterInvisibleCardContainer = styled.div({
  maxWidth: containerWidth,
  marginLeft: "auto",
  marginRight: "auto",
  display: "flex",
  flex: 1,
  flexDirection: "column",
  justifyContent: "center"
})

export const PaddedByGutter = styled.div<{ fullWidth? }>((props) => ({
  padding: props.fullWidth ? `${gutter} 0` : gutter,
  display: "flex",
  flexDirection: "column"
}))

type Props = {
  children
}

export const Container = ({ children }: Props) => {
  return (
    <OuterContainer>
      <InnerContainer>{children}</InnerContainer>
    </OuterContainer>
  )
}

export const CardSizedContainer = ({ children }: Props) => {
  return (
    <OuterInvisibleCardContainer>
      <InvisibleCard>{children}</InvisibleCard>
    </OuterInvisibleCardContainer>
  )
}
