import React from "react"
import styled from "@emotion/styled"
import FontAwesome from "react-fontawesome"
import colours from "../themes/colours"
import ControlledFileInput from "./ControlledFileInput"

const IconButton = styled(FontAwesome)<{ height: number }>((props) => ({
  fontSize: `${props.height * 0.8}px`,
  lineHeight: `${props.height}px`,
  marginRight: 15
}))

const LinkStyledLabel = styled.label<{ disabled: boolean }>(
  {
    textDecoration: "none",
    color: colours.blue,
    cursor: "pointer"
  },
  ({ disabled }) => {
    return disabled
      ? {
          color: "#AAAAAA",
          cursor: "arrow"
        }
      : {}
  }
)

type Props = {
  name: string
  height: number
  value?: File
  disabled?: boolean
  onChange: (event: React.SyntheticEvent<HTMLInputElement>) => any
}

const AttachFileButton = ({
  name,
  disabled,
  value,
  onChange,
  height
}: Props) => {
  return (
    <span>
      <ControlledFileInput
        invisible
        disabled={disabled}
        name={name}
        value={value}
        onChange={onChange}
      />
      <LinkStyledLabel htmlFor={name} disabled={disabled}>
        <IconButton name="paperclip" height={height} />
      </LinkStyledLabel>
    </span>
  )
}

AttachFileButton.defaultProps = {
  disabled: false
}

export default AttachFileButton
