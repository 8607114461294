import type { BulkActionType } from "../../state_types"

const tenses = {
  ARCHIVE: "archived",
  RESTORE: "restored"
}

export function pastTense(action: BulkActionType): string {
  return tenses[action]
}
