import React from "react"
import styled from "@emotion/styled"
import type { UserScore } from "../state_types"
import { User, UserHash } from "../state_types"
import CommentCard from "./CommentCard"

type Props = {
  userBreakdown: Array<UserScore>
  users: UserHash
}

const QuestionContainer = styled.div({})

const Row = styled.div({
  paddingBottom: "0.8em"
})

const defaultUserDetail = (index): User => {
  return {
    avatar: "",
    name: `Anonymous ${index}`,
    fullName: `Anonymous ${index}`,
    email: "",
    phone: "",
    orgs: [],
    defaultRippleUuid: ""
  }
}

const CommentsList = ({ userBreakdown, users }: Props) => {
  return (
    <QuestionContainer>
      {userBreakdown.map((user, index) => {
        const userDetail = users[user.id] || defaultUserDetail(index)
        const assessText = user.score
          ? `Assessed: ${user.score} `
          : "Did not assess"
        if (!userDetail) return null
        return (
          <Row key={user.id}>
            <CommentCard
              userName={userDetail.name}
              userAvatar={userDetail.avatar}
              commentText={user.comment}
              detailText={assessText}
            />
          </Row>
        )
      })}
    </QuestionContainer>
  )
}

export default CommentsList
