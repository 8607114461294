import * as React from "react"
import styled from "@emotion/styled"
import { FieldArrayFieldsProps } from "redux-form"
import colours from "../themes/colours"
import iconImage from "../images/add_button.svg"

const Button = styled.button({
  border: "none",
  display: "flex",
  background: "none",
  alignSelf: "center",
  cursor: "pointer",
  color: "black",
  ":disabled": {
    cursor: "not-allowed",
    color: colours.deemphasisedText
  }
})

const LinkText = styled.p({
  paddingLeft: "0.5em",
  fontSize: "1em",
  fontWeight: "bold"
})

const LinkIcon = styled.img<{ disabled: boolean }>((props) => ({
  cursor: props.disabled ? "not-allowed" : "pointer",
  opacity: props.disabled ? 0.5 : 1
}))

type Props = {
  fields: FieldArrayFieldsProps<any>
  disabled?: boolean
}

const AddAnotherButton = ({ fields, disabled }: Props) => {
  const handleClick = (event) => {
    fields.push({ roleNames: ["viewer"] })
    event.preventDefault()
    return false
  }

  return (
    <Button disabled={disabled} onClick={handleClick}>
      <LinkIcon src={iconImage} alt="Add Participant" disabled={disabled} />
      <LinkText>Add Participant</LinkText>
    </Button>
  )
}

AddAnotherButton.defaultProps = {
  disabled: false
}

export default AddAnotherButton
