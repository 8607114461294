import React from "react"
import styled from "@emotion/styled"
import isNil from "lodash.isnil"

type Props = {
  colour: string
  threshold: number
  height: number
}

type StyledComponentsCustom = {
  barLocation: number
  barColour: string | Array<string>
  height
}

const BarStyle = styled.div<StyledComponentsCustom>(
  ({ barLocation, barColour, height }) => ({
    display: "flex",
    height,
    width: 2,
    position: "absolute",
    left: Math.round(barLocation),
    backgroundColor: barColour,
    top: 0,
    justifyContent: "center",
    alignItems: "center",
    zIndex: 0
  })
)

const Thresholds = ({ threshold, colour, height }: Props) => {
  if (isNil(threshold)) return null
  return <BarStyle barLocation={threshold} barColour={colour} height={height} />
}

export default Thresholds
