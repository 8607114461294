import React from "react"
import styled from "@emotion/styled"
import Theme from "../themes/Silver"
import Header from "../components/Header"
import LayoutWithHeader from "../components/LayoutWithHeader"
import LoadablePage from "../components/LoadablePage"
import OrgManagement from "../components/OrgManagement"
import { largePageWidth, gutter } from "../components/Layout"
import type { OrgUsersApiResult } from "../state_types"
import OrgDashboardUsers from "../components/OrgDashboardUsers"

type OrgDashboardProps = {
  org: OrgUsersApiResult
  loaded: boolean
}

const Container = styled.div({
  display: "flex",
  flexDirection: "row",
  justifyContent: "center",
  padding: `0 ${gutter}`
})

const Contents = styled.div({
  flex: 1,
  maxWidth: largePageWidth,
  width: "100%"
})

const Details = ({ org }: { org: OrgUsersApiResult }) => {
  if (org.data) {
    return <OrgDashboardUsers orgSlug={org.slug} />
  }

  if (org.page) {
    return <h2>Archive stuff!!</h2>
  }

  return null
}

const OrgDashboardDisconnected = ({ org, loaded }: OrgDashboardProps) => (
  <Theme>
    <LayoutWithHeader
      header={
        <div>
          <Header />
        </div>
      }
    >
      <LoadablePage loaded={loaded}>
        {() => (
          <Container>
            <Contents>
              <OrgManagement />
              <Details org={org} />
            </Contents>
          </Container>
        )}
      </LoadablePage>
    </LayoutWithHeader>
  </Theme>
)

export default OrgDashboardDisconnected
