import React from "react"
import type { ReactNode } from "react"
import styled from "@emotion/styled"
import FontAwesome from "react-fontawesome"
import isNil from "lodash.isnil"
import Link from "redux-first-router-link"
import withQuery from "with-query"
import AssessmentItemScores from "./AssessmentItemScores"
import AssessmentCommentSummary from "./AssessmentCommentSummary"
import type {
  TeamAssessment as TeamAssessmentType,
  UsersHash
} from "../../state_types"
import colours from "../../themes/colours"
import AssessmentDate from "./AssessmentDate"
import type { DialogFunctions } from "../BubbleDialogContainer"
import { interpolateInteger } from "../helpers/math"

type Props = {
  users: UsersHash
  teamAssessment: TeamAssessmentType
  width: number
  dialog?: DialogFunctions
  showDate?: boolean
  customTitle?: ReactNode
  linkTo?: string
  titleWidth?: number
  outOf: number
  queryParams?: {}
  scoreSuffix?: string
}

const Container = styled.div({
  display: "flex",
  flexDirection: "row"
})

const RowTitle = styled.div<{ barHeight; titleWidth }>((props) => ({
  width: props.titleWidth,
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  position: "relative",
  height: props.barHeight
}))

const Bookmark = styled.div<{ colour; topOffset; height }>((props) => ({
  backgroundColor: props.colour,
  width: 5,
  height: props.height,
  position: "absolute",
  top: props.topOffset,
  left: "-1.3em"
}))

const RowDetails = styled.div<{ width }>((props) => ({
  flex: 1,
  width: props.width
}))

function scoreIsBelowLowerThreshold({ score, thresholds }) {
  const lowerThreshold = thresholds[0]
  return lowerThreshold && score < lowerThreshold
}

const TeamRippleAssessment = ({
  users,
  teamAssessment,
  width,
  dialog,
  showDate,
  customTitle,
  linkTo,
  titleWidth,
  outOf,
  queryParams,
  scoreSuffix
}: Props) => {
  const { date, responseType } = teamAssessment
  const belowLowerThreshold = scoreIsBelowLowerThreshold(teamAssessment)
  const bookmarkColour = belowLowerThreshold ? colours.badScore : null
  const radius = interpolateInteger([18, 24], [360, 640], width)
  const barPadding = interpolateInteger([7, 9], [360, 640], width)
  const diameter = radius * 2
  const barHeight = diameter + barPadding * 2
  const noAssessments = isNil(teamAssessment.score)

  const tableWidth = width - (titleWidth || 50)
  const hasLink = !noAssessments && linkTo

  const Row = [
    <RowTitle
      key="rowTitle"
      barHeight={barHeight}
      titleWidth={titleWidth}
      data-test-id="team-assessment"
    >
      {bookmarkColour && (
        <Bookmark
          key="bookmark"
          height={diameter}
          colour={bookmarkColour}
          topOffset={barPadding}
        />
      )}
      {showDate && <AssessmentDate date={date} />}
      {customTitle}
      {hasLink && (
        <FontAwesome style={{ color: colours.blue }} name="chevron-right" />
      )}
    </RowTitle>,
    <RowDetails width={tableWidth} key="rowDetails">
      {responseType === "comment_only" ? (
        <AssessmentCommentSummary teamAssessment={teamAssessment} />
      ) : (
        <AssessmentItemScores
          users={users}
          teamAssessment={teamAssessment}
          width={tableWidth}
          radius={radius}
          height={barHeight}
          dialog={dialog}
          scoreOverride={noAssessments && { text: "no responses received" }}
          outOf={outOf}
          suffix={scoreSuffix}
        />
      )}
    </RowDetails>
  ]

  const LinkWrappedRow = hasLink && (
    <Link
      to={withQuery(linkTo, queryParams)}
      style={{ display: "flex", width: "100%", textDecoration: "none" }}
    >
      {Row}
    </Link>
  )

  return (
    <Container className="teamRippleAssessment">
      {LinkWrappedRow || Row}
    </Container>
  )
}

export default TeamRippleAssessment
