import { connect } from "react-redux"
import { AnyAction } from "redux"
import { ThunkDispatch } from "redux-thunk"
import { reset } from "redux-form"
import get from "lodash.get"
import type { RippleInfoPanelProps } from "./RippleInfoPanelDisconnected"
import RippleInfoPanelDisconnected from "./RippleInfoPanelDisconnected"
import { archiveRipple } from "../api_actions"
import UserStateDecorator from "../contexts/user/UserStateDecorator"
import RippleStateDecorator from "../contexts/ripple_details/StateDecorator"

const mapStateToProps = (state) => {
  const ripple = get(state, "rippleDetails.data.ripple") || {}
  const clientsRipples = get(state, "rippleDetails.data.clientsRipples") || []
  const permissions = get(state, "rippleDetails.data.permissions") || {}
  const userState = new UserStateDecorator(state)
  const rippleState = new RippleStateDecorator(ripple)

  return {
    ripple,
    clientsRipples,
    permissions,
    userState,
    rippleState
  }
}

const mapDispatchToProps = (
  dispatch: ThunkDispatch<any, any, AnyAction>,
  ownProps: RippleInfoPanelProps
) => ({
  dispatchArchiveRipple: () => {
    dispatch(archiveRipple(ownProps.ripple.uuid))
  },
  dispatchAddRippleToClient: (clientId) => {
    dispatch({
      type: "NEW_RIPPLE_STEP_FOR_CLIENT",
      payload: { step: "assessment", clientId }
    })
    dispatch(reset("ripple"))
  }
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(RippleInfoPanelDisconnected)
