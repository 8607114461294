import React from "react"
import { IconButton as MuiIconButton } from "@material-ui/core"
import colours from "../themes/colours"

type Props = {
  type?: "button" | "reset" | "submit"
  disabled?: boolean
  onClick?
  style?
}

const IconButton: React.FC<Props> = ({
  disabled,
  style,
  type,
  children,
  ...rest
}) => {
  const color = disabled ? colours.deemphasisedText : colours.blue

  return (
    <MuiIconButton
      {...rest}
      color="primary"
      style={{
        color,
        ...style
      }}
      type={type}
      disabled={disabled}
    >
      {children}
    </MuiIconButton>
  )
}

IconButton.defaultProps = {
  type: "button",
  disabled: false
}

export default IconButton
