import React from "react"
import styled from "@emotion/styled"
import filter from "lodash.filter"
import { CardSizedContainer } from "../components/Layout"
import { HeadingOne } from "../components/TextStyles"
import Actions from "../components/PageBottomActions"
import Button from "../components/Button"
import ArrowButton from "../components/ArrowButton"
import AssessmentAverages from "../components/AssessmentAverages"
import BlueTheme from "../themes/Blue"
import InputModel from "../contexts/assessment/InputModel"
import type { Assessment } from "../contexts/assessment/types"

const Body = styled.div({
  flexGrow: 1.0,
  flexShrink: 0.0
})

const emptyInput = {
  attributes: {}
}

type Props = {
  assessment: Assessment
  previousPage: () => unknown
  submitAssessment: () => unknown
}

function allQuestionSteps(steps) {
  return filter(steps, (step) => step.type === "question")
}

const AssessmentSummaryPage = ({
  assessment,
  previousPage,
  submitAssessment
}: Props) => {
  const input = assessment.input || emptyInput
  const { framework } = assessment
  const steps = framework.steps
  const questions = allQuestionSteps(steps)
  const inputModel = new InputModel(input.attributes, questions)
  const ratedCount = inputModel.ratedQuestionCount()
  const noneRated = ratedCount === 0

  return (
    <BlueTheme>
      <CardSizedContainer>
        <HeadingOne>Assessment summary</HeadingOne>
        <Body>
          <AssessmentAverages
            input={input.attributes}
            questions={questions}
            outOf={framework.outOf}
          />
          {noneRated && (
            <p>
              You must supply a score for at least one question before you can
              submit this assessment.
            </p>
          )}
        </Body>
        <Actions>
          <ArrowButton onClick={previousPage} direction="left" text="Back" />
          <Button onClick={submitAssessment} disabled={noneRated}>
            Submit Assessment
          </Button>
        </Actions>
      </CardSizedContainer>
    </BlueTheme>
  )
}

export default AssessmentSummaryPage
