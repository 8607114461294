import React from "react"
import GradientCircle from "./GradientCircle"
import BottomFadeOutCircle from "./BottomFadeOutCircle"
import { Vector2 } from "./types"

type Props = {
  id: string
  radius: number
  outerThickness: number
  centre: Vector2
  colour: string
  angleDegrees: number
}

const DoubleGradientCircle = ({
  id,
  radius,
  centre,
  outerThickness,
  colour,
  angleDegrees
}: Props) => {
  return (
    <React.Fragment>
      <GradientCircle
        name={`outer-${id}`}
        radius={radius}
        centre={centre}
        colour={colour}
        angleDegrees={angleDegrees}
        maxOpacity={1.0}
      />
      <GradientCircle
        name={`inner-${id}`}
        radius={radius - outerThickness}
        centre={centre}
        colour={colour}
        angleDegrees={0}
        maxOpacity={0.7}
      />
      <BottomFadeOutCircle radius={radius + 6} centre={centre} />
    </React.Fragment>
  )
}

export default DoubleGradientCircle
