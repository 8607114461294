import React from "react"
import { Vector2 } from "./types"
import ArcDraggable from "./ArcDraggable"
import GradientArc from "./GradientArc"

type Props = {
  centre: Vector2
  radius: number
  svgWidth: number
  arcAngleDegrees: number
  value: number
  outOf: number
  onChange: (value: number) => void
  startColour: string
  endColour: string
  thickness: number
  atTop: boolean
}

const ArcSlider = ({
  startColour,
  endColour,
  thickness,
  atTop,
  ...props
}: Props) => {
  const { centre, radius, arcAngleDegrees } = props
  const sharedProps = { centre, radius, arcAngleDegrees, atTop }
  const arcProps = { startColour, endColour, thickness }

  return (
    <React.Fragment>
      <GradientArc {...sharedProps} {...arcProps} />
      <ArcDraggable
        {...sharedProps}
        {...props}
        handleRadius={thickness / 2 + 4}
      />
    </React.Fragment>
  )
}

export default ArcSlider
