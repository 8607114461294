import React from "react"
import { SearchFilter, DropDownFilter, FilterGridContainer } from "../Filters"
import type { DropDownFilterProps } from "../Filters"

type ReportFilterParams = {
  organisation?: DropDownFilterProps
  framework?: DropDownFilterProps
  group?: DropDownFilterProps
  timeframe?: DropDownFilterProps
  threshold?: DropDownFilterProps
  searchTerm: string
  filters: {
    searchTerm: string
  }
  onChange: (newFilters) => void
  showTextSearch?: boolean
  showTimeframe?: boolean
  showThreshold?: boolean
  disabled?: boolean
}

const ReportFilters = ({
  organisation,
  framework,
  group,
  timeframe,
  threshold,
  searchTerm,
  filters,
  onChange,
  showTextSearch,
  showTimeframe,
  showThreshold,
  disabled
}: ReportFilterParams) => {
  const buildChangeHandler = (key: string) => (newValue: string) =>
    onChange({ ...filters, [key]: newValue, page: undefined })

  return (
    <FilterGridContainer>
      {organisation && (
        <DropDownFilter
          {...organisation}
          onChange={buildChangeHandler("organisation")}
          disabled={disabled}
        />
      )}
      {framework && (
        <DropDownFilter
          {...framework}
          onChange={buildChangeHandler("framework")}
          disabled={disabled}
        />
      )}

      {showTimeframe && (
        <DropDownFilter
          className="timeframe"
          {...timeframe}
          onChange={buildChangeHandler("timeframe")}
          disabled={disabled}
        />
      )}

      {group && group.items.length > 1 && (
        <DropDownFilter
          {...group}
          onChange={buildChangeHandler("group")}
          disabled={disabled}
        />
      )}

      {showTextSearch && (
        <SearchFilter
          value={searchTerm}
          onChange={buildChangeHandler("searchTerm")}
          disabled={disabled}
        />
      )}

      {showThreshold && (
        <DropDownFilter
          {...threshold}
          onChange={buildChangeHandler("threshold")}
          disabled={disabled}
        />
      )}
    </FilterGridContainer>
  )
}

export default ReportFilters
