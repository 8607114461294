import styled from "@emotion/styled"
import { Checkbox } from "@material-ui/core"
import { withStyles } from "@material-ui/core/styles"
import colours from "../../themes/colours"

export const UserRow = styled.div({
  display: "flex",
  fontSize: "0.9em",
  borderTop: `1px solid ${colours.boldLineOnSilver}`,
  padding: "20px 20px 20px 0px",
  justifyContent: "space-between"
})

export const SelectionContainer = styled.div({
  display: "none", // hiding selection stuff for now...change this to 'flex' when ready
  flex: "none",
  width: "50px",
  justifyContent: "center",
  alignContent: "flex-start"
})

export const StyledCheckbox = withStyles({
  root: {
    margin: "0",
    padding: "2px 0 0",
    width: "18px",
    height: "18px",
    maxWidth: "18px"
  }
})(Checkbox)

export const Cell = styled.div({
  display: "flex",
  flex: "1 1 auto"
})
