import styled from "@emotion/styled"
import colours from "../themes/colours"

const Card = styled.div<{
  width?: number
  height?: number
  noPadding?: boolean
}>((props) => ({
  backgroundColor: "white",
  borderWidth: 1,
  borderColor: colours.boldLineOnSilver,
  borderStyle: "solid",
  borderRadius: 3,
  position: "relative",
  padding: props.noPadding ? 0 : 10,
  width: props.width,
  height: props.height
}))

export const CardLink = styled(Card)({
  ":hover": {
    borderColor: colours.blue
  }
})

export default Card
