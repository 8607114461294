import React from "react"
import styled from "@emotion/styled"
import ContainedButton from "./ContainedButton"
import colours from "../themes/colours"
import { gutter, mediaQueries } from "./Layout"

const Overlay = styled.div<{ closed }>(
  {
    width: "100%",
    zIndex: 998,
    position: "absolute",
    left: "0",
    bottom: 0,
    transition: "background-color 300ms ease-in",
    [mediaQueries.desktop]: {
      borderRadius: 20
    }
  },
  (props) => {
    return props.closed
      ? {
          backgroundColor: "rgba(0,0,0,0)",
          height: 0
        }
      : {
          backgroundColor: "rgba(0,0,0,0.2)",
          height: "100%"
        }
  }
)

const CommentArea = styled.div<{ closed }>((props) => ({
  height: props.closed ? 0 : "50%",
  borderTop: props.closed ? 0 : "1px solid #DDDDDD",
  width: "100%",
  position: "absolute",
  backgroundColor: colours.silver,
  zIndex: 999,
  left: "0",
  bottom: "0",
  overflow: "hidden",
  display: "flex",
  flexDirection: "column",
  justifyContent: "flex-start",
  transition: "height 300ms ease-in",
  [mediaQueries.desktop]: {
    borderRadius: 20
  }
}))

const SubmitComment = styled.div({
  display: "flex",
  width: "100%",
  justifyContent: "flex-end",
  padding: "1em 1em 1em 0"
})

const TextInput = styled.div({
  flex: 1,
  marginTop: gutter
})

const Actions = styled.div({})

const PaddedContainer = styled.div({
  height: "100%",
  marginLeft: gutter,
  marginRight: gutter,
  position: "relative"
})

const CommentText = styled.textarea({
  height: "100%",
  width: "100%",
  boxSizing: "border-box",
  resize: "none",
  padding: "1em",
  fontSize: "16px"
})

type Props = {
  showComment: boolean
  onUpdate: (value: boolean) => any
  onChange: (value: string) => unknown
  comment: string
}

class Comment extends React.Component<Props> {
  handleUpdate = () => {
    this.props.onUpdate(!this.props.showComment)
  }

  handleTextChange = (event: { target: { value: string } }) => {
    const { value } = event.target
    this.props.onChange(value)
  }

  render() {
    const { showComment } = this.props

    return [
      <Overlay key="overlay" closed={!showComment} />,
      <CommentArea key="comments" closed={!showComment}>
        <TextInput>
          <PaddedContainer>
            <CommentText
              placeholder="Leave a comment"
              value={this.props.comment}
              onChange={this.handleTextChange}
            />
          </PaddedContainer>
        </TextInput>
        <Actions>
          <PaddedContainer>
            <SubmitComment>
              <ContainedButton
                style={{ fontSize: "14px", padding: "5px 10px" }}
                label="Done"
                type="submit"
                onClick={this.handleUpdate}
              />
            </SubmitComment>
          </PaddedContainer>
        </Actions>
      </CommentArea>
    ]
  }
}

export default Comment
