import React from "react"
import styled from "@emotion/styled"
import QRCode from "qrcode.react"
import {
  Header,
  SubHeader,
  PrimaryActions,
  Instructions
} from "../AuthFormStyles"
import BackButton from "../BackButton"
import NextButton from "../NextButton"

// Monospace text block
const MonospaceTextBlock = styled.div({
  background: "#EEEEEE",
  padding: "10px",
  border: "1px solid #CCCCCC",
  borderRadius: "4px",
  fontFamily: '"Roboto Mono", Monospace',
  width: "min(500px, 100%)",
  marginBottom: "1em",
  ["wordWrap" as any]: ["break-word", "anywhere"] // force to be ok with "anywhere" as a value
})

const QRCodeContainer = styled.div({
  display: "flex",
  justifyContent: "center",
  marginBottom: "1em",
  width: "min(500px, 100%)"
})
type Props = {
  mfaSetupUrl: string
  submit: () => void
  back: () => void
}
const ScanQRCode = ({ mfaSetupUrl, submit, back }: Props) => {
  return (
    <>
      <Header> Set up two-factor authentication (2FA)</Header>
      <SubHeader>Step 2</SubHeader>
      <Instructions>
        <p>
          Next, using the app you’ve downloaded, scan the below code. This will
          allow the app to generate One Time Passwords (OTP), which you’ll need
          each time you sign in.
        </p>
      </Instructions>

      <QRCodeContainer>
        <QRCode value={mfaSetupUrl} size={200} />
      </QRCodeContainer>

      <Instructions>
        <p>
          If you’re using an app that does not support scanning a QR code, or if
          your camera doesn’t work, you can use the following URL:
        </p>
      </Instructions>
      <MonospaceTextBlock>{mfaSetupUrl}</MonospaceTextBlock>

      <PrimaryActions>
        <BackButton onClick={back} />
        <NextButton onClick={submit}>I have scanned this QR code</NextButton>
      </PrimaryActions>
    </>
  )
}

export default ScanQRCode
