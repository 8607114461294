import React from "react"
import styled from "@emotion/styled"
import InputModel from "../contexts/assessment/InputModel"
import type {
  AssessmentAttributesInput,
  Attribute
} from "../contexts/assessment/types"

type Props = {
  input: AssessmentAttributesInput
  questions: Array<Attribute>
  outOf: number
}

const AveragesTable = styled.table({
  fontWeight: 600
})

const LabelColumn = styled.td({
  paddingRight: "1em",
  lineHeight: "160%"
})

const AssessmentAverages = ({ input, questions, outOf }: Props) => {
  const inputModel = new InputModel(input, questions)
  const average = inputModel.averageIntegerRating()
  const ratedCount = inputModel.ratedQuestionCount()
  const questionCount = inputModel.questionCount()
  const isPercent = outOf === 100

  return (
    <div>
      <AveragesTable>
        <caption style={{ display: "none" }}>Assessment Scores</caption>
        <tbody>
          <tr>
            <LabelColumn>Completed:</LabelColumn>
            <td>
              {ratedCount} of {questionCount}
            </td>
          </tr>
          {average && (
            <tr>
              <LabelColumn>Average score:</LabelColumn>
              <td>
                {isPercent ? `${average}%` : `${average} out of ${outOf}`}
              </td>
            </tr>
          )}
        </tbody>
      </AveragesTable>
    </div>
  )
}

export default AssessmentAverages
