import React from "react"
import styled from "@emotion/styled"
import filter from "lodash.filter"
import includes from "lodash.includes"
import { CardSizedContainer } from "../components/Layout"
import { HeadingOne } from "../components/TextStyles"
import Actions from "../components/PageBottomActions"
import ArrowButton from "../components/ArrowButton"
import TealTheme from "../themes/Teal"
import QuestionAnswers from "../components/QuestionAnswers"
import type { SectionSummary, Assessment } from "../contexts/assessment/types"

const ScrollContainer = styled.div({
  flex: 1,
  display: "flex",
  flexDirection: "column",
  overflow: "scroll"
})

const Scrollable = styled.div({
  flexGrow: 1,
  flexShrink: 0,
  height: "100%"
})

const Body = styled.div({
  flex: 1,
  display: "flex",
  position: "relative",
  ":before": {
    content: " ",
    width: "100%",
    height: 100,
    bottom: 0,
    position: "absolute",
    backgroundImage:
      "linear-gradient(rgba(255, 255, 255, 0) 0%, rgba(80, 227, 194, 1) 100%)"
  }
})

const emptyInput = {
  attributes: {}
}

const UpNextLabel = styled.div({
  fontSize: 18,
  lineHeight: "29px",
  fontWeight: 600,
  color: "#444444"
})

const NextSectionName = styled.div({
  fontSize: 24,
  lineHeight: "34px",
  fontWeight: "bold",
  color: "#444444"
})

const UpNextContainer = styled.div({
  textAlign: "right",
  paddingBottom: 30,
  paddingTop: 20
})

const UpNext = ({ sectionName }: { sectionName: string }) => {
  return (
    <UpNextContainer>
      <UpNextLabel>Up Next:</UpNextLabel>
      <NextSectionName>{sectionName}</NextSectionName>
    </UpNextContainer>
  )
}

function sectionQuestions(steps, questionIds) {
  return filter(steps, (step) => includes(questionIds, step.id))
}

type Props = {
  step: SectionSummary
  previousPage: () => unknown
  nextPage: () => unknown
  assessment: Assessment
}

const SectionSummaryPage = ({
  assessment,
  step,
  previousPage,
  nextPage
}: Props) => {
  const input = assessment.input || emptyInput
  const steps = assessment.framework.steps
  const questions = sectionQuestions(steps, step.questionIds)

  return (
    <TealTheme>
      <CardSizedContainer>
        <HeadingOne>{step.title}: Complete</HeadingOne>
        <Body>
          <ScrollContainer>
            <Scrollable>
              <QuestionAnswers input={input.attributes} questions={questions} />
            </Scrollable>
          </ScrollContainer>
        </Body>
        {step.nextSectionTitle && (
          <UpNext sectionName={step.nextSectionTitle} />
        )}
        <Actions>
          <ArrowButton onClick={previousPage} direction="left" text="Prev" />
          <ArrowButton onClick={nextPage} direction="right" text="Next" />
        </Actions>
      </CardSizedContainer>
    </TealTheme>
  )
}

export default SectionSummaryPage
