import React from "react"
import isEmpty from "lodash.isempty"
import { connect } from "react-redux"
import styled from "@emotion/styled"
import FlatButton from "./FlatButton"
import AvatarPreview from "./AvatarPreview"
import AvatarContainer from "./AvatarContainer"
import colours from "../themes/colours"
import AttachFileButton from "./AttachFileButton"
import AttachmentDetails from "./AttachmentDetails"
import type {
  ProposedAttachment,
  ProposedComment
} from "../contexts/feed/types"
import type { User } from "../state_types"
import TextButton from "./TextButton"

type Props = {
  showComment: boolean
  comment: ProposedComment
  canSubmit: boolean
  sending: boolean
  errors: Array<string>
  onFocus: () => any
  onCommentChange: (event: React.ChangeEvent<HTMLTextAreaElement>) => any
  onAttachmentChange: (attachment?: ProposedAttachment) => any
  onCancel: () => any
  onSubmit: () => any
  user: User
}

function borderColor(expanded, error) {
  // Helper function to make border color logic easier to understand
  if (expanded && error) {
    return `solid 2px ${colours.error}`
  }
  if (expanded) {
    return `solid 2px ${colours.blue}`
  }
  return "none"
}

const Container = styled.div<{ expanded; error }>((props) => ({
  displayName: "Container",
  position: "relative",
  display: "flex",
  transition: "box-shadow 0.5s ease",
  flexDirection: "column",
  backgroundColor: colours.silver,
  height: "100%",
  zIndex: 1,
  boxShadow: props.expanded ? "0 3px 12px rgba(0, 0, 0, 0.15)" : "none",
  borderLeft: borderColor(props.expanded, props.error)
}))

const AvatarPosition = styled.div<{ expanded }>((props) => ({
  displayName: "AvatarPosition",
  position: "absolute",
  top: 0,
  left: 0,
  padding: "2px 5px",
  borderLeft: props.expanded ? "none" : `solid 2px ${colours.blue}`
}))

const BorderedAvatarContainer = styled(AvatarContainer)({
  displayName: "BorderedAvatarContainer",
  borderStyle: "solid",
  borderWidth: "1px"
})

const CommentText = styled.textarea<{ expanded }>((props) => ({
  displayName: "CommentText",
  cursor: props.expanded ? "auto" : "pointer",
  transition: "height 0.5s ease",
  resize: "none",
  border: "none",
  height: props.expanded ? "7.5em" : "1.5em",
  lineHeight: "1.5em",
  outline: "none",
  padding: "14px 20px 20px 50px",
  background: props.expanded ? "white" : "none",
  "&::placeholder": {
    color: colours.blue,
    textAlign: "initial",
    fontWeight: 700
  }
}))

const Footer = styled.div({
  displayName: "Footer",
  display: "flex",
  padding: "10px 10px 10px 0",
  background: "white"
})

const CancelComment = styled.div({
  displayName: "CancelComment",
  flex: 0.5,
  display: "flex",
  justifyContent: "flex-start",
  marginLeft: 10
})

const SubmitComment = styled.div({
  displayName: "SubmitComment",
  flex: 0.5,
  display: "flex",
  justifyContent: "flex-end"
})

const CardError = styled.div({
  backgroundColor: "red",
  color: "white",
  padding: "4px 8px"
})

const CardErrors = ({ errors }: { errors: Array<string> }) => {
  return (
    <div>
      {errors.map((error) => (
        <CardError>{error}</CardError>
      ))}
    </div>
  )
}

const CommentEntry = (props: Props) => {
  const {
    comment,
    sending,
    showComment,
    canSubmit,
    onFocus,
    onCommentChange,
    onAttachmentChange,
    onCancel,
    onSubmit,
    user,
    errors
  } = props

  const { avatar, name } = user
  const { text, attachment } = comment
  const error = !isEmpty(errors)

  const avatarSize = 35

  return (
    <Container expanded={showComment} error={error}>
      <AvatarPosition expanded={showComment}>
        <BorderedAvatarContainer size={avatarSize}>
          <AvatarPreview
            size={avatarSize}
            value={avatar}
            name={name && name[0]}
          />
        </BorderedAvatarContainer>
      </AvatarPosition>
      <CommentText
        onChange={onCommentChange}
        onFocus={onFocus}
        placeholder="Add a comment or file"
        value={text}
        expanded={showComment}
      />
      {attachment && (
        <AttachmentDetails
          attachment={attachment}
          onChange={onAttachmentChange}
        />
      )}
      {showComment && (
        <Footer>
          <CancelComment>
            <TextButton
              style={{ height: "40px" }}
              label="cancel"
              type="submit"
              onClick={onCancel}
            />
          </CancelComment>
          <SubmitComment>
            <AttachFileButton
              name="comment-attachment"
              value={attachment ? attachment.file : null}
              onChange={(event: React.SyntheticEvent<HTMLInputElement>) => {
                const file = event.currentTarget.files[0]
                onAttachmentChange({ file })
              }}
              disabled={!!attachment}
              height={40}
            />
            <FlatButton
              label={sending ? "Sending" : "Publish"}
              style={{ padding: "0 7px" }}
              disabled={!canSubmit}
              onClick={onSubmit}
            />
          </SubmitComment>
        </Footer>
      )}
      {error && showComment && <CardErrors errors={errors} />}
    </Container>
  )
}

const mapStateToProps = (state) => {
  const me = state.me || {}
  const loggedIn = me.data && me.data.token
  const user = loggedIn && me.data ? me.data.user : null
  return {
    user
  }
}

const mapDispatchToProps = (_dispatch) => ({})

export default connect(mapStateToProps, mapDispatchToProps)(CommentEntry)
