import React from "react"
import ReactDOM from "react-dom"
import { AppContainer } from "react-hot-loader"
import * as Sentry from "@sentry/react"
import RippleGA from "../ripple_ga"
import createRippleStore from "../store"
import App from "../containers/App"

const beforeSend = (event, hint) => {
  const info = event
  info.extra = info.extra || {}

  if (hint.originalException != null) {
    // Assign enumerable error properties to extras
    try {
      const keys = Object.keys(hint.originalException)
      if (keys.length) {
        info.extra.errorProperties = {}
        keys.forEach((key) => {
          info.extra.errorProperties[key] = hint.originalException[key]
        })
      }
    } catch (error) {
      // eslint-disable-next-line no-console
      console.warn(
        "[sentry] Failed to assign enumerable error properties to extras",
        error
      )
    }
  }

  return info
}

// Note: Ignore "Non-Error promise..." errors generated by Microsoft's Outlook
// safe-link crawler tech. Their stuff raises these errors (can tell its Microsoft by
// doing a who-is look up on the IP generating the errors )
if (
  process.env.RAILS_ENV !== "development" &&
  process.env.RAILS_ENV !== "test"
) {
  Sentry.init({
    beforeSend,
    dsn: "https://e9d92c6c03b64ff99c55326dccf809b4@o132243.ingest.sentry.io/292219",
    ignoreErrors: ["Non-Error promise rejection captured"]
  })
}

const { store, persistor } = createRippleStore()

const render = (Component) => {
  ReactDOM.render(
    <AppContainer>
      <Component store={store} persistor={persistor} />
    </AppContainer>,
    document.getElementById("react-app")
  )
}

document.addEventListener("DOMContentLoaded", () => {
  window.rippleCSRF = document.getElementById("react-app").dataset.csrf
  render(App)
  RippleGA.boot()
})

if (module.hot) {
  module.hot.accept("../containers/App", () => {
    render(App)
  })
}
