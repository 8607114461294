import React from "react"
import styled from "@emotion/styled"
import get from "lodash.get"
import { isEnabledByCondition } from "./helpers/sdq_group_helpers"
import type { EnabledCondition } from "./helpers/sdq_group_helpers"
import SdqClientDetails from "./SdqClientDetails"
import SdqAssessmentCommentInput from "./SdqAssessmentCommentInput"
import SdqAssessmentIdentityInput from "./SdqAssessmentIdentityInput"
import SdqAssessmentRelationshipInput from "./SdqAssessmentRelationshipInput"
import RadioQuestionTable from "./RadioQuestionTable"
import type { Assessment } from "../contexts/assessment/types"

type Option = [number, string]

type Attribute = {
  attributes?: Array<Attribute>
  id: string
  type?: string
  options?: Array<Option>
  preamble?: string
  indented?: boolean
  enabledCondition?: EnabledCondition
  hasError?: boolean
  question?: string
}

type Props = {
  assessment: Assessment
  attribute: Attribute
  questionErrors: Array<string>
  identityErrors: Array<string>
  updateRating: (questionId: string, newValue?: number) => unknown
  updateQuestionComment: (
    attributeId: string | null | undefined,
    comment: string
  ) => unknown
  updateData: (
    attributeId: string | null | undefined,
    value: string | boolean
  ) => unknown
  mobile: boolean
}

const Container = styled.div<{ disabled }>((props) => ({
  opacity: props.disabled ? 0.6 : 1.0
}))

const mergeErrors = (questions, questionErrors) =>
  questions.map((question) =>
    Object.assign(question, { hasError: questionErrors.includes(question.id) })
  )

const SdqGrouping = ({
  assessment,
  attribute,
  questionErrors,
  identityErrors,
  updateRating,
  updateQuestionComment,
  updateData,
  mobile
}: Props) => {
  const attributes = attribute.attributes || []
  const disabled = attribute.enabledCondition
    ? !isEnabledByCondition(attribute.enabledCondition, assessment)
    : false
  const ripple = assessment.ripple
  const assessorName = assessment.user
  const inputAttributes = get(assessment, "input.attributes") || {}

  const questionParts = attributes.map((child) => {
    switch (child.type) {
      case "client_details":
        return (
          <SdqClientDetails
            key={child.id}
            name={ripple.clientName}
            gender={ripple.gender}
            dateOfBirth={ripple.dateOfBirth}
          />
        )
      case "question_set": {
        const questions = mergeErrors(child.attributes || [], questionErrors)
        return (
          <RadioQuestionTable
            key={child.id}
            options={child.options || []}
            questions={questions}
            preamble={child.preamble}
            indented={child.indented}
            disabled={disabled}
            updateRating={updateRating}
            input={inputAttributes}
            mobile={mobile}
          />
        )
      }
      case "assessment_comments":
        return (
          <SdqAssessmentCommentInput
            key={child.id}
            updateQuestionComment={updateQuestionComment}
          />
        )
      case "grouping":
        return (
          <SdqGrouping
            key={child.id}
            attribute={child}
            questionErrors={questionErrors}
            identityErrors={identityErrors}
            assessment={assessment}
            updateRating={updateRating}
            updateQuestionComment={updateQuestionComment}
            updateData={updateData}
            mobile={mobile}
          />
        )
      default:
        return null
    }
  })

  const identityParts = attributes.map((child) => {
    switch (child.type) {
      case "assessment_relationship":
        return (
          <SdqAssessmentRelationshipInput
            key={child.id}
            onChange={(value) => updateData(child.id, value)}
            hasError={
              identityErrors &&
              identityErrors.includes("assessment_relationship_other")
            }
          />
        )
      case "assessment_identity":
        return (
          <SdqAssessmentIdentityInput
            value={get(assessment, ["input", "data", child.id])}
            key={child.id}
            assessorName={assessorName}
            onChange={(value) => updateData(child.id, value)}
            hasError={
              identityErrors &&
              identityErrors.includes("assessment_identity_not_confirmed")
            }
          />
        )
      default:
        return null
    }
  })

  return (
    <div>
      <Container disabled={disabled}>
        {attribute.preamble && <p>{attribute.preamble}</p>}
        {questionParts}
      </Container>
      {identityParts}
    </div>
  )
}

export default SdqGrouping
