import React from "react"
import styled from "@emotion/styled"
import reject from "lodash.reject"
import includes from "lodash.includes"
import ToggleButtonGroup from "./ToggleButtonGroup"
import ToggleButton from "./ToggleButton"

type Props = {
  input: {
    value: Array<string>
    onChange: (element: any) => any
  }

  updateRoles: (roles: Array<string>) => any
  buttonNames: Array<string>
  primaryColour: string
}

const ButtonSet = styled.div({
  display: "flex",
  flexDirection: "row"
})

const Seperator = styled.span({
  borderRight: "1px solid silver",
  marginLeft: 10,
  marginRight: 10,
  display: "block"
})

const withoutAdmin = (roles: Array<string>): Array<string> =>
  reject(roles, (role) => role === "admin")

const buildRoles = (
  isAdmin: boolean,
  nonAdminRoles: Array<string>
): Array<string> => (isAdmin ? [...nonAdminRoles, "admin"] : nonAdminRoles)

class RippleRolesField extends React.Component<Props> {
  onRoleChange = (roleName: string) => {
    this.setNewRoles(buildRoles(this.isAdmin(), [roleName]))
  }

  onAdminChange = (isAdmin: boolean) => {
    this.setNewRoles(buildRoles(isAdmin, this.nonAdminRoles()))
  }

  setNewRoles(roles: Array<string>) {
    this.props.updateRoles(roles)
    this.props.input.onChange(roles)
  }

  isAdmin(): boolean {
    const { value } = this.props.input
    return includes(value, "admin")
  }

  nonAdminRoles(): Array<string> {
    const { value } = this.props.input
    return withoutAdmin(value)
  }

  render() {
    const { input, ...rest } = this.props
    const { value, onChange, ...inputRest } = input
    const roleValue: string = withoutAdmin(value)[0]

    return (
      <ButtonSet>
        <ToggleButton
          label="Admin"
          faIcon="star"
          value={this.isAdmin()}
          onChange={this.onAdminChange}
        />
        <Seperator />
        <ToggleButtonGroup
          {...inputRest}
          {...rest}
          value={roleValue}
          onChange={this.onRoleChange}
        />
      </ButtonSet>
    )
  }
}

export default RippleRolesField
