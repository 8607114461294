import React from "react"
import styled from "@emotion/styled"
import Markdown from "react-markdown"
import RadioQuestion from "../../RadioQuestion"

import { mediaQueries } from "../../Layout"
import type { AssessmentInstruction } from "../../../contexts/assessment/types"
import colours from "../../../themes/colours"

const Body = styled.div({
  textAlign: "center"
})

const HelpText = styled.div`
  background-color: ${colours.textFieldBorderGrey};
  margin: 20px 0;
  padding: 8px;
  border-radius: 4px;

  p {
    margin: 0;
  }
`

const RadioQuestionWrapper = styled.div({
  borderRadius: "40px",
  border: `1px solid ${colours.textFieldBorderGrey}`,
  padding: "1rem",
  margin: "20px 0"
})

const CardWrapper = styled.div({
  boxSizing: "border-box",
  margin: "auto",
  padding: ".5rem 1.25rem 1.25rem 1.25rem",
  whiteSpace: "pre-wrap",
  [mediaQueries.desktop]: {
    padding: ".5rem 3rem 1rem 3rem "
  }
})

const SectionLabel = styled.div({
  borderBottom: `1px solid ${colours.lightGrey}`,
  fontWeight: "bold",
  padding: "6px 18px",
  fontSize: "12px"
})

type Props = {
  step: AssessmentInstruction
}

const SimpleRadioInstructionSlide = ({ step }: Props) => {
  const { title, subtitle, helpText, body, options, id } = step
  const isPracticeQuestion = id === "practice_question"

  const sectionLabel =
    id.charAt(0).toUpperCase() + id.replace(/_/g, " ").slice(1)

  return (
    <>
      <SectionLabel>{sectionLabel}</SectionLabel>
      <CardWrapper>
        <Body>
          <h2>{title}</h2>
          <Markdown>{subtitle}</Markdown>
          {isPracticeQuestion && (
            <RadioQuestion
              questionData={step}
              options={options}
              updateRating={() => {}}
              hasError={false}
              rating={null}
              disabled={!isPracticeQuestion}
            />
          )}
          {helpText && (
            <HelpText>
              <Markdown>{helpText}</Markdown>
            </HelpText>
          )}
          {!isPracticeQuestion && options && (
            <RadioQuestionWrapper>
              <RadioQuestion
                questionData={step}
                options={options}
                updateRating={() => {}}
                hasError={false}
                rating={null}
                disabled
              />
            </RadioQuestionWrapper>
          )}
          <Markdown>{body}</Markdown>
        </Body>
      </CardWrapper>
    </>
  )
}

export default SimpleRadioInstructionSlide
