import React from "react"
import compact from "lodash.compact"
import map from "lodash.map"
import type { UserScore } from "../../state_types"

type Props = {
  userBreakdown: Array<UserScore>
}

const NumUserNotes = ({ userBreakdown }: Props) => {
  if (!userBreakdown) return null
  const numComments = compact(map(userBreakdown, "comment")).length
  const notes = numComments > 1 ? "notes" : "note"

  if (numComments === 0) return null

  return <>{`${numComments} ${notes}`}</>
}

export default NumUserNotes
