import React from "react"
import styled from "@emotion/styled"
import FixedBottomLayout from "./FixedBottomLayout"
import WizardPageBody from "./WizardPageBody"
import WizardActions from "./WizardActions"
import type { Steps, TransitionToStepFunc } from "../contexts/shared/step_types"

const Footer = styled.div({
  borderTop: "1px solid #DDDDDD",
  paddingTop: "0.5em",
  paddingBottom: "0.5em"
})

type Props = {
  children
  currentStepID: string
  steps: Steps
  transitionToStep: TransitionToStepFunc
  submitting?: boolean
  onSubmit: () => any
}

const Wizard = ({
  children,
  currentStepID,
  steps,
  transitionToStep,
  submitting,
  onSubmit
}: Props) => {
  return (
    <FixedBottomLayout
      footer={
        <Footer>
          <WizardActions
            steps={steps}
            disabled={submitting}
            currentStepID={currentStepID}
            transitionToStep={transitionToStep}
            onSubmit={onSubmit}
          />
        </Footer>
      }
    >
      <WizardPageBody
        steps={steps}
        currentStepID={currentStepID}
        transitionToStep={transitionToStep}
      >
        {children}
      </WizardPageBody>
    </FixedBottomLayout>
  )
}

Wizard.defaultProps = {
  submitting: false
}

export default Wizard
