import isempty from "lodash.isempty"

const DBC = {
  require: (check, message = "") => {
    if (!check) {
      throw new Error(message)
    }
  },
  requireNotBlank: (s, message = "") => {
    DBC.require(!isempty(s), message)
  }
}

export default DBC
