import { connect } from "react-redux"
import ConfirmAuthPage from "./ConfirmAuthPage"
import { submitDisableOtp } from "../api_actions"

const mapStateToProps = (state) => {
  const me = state.me || {}

  return {
    errors: me.errors,
    syncing: !!me.syncing,
    loginStep: me.loginStep
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    onSubmit: (password: string, otpCode: string) =>
      // eslint-disable-next-line @typescript-eslint/no-unsafe-return
      dispatch(submitDisableOtp(password, otpCode)),

    onNoPass: () => dispatch({ type: "DISABLE_OTP" })
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ConfirmAuthPage)
