import React from "react"
import { ThemeProvider } from "emotion-theming"
import colours from "./colours"
import ColouredBackground from "../components/ColouredBackground"

const theme = {}

type Props = {
  children: React.ReactNode
  scollableContents?: boolean
}

const Background = ({ children, scollableContents }: Props) => {
  return (
    <ThemeProvider theme={theme}>
      <ColouredBackground colour={colours.silver}>
        {scollableContents ? <div>{children}</div> : children}
      </ColouredBackground>
    </ThemeProvider>
  )
}

Background.defaultProps = {
  scollableContents: false
}

export default Background
