import { connect } from "react-redux"
import React, { Component } from "react"
import AssessmentPage from "./AssessmentPage"
import { getOpenAssessment, postAssessment } from "../api_actions"
import Loading from "../components/Loading"
import FatalError from "../components/FatalError"

import type { AssessmentApiResult } from "../state_types"

type Props = {
  assessment?: AssessmentApiResult
  getOpenAssessment: (token?: string) => unknown
  nextPage: () => unknown
  previousPage: () => unknown
  updateRating: (questionId: string, rating?: number) => unknown
  updateQuestionComment: (
    attributeId: string | null | undefined,
    comment: string
  ) => unknown
  updateCommentOnly: (
    attributeId: string | null | undefined,
    comment: string
  ) => unknown
  updateData: (
    attributeId: string | null | undefined,
    value: string | boolean
  ) => unknown
  clearRating: (questionId: string) => unknown
  submitAssessment: (assessmentApiResult: AssessmentApiResult) => unknown
}

class AssessmentPageContainer extends Component<Props> {
  componentDidMount() {
    if (!this.props.assessment) {
      const token = this.getToken()
      this.props.getOpenAssessment(token)
    }
  }

  getToken(): string | null | undefined {
    const pathParts = window.location.pathname.split("/")
    const lastPart = pathParts[pathParts.length - 1]
    return lastPart
  }

  render() {
    const {
      assessment,
      nextPage,
      previousPage,
      updateRating,
      updateQuestionComment,
      updateCommentOnly,
      updateData,
      clearRating,
      submitAssessment
    } = this.props

    if (assessment && !assessment.syncing) {
      if (assessment.errors) {
        return <FatalError errors={assessment.errors} />
      }

      if (assessment.data) {
        return (
          <AssessmentPage
            assessment={assessment.data}
            nextPage={nextPage}
            previousPage={previousPage}
            updateRating={updateRating}
            updateQuestionComment={updateQuestionComment}
            updateCommentOnly={updateCommentOnly}
            updateData={updateData}
            clearRating={clearRating}
            submitAssessment={() => {
              if (assessment) submitAssessment(assessment)
            }}
          />
        )
      }
    }

    return <Loading />
  }
}

const mapStateToProps = (state) => {
  return {
    assessment: state.assessment
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    getOpenAssessment: (token) => {
      dispatch(getOpenAssessment(token))
    },
    nextPage: () => {
      dispatch({ type: "NEXT_ASSESSMENT_PAGE" })
    },
    previousPage: () => {
      dispatch({ type: "PREVIOUS_ASSESSMENT_PAGE" })
    },
    updateRating: (id: string, rating: number) => {
      dispatch({ type: "UPDATE_QUESTION_INPUT", payload: { id, rating } })
    },
    updateQuestionComment: (id: string, comment: string) => {
      dispatch({ type: "UPDATE_QUESTION_COMMENT", payload: { id, comment } })
    },
    updateCommentOnly: (id: string, comment: string) => {
      dispatch({ type: "UPDATE_COMMENT_ONLY", payload: { id, comment } })
    },
    updateData: (id: string, value: string) => {
      dispatch({ type: "UPDATE_ASSESSMENT_DATA", payload: { id, value } })
    },
    clearRating: (id: string) => {
      dispatch({ type: "CLEAR_QUESTION_INPUT", payload: { id } })
    },
    submitAssessment: (assessment: AssessmentApiResult) => {
      dispatch(postAssessment(assessment))
    }
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AssessmentPageContainer)
