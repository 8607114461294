import styled from "@emotion/styled"

const AvatarContainer = styled.div<{ size: number }>((props) => ({
  backgroundColor: "#CCCCCC",
  borderColor: "#AAAAAA",
  width: props.size,
  height: props.size,
  borderRadius: props.size,
  overflow: "hidden",
  textAlign: "center",
  lineHeight: `${props.size}px`,
  color: "white",
  fontSize: `${props.size * 0.6}px`
}))

export default AvatarContainer
