import React from "react"
import withQuery from "with-query"
import styled from "@emotion/styled"
import type { ScoreReport as ScoreReportType } from "../../state_types"
import ScoreReport from "./ScoreReport"

const TitleParts = styled.div({
  display: "flex",
  flexDirection: "row",
  marginBottom: 4
})

const Title = styled.div({
  fontSize: "12px",
  lineHeight: "16px",
  color: "black",
  marginLeft: 8
})

const titleGenerator = (row) => (
  <TitleParts>
    <Title>{row.title}</Title>
  </TitleParts>
)

type TopReportParams = ScoreReportType & {
  title: string
  reportId: string
  reportUrl: string
  returnTo?: {
    to: string
    name: string
  }
}

const TopReport = (params: TopReportParams) => {
  const { reportId, reportUrl, filters } = params

  const linkToGenerator = (row) =>
    withQuery(`/reports/${reportId}/${row.subReportId}`, {
      organisation: filters.organisation,
      framework: filters.framework,
      parentUrl: reportUrl,
      group: filters.group
    })

  return (
    <ScoreReport
      {...params}
      customTitle={titleGenerator}
      linkTo={linkToGenerator}
    />
  )
}

export default TopReport
