import React from "react"
import styled from "@emotion/styled"
import { gutter } from "./Layout"
import colours from "../themes/colours"

const Container = styled.div<{ isError }>((props) => ({
  backgroundColor: props.isError ? colours.badScore : colours.goodScore,
  color: "white",
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  justifyContent: "center",
  textAlign: "center",
  paddingLeft: gutter,
  paddingRight: gutter
}))

type Props = {
  height: number
  flash: {
    message: string
    isError: boolean
  }
}

const FlashMessage = ({ height, flash }: Props) => {
  return (
    <Container
      className="flashMessage"
      isError={flash.isError}
      style={{ height }}
    >
      {flash.message}
    </Container>
  )
}

export default FlashMessage
