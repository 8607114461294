import Wizard from "../shared/Wizard"
import type { Steps } from "../shared/step_types"
import type { FrameworkSteps } from "./types"

export default class FrameworkModel extends Wizard {
  constructor(questions: FrameworkSteps) {
    const steps = questions as Steps
    super(steps)
  }
}
