import React from "react"
import AvatarContainer from "./AvatarContainer"

type Props = {
  name?: string
  size: number
}

const NameAvatar = ({ name, size }: Props) => (
  <AvatarContainer size={size}>{name && name[0].toUpperCase()}</AvatarContainer>
)

export default NameAvatar
