import React from "react"
import ArrowButton from "./ArrowButton"
import PageBottomActions from "./PageBottomActions"
import Wizard from "../contexts/shared/Wizard"
import SubmitButton from "./SubmitButton"
import type { Steps, TransitionToStepFunc } from "../contexts/shared/step_types"

type Props = {
  transitionToStep: TransitionToStepFunc
  steps: Steps
  onSubmit: () => any
  disabled?: boolean
  currentStepID: string
}

const WizardActions = ({
  transitionToStep,
  steps,
  disabled,
  currentStepID,
  onSubmit
}: Props) => {
  const wizard = new Wizard(steps)
  const currentStep = wizard.stepForID(currentStepID)
  const nextStepID = wizard.nextStepID(currentStepID)
  const previousStepID = wizard.previousStepID(currentStepID)
  const canProceed = currentStep.valid && !disabled

  const handler = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault()
    onSubmit()
  }

  return (
    <PageBottomActions>
      {previousStepID && (
        <ArrowButton
          disabled={disabled}
          onClick={() => transitionToStep(previousStepID)}
          key="left"
          direction="left"
          text="Prev"
        />
      )}
      {nextStepID ? (
        <ArrowButton
          disabled={!canProceed}
          onClick={() => transitionToStep(nextStepID)}
          key="right"
          direction="right"
          text="Next"
        />
      ) : (
        <SubmitButton disabled={!canProceed} onClick={handler} />
      )}
    </PageBottomActions>
  )
}

WizardActions.defaultProps = {
  disabled: false
}

export default WizardActions
