import immutable from "object-path-immutable"
import { applyReducerHash } from "../../redux_helpers"
import type { RippleFormApiResult, Action } from "../../state_types"
import newRippleWizard, { initialStepID } from "./wizard"

const newRippleStepAction = (
  state: RippleFormApiResult | null | undefined,
  action: Action
) => {
  const payload = action.payload || {}
  const payloadStep =
    payload.step && newRippleWizard.containsStep(payload.step)
      ? payload.step
      : null
  const desiredStep = payloadStep || initialStepID

  const newState = immutable.set(state, ["currentStepID"], desiredStep)
  return immutable.set(newState, ["initialValues"], {
    clientId: payload.clientId
  })
}

const reducers = {
  NEW_RIPPLE_STEP: newRippleStepAction,
  NEW_RIPPLE_STEP_FOR_CLIENT: newRippleStepAction,

  SUBMITTING_RIPPLE_FORM: (
    state: RippleFormApiResult | null | undefined,
    _action: Action
  ) => {
    return { ...state, syncing: "sending" }
  },

  SUBMITTED_RIPPLE_FORM: () => {
    return null
  },

  NEW_RIPPLE_OPTIONS: (
    state: RippleFormApiResult | null | undefined,
    action: Action
  ) => {
    const payload = action.payload || {}
    const newState = immutable.set(state, ["data"], payload.data)
    return immutable.set(
      newState,
      ["initialValues", "organisation"],
      payload.initialValues && payload.initialValues.organisation
    )
  }
}

export default function reducer(
  state: RippleFormApiResult = null,
  action: Action
): RippleFormApiResult | null | undefined {
  return applyReducerHash(reducers, state, action)
}
