import get from "lodash.get"
import find from "lodash.find"
import map from "lodash.map"
import naturalCompare from "natural-compare"
import type { State } from "../../state_types"

export default class FilterState {
  orgs

  filters

  constructor(state: State) {
    this.orgs = get(state, "me.data.user.orgs") || []
    this.filters = get(state, "qrCodes.filters") || {}
  }

  get availableOrgs() {
    return this.orgs
  }

  get availableGroups() {
    const groupSorted = this.currentOrg.groupNames.sort(naturalCompare)

    return [{ value: "", text: "All" }].concat(
      map(groupSorted, (group) => {
        return {
          value: group,
          text: group
        }
      })
    )
  }

  get currentOrg() {
    return this._orgForSlug() || this.orgs[0]
  }

  // PRIVATE

  _orgForSlug() {
    return find(this.availableOrgs, { slug: this.filters.organisation })
  }
}
