import React from "react"
import styled from "@emotion/styled"
import { containerWidth, mediaQueries } from "./Layout"

type Props = {
  children
  maxHeight?: number
  id?: string
}

const OuterCardContainer = styled.div({
  maxWidth: containerWidth,
  marginLeft: "auto",
  marginRight: "auto",
  display: "flex",
  flex: 1,
  flexDirection: "column",
  justifyContent: "center",
  backgroundColor: "white",
  minHeight: 500,
  [mediaQueries.desktop]: {
    backgroundColor: "transparent"
  }
})

const VisibleCard = styled.div<{ maxHeight }>(({ maxHeight }) => ({
  backgroundColor: "white",
  padding: 20,
  maxHeight,
  display: "flex",
  flex: 1,
  flexDirection: "column",
  [mediaQueries.desktop]: {
    border: "2px solid #AAAAAA",
    position: "relative",
    borderRadius: 20,
    marginTop: 20,
    marginBottom: 20,
    boxShadow: "0 10px 20px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23)"
  }
}))

const AssessmentCard = ({ children, maxHeight, ...rest }: Props) => {
  return (
    <OuterCardContainer {...rest}>
      <VisibleCard maxHeight={maxHeight}>{children}</VisibleCard>
    </OuterCardContainer>
  )
}

AssessmentCard.defaultProps = {
  maxHeight: null
}

export default AssessmentCard
