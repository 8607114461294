import React from "react"
import styled from "@emotion/styled"
import { scoreColour } from "../utils/scoreColour"

type Props = {
  score: number
  displayScore?: number
  radius: number
  thresholds: Array<number>
  top?: number
  left?: number
  onClick?: (event) => void
  hideScore?: boolean
  suffix?: string
}

const CircleStyle = styled.div<{ radius; top?; left?; gradientColor }>(
  (props) => ({
    display: "flex",
    height: props.radius * 2,
    width: props.radius * 2,
    minWidth: props.radius * 2,
    position: props.top == null ? "static" : "absolute",
    left: props.left,
    backgroundColor: props.gradientColor,
    borderRadius: "50%",
    top: props.top,
    justifyContent: "center",
    alignItems: "center",
    zIndex: 2
  })
)

const Score = styled.div<{ fontSize }>((props) => ({
  margin: "auto",
  color: "white",
  fontSize: props.fontSize
}))

export function clampAboveZero(value: number) {
  return Math.max(0, value)
}

const roundScore = (score: number, places: number) => {
  if (places === 0) return Math.round(score)
  const order = 10 * places
  return Math.round(score * order) / order
}

const getFontSize = (radius, suffix) => {
  if (suffix) {
    return "15px"
  }

  if (radius > 20) {
    return "17px"
  }

  return "11px"
}

const ScoreCircle = ({
  top,
  left,
  score,
  displayScore,
  radius,
  thresholds,
  onClick,
  hideScore,
  suffix
}: Props) => {
  const defaultCirclePosition = "40%"
  let screenScore = displayScore ?? score
  screenScore = roundScore(screenScore, screenScore <= 20 ? 1 : 0)

  const fontSize = getFontSize(radius, suffix)

  return (
    <CircleStyle
      onClick={onClick}
      radius={radius}
      top={top}
      left={left ?? defaultCirclePosition}
      gradientColor={scoreColour(score, thresholds)}
    >
      {!hideScore && (
        <Score fontSize={fontSize}>
          {screenScore}
          {suffix}
        </Score>
      )}
    </CircleStyle>
  )
}

ScoreCircle.defaultProps = {
  top: null,
  left: null,
  onClick: null,
  displayScore: null,
  hideScore: false
}

export { CircleStyle }
export default ScoreCircle
