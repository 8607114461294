import get from "lodash.get"
import includes from "lodash.includes"
import type { Assessment } from "../../contexts/assessment/types"

export type EnabledCondition = [string, Array<number>]

export function isEnabledByCondition(
  enabledCondition: EnabledCondition,
  assessment: Assessment
) {
  const [key, possibleValues] = enabledCondition
  const conditionValue = get(assessment, ["input", "attributes", key, "rating"])
  return includes(possibleValues, conditionValue)
}
