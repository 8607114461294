import styled from "@emotion/styled"

type EllipsisProps = {
  maxWidth?: string
}

const Ellipsis = styled.span<EllipsisProps>({
  display: "inline-block",
  overflow: "hidden",
  whiteSpace: "nowrap",
  textOverflow: "ellipsis",
  width: "100%"
})

export default Ellipsis
