import { applyReducerHash } from "../../redux_helpers"
import type { UserInviteApiResult, Action } from "../../state_types"

const reducers = {
  USER_INVITE: () => ({ syncing: "receiving" }),
  RECEIVED_USER_INVITE: (
    state: UserInviteApiResult | null | undefined,
    action: Action
  ) => {
    return action.payload
  },
  ACCEPTING_USER_INVITE: (state?: UserInviteApiResult) => {
    return { ...state, syncing: "sending" }
  },
  ACCEPT_USER_INVITE_SETUP_OTP: (
    state: UserInviteApiResult | null | undefined,
    action: Action
  ) => {
    return {
      ...state,
      errors: {},
      ...action.payload,
      syncing: false
    }
  }
}

export default function userInviteReducer(
  state: UserInviteApiResult = null,
  action: Action
): UserInviteApiResult | null | undefined {
  return applyReducerHash(reducers, state, action)
}
