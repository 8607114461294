import { degrees } from "radians"

export const getArcBound = (arcAngleDegrees, radius) => {
  const sideAngleDegrees = arcAngleDegrees / 2
  const sideAngleRadians = degrees(sideAngleDegrees)
  return Math.sin(sideAngleRadians) * radius
}

export const getXOnArc = (normalizedValue, bound) => {
  return normalizedValue * bound
}

export const normalizeValue = (value, outOf) => {
  const normalisedPositiveValue = value / outOf
  return normalisedPositiveValue * 2 - 1 // between -1 and 1
}

export function angleToXOnArc(xOnArc, radius) {
  if (!radius || xOnArc === 0) return 0
  return Math.asin(xOnArc / radius)
}

export function getAngleToHandle(value, outOf, arcAngleDegrees, radius) {
  const bound = getArcBound(arcAngleDegrees, radius)
  const normalisedValue = normalizeValue(value, outOf)
  const xOnArc = getXOnArc(normalisedValue, bound)

  return angleToXOnArc(xOnArc, radius)
}
