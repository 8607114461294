import React from "react"
import isEmpty from "lodash.isempty"
import styled from "@emotion/styled"
import colours from "../themes/colours"
import { scoreColour } from "../utils/scoreColour"

const NoData = styled.div({
  color: colours.grey,
  textAlign: "center",
  marginTop: 30
})

const HeatmapTable = styled.table({
  paddingTop: "10px",
  width: "100%",
  borderSpacing: "1px",
  fontSize: "80%",
  tableLayout: "fixed"
})

const HeatmapTableHeader = styled.thead({})

const Header = styled.th({
  textAlign: "left"
})

const ColumnHeader = styled.th({
  overflow: "hidden",
  textOverflow: "ellipsis",
  wordBreak: "break-all",
  wordWrap: "break-word",
  whiteSpace: "nowrap"
})

const HeatmapRow = styled.tr({})

const Item = styled.td<{ gradientColor; numberOfColumns }>((props) => ({
  width: "1%",
  backgroundColor: props.gradientColor,
  textAlign: "right",
  maxWidth: `${100 / (props.numberOfColumns + 1)}%`,
  padding: "0 4px",
  color: colours.white
}))

const renderHeader = (columns) => {
  return (
    <HeatmapTableHeader>
      <HeatmapRow>
        <Header colSpan={2} />
        {columns.map((column) => {
          return <ColumnHeader key={column}>{column}</ColumnHeader>
        })}
      </HeatmapRow>
    </HeatmapTableHeader>
  )
}

type Props = {
  columns: []
  rows: []
  renderRowHeader: (any) => any
  renderValue: (any) => any
}

const Heatmap = ({ columns, rows, renderRowHeader, renderValue }: Props) => {
  const numberOfColumns = columns.length

  const renderCell = (cell) => {
    const colour = scoreColour(cell.value, cell.thresholds)

    return (
      <Item
        key={cell.key}
        gradientColor={colour}
        numberOfColumns={numberOfColumns}
      >
        {renderValue(cell)}
      </Item>
    )
  }

  const renderRow = (row) => (
    <HeatmapRow key={row.key}>
      <Header colSpan={2}>{renderRowHeader(row)}</Header>
      {row.cells.map((cell) => renderCell(cell))}
    </HeatmapRow>
  )

  if (isEmpty(rows)) {
    return <NoData>There are no results for the current set of filters.</NoData>
  }

  return (
    <HeatmapTable className="heatmap">
      {renderHeader(columns)}
      <tbody>{rows.map((row) => renderRow(row))}</tbody>
    </HeatmapTable>
  )
}

export default Heatmap
