import React from "react"
import type { ReactNode } from "react"
import styled from "@emotion/styled"
import { largePageWidth, gutter } from "../Layout"
import Breadcrumb from "../Breadcrumb"

const LayoutContainer = styled.div({
  display: "flex",
  flexDirection: "column"
})

const ReportSection = styled.div({
  display: "flex",
  flexDirection: "row",
  justifyContent: "center",
  flexWrap: "wrap",
  marginTop: 16
})

const Header = styled.div({
  borderBottom: "2px solid #E0E0E0",
  display: "flex",
  marginTop: 16,
  flexGrow: 1,
  flexShrink: 0,
  flexDirection: "row",
  justifyContent: "center"
})

const Container = styled.div({
  maxWidth: largePageWidth,
  flex: 1,
  display: "flex",
  flexDirection: "column",
  minWidth: "500px",
  overflow: "hidden",
  padding: `0 ${gutter}`
})

const ButtonsContainer = styled.div({
  flex: "0 0",
  width: "auto"
})

const ReportBody = styled(ReportSection)({})

type ReportLayoutProps = {
  title?: string
  subtitle?: string
  children: ReactNode
  header?: ReactNode
  buttons?: ReactNode
  breadcrumb?: {
    to: string
    name: string
  }
}

const Subtitle = styled.small({
  fontWeight: 300,
  fontSize: "100%"
})

const renderSubtitle = (subtitle) => {
  if (!subtitle) return null

  return <Subtitle>({subtitle})</Subtitle>
}

const ReportLayout = ({
  children,
  title,
  subtitle,
  header,
  buttons,
  breadcrumb
}: ReportLayoutProps) => (
  <LayoutContainer>
    <Header>
      <Container>
        {breadcrumb && <Breadcrumb {...breadcrumb} />}

        {title && (
          <h1>
            {title} {renderSubtitle(subtitle)}
          </h1>
        )}
        {header}
      </Container>
    </Header>
    <ReportBody>
      <Container>{children}</Container>
      <ButtonsContainer>{buttons}</ButtonsContainer>
    </ReportBody>
  </LayoutContainer>
)

export default ReportLayout
