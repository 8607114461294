const colours = {
  black: "#000",
  deemphasisedText: "#999999",
  blue: "#00AEEF",
  activeBlue: "#0088BB",
  darkBlue: "#009BD5",
  teal: "#50E3C2",
  silver: "#F8F8F8",
  whiteSilverBorder: "#EAEAEA",
  boldLineOnSilver: "#CCCCCC",
  green: "#4AE2B2",
  lightGreen: "#ACCA36",
  lightGrey: "#EEEEEE",
  grey: "#6C727C",
  darkGrey: "#4A4A4A",
  badScore: "#F77171",
  mediumScore: "#9A6B96",
  goodScore: "#00AEEF",
  white: "#FFFFFF",
  error: "#FF0000",
  textFieldBorderGrey: "#e0e0e0",
  textFieldPlaceholderGrey: "rgba(0, 0, 0, 0.3)"
}

export default colours
