import React from "react"
import { connect } from "react-redux"
import styled from "@emotion/styled"
import get from "lodash.get"
import Theme from "../themes/White"
import { Container, gutter } from "../components/Layout"
import RippleLogo from "../components/RippleLogo"
import type { State as ReduxState, UserInviteApiResult } from "../state_types"
import Loading from "../components/Loading"
import NoUserInvitation from "../components/errors/NoUserInvitation"
import UserInviteForm from "../components/UserInviteForm"
import { acceptUserInvite } from "../api_actions"
import DownloadMFAApp from "../components/login_steps/DownloadMFAApp"
import ScanQRCode from "../components/login_steps/ScanQRCode"
import EnterCodeSetup from "../components/login_steps/EnterCodeSetup"

const Background = styled.div({
  height: "100%",
  display: "flex",
  flexDirection: "column",
  backgroundColor: "white",
  alignItems: "center"
})

const LogoContainer = styled.div({
  flexGrow: 0.2,
  flexShrink: 0,
  display: "flex",
  flexDirection: "column",
  justifyContent: "flex-start",
  alignItems: "center",
  marginBottom: gutter
})

const OtpSetupContainer = styled.div({
  display: "flex",
  flexDirection: "column",
  alignItems: "center"
})

type State = {
  values?: {}
  otpCodeForUnconfirmedSecret?: string
  step: string
}

type Props = {
  assessed?: boolean
  userInvite: UserInviteApiResult
  acceptUserInvite: (token: string, values) => void
}

class UserInvitePage extends React.Component<Props, State> {
  state = {
    values: {},
    otpCodeForUnconfirmedSecret: "",
    step: "sign_up"
  }

  static defaultProps = { assessed: false }

  onSubmitSignUp = (values) => {
    this.setState({ values })
    if (this.props.userInvite.mfaRequired) {
      this.setState({ step: "download_authenticator" })
    } else {
      this.onSubmit()
    }
  }

  onSubmit = () => {
    const { values, otpCodeForUnconfirmedSecret } = this.state
    const token: string = this.props.userInvite.data.inviteToken
    this.props.acceptUserInvite(token, {
      ...values,
      otpCodeForUnconfirmedSecret
    })
  }

  updateStep = (step) => {
    return () => {
      this.setState({ step })
    }
  }

  updateOtpCodeForUnconfirmedSecret = (otpCodeForUnconfirmedSecret) => {
    this.setState({ otpCodeForUnconfirmedSecret })
  }

  renderPageBody() {
    const { userInvite, assessed } = this.props
    const { step, otpCodeForUnconfirmedSecret } = this.state

    if (step === "sign_up") {
      if (userInvite.errors) {
        return <NoUserInvitation errors={userInvite.errors} />
      }

      return (
        <UserInviteForm
          {...userInvite.data}
          onSubmit={this.onSubmitSignUp}
          assessed={assessed}
        />
      )
    }

    if (step === "download_authenticator") {
      return (
        <OtpSetupContainer>
          <DownloadMFAApp submit={this.updateStep("setup_authenticator")} />
        </OtpSetupContainer>
      )
    }
    if (step === "setup_authenticator") {
      return (
        <OtpSetupContainer>
          <ScanQRCode
            submit={this.updateStep("confirm_code")}
            back={this.updateStep("download_authenticator")}
            mfaSetupUrl={userInvite.mfaSetupUrl}
          />
        </OtpSetupContainer>
      )
    }
    if (step === "confirm_code") {
      return (
        <OtpSetupContainer>
          <EnterCodeSetup
            onChange={this.updateOtpCodeForUnconfirmedSecret}
            back={this.updateStep("setup_authenticator")}
            submit={this.onSubmit}
            errors={userInvite.errors}
            syncing={!!userInvite.syncing}
            otpCode={otpCodeForUnconfirmedSecret}
          />
        </OtpSetupContainer>
      )
    }
    return null
  }

  render() {
    const { userInvite } = this.props
    if (userInvite.syncing) return <Loading />

    return (
      <Theme>
        <Background>
          <Container>
            <LogoContainer>
              <RippleLogo colour="blue" />
            </LogoContainer>
            {this.renderPageBody()}
          </Container>
        </Background>
      </Theme>
    )
  }
}

const mapStateToProps = (state: ReduxState) => ({
  userInvite: state.userInvite || {},
  assessed: get(state, "location.query.assessed")
})

const mapDispatchToProps = (dispatch) => {
  return {
    acceptUserInvite: (token: string, values) => {
      dispatch(acceptUserInvite(token, values))
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(UserInvitePage)
