import moment from "moment"
import includes from "lodash.includes"
import reduce from "lodash.reduce"
import join from "lodash.join"
import { Ripple } from "../../state_types"

class RippleDetails {
  data: Ripple

  constructor(data: Ripple) {
    this.data = data
  }

  computeAge() {
    if (!this.data.dateOfBirth) return null
    return moment().diff(this.data.dateOfBirth, "years")
  }

  adminEmails() {
    if (!this.users()) return []

    return reduce(
      this.users(),
      (list, user) => {
        if (includes(user.roles, "admin")) {
          list.push(user.email)
        }
        return list
      },
      []
    )
  }

  orgSlug() {
    return this.data && this.data.orgSlug
  }

  users() {
    return this.data.users
  }

  groupNamesFormatted() {
    return join(this.data.groupNames, ", ")
  }
}

export default RippleDetails
