import get from "lodash.get"
import map from "lodash.map"
import naturalCompare from "natural-compare"
import type { State } from "../../state_types"

export default class RipplesFilterState {
  orgs

  filters: { organisation: any; order: any; group: any }

  groups

  constructor(state: State, namespace: string) {
    this.orgs = get(state, "me.data.user.orgs") || []
    this.filters = get(state, `${namespace}.filters`) || {}
    this.groups = get(state, `${namespace}.data.groups`) || []

    this.filters.order = this.filters.order || null
    this.filters.group = this.filters.group || null
  }

  get availableOrders() {
    return [
      { value: "lowscore", text: "score ( low - high )" },
      { value: "highscore", text: "score ( high - low )" },
      { value: "name", text: "name" },
      { value: "date", text: "date" },
      { value: "oldestdate", text: "date ( oldest )" }
    ]
  }

  get availableOrgs() {
    return [{ value: "", text: "All" }].concat(
      map(this.orgs, (org) => {
        return {
          value: org.slug,
          text: org.name
        }
      })
    )
  }

  get availableGroups() {
    const groupSorted = this.groups.sort(naturalCompare)

    return [{ value: "", text: "All" }].concat(
      map(groupSorted, (group) => {
        return {
          value: group,
          text: group
        }
      })
    )
  }
}
