import React from "react"
import StringSelectField from "./StringSelectField"

const numbersToOptions = (numbers) =>
  (numbers || []).map((number) => [number, number])

const NumberSelectField = ({
  options,
  ...rest
}: {
  options
  floatingLabelText
  input
}) => {
  return <StringSelectField {...rest} options={numbersToOptions(options)} />
}

export default NumberSelectField
