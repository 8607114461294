import React from "react"
import { InjectedFormProps, reduxForm } from "redux-form"
import { stepValidator } from "../contexts/ripple_form/wizard"
import RippleMembersForm from "../components/RippleMembersForm"

const handleSubmit = () => {}

interface IProps {
  roles: Array<string>
}

const NewRippleInviteParticipants = ({
  roles
}: IProps & InjectedFormProps<{}, IProps>) => {
  return (
    <form onSubmit={handleSubmit} id="ripple-details-form">
      <RippleMembersForm roles={roles} />
    </form>
  )
}

export default reduxForm<{}, IProps>({
  form: "ripple",
  destroyOnUnmount: false,
  forceUnregisterOnUnmount: true,
  validate: stepValidator("participants"),
  enableReinitialize: true
})(NewRippleInviteParticipants)
