import React from "react"
import styled from "@emotion/styled"
import { ThemeProvider } from "emotion-theming"
import colours from "./colours"

const theme = {
  headings: { color: "white" },
  buttons: {
    backgroundColor: "white",
    color: colours.blue
  }
}
const BlueBackground = styled.div({
  backgroundColor: colours.blue,
  flex: 1,
  display: "flex",
  flexDirection: "row",
  color: "white",
  overflowY: "scroll"
})

type Props = {
  children
}

const Background = ({ children }: Props) => {
  return (
    <ThemeProvider theme={theme}>
      <BlueBackground>{children}</BlueBackground>
    </ThemeProvider>
  )
}

export default Background
