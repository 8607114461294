import React from "react"
import { Button as MuiButton } from "@material-ui/core"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

type TallButtonProps = {
  label: string
  onClick: React.MouseEventHandler<HTMLElement>
  buttonType?: "button" | "submit" | "reset"
  icon?
  style?
  disabled?: boolean
}

export const TallButton = ({
  label,
  onClick,
  buttonType,
  icon,
  style,
  disabled
}: TallButtonProps) => (
  <MuiButton
    style={{
      ...style,
      height: "40px"
    }}
    variant="outlined"
    color="primary"
    startIcon={icon && <FontAwesomeIcon icon={icon} />}
    type={buttonType || "submit"}
    onClick={onClick}
    disabled={disabled}
  >
    {label}
  </MuiButton>
)
