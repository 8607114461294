import styled from "@emotion/styled"

export const headerGrow = 0.15

const Header = styled.div({
  flexGrow: headerGrow,
  flexShrink: 0.0,
  display: "flex",
  flexDirection: "column"
})

export default Header
