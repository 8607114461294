import React from "react"
import styled from "@emotion/styled"

type Props = {
  thresholdClassifiers: Array<string>
}

const ThresholdHeadings = styled.div({
  display: "flex",
  fontSize: "12px",
  paddingLeft: "25%",
  paddingTop: "1rem",
  justifyContent: "space-around"
})

const ThresholdClassifiers = ({ thresholdClassifiers }: Props) => {
  return thresholdClassifiers ? (
    <ThresholdHeadings>
      {thresholdClassifiers.map((str) => {
        return <span key={str}>{str}</span>
      })}
    </ThresholdHeadings>
  ) : null
}

export default ThresholdClassifiers
