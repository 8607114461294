import React from "react"
import styled from "@emotion/styled"
import colours from "../themes/colours"

const Card = styled.div({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  width: "275px",
  padding: "10px 0",
  border: `1px solid ${colours.whiteSilverBorder}`,
  borderRadius: "3px",
  backgroundColor: "white",
  height: "343.797px",
  boxSizing: "border-box",
  "@media (max-width: 600px)": {
    width: "100%"
  }
})

const CardContainer = styled.div({
  textDecoration: "none",
  color: "inherit",
  margin: "10px",
  "@media (max-width: 600px)": {
    width: "100%",
    maxWidth: 360
  }
})

const CreateRippleText = styled.span({
  color: colours.deemphasisedText,
  fontFamily: "Poppins",
  fontSize: "14px",
  lineHeight: "23px"
})

const NoRipplesCard = () => {
  return (
    <CardContainer>
      <Card>
        <CreateRippleText>You have no ripples yet.</CreateRippleText>
      </Card>
    </CardContainer>
  )
}

export default NoRipplesCard
