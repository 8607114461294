import React from "react"
import Linkify from "react-linkify"
import styled from "@emotion/styled"
import AvatarPreview from "./AvatarPreview"
import DiscussionCommentDate from "./DiscussionCommentDate"
import FeedItemAttachment from "./FeedItemAttachment"
import colours from "../themes/colours"
import type { FeedItem } from "../state_types"

type Props = {
  feedItem: FeedItem
}

const Container = styled.div({
  display: "flex",
  flexDirection: "column",
  backgroundColor: "white",
  justifyContent: "center",
  padding: "10px",
  border: "1px solid #DCDCDC"
})

const UserDetail = styled.div({
  display: "flex",
  alignItems: "center"
})

const Note = styled.div({
  fontSize: "0.8em",
  whiteSpace: "pre-wrap",
  wordWrap: "break-word"
})

const Detail = styled.div({
  display: "flex",
  flexDirection: "column",
  paddingLeft: "10px"
})

const Avatar = styled.div({})

const Name = styled.div({
  fontWeight: 600
})

const FurtherDetail = styled.div({
  fontSize: "0.8em"
})

const Parts = styled.div({
  color: colours.grey,
  "& > div": {
    marginTop: 15,
    ":last-child": {
      marginBottom: 0
    }
  }
})

const DiscussionCommentCard = ({ feedItem }: Props) => {
  const { postedAt, attachment } = feedItem
  const userName = feedItem.author.name
  const text = feedItem.contents.text

  return (
    <Container>
      <UserDetail>
        <Avatar>
          <AvatarPreview
            value={feedItem.author.avatar}
            size={37}
            name={userName}
          />
        </Avatar>
        <Detail>
          <Name>{userName}</Name>
          <FurtherDetail>
            <DiscussionCommentDate date={postedAt} />
          </FurtherDetail>
        </Detail>
      </UserDetail>
      <Parts>
        {text && (
          <Note>
            <Linkify
              properties={{ target: "_blank", rel: "noopener noreferrer" }}
            >
              {text}
            </Linkify>
          </Note>
        )}
        {attachment && <FeedItemAttachment attachment={attachment} />}
      </Parts>
    </Container>
  )
}

export default DiscussionCommentCard
