import React from "react"
import styled from "@emotion/styled"
import LinearProgress from "./MuiExtensions/LinearProgress"
import type { Steps } from "../contexts/shared/step_types"
import Wizard from "../contexts/shared/Wizard"

const BarContainer = styled.div({
  backgroundColor: "##00AEEF",
  paddingTop: 3,
  paddingBottom: 3
})

type Props = {
  steps: Steps
  currentStepID: string
}

const WizardProgressBar = ({ steps, currentStepID }: Props) => {
  const wizard = new Wizard(steps)
  const stepNumber = wizard.stepNumber(currentStepID)
  const stepCount = wizard.stepCount()

  return (
    <BarContainer>
      <LinearProgress stepCount={stepCount} currentStep={stepNumber} />
    </BarContainer>
  )
}

export default WizardProgressBar
