import type { State } from "../../state_types"

export default class ImportRipplesStateDecorator {
  form

  constructor(state: State) {
    this.form = state.form
  }

  selectedOrganisationForRipple(): string | null | undefined {
    return (
      this.form &&
      this.form.importRipples &&
      this.form.importRipples.values &&
      this.form.importRipples.values.organisation
    )
  }
}
