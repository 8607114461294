import React from "react"
import { withStyles } from "@material-ui/core/styles"
import LinearProgress from "@material-ui/core/LinearProgress"
import colours from "../../../themes/colours"
import type { Steps } from "../../../contexts/shared/step_types"
import Wizard from "../../../contexts/shared/Wizard"
import { mediaQueries } from "../../Layout"

const BorderLinearProgress = withStyles((theme) => ({
  root: {
    minHeight: 10,
    [mediaQueries.desktop]: {
      borderRadius: "5px 5px 0 0"
    }
  },
  colorPrimary: {
    backgroundColor:
      theme.palette.grey[theme.palette.type === "light" ? 200 : 700]
  },
  bar: {
    backgroundColor: colours.teal,
    [mediaQueries.desktop]: {
      borderRadius: "5px 5px 5px 0"
    }
  }
}))(LinearProgress)

type Props = {
  steps: Steps
  currentStepID: string
}

const ProgressBar = ({ steps, currentStepID }: Props) => {
  const wizard = new Wizard(steps)
  const stepNumber = wizard.stepNumber(currentStepID)
  const stepCount = wizard.stepCount()

  const progress = (stepNumber / stepCount) * 100
  return <BorderLinearProgress variant="determinate" value={progress} />
}

export default ProgressBar
