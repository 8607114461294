import React from "react"
import { connect } from "react-redux"
import get from "lodash.get"
import styled from "@emotion/styled"
import QrRippleTableRow from "./RippleTableRow"
import { setQrStatus } from "../../api_actions"
import { gutter } from "../Layout"
import { TallButton } from "../TallButton"
import colours from "../../themes/colours"
import { Assessor, Ripple } from "../../state_types"

type Props = {
  ripples: Array<Ripple>
  assessors: Array<Assessor>
  activeAssessors: []
  handleSetQrCodes: (assessorIds: Array<any>, activeStatus: boolean) => any
  viewQrCodes: (ripples: any) => any
  organisationChoosen: boolean
}

const TableContainer = styled.div({
  backgroundColor: "white",
  padding: gutter,
  borderRadius: 3,
  boxShadow: "0 2px 10px 0 rgba(0,0,0,0.1)",
  marginBottom: 50
})

const Table = styled.table({})

const TH = styled.th({
  fontSize: "0.9em",
  textAlign: "left"
})

const ButtonContainer = styled.div({
  display: "flex",
  justifyContent: "flex-end",
  paddingTop: gutter
})

const Button = (props) => (
  <TallButton {...props} style={{ marginLeft: "10px" }} />
)

const Summary = styled.div({
  textAlign: "right",
  color: colours.deemphasisedText
})

const NoData = styled.div({
  color: colours.grey,
  textAlign: "center",
  marginTop: 30
})

const RippleTable = (props: Props) => {
  const {
    ripples,
    assessors,
    activeAssessors,
    handleSetQrCodes,
    viewQrCodes,
    organisationChoosen
  } = props

  const handleViewQrCodes = () => {
    viewQrCodes(ripples)
  }

  const assessorIds = assessors.map((a) => a.id)

  if (ripples.length === 0) {
    return <NoData>There are no results for the current set of filters.</NoData>
  }

  if (organisationChoosen) {
    return (
      <TableContainer>
        <ButtonContainer>
          <Button
            label="Enable all"
            buttonType="submit"
            onClick={() => handleSetQrCodes(assessorIds, true)}
            disabled={activeAssessors.length === assessors.length}
          />
          <Button
            label="Disable all"
            buttonType="submit"
            onClick={() => handleSetQrCodes(assessorIds, false)}
            disabled={activeAssessors.length === 0}
          />
          <Button
            label="View QR codes"
            buttonType="submit"
            onClick={handleViewQrCodes}
            disabled={activeAssessors.length === 0}
          />
        </ButtonContainer>
        <Summary>{activeAssessors.length} QR codes enabled</Summary>

        <Table cellPadding={0} cellSpacing={0}>
          <thead>
            <tr>
              <TH />
              <TH />
              <TH>Assessors</TH>
            </tr>
          </thead>
          <tbody>
            {ripples.map((ripple) => {
              const rippleAssessors = assessors.filter(
                (a) => a.rippleId === ripple.id
              )

              return (
                <QrRippleTableRow
                  key={get(ripple, "id")}
                  ripple={ripple}
                  assessors={rippleAssessors}
                  toggleQrStatus={handleSetQrCodes}
                />
              )
            })}
          </tbody>
        </Table>
      </TableContainer>
    )
  }

  return <NoData>Please pick an organisation first.</NoData>
}

const mapStateToProps = (state) => {
  const organisation = get(state, "qrCodes.filters.organisation")
  const organisationChoosen = !!organisation

  return { organisationChoosen }
}

const mapDispatchToProps = (dispatch) => {
  return {
    handleSetQrCodes: (rippleRoleIds, activeStatus) => {
      dispatch(setQrStatus(rippleRoleIds, activeStatus))
    },
    viewQrCodes: () => {
      dispatch({ type: "PRINT_QR_CODES" })
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(RippleTable)
