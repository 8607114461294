import React from "react"
import MenuItem from "material-ui/MenuItem"
import { Field } from "redux-form"
import MuiSelectField from "./MuiExtensions/components/MuiSelectField"

type Option = [string, string]

type Props = {
  floatingLabelText: string
  options: Array<Option>
  input: { name: string }
  blankOption?: string
}

const StringSelectHtmlField = ({
  floatingLabelText,
  input,
  options
}: Props) => {
  return (
    <div>
      <label htmlFor={input.name}>{floatingLabelText}</label>
      <select id={input.name} {...input}>
        <option value="" />
        {options.map((option) => (
          <option key={option[0]} value={option[0]}>
            {option[1]}
          </option>
        ))}
      </select>
    </div>
  )
}

const StringSelectMuiField = ({
  input,
  options,
  blankOption,
  ...rest
}: Props) => {
  return (
    <Field
      name={input.name}
      component={MuiSelectField}
      floatingLabelStyle={{ fontFamily: "Poppins" }}
      fullWidth
      {...rest}
    >
      {blankOption && (
        <MenuItem key="null" value={null} primaryText={blankOption} />
      )}
      {options.map((option) => (
        <MenuItem key={option[0]} value={option[0]} primaryText={option[1]} />
      ))}
    </Field>
  )
}

StringSelectHtmlField.defaultProps = {
  blankOption: null
}

StringSelectMuiField.defaultProps = {
  blankOption: null
}

export default window.gon.rails_env === "test"
  ? StringSelectHtmlField
  : StringSelectMuiField
