import isEmpty from "lodash.isempty"
import isNil from "lodash.isnil"
import { applyReducerHash } from "../../redux_helpers"
import type {
  ReportApiResult,
  Action,
  OrgUsersApiResult
} from "../../state_types"

const reducers = {
  REPORT: (state?: ReportApiResult) => {
    if (!isEmpty(state) && state.reportId && isNil(state.subReportId)) {
      return state
    }

    return { syncing: "receiving" }
  },
  RECEIVED_REPORT: (
    state: ReportApiResult | null | undefined,
    action: Action
  ) => {
    return { data: action.data, reportId: action.reportId }
  },
  SUB_REPORT: (state?: ReportApiResult) => {
    if (!isEmpty(state) && state.reportId && state.subReportId) {
      return state
    }

    return { syncing: "receiving" }
  },
  RECEIVED_SUB_REPORT: (
    state: ReportApiResult | null | undefined,
    action: Action
  ) => {
    return {
      data: action.data,
      reportId: action.reportId,
      subReportId: action.subReportId
    }
  },
  REPORT_INDEX: () => {
    return { syncing: "receiving" }
  },
  RECEIVED_REPORT_INDEX: (
    state: ReportApiResult | null | undefined,
    action: Action
  ) => {
    return { data: action.data }
  }
}

export default function reportsReducer(
  state: ReportApiResult = null,
  action: Action
): OrgUsersApiResult | null | undefined {
  return applyReducerHash(reducers, state, action)
}
