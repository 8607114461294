import React from "react"
import styled from "@emotion/styled"
import SimpleRadioButton from "./SimpleRadioButton"

type Props = {
  submitAssessment: () => void
}

const SummaryWrapper = styled.div({
  padding: "2rem",
  fontSize: "1.5rem",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  marginTop: "7rem",
  p: {
    fontSize: "20px",
    fontWeight: 500,
    lineHeight: "28px"
  }
})

const SimpleRadioTitleSlide = ({ submitAssessment }: Props) => {
  return (
    <SummaryWrapper>
      <p>Your answers have been submitted!</p>
      <SimpleRadioButton
        onClick={submitAssessment}
        icon="xMark"
        text="Close"
        large
      />
    </SummaryWrapper>
  )
}

export default SimpleRadioTitleSlide
