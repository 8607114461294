import React from "react"
import { connect } from "react-redux"
import type {
  State,
  Errors,
  Report as ReportType,
  ReportApiResult
} from "../state_types"
import Theme from "../themes/Silver"
import Header from "../components/Header"
import LayoutWithHeader from "../components/LayoutWithHeader"
import ReportIndex from "../components/reports/ReportIndex"
import LoadablePage from "../components/LoadablePage"

type ReportIndexPageProps = {
  loadingErrors?: Errors // eslint-disable-line react/no-unused-prop-types
  loaded: boolean
  report: ReportType
  goToReport: (reportId: string, filters) => any
}

const ReportIndexPage = ({
  loaded,
  report,
  goToReport
}: ReportIndexPageProps) => (
  <Theme>
    <LayoutWithHeader
      header={
        <div>
          <Header />
        </div>
      }
    >
      <LoadablePage loaded={loaded}>
        {() => (
          <ReportIndex
            reports={report.reports}
            goToReport={(reportId) => goToReport(reportId, report.filters)}
          />
        )}
      </LoadablePage>
    </LayoutWithHeader>
  </Theme>
)

const mapStateToProps = (state: State) => {
  const report: ReportApiResult = state?.report

  return {
    loadingErrors: report?.errors,
    loaded: !report?.syncing,
    report: report?.data
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    goToReport: (reportId: string, filters) => {
      dispatch({
        type: "REPORT",
        payload: {
          reportId,
          query: filters
        }
      })
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ReportIndexPage)
