import get from "lodash.get"
import styled from "@emotion/styled"

// const column = '295px'
const twoColumns = "594px"
const threeColumns = "891px"
const fourColumns = "1188px"
const fiveColumns = "1485px"
const sixColumns = "1782px"

export const widths = {
  twoColumns,
  threeColumns,
  fourColumns,
  fiveColumns,
  sixColumns
}

type FlexValue = number | "none"

function noFlex(flex?: FlexValue) {
  return !flex || flex === "none"
}

const defaultFlex: FlexValue = 1

const defaultWidths = {
  "@media (min-width: 1800px)": { maxWidth: sixColumns },
  "@media (max-width: 1800px)": { maxWidth: fiveColumns },
  "@media (max-width: 1500px)": { maxWidth: fourColumns },
  "@media (max-width: 1200px)": { maxWidth: threeColumns },
  "@media (max-width: 900px)": { maxWidth: twoColumns },
  "@media (max-width: 600px)": {
    // maxWidth: column,
    justifyContent: "center"
  }
}

export const CardContainer = styled.div((props) => {
  const flex = get(props, "flex", defaultFlex)
  const gap = get(props, "gap", null)
  const responsive = get(props, "responsive", true)
  const cardWidths = responsive ? get(props, "widths", defaultWidths) : {}
  const flexValue = noFlex(flex) ? {} : { flex }

  const result = {
    ...flexValue,
    ...cardWidths,
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "left"
  }
  if (gap) {
    result.marginLeft = -gap
    result.marginRight = -gap
    result["& > *"] = {
      margin: gap
    }
  }

  return result
})
