import React from "react"
import type { ReactNode } from "react"
import styled from "@emotion/styled"
import Theme from "../themes/White"
import { Container, gutter } from "../components/Layout"
import RippleLogo from "../components/RippleLogo"

const Background = styled.div({
  height: "100%",
  display: "flex",
  flexDirection: "column",
  backgroundColor: "white",
  alignItems: "center"
})

const LogoContainer = styled.div({
  flexGrow: 0.2,
  flexShrink: 0,
  display: "flex",
  flexDirection: "column",
  justifyContent: "flex-start",
  alignItems: "center",
  marginBottom: gutter
})

const AuthFields = styled.div({
  display: "flex",
  flexDirection: "column",
  alignItems: "center"
})

type Props = {
  children: ReactNode
  hideLogo?: boolean
}

const LoginLayout = ({ children, hideLogo }: Props) => (
  <Theme>
    <Background>
      <Container>
        {!hideLogo && (
          <LogoContainer>
            <RippleLogo colour="blue" />
          </LogoContainer>
        )}
        <AuthFields>{children}</AuthFields>
      </Container>
    </Background>
  </Theme>
)

export default LoginLayout
