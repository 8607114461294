import omit from "lodash.omit"
import FetchError from "fetch-error"
import type { Link, State } from "./state_types"
import type { JsonResponse } from "./api_actions"

type ParamType = {
  headers?
  data?
  method?
}

type OptionType = {
  multipart?: boolean
}

export class AccessDeniedError extends Error {}

export function errorify(res: Response): Promise<{}> | Response {
  if (res.status >= 400 && res.status < 600) {
    if (res.json) {
      return res.json().then((json: JsonResponse) => {
        throw new FetchError(res.status, res.statusText, {
          errors: json.errors,
          json,
          response: res
        })
      })
    }

    throw new FetchError(res.status, res.statusText, { response: res })
  } else {
    return res
  }
}

function prepareJSONData(data) {
  return JSON.stringify(data)
}

function prepareMultipartData(data) {
  const formData = new FormData()

  for (const name in data) {
    if (Object.prototype.hasOwnProperty.call(data, name))
      formData.append(name, data[name] as string | Blob)
  }

  return formData
}

function saveNewCSRFToken(res: Response): Response {
  if (res.headers.get("X-CSRF-Token")) {
    window.rippleCSRF = res.headers.get("X-CSRF-Token")
  }

  return res
}
export function fetchWithHeaders(
  url: string,
  jwt: string | null | undefined = null,
  params: ParamType = {},
  options: OptionType = {}
): Promise<{} | Response> {
  const customHeaders = params.headers || {}
  const defaultHeaders: HeadersInit = {
    "X-Requested-With": "XMLHttpRequest",
    "X-CSRF-Token": window.rippleCSRF,
    AppVersionId: window.appVersionId,
    Accept: "application/json, text/plain, */*"
  }
  if (jwt) {
    defaultHeaders.Authorization = `Bearer ${jwt}`
  }
  let body
  if (params.data) {
    if (options.multipart) {
      body = prepareMultipartData(params.data)
    } else {
      defaultHeaders["Content-Type"] = "application/json"
      body = prepareJSONData(params.data)
    }
  }
  const otherParams = omit(params, ["headers", "data"])
  const fetchParams = {
    ...otherParams,
    headers: { ...defaultHeaders, ...customHeaders },
    body,
    credentials: "same-origin"
  }
  return window
    .fetch(url, fetchParams as RequestInit)
    .then(saveNewCSRFToken)
    .then(errorify)
}

export const jwtFromState = (state: State) => {
  return state.me && state.me.data ? state.me.data.token : null
}

export const responseToJson = (response: Response) => response.json()

export const fetchJson = (
  ...params: [string, string?, ParamType?, OptionType?]
) => fetchWithHeaders(...params).then(responseToJson)

export function fetchLink(
  link: Link,
  jwt: string | null | undefined = null,
  params: ParamType = {}
) {
  if (typeof link === "string") {
    return fetchJson(link, jwt, params)
  }
  return fetchJson(link.href, jwt, { method: link.method, ...params })
}

// https://stackoverflow.com/a/42274086
// Recommended to replace this with more
// reliable http://danml.com/download.htm library
export function downloadFile(
  url: string,
  jwt: string,
  filename: string,
  acceptHeader: string
) {
  fetchWithHeaders(url, jwt, {
    method: "GET",
    headers: { Accept: acceptHeader }
  })
    .then((response: Response) => response.blob())
    .then((blob) => {
      const linkUrl = window.URL.createObjectURL(blob)
      const a = document.createElement("a")

      a.href = linkUrl
      a.download = filename

      document.body.appendChild(a)
      a.click()

      a.remove()
    })
}

export function downloadCSV(url: string, jwt: string, filename: string) {
  downloadFile(url, jwt, filename, "text/csv")
}
