import React from "react"
import { connect, Provider } from "react-redux"
import { Store } from "redux"
import { PersistGate } from "redux-persist/es/integration/react"
import type { Persistor } from "redux-persist/lib/types"
import WithFlash from "./WithFlash"
import ErrorBoundary from "../components/shared/ErrorBoundary"
import StyleBoard from "../pages/StyleBoard"
import AssessmentPageContainer from "../pages/AssessmentPageContainer"
import NewRipple from "../pages/NewRipple"
import ImportRipplesPage from "../pages/import_ripples/Page"
import MuiTheme from "../themes/MuiTheme"
import RipplesIndex from "../pages/RipplesIndex"
import ArchivedRipples from "../pages/ArchivedRipples"
import RippleDetail from "../pages/RippleDetail"
import LoginPage from "../pages/LoginPage"
import ManageMFAPage from "../pages/ManageMFAPage"
import UpdateOTPPage from "../pages/UpdateOTPPage"
import DisableOTPPage from "../pages/DisableOTPPage"
import Loading from "../components/Loading"
import UserInvitePage from "../pages/UserInvitePage"
import UserEditPage from "../pages/UserEditPage"
import EditRippleSchedulePage from "../pages/EditRippleSchedulePage"
import EditRippleMembersPage from "../pages/EditRippleMembersPage"
import EditRippleClientPage from "../pages/EditRippleClientPage"
import EditRippleAssessmentPage from "../pages/EditRippleAssessmentPage"
import UserPasswordPage from "../pages/UserPasswordPage"
import OrgDashboard from "../pages/OrgDashboard"
import OrgArchiveRipplesPage from "../pages/org/ArchiveRipplesPage"
import QrManagePage from "../pages/qr/ManagePage"
import NotFoundPage from "../pages/NotFoundPage"
import ForgotPasswordPage from "../pages/ForgotPasswordPage"
import ForgotPasswordSentPage from "../pages/ForgotPasswordSentPage"
import NewPasswordPage from "../pages/NewPasswordPage"
import ReportIndexPage from "../pages/ReportIndexPage"
import ReportPage from "../pages/ReportPage"
import SectionReportPage from "../pages/SectionReportPage"
import DevLogin from "../components/dev/DevLogin"
import RemoveBackupCodes from "../pages/RemoveBackupCodes"
import CreateBackupCodes from "../pages/CreateBackupCodes"
import DisplayBackupCodes from "../pages/DisplayBackupCodes"

const pages = {
  HOME: LoginPage,
  STYLEBOARD: StyleBoard,
  ASSESS: AssessmentPageContainer,
  NEW_RIPPLE_STEP: NewRipple,
  NEW_RIPPLE_STEP_FOR_CLIENT: NewRipple,
  IMPORT_RIPPLES_STEP: ImportRipplesPage,
  RIPPLES: RipplesIndex,
  ARCHIVED_RIPPLES: ArchivedRipples,
  RIPPLE: RippleDetail,
  ORG: OrgDashboard,
  ORG_ARCHIVE_RIPPLES: OrgArchiveRipplesPage,
  EDIT_QR_CODES: QrManagePage,
  RIPPLE_ASSESSMENT_REPORT: RippleDetail,
  RIPPLE_SECTION_REPORT: RippleDetail,
  RIPPLE_QUESTION_REPORT: RippleDetail,
  RIPPLE_HEATMAP: RippleDetail,
  LOGIN: LoginPage,
  LOGIN_FAILED: LoginPage,
  LOGIN_ENTER_OTP: LoginPage,
  LOGIN_SETUP_OTP: LoginPage,
  UPDATE_OTP: UpdateOTPPage,
  UPDATE_OTP_FAILED: UpdateOTPPage,
  UPDATE_OTP_ENTER_OTP: UpdateOTPPage,
  UPDATE_OTP_SETUP_OTP: UpdateOTPPage,
  DISABLE_OTP: DisableOTPPage,
  DISABLE_OTP_FAILED: DisableOTPPage,
  DISABLE_OTP_ENTER_OTP: DisableOTPPage,
  DISPLAY_BACKUP_CODES: DisplayBackupCodes,
  CREATE_BACKUP_CODES_ENTER_OTP: CreateBackupCodes,
  CREATE_BACKUP_CODES_FAILED: CreateBackupCodes,
  CREATE_BACKUP_CODES: CreateBackupCodes,
  REMOVE_BACKUP_CODES_ENTER_OTP: RemoveBackupCodes,
  REMOVE_BACKUP_CODES_FAILED: RemoveBackupCodes,
  REMOVE_BACKUP_CODES: RemoveBackupCodes,
  MANAGE_MFA: ManageMFAPage,
  USER_INVITE: UserInvitePage,
  ACCEPT_USER_INVITE_SETUP_OTP: UserInvitePage,
  USER_EDIT: UserEditPage,
  USER_PASSWORD: UserPasswordPage,
  FORGOT_PASSWORD: ForgotPasswordPage,
  FORGOT_PASSWORD_SENT: ForgotPasswordSentPage,
  NEW_PASSWORD: NewPasswordPage,
  LOGOUT: LoginPage,
  ACCESS_DENIED: LoginPage,
  CLIENT_OUTDATED: LoginPage,
  EDIT_RIPPLE_SCHEDULE: EditRippleSchedulePage,
  EDIT_RIPPLE_MEMBERS: EditRippleMembersPage,
  EDIT_RIPPLE_CLIENT: EditRippleClientPage,
  EDIT_RIPPLE_ASSESSMENT: EditRippleAssessmentPage,
  REPORT_INDEX: ReportIndexPage,
  REPORT: ReportPage,
  SUB_REPORT: SectionReportPage,
  "@@redux-first-router/NOT_FOUND": NotFoundPage
}

const PageChooser = ({ location }: { location?: { type } }) => {
  const locationType = location ? location.type : null
  if (locationType == null) return null

  const Page = pages[locationType]
  if (Page == null) return null

  return <Page />
}

const mapStateToProps = (state) => {
  return {
    location: state.location
  }
}

const ConnectedPageChooser = connect(mapStateToProps)(PageChooser)

type Props = {
  store: Store
  persistor: Persistor
}

const App = ({ store, persistor }: Props) => {
  return (
    <MuiTheme>
      <Provider store={store}>
        <PersistGate persistor={persistor} loading={<Loading />}>
          <ErrorBoundary>
            <DevLogin />
            <WithFlash>
              <ConnectedPageChooser />
            </WithFlash>
          </ErrorBoundary>
        </PersistGate>
      </Provider>
    </MuiTheme>
  )
}

export default App
