import React from "react"
import CloudinaryUrlAvatarPreview from "./CloudinaryUrlAvatarPreview"
import NameAvatar from "./NameAvatar"
import AvatarProgress from "./AvatarProgress"
import AvatarContainer from "./AvatarContainer"

type PublicId = string

type FileProgress = {
  loading: true
  progress: number
  percentComplete: number
  error?
}

type Props = {
  value?: PublicId | FileProgress
  size: number
  name: string
}

function AvatarPreview({ value, name, size }: Props) {
  if (value) {
    if (typeof value === "string") {
      return <CloudinaryUrlAvatarPreview size={size} publicId={value} />
    }

    if (value.loading) {
      return (
        <AvatarProgress size={size} percentComplete={value.percentComplete} />
      )
    }

    if (value.error) {
      return <AvatarContainer size={size}>!!!</AvatarContainer>
    }
  }
  return <NameAvatar size={size} name={name} />
}

export default AvatarPreview
