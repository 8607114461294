import React from "react"
import styled from "@emotion/styled"
import ContainerDimensions from "react-container-dimensions"
import Vec2 from "vec2"
import reject from "lodash.reject"
import colours from "../themes/colours"
import SvgScoringWidget from "./svg/SvgScoringWidget"
import type { ImageScale } from "../contexts/assessment/types"
import ImageScaleDisplay from "./ImageScaleDisplay"

const WidgetContainer = styled.div<{ arcAtTop }>((props) => ({
  flex: 1,
  minHeight: 250,
  marginBottom: props.arcAtTop ? 0 : 20,
  position: "relative"
}))

const InsideWidgetContainer = styled.div<{ z }>((props) => ({
  position: "absolute",
  top: 0,
  bottom: 0,
  left: 0,
  right: 0,
  zIndex: props.z,
  display: "flex",
  flexDirection: "column"
}))

export type Props = {
  id: string
  onUpdate: (rating: number) => unknown
  value?: number
  outOf?: number
  previousValue?: number
  startColour?: string
  endColour?: string
  imageScale?: null | ImageScale
}

type Dimensions = {
  height: number
  width: number
}

const defaultOutOf = 100

function firstNonNull(...values) {
  const nonNullValues = reject(values, (value) => value === null)
  return nonNullValues[0]
}

const defaultValue = (outOf: number): number => outOf / 2

class ScoringWidget extends React.Component<Props> {
  static defaultProps = {
    startColour: colours.badScore,
    endColour: colours.goodScore,
    outOf: defaultOutOf,
    imageScale: null
  }

  outOf(): number {
    const { outOf = defaultOutOf } = this.props
    return outOf
  }

  controlValue(): number {
    const { value, previousValue } = this.props

    return firstNonNull(value, previousValue, defaultValue(this.outOf()))
  }

  thickessFor(dimension: number) {
    const maxThickness = 35
    const desired = dimension * 0.1
    return Math.min(desired, maxThickness)
  }

  handleChange = (value: number) => {
    this.props.onUpdate(value)
  }

  arcAtTop() {
    const { imageScale } = this.props
    return !imageScale
  }

  renderSvg = ({ height, width }: Dimensions) => {
    const { id, value, previousValue, startColour, endColour, imageScale } =
      this.props

    const arcAtTop = this.arcAtTop()
    const smallestDimension = Math.min(height, width)
    const arcThickness = this.thickessFor(smallestDimension)
    const bottomClip = arcAtTop ? arcThickness * 2 : 0
    const topClip = arcAtTop ? 0 : arcThickness * 2

    const heightRadius = height / 2 - arcThickness / 2 + bottomClip
    const widthRadius = width / 2 + arcThickness
    const maxRadius = 250
    const radius = Math.min(heightRadius, widthRadius, maxRadius)

    const centre = Vec2(width * 0.5, height * 0.5 + bottomClip - topClip)

    return (
      <React.Fragment>
        <InsideWidgetContainer z={2}>
          <SvgScoringWidget
            id={id}
            radius={radius}
            width={width}
            centre={centre}
            arcThickness={arcThickness}
            value={value}
            controlValue={this.controlValue()}
            previousValue={previousValue}
            outOf={this.outOf()}
            startColour={startColour as any}
            endColour={endColour as any}
            showCircle={!imageScale}
            arcAtTop={arcAtTop}
            handleChange={this.handleChange}
          />
        </InsideWidgetContainer>
        {imageScale && (
          <InsideWidgetContainer z={1}>
            <ImageScaleDisplay
              imageScale={imageScale}
              value={this.controlValue()}
              width={smallestDimension}
              topClip={topClip}
            />
          </InsideWidgetContainer>
        )}
      </React.Fragment>
    )
  }

  render() {
    return (
      <WidgetContainer arcAtTop={this.arcAtTop()}>
        <ContainerDimensions>{this.renderSvg}</ContainerDimensions>
      </WidgetContainer>
    )
  }
}

export default ScoringWidget
