import React from "react"
import Link from "redux-first-router-link"
import FontAwesome from "react-fontawesome"
import styled from "@emotion/styled"
import withQuery from "with-query"
import colours from "../../themes/colours"
import ReportLayout from "./ReportLayout"
import ReportFilters from "./ConnectedReportFilters"
import Heatmap from "../Heatmap"
import AvatarPreview from "../AvatarPreview"
import RippleGA from "../../ripple_ga"

const RowHeaderContainer = styled.div({
  overflow: "hidden",
  textOverflow: "ellipsis",
  wordBreak: "break-all",
  wordWrap: "break-word",
  whiteSpace: "nowrap",
  display: "flex",
  alignItems: "center"
})

const RowHeaderAvatar = styled.div({
  width: "30px",
  paddingLeft: "5px",
  paddingRight: "5px"
})

const RowHeaderText = styled.div({
  color: "black",
  flex: 1,
  fontWeight: "normal",
  overflow: "hidden",
  textOverflow: "ellipsis",
  wordBreak: "break-all",
  wordWrap: "break-word",
  whiteSpace: "nowrap",
  height: "37px",
  lineHeight: "37px",
  verticalAlign: "middle"
})

type Props = {
  title: string
  subtitle: string
  reportId: number
  reportUrl: string
  reportData: {
    columns: []
    rows: []
  }
  returnTo: {
    name: string
    to: string
  }
  filtersReadonly: boolean
}

const HeatmapReportElement = (params: Props) => {
  const {
    title,
    subtitle,
    reportUrl,
    reportId,
    reportData,
    returnTo,
    filtersReadonly
  } = params

  const renderRowHeader = (row) => {
    const Avatar = () => {
      if (row.avatar === undefined) return null

      return (
        <RowHeaderAvatar>
          <AvatarPreview value={row.avatar} name={row.name} size={30} />
        </RowHeaderAvatar>
      )
    }

    if (row.rippleUuid) {
      const linkTo = `/ripple/${row.rippleUuid}`
      const queryParams = {
        returnTo: {
          name: `Reports: ${title}`,
          url: reportUrl
        }
      }

      return (
        <Link
          to={withQuery(linkTo, queryParams)}
          style={{ width: "100%", textDecoration: "none" }}
        >
          <RowHeaderContainer>
            <Avatar />
            <RowHeaderText>
              {row.name}
              <FontAwesome
                style={{ paddingLeft: "0.5rem", color: colours.blue }}
                name="chevron-right"
              />
            </RowHeaderText>
          </RowHeaderContainer>
        </Link>
      )
    }

    return (
      <RowHeaderContainer>
        <Avatar />
        <RowHeaderText>{row.name}</RowHeaderText>
      </RowHeaderContainer>
    )
  }

  const renderValue = (cell) => cell.value

  const heatmapParams = {
    ...reportData,
    renderRowHeader,
    renderValue
  }

  return (
    <ReportLayout
      title={title}
      subtitle={subtitle}
      breadcrumb={returnTo}
      header={
        <ReportFilters
          reportId={reportId}
          disabled={filtersReadonly}
          showTextSearch
          showTimeframe
        />
      }
    >
      <Heatmap {...heatmapParams} />
    </ReportLayout>
  )
}

class HeatmapReport extends React.Component<any, any> {
  render() {
    return HeatmapReportElement(this.props as Props)
  }

  componentDidMount() {
    RippleGA.heatmapStart(this.props.filters.organisation, {
      organisation: this.props.filters.organisation,
      reportId: this.props.reportId,
      framework: this.props.filters.framework,
      reportUrl: this.props.reportUrl
    })
  }

  componentWillUnmount() {
    RippleGA.heatmapExit(this.props.filters.organisation, {
      organisation: this.props.filters.organisation,
      reportId: this.props.reportId,
      framework: this.props.filters.framework,
      reportUrl: this.props.reportUrl
    })
  }

  componentDidUpdate(prevProps) {
    if (prevProps.filters.organisation !== this.props.filters.organisation) {
      RippleGA.heatmapExit(prevProps.filters.organisation, {
        organisation: prevProps.filters.organisation,
        reportId: prevProps.reportId,
        framework: prevProps.filters.framework,
        reportUrl: prevProps.reportUrl
      })
      RippleGA.heatmapStart(this.props.filters.organisation, {
        organisation: this.props.filters.organisation,
        reportId: this.props.reportId,
        framework: this.props.filters.framework,
        reportUrl: this.props.reportUrl
      })
    }
  }
}

export default HeatmapReport
