import React from "react"
import { connect } from "react-redux"
import map from "lodash.map"
import capitalize from "lodash.capitalize"
import { submitRippleComment } from "../api_actions"
import CommentEntry from "../components/CommentEntry"
import type {
  ProposedAttachment,
  ProposedComment
} from "../contexts/feed/types"

type Props = {
  rippleUuid: string
  submitRippleComment: typeof submitRippleComment
}

type State = {
  showComment: boolean
  comment: ProposedComment
  sending: boolean
  errors?: {}
}

const emptyState: State = {
  showComment: false,
  sending: false,
  comment: {
    text: "",
    attachment: null
  }
}

class AddComment extends React.Component<Props, State> {
  state = emptyState

  onFocus = () => {
    this.setState({ showComment: true })
  }

  handleChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    const changes = { text: event.target.value }
    this.updateQuestionComment(changes)
  }

  handleSubmit = () => {
    const { comment } = this.state
    this.props.submitRippleComment(this.props.rippleUuid, comment, {
      onSubmitting: this.markAsSubmitting,
      onSubmitted: this.onSubmitted,
      onError: this.onError
    })
  }

  markAsSubmitting = () => {
    this.setState({ sending: true })
  }

  onSubmitted = () => {
    this.setState(emptyState)
  }

  onError = (errors) => {
    this.setState({ errors, sending: false })
  }

  handleCancel = () => {
    this.setState(emptyState)
  }

  handleAttachmentChange = (attachment?: ProposedAttachment) => {
    const changes = { attachment }
    this.updateQuestionComment(changes)
  }

  updateQuestionComment(changes) {
    const updateQuestionComment = (prevState) => ({
      comment: {
        ...prevState.comment,
        ...changes
      }
    })
    this.setState(updateQuestionComment)
  }

  canSubmit(): boolean {
    const { comment, sending } = this.state
    const commentValid = !!comment.text.trim() || !!comment.attachment

    return commentValid && !sending
  }

  errorsArray(): [string] {
    return map(
      this.state.errors || {},
      (value, key) => `${capitalize(key)} - ${value}`
    )
  }

  render() {
    return (
      <CommentEntry
        {...this.state}
        errors={this.errorsArray()}
        onFocus={this.onFocus}
        onCommentChange={this.handleChange}
        onAttachmentChange={this.handleAttachmentChange}
        onCancel={this.handleCancel}
        onSubmit={this.handleSubmit}
        canSubmit={this.canSubmit()}
        sending={this.state.sending}
      />
    )
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    submitRippleComment: (
      rippleUuid: string,
      comment: ProposedComment,
      callbacks
    ) => {
      dispatch(submitRippleComment(rippleUuid, comment, callbacks))
    }
  }
}

export default connect(null, mapDispatchToProps)(AddComment)
