import React from "react"
import styled from "@emotion/styled"
import colours from "../themes/colours"

const ButtonContainer = styled.label<{ disabled?; checked }>(
  (props) => ({
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    position: "relative",
    cursor: props.disabled ? "auto" : "pointer",
    userSelect: "none"
    // border: '1px solid red',
  }),
  ({ checked, disabled }) => {
    return checked || disabled
      ? {}
      : {
          ":hover > span": {
            backgroundColor: "#CCCCCC"
          }
        }
  }
)

const RadioButton = styled.input({
  cursor: "inherit",
  position: "absolute",
  opacity: 0.0
})

const Checkmark = styled.span<{ checked }>((props) => ({
  height: "20px",
  width: "20px",
  backgroundColor: props.checked ? colours.grey : "#EEEEEE",
  borderRadius: "50%",
  border: `1px solid ${colours.grey}`
}))

type Props = {
  name: string
  value: string
  checked: boolean
  onChange: (any) => any
  disabled?: boolean
}

const SdqRadioButton = ({
  name,
  value,
  checked,
  onChange,
  disabled
}: Props) => {
  return (
    <ButtonContainer checked={checked && !disabled} disabled={disabled}>
      <RadioButton
        type="radio"
        name={name}
        value={value}
        checked={checked && !disabled}
        onChange={onChange}
        disabled={disabled}
      />
      <Checkmark checked={checked && !disabled} />
    </ButtonContainer>
  )
}

SdqRadioButton.defaultProps = {
  disabled: false
}

export default SdqRadioButton
