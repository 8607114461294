import React from "react"
import styled from "@emotion/styled"
import Markdown from "react-markdown"
import { Container, gutter } from "../components/Layout"
import RippleLogo from "../components/RippleLogo"
import Actions from "../components/PageBottomActions"
import Button from "../components/Button"
import BlueTheme from "../themes/Blue"
import type { AssessmentIntro } from "../contexts/assessment/types"

const IntroPageContainer = styled.div({
  flex: 1,
  display: "flex",
  flexDirection: "column",
  overflow: "scroll"
})

const LogoContainer = styled.div({
  flexGrow: 0.2,
  flexShrink: 0,
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  marginBottom: gutter
})

const Body = styled.div({
  flex: 1
})

const FrameworkDescription = styled.div({
  whiteSpace: "pre-wrap"
})

const SectionList = styled.ul({
  listStyle: "none",
  padding: 0,
  fontSize: "1.2em",
  lineHeight: "140%",
  fontWeight: 500
})

type Props = {
  nextPage: () => unknown
  step: AssessmentIntro
}

const AssessmentIntroPage = ({ nextPage, step }: Props) => {
  return (
    <BlueTheme>
      <Container>
        <IntroPageContainer>
          <LogoContainer>
            <RippleLogo colour="white" />
          </LogoContainer>
          <Body>
            <FrameworkDescription>
              {step.introMessage && <Markdown>{step.introMessage}</Markdown>}
            </FrameworkDescription>
            <SectionList>
              {step.sectionTitles.map((title) => (
                <li key={title}>{title}</li>
              ))}
            </SectionList>
          </Body>
          <Actions>
            <Button key="right" onClick={nextPage}>
              Start
            </Button>
          </Actions>
        </IntroPageContainer>
      </Container>
    </BlueTheme>
  )
}

export default AssessmentIntroPage
