import React from "react"
import ContainerDimensions from "react-container-dimensions"
import AssessmentList from "./AssessmentList"
import AssessmentsReport from "../../contexts/assessment/AssessmentsReport"
import type { DialogFunctions } from "../BubbleDialogContainer"
import type { UserHash } from "../../state_types"

type Props = {
  users: UserHash
  report: AssessmentsReport
  rippleUuid: string
  dialog: DialogFunctions
  titleWidth?: number
  outOf: number
  queryParams?
}

const YearlyAssessmentLists = ({
  users,
  report,
  rippleUuid,
  dialog,
  titleWidth,
  outOf,
  queryParams
}: Props) => {
  const currentYear = new Date().getFullYear()

  return (
    <ContainerDimensions>
      {({ width }) => (
        <div style={{ flex: 1 }}>
          {report.mapYears((year, data) => (
            <AssessmentList
              users={users}
              width={width}
              key={year}
              year={year}
              showYear={year !== currentYear}
              teamAssessments={data}
              rippleUuid={rippleUuid}
              dialog={dialog}
              titleWidth={titleWidth}
              outOf={outOf}
              queryParams={queryParams}
            />
          ))}
        </div>
      )}
    </ContainerDimensions>
  )
}

YearlyAssessmentLists.defaultProps = {
  titleWidth: 50
}

export default YearlyAssessmentLists
