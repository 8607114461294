import { redirect } from "redux-first-router"
import isEmpty from "lodash.isempty"
import mapValues from "lodash.mapvalues"
import UserStateDecorator from "./contexts/user/UserStateDecorator"
import type { GetStateFunc, DispatchFunction, Action } from "./state_types"

type ThunkType = (dispatch: DispatchFunction, getState: GetStateFunc) => void
type ThunkOrAction = Action | ThunkType

type RequirementOptions = {
  otherwise?: ThunkOrAction
  then?: ThunkOrAction
}

function redirectToLoginForm(
  dispatch: DispatchFunction,
  getState: GetStateFunc
) {
  const location = getState().location
  const previousAction = { type: location.type, payload: location.payload }
  const action = redirect({
    type: "LOGIN",
    payload: { afterLogin: previousAction }
  })
  dispatch(action)
}

function dispatchActionOrThunk(
  dispatch: DispatchFunction,
  getState: GetStateFunc,
  target: ThunkOrAction
) {
  if (target) {
    if (typeof target === "function") {
      target(dispatch, getState)
    } else {
      dispatch(target)
    }
  }
}

export const requiresLoggedIn = (options: RequirementOptions = {}) => {
  return (dispatch: DispatchFunction, getState: GetStateFunc) => {
    const userState = new UserStateDecorator(getState())
    const { then, otherwise } = options
    if (userState.loggedIn()) {
      dispatchActionOrThunk(dispatch, getState, then)
    } else if (otherwise) {
      dispatchActionOrThunk(dispatch, getState, otherwise)
    } else {
      redirectToLoginForm(dispatch, getState)
    }
  }
}

export const requiresLoggedOut = (options: RequirementOptions = {}) => {
  return (dispatch: DispatchFunction, getState: GetStateFunc) => {
    const userState = new UserStateDecorator(getState())
    const { then, otherwise } = options
    if (userState.loggedIn()) {
      if (otherwise) {
        dispatchActionOrThunk(dispatch, getState, otherwise)
      }
    } else {
      dispatchActionOrThunk(dispatch, getState, then)
    }
  }
}

export function goToUserHome() {
  return (dispatch: DispatchFunction, getState: GetStateFunc) => {
    const userState = new UserStateDecorator(getState())
    const adminOrgs = userState.orgsAdministering()

    if (isEmpty(adminOrgs)) {
      dispatch({ type: "RIPPLES" })
    } else {
      const firstOrg = adminOrgs[0]
      dispatch({ type: "ORG", payload: { slug: firstOrg.slug } })
    }
  }
}

export function redirectToRoot() {
  return (dispatch: DispatchFunction) => {
    dispatch({ type: "HOME" })
  }
}

export function wrapThunkWithHelp(existingThunk, hideHelp = false) {
  return (dispatch) => {
    if (typeof window.zE === "function") {
      if (hideHelp) {
        window.zE(() => {
          window.zE.hide()
        })
      } else {
        window.zE(() => {
          window.zE.show()
        })
      }
    }
    if (existingThunk) dispatch(existingThunk)
  }
}

export function decorateRoutesWithHelp(input) {
  return mapValues(input, (value) => {
    let result = value
    if (typeof result === "string") {
      result = { path: value }
    }
    return {
      ...result,
      thunk: wrapThunkWithHelp(result.thunk, result.hideHelp)
    }
  })
}
