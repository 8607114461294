import React from "react"
import get from "lodash.get"
import { connect } from "react-redux"
import styled from "@emotion/styled"
import Theme from "../themes/White"
import { submitEditRippleForm } from "../api_actions"
import EditRippleMembersForm from "../components/EditRippleMembersForm"
import LoadablePage from "../components/LoadablePage"
import type { DispatchFunction, State } from "../state_types"

const EditPageContainer = styled.div({
  display: "flex",
  flexDirection: "column",
  flexGrow: 1
})

type Props = {
  submitEditRippleForm: () => any
  onSubmit: DispatchFunction
  hasRipple: boolean
  roles?: Array<string>
}

class EditRippleMembersPage extends React.Component<Props> {
  handleSubmit = () => {
    this.props.onSubmit()
  }

  renderPageBody = () => {
    return (
      <EditPageContainer>
        <EditRippleMembersForm
          onSubmit={this.handleSubmit}
          roles={this.props.roles}
        />
      </EditPageContainer>
    )
  }

  render() {
    const { hasRipple } = this.props
    return (
      <Theme>
        <LoadablePage loaded={hasRipple}>{this.renderPageBody}</LoadablePage>
      </Theme>
    )
  }
}

const mapStateToProps = (state: State) => {
  const hasRipple = !!get(state, "rippleDetails.data.ripple")
  return {
    hasRipple,
    roles: get(state, "rippleDetails.data.ripple.frameworkInfo.roles")
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    onSubmit: () => {
      dispatch(submitEditRippleForm())
    }
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EditRippleMembersPage)
