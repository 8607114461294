import React from "react"
import { connect } from "react-redux"
import isNil from "lodash.isnil"
import get from "lodash.get"
import debounce from "lodash.debounce"
import windowDimensions from "react-window-dimensions"
import Header from "../components/Header"
import { RippleDetailApiResult } from "../state_types"
import LoadablePage from "../components/LoadablePage"
import LayoutWithHeader from "../components/LayoutWithHeader"
import CommentsContainer from "../components/CommentsContainer"
import RippleDetailHeader from "../components/RippleDetailHeader"
import TwoColumnResponsive from "../components/TwoColumnResponsive"
import RippleDataReport from "../components/team_assessment/RippleDataReport"
import Theme from "../themes/Silver"

function displayMobile(width: number): boolean {
  if (width <= 768) {
    return true
  }
  return false
}

type Props = {
  rippleDetails: RippleDetailApiResult
  assessmentNumber: number
  sectionId: string
  questionId: string
  windowWidth: number
  showMessages: boolean
  showHeatmap: boolean
  returnTo?: {
    url: string
    name: string
  }
}

class RippleDetail extends React.Component<Props> {
  isDataLoaded() {
    const { rippleDetails } = this.props
    return !isNil(get(rippleDetails, "data")) && !get(rippleDetails, "syncing")
  }

  rippleDetails() {
    return get(this.props, "rippleDetails.data") || {}
  }

  renderRippleDetailHeader(width: number) {
    const { ripple } = this.rippleDetails()
    const showMobile = displayMobile(width)

    return (
      <RippleDetailHeader
        ripple={ripple}
        collapseDetails={showMobile}
        infoPanelWidth={500}
      />
    )
  }

  renderRippleComments = () => {
    const { ripple } = this.rippleDetails()
    return (
      <CommentsContainer rippleUuid={ripple.uuid} rippleFeed={ripple.feed} />
    )
  }

  renderSlidableReport = () => {
    const { assessmentNumber, sectionId, questionId, showHeatmap, returnTo } =
      this.props
    const props = {
      data: this.rippleDetails(),
      assessmentNumber,
      sectionId,
      questionId,
      showHeatmap,
      returnTo
    }

    return <RippleDataReport {...props} />
  }

  render() {
    const width = this.props.windowWidth

    return (
      <Theme>
        <LayoutWithHeader
          header={
            <div>
              <Header />
            </div>
          }
        >
          <LoadablePage loaded={this.isDataLoaded()}>
            {() => (
              <div
                style={{ flex: 1, display: "flex", flexDirection: "column" }}
              >
                {this.renderRippleDetailHeader(width)}
                {this.renderBody(width)}
              </div>
            )}
          </LoadablePage>
        </LayoutWithHeader>
      </Theme>
    )
  }

  renderBody(width: number) {
    const { showMessages } = this.props

    if (!showMessages) {
      return this.renderSlidableReport()
    }

    return (
      <TwoColumnResponsive
        mobileView={displayMobile(width)}
        columns={[
          {
            name: "reports",
            render: this.renderSlidableReport
          },
          {
            name: "feed",
            render: this.renderRippleComments,
            maxWidth: 400
          }
        ]}
      />
    )
  }
}

const RippleDetailWithWidth = windowDimensions({
  take: () => ({ windowWidth: window.innerWidth }),
  debounce: (onResize) => debounce(onResize, 100)
})(RippleDetail)

type ReturnToQuery = {
  "returnTo[name]"?: string
  "returnTo[url]"?: string
}

const buildReturnTo = (query: ReturnToQuery | null) => {
  if (!query) return null

  const name = query["returnTo[name]"]
  const url = query["returnTo[url]"]
  return url ? { name: name || "Back", url } : null
}

const mapStateToProps = (state) => {
  const rippleDetails = state.rippleDetails || {}
  const location = state.location || {}
  const assessmentPayload = location.payload.assessmentNumber
  const showMessages = get(rippleDetails, "data.ripple.feed") != null

  return {
    rippleDetails,
    assessmentNumber: assessmentPayload
      ? parseInt(assessmentPayload, 10)
      : assessmentPayload,
    sectionId: state.location.payload.sectionId,
    questionId: state.location.payload.questionId,
    showHeatmap: state.location.type === "RIPPLE_HEATMAP",
    showMessages,
    returnTo: buildReturnTo(location.query)
  }
}

export default connect(mapStateToProps)(RippleDetailWithWidth)
