import React from "react"
import styled from "@emotion/styled"
import omit from "lodash.omit"
import type { WrappedFieldInputProps } from "redux-form"
import { FormErrorMessage } from "./TextStyles"
import colours from "../themes/colours"
import IconFileInput from "./IconFileInput"
import AvatarPreview from "./AvatarPreview"
import { uploadFile } from "./helpers/cloudinary"

const AvatarContainer = styled.div({
  display: "flex",
  alignItems: "center",
  marginBottom: "1.5em",
  flexDirection: "column"
})

const avatarSize = 120

const AvatarWidget = styled.div({
  marginRight: "0.5em",
  position: "relative",
  width: avatarSize,
  height: avatarSize
})

const ButtonContainer = styled.div({
  position: "absolute",
  bottom: 0,
  right: 0
})

const AvatarText = styled.div({
  display: "flex",
  flexDirection: "column",
  alignItems: "flex-start",
  marginLeft: "1em",
  paddingTop: "20px"
})

const FadedText = styled.div({
  display: "flex",
  fontWeight: 300,
  color: colours.deemphasisedText,
  fontSize: "0.7em",
  lineHeight: "140%"
})

const Instruction = styled.div({
  display: "flex"
})

type Props = {
  input: WrappedFieldInputProps
  meta: {
    error?: string
  }
  personName: string
  defaultValue?: string
}

class AvatarUploader extends React.Component<Props> {
  onFileSelection = (file?: Blob) => {
    if (file)
      uploadFile(
        file,
        this.onFileUploadSuccess,
        this.onFileUploadFailure,
        this.onFileUploadProgress
      )
  }

  onFileUploadSuccess = (url: string) => {
    const onChange = this.props.input.onChange
    onChange(url)
  }

  onFileUploadFailure = (error: string) => {
    const onChange = this.props.input.onChange
    onChange({ error: true, message: error })
  }

  onFileUploadProgress = (percentComplete: number) => {
    const onChange = this.props.input.onChange
    onChange({ loading: true, percentComplete })
  }

  render() {
    const { personName, input, defaultValue } = this.props
    const value = input.value || defaultValue
    const fileInputProps = omit(input, ["onChange", "value"])
    const { error } = this.props.meta

    return (
      <AvatarContainer>
        <AvatarWidget>
          <AvatarPreview size={avatarSize} value={value} name={personName} />
          <ButtonContainer>
            <IconFileInput
              icon="plus"
              {...fileInputProps}
              onChange={this.onFileSelection}
            />
          </ButtonContainer>
        </AvatarWidget>
        <AvatarText>
          <Instruction>
            <FadedText>
              must be under 4 MB and jpeg, png, or tiff format
            </FadedText>
          </Instruction>
          {error && <FormErrorMessage>{error}</FormErrorMessage>}
        </AvatarText>
      </AvatarContainer>
    )
  }
}

export default AvatarUploader
