import React from "react"
import get from "lodash.get"
import { connect } from "react-redux"
import { reduxForm, formValueSelector, InjectedFormProps } from "redux-form"
import { stepValidator } from "../contexts/ripple_form/wizard"
import type {
  FrameworkInfo,
  RippleFormSettings
} from "../contexts/ripple_form/types"
import RippleAssessmentForm from "../components/RippleAssessmentForm"
import { todayIsoDate } from "../components/helpers/date"

interface IProps {
  frameworks: Array<FrameworkInfo>
  formSettings: RippleFormSettings
  currentFrameworkId?: string
}

class NewRippleAssessmentDetails extends React.Component<
  InjectedFormProps<IProps> & IProps
> {
  handleSubmit = () => {}

  render() {
    const noFramework = !this.props.currentFrameworkId
    const { frameworks, formSettings, currentFrameworkId } = this.props

    return (
      <form onSubmit={this.handleSubmit}>
        <RippleAssessmentForm
          noFramework={noFramework}
          frameworks={frameworks}
          formSettings={formSettings}
          currentFrameworkId={currentFrameworkId}
        />
      </form>
    )
  }
}

const NewRippleAssessmentDetailsForm = reduxForm({
  form: "ripple",
  enableReinitialize: true,
  keepDirtyOnReinitialize: true,
  destroyOnUnmount: false,
  forceUnregisterOnUnmount: true,
  validate: stepValidator("assessment")
})(NewRippleAssessmentDetails)

const selector = formValueSelector("ripple")

const mapStateToProps = (state) => {
  const initialValues = get(state, "rippleForm.initialValues")

  const result = {
    currentFrameworkId: selector(state, "framework"),
    initialValues: null
  }

  if (initialValues) {
    result.initialValues = {
      ...initialValues,
      members: [{ roleName: "viewer" }],
      startOn: todayIsoDate(),
      periodType: "week",
      period: 1,
      daysOpen: 1
    }
  }

  return result
}

const ConnectedNewRippleAssessmentDetailsForm = connect<any, any, IProps>(
  mapStateToProps
)(NewRippleAssessmentDetailsForm)

export default ConnectedNewRippleAssessmentDetailsForm
