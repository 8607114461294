import React from "react"
import isNil from "lodash.isnil"
import map from "lodash.map"
import type { ReactNode } from "react"

type Value = string | number

type Option = [Value, string]

type Props = {
  options: Array<Option>
  renderButton: (
    _option: Array<any>,
    _checked: boolean,
    _onChange: (_) => any
  ) => ReactNode
  onChange: (_value?: number) => any
  value
}

class ControlledRadioButtonGroup extends React.Component<Props> {
  handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const stringValue = event.target.value
    const value = isNil(stringValue) ? null : parseInt(stringValue, 10)
    this.props.onChange(value)
  }

  render() {
    const { options, renderButton } = this.props
    const { value } = this.props
    return map(options, (option) => {
      const checked = String(option[0]) === String(value)
      return renderButton(option, checked, this.handleChange)
    })
  }
}

export default ControlledRadioButtonGroup
