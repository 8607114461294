import React from "react"
import { connect } from "react-redux"
import get from "lodash.get"
import { DropDownFilter, SearchFilter, FilterGridContainer } from "../Filters"
import type { DispatchFunction } from "../../state_types"
import RipplesFilterState from "../ripples/RipplesFilterState"

type OrgListItem = {
  value: string
  key: string
}

type Props = {
  sortBy?: number
  rippleOrgs?: Array<OrgListItem>
  changeRippleList?: DispatchFunction
  organisation?
  filters?
  ripples?
  order?
  group?
  onChange?
}

class FilterGrid extends React.Component<Props> {
  render() {
    const { filters, organisation, order, group, onChange } = this.props

    const buildChangeHandler = (key: string) => (newValue: string) => {
      if (key === "organisation") {
        filters.group = undefined
      }
      return onChange({ ...filters, [key]: newValue, page: undefined })
    }

    return (
      <FilterGridContainer>
        <DropDownFilter
          className="organisation"
          {...organisation}
          onChange={buildChangeHandler("organisation")}
        />

        <DropDownFilter {...group} onChange={buildChangeHandler("group")} />

        <SearchFilter
          value={filters.searchTerm}
          onChange={buildChangeHandler("searchTerm")}
        />

        <DropDownFilter {...order} onChange={buildChangeHandler("order")} />
      </FilterGridContainer>
    )
  }
}

const mapStateToProps = (state) => {
  const filterState = new RipplesFilterState(state, "archivedRipples")

  const mostRecentOrganisationSlug: string = get(
    state,
    "memory.mostRecent.organisationSlug"
  )

  const result = {
    filters: filterState.filters,
    organisation: {
      label: "Organisation",
      value: filterState.filters.organisation,
      items: filterState.availableOrgs
    },
    order: {
      label: "Sort by",
      value: filterState.filters.order,
      items: filterState.availableOrders
    },
    group: {
      label: "Group",
      value: filterState.filters.group,
      items: filterState.availableGroups
    }
  }

  return {
    ...result,
    mostRecentOrganisationSlug
  }
}

const mapDispatchToProps = (dispatch) => ({
  onChange: (newFilters: { organisation }) => {
    dispatch({
      type: "ARCHIVED_RIPPLES",
      query: newFilters
    })
    dispatch({
      type: "REMEMBER_ORG_SLUG",
      payload: newFilters.organisation
    })
  }
})

export default connect(mapStateToProps, mapDispatchToProps)(FilterGrid)
