import React, { useMemo } from "react"
import Markdown from "react-markdown"
import styled from "@emotion/styled"
import findIndex from "lodash.findindex"
import AssessmentHeader from "../../AssessmentHeader"
import { mediaQueries } from "../../Layout"
import colours from "../../../themes/colours"
import RadioQuestion from "../../RadioQuestion"
import type {
  Question,
  QuestionInput,
  Framework
} from "../../../contexts/assessment/types"
import Trees from "../../../images/pedsql/trees.png"
import splitFrameworkIntoQuestionSets from "../../../utils/splitFrameworkIntoQuestionSets"

const imageSources = {
  trees: Trees
}

const QuestionWrapper = styled.div<{ src: string }>(({ src }) => ({
  background: `url(${imageSources[src]}) no-repeat bottom`,
  backgroundSize: "contain",
  height: "100%",
  width: "100%",
  boxSizing: "border-box",
  display: "flex",
  flexDirection: "column"
}))

const QuestionDetails = styled.div({
  margin: "auto",
  padding: "0 20px",
  [mediaQueries.desktop]: {
    padding: "1rem 3rem 1rem 3rem"
  }
})

const QuestionHeader = styled(AssessmentHeader)({
  justifyContent: "flex-start",
  flexGrow: 0,
  flexShrink: 0
})

const QuestionBody = styled.div({
  fontSize: "24px",
  textAlign: "center",
  fontWeight: 600,
  marginBottom: "10px",
  lineHeight: "140%"
})

const SectionLabel = styled.div({
  borderBottom: `1px solid ${colours.lightGrey}`,
  fontWeight: "bold",
  padding: "6px 18px",
  fontSize: "12px"
})

type SimpleRadioQuestionProps = {
  step: Question
  current: boolean
  input?: QuestionInput
  updateRating: (rating: number) => unknown
  framework: Framework
}

const defaultRating = null

const SimpleRadioQuesiton: React.FC<SimpleRadioQuestionProps> = ({
  input,
  step,
  current,
  updateRating,
  framework
}) => {
  const { options, steps } = framework
  const {
    sectionNumber,
    sectionTitle,
    question,
    backgroundImage,
    instructions
  } = step

  const rating = input?.rating ?? defaultRating

  const questionArray = useMemo(
    () => splitFrameworkIntoQuestionSets(steps),
    [steps]
  )

  const questionSet = questionArray.find((set) =>
    set.find((q) => q.id === step.id)
  )

  const questionNumber = findIndex(questionSet, ["id", step.id]) + 1

  return (
    <QuestionWrapper
      src={backgroundImage}
      id={current ? "current-question" : null}
    >
      <QuestionHeader>
        <SectionLabel>
          {sectionNumber}: {sectionTitle}
        </SectionLabel>
      </QuestionHeader>
      <QuestionDetails>
        <Markdown>{instructions}</Markdown>
        <QuestionBody>
          {questionNumber}. {question}
        </QuestionBody>
        <RadioQuestion
          questionData={question}
          options={options}
          updateRating={updateRating}
          hasError={false}
          rating={rating}
        />
      </QuestionDetails>
    </QuestionWrapper>
  )
}

export default SimpleRadioQuesiton
