import get from "lodash.get"

class DetailsApiResult {
  apiResult

  constructor(apiResult) {
    this.apiResult = apiResult
  }

  validForRipple(uuid) {
    return (
      get(this.apiResult, "data.ripple.uuid") === uuid &&
      !get(this.apiResult, "changed")
    )
  }
}

export default DetailsApiResult
