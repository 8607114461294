import React from "react"
import styled from "@emotion/styled"
import AssessmentsReport from "../contexts/assessment/AssessmentsReport"
import { Ripple } from "../state_types"
import colours from "../themes/colours"
import Breadcrumb from "./Breadcrumb"
import { scoreColour } from "../utils/scoreColour"

const Container = styled.div({
  backgroundColor: colours.silver,
  width: "100%"
})

const HeatmapTable = styled.table({
  paddingTop: "10px",
  width: "100%",
  borderSpacing: "1px",
  fontSize: "80%",
  tableLayout: "fixed"
})

const HeatmapTableHeader = styled.thead({})

const Header = styled.th<{ numberOfAttributes: number }>((props) => ({
  width: `${100 / (props.numberOfAttributes + 2)}%`,
  maxWidth: `${100 / (props.numberOfAttributes + 2)}%`,
  overflow: "hidden",
  textOverflow: "ellipsis",
  wordBreak: "break-all",
  wordWrap: "break-word",
  whiteSpace: "nowrap"
}))

const HeatmapRow = styled.tr({})

const Item = styled.td<{ gradientColor?: string; numberOfAttributes: number }>(
  (props) => ({
    backgroundColor: `${props.gradientColor}`,
    textAlign: "right",
    width: `${100 / (props.numberOfAttributes + 2)}%`,
    padding: "0 4px",
    color: colours.darkGrey
  })
)

type Props = {
  ripple: Ripple
  report: AssessmentsReport
  returnTo?: {
    name: string
    url: string
  }
}

const renderHeader = (frameworkAttributes) => {
  const numberOfAttributes = frameworkAttributes.length
  return (
    <HeatmapTableHeader>
      <HeatmapRow>
        <Header numberOfAttributes={numberOfAttributes} />
        <Header numberOfAttributes={numberOfAttributes}>Score</Header>
        {frameworkAttributes.map((frameworkAttribute) => {
          return (
            <Header
              key={`Attribute${frameworkAttribute}`}
              numberOfAttributes={numberOfAttributes}
            >
              {frameworkAttribute}
            </Header>
          )
        })}
      </HeatmapRow>
    </HeatmapTableHeader>
  )
}

const findAttributeScore = (frameworkAttribute, teamAssessment) => {
  if (teamAssessment.score) {
    const attribute = teamAssessment.attributes.find((assessmentAttribute) => {
      return assessmentAttribute.title === frameworkAttribute
    })
    return attribute ? attribute.score : null
  }

  return null
}

const AssessmentHeatmap = ({ ripple, report, returnTo }: Props) => {
  const returnToDetails = returnTo || {
    url: `/ripple/${ripple.uuid}/`,
    name: "Heatmap"
  }

  const frameworkAttributes = ripple.frameworkInfo.attributes
  const numberOfAttributes = frameworkAttributes.length

  return (
    <Container>
      <Breadcrumb to={returnToDetails.url} name={returnToDetails.name} />
      <HeatmapTable>
        {renderHeader(frameworkAttributes)}
        <tbody>
          {report.teamAssessments.map((teamAssessment) => {
            const overallColour = scoreColour(
              teamAssessment.score,
              teamAssessment.thresholds
            )

            return (
              <HeatmapRow key={teamAssessment.id}>
                <Item numberOfAttributes={numberOfAttributes}>
                  {teamAssessment.date}
                </Item>
                <Item
                  numberOfAttributes={numberOfAttributes}
                  gradientColor={overallColour}
                >
                  {teamAssessment.score}
                </Item>
                {frameworkAttributes.map((frameworkAttribute) => {
                  const attributeScore = findAttributeScore(
                    frameworkAttribute,
                    teamAssessment
                  )
                  const colour = scoreColour(
                    attributeScore,
                    teamAssessment.thresholds
                  )
                  return (
                    <Item
                      key={frameworkAttribute}
                      gradientColor={colour}
                      numberOfAttributes={numberOfAttributes}
                    >
                      {attributeScore}
                    </Item>
                  )
                })}
              </HeatmapRow>
            )
          })}
        </tbody>
      </HeatmapTable>
    </Container>
  )
}

export default AssessmentHeatmap
