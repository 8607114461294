import traverse from "traverse"
import isEmpty from "lodash.isempty"

function addLeafPath(results, _node) {
  if (this.isLeaf && !isEmpty(this.path)) results.push(this.path.join("."))
  return results
}

export const flatFieldNamesFromErrors = (errors) => {
  return traverse(errors).reduce(addLeafPath, [])
}
