import React from "react"
import { Transition } from "react-transition-group"

const duration = 300

const defaultStyle = {
  transition: `height ${duration}ms ease-in-out, opacity ${duration}ms ease-in-out`,
  opacity: 0,
  height: "0"
}

const transitionStyles = {
  entering: { height: "0px", opacity: 0 },
  entered: { height: "100%", opacity: 100 }
}

const FadeIn: React.FC<{
  in: boolean
}> = ({ in: inProp, children }) => (
  <Transition in={inProp} timeout={duration}>
    {(state) => (
      <div
        style={{
          ...defaultStyle,
          ...transitionStyles[state]
        }}
      >
        {children}
      </div>
    )}
  </Transition>
)

const Fade: React.FC<{ show: boolean }> = ({ show, children }) => {
  return <FadeIn in={show}>{children}</FadeIn>
}

export default Fade
