import React from "react"
import type { Dispatch } from "redux"
import filter from "lodash.filter"
import includes from "lodash.includes"
import map from "lodash.map"
import styled from "@emotion/styled"
import { connect } from "react-redux"
import Link from "redux-first-router-link"
import get from "lodash.get"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faCheck } from "@fortawesome/free-solid-svg-icons"
import { IconProp } from "@fortawesome/fontawesome-svg-core"
import AvatarPreview from "./AvatarPreview"
import AppBar from "./AppBar"
import Popover from "./Popover"
import colours from "../themes/colours"
import type { User, OrgMembership } from "../state_types"
import logo from "../images/ripple_logo_black.svg"
import OrgAdminNav from "./OrgAdminNav"
import { logout } from "../api_actions"

const headerImageSize = 35

const Text = styled.div<{ fontSize; lineHeight; fontWeight?; paddingBottom? }>(
  (props) => {
    return {
      color: "#000000",
      fontFamily: "Poppins",
      fontSize: props.fontSize || "12px",
      lineHeight: props.lineHeight || "16px",
      paddingBottom: props.paddingBottom || 0,
      fontWeight: props.fontWeight || "normal"
    }
  }
)

const MenuContainer = styled.div({
  display: "flex",
  width: "110px",
  justifyContent: "flex-end",
  alignItems: "center",
  paddingRight: "0px"
})

const Item = styled.li<{ styles }>((props) => ({
  display: "grid",
  gridTemplateColumns: "1.5em 1fr",
  alignItems: "center",
  padding: "10px 20px",
  borderBottom: "1px solid #E3E2E2",
  color: colours.blue,
  fontFamily: "Poppins",
  fontSize: "14px",
  fontWeight: "600",
  lineHeight: "23px",
  listStyleType: "none",
  ...props.styles,
  ":hover": {
    color: colours.white,
    backgroundColor: colours.blue
  }
}))

const RippleLogo = styled.img(({ width }) => ({
  width,
  height: width
}))

const AvatarContainer = styled.a({
  cursor: "pointer",
  marginLeft: "10px",
  textDecoration: "none"
})

type ItemProps = {
  to: () => void
  text?: string
  selected?: boolean
  styles?
}

const MenuItem = ({ to, text, selected, styles }: ItemProps) => {
  return (
    <a
      href="#"
      onClick={() => to()}
      style={{
        textDecoration: "none"
      }}
    >
      <Item styles={styles}>
        {selected && <FontAwesomeIcon icon={faCheck as IconProp} />}
        <span style={{ gridColumn: 2 }}>{text}</span>
      </Item>
    </a>
  )
}

MenuItem.defaultProps = { styles: {} }

type MenuProps = {
  user: User
  currentOrg?: string
  dispatch: Dispatch<any>
  marginTop?: string
}

type MenuState = {
  open: boolean
  anchorEl?: HTMLElement
}

function withAdminRole(orgs: Array<OrgMembership>): Array<OrgMembership> {
  return filter(orgs, (org: OrgMembership) => includes(org.roles, "admin"))
}

class RightMenu extends React.Component<MenuProps, MenuState> {
  constructor(props) {
    super(props)

    this.handleClick = this.handleClick.bind(this)
    this.handleRequestClose = this.handleRequestClose.bind(this)

    this.state = {
      open: false,
      anchorEl: null
    }
  }

  handleClick = (event) => {
    event.preventDefault()

    this.setState({
      open: true,
      anchorEl: event.currentTarget
    })
  }

  handleRequestClose = () => {
    this.setState({
      open: false
    })
  }

  render() {
    const { user, currentOrg, dispatch } = this.props
    const { open, anchorEl } = this.state
    const { avatar, name, orgs } = user
    const adminOrgs = withAdminRole(orgs)

    const changeOrg = (org) => {
      dispatch({ type: "ORG", payload: { slug: org.slug } })
      dispatch({
        type: "REMEMBER_ORG_SLUG",
        payload: org.slug
      })
    }

    return (
      <MenuContainer>
        <Text fontSize="14px" lineHeight="20px">
          {name}
        </Text>
        <AvatarContainer
          id="header-avatar-link"
          href="#"
          onClick={this.handleClick}
        >
          <AvatarPreview
            size={headerImageSize}
            value={avatar}
            name={name && name[0]}
          />
        </AvatarContainer>
        <Popover
          open={open}
          anchorEl={anchorEl}
          handleRequestClose={this.handleRequestClose}
        >
          {map(adminOrgs, (org) => (
            <MenuItem
              to={() => changeOrg(org)}
              key={`org-${org.slug}`}
              text={`Manage ${org.name}`}
              selected={currentOrg === org.name}
            />
          ))}
          <MenuItem
            to={() => dispatch({ type: "USER_EDIT" })}
            text="Edit Profile"
            key="profile"
          />
          <MenuItem
            to={() => dispatch({ type: "USER_PASSWORD" })}
            text="Edit Password"
            key="password"
          />
          <MenuItem
            to={() => dispatch({ type: "MANAGE_MFA" })}
            text="Two-factor Authentication"
            key="mfa"
          />
          <MenuItem
            to={() => dispatch(logout())}
            text="Log out"
            styles={{ borderBottom: "none", paddingBottom: "4px" }}
            key="logout"
          />
        </Popover>
      </MenuContainer>
    )
  }
}

const LogoHomeLink = ({ size }: { size: number | string }) => (
  <Link to={{ type: "HOME" }} style={{ height: size, marginRight: "5px" }}>
    <RippleLogo src={logo} width={size} />
  </Link>
)

type Props = {
  loggedIn: boolean
  user: User
  currentOrg?: string
  dispatch: Dispatch<any>
}

const Header = ({ loggedIn, user, currentOrg, dispatch }: Props) => {
  return (
    <AppBar>
      <LogoHomeLink size={headerImageSize} />
      <OrgAdminNav />
      {loggedIn && (
        <RightMenu
          user={user}
          marginTop="12px"
          dispatch={dispatch}
          currentOrg={currentOrg}
        />
      )}
    </AppBar>
  )
}

const mapStateToProps = (state) => {
  const me = state.me || {}
  const loggedIn = me.data && me.data.token
  const user = loggedIn && me.data ? me.data.user : null
  const currentOrg = get(state.org, "name")
  return {
    loggedIn,
    user,
    currentOrg
  }
}

const mapDispatchToProps = (dispatch) => ({ dispatch })

export default connect(mapStateToProps, mapDispatchToProps)(Header)
