import React from "react"
import styled from "@emotion/styled"
import { connect } from "react-redux"
import { getLatestMessage } from "redux-flash"
import FlashMessage from "../components/FlashMessage"

const testing = process.env.RAILS_ENV === "test"

const Container = styled.div({
  height: "100%"
})

const messageHeight = 70

const FlashContainer = styled.div<{ messageCount: number }>(
  ({ messageCount }) => ({
    backgroundColor: "#333333",
    height: messageCount * messageHeight,
    transition: "height 1000ms ease",
    // Make sure flash message doesn't display over the top of elements in testing mode
    position: testing ? "static" : "absolute",
    top: 0
  })
)

const FlashContents = styled.div({
  height: "100%"
})

type Props = {
  children: unknown
  flash?: {
    message: string
    isError: boolean
  }
}

const WithFlash = ({ children, flash }: Props) => {
  const messageCount = flash ? 1 : 0

  return (
    <Container>
      <FlashContainer messageCount={messageCount}>
        {flash && <FlashMessage height={messageHeight} flash={flash} />}
      </FlashContainer>
      <FlashContents>{children}</FlashContents>
    </Container>
  )
}

function mapStateToProps(state) {
  return {
    flash: getLatestMessage(state)
  }
}

export default connect(mapStateToProps)(WithFlash)
