import React from "react"
import styled from "@emotion/styled"
import colours from "../themes/colours"
import { gutter } from "./Layout"

const Container = styled.div({
  backgroundColor: colours.whiteSilverBorder,
  borderWidth: 1,
  borderStyle: "solid",
  borderColor: colours.boldLineOnSilver,
  padding: `calc(${gutter} * 2) ${gutter}`,
  borderRadius: 3,
  marginTop: gutter
})

const P = styled.p({
  color: "#444444",
  marginBottom: 0
})

type Props = {
  clientName?: string
  message?: string
}

const AssessmentResultsHidden = ({ clientName, message }: Props) => {
  const parsedMessage = message.replace(
    "{clientName}",
    clientName || "the client"
  )

  return (
    <Container>
      <P>{parsedMessage}</P>
    </Container>
  )
}

export default AssessmentResultsHidden
