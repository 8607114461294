import React from "react"
import styled from "@emotion/styled"
import FontAwesome from "react-fontawesome"
import { CircularProgress } from "@material-ui/core"
import RemoveOrgUserLink from "../org_admin/RemoveUserLink"
import BubbleDialogContainer from "../BubbleDialogContainer"
import EmailLink from "../EmailLink"
import PhoneLink from "../PhoneLink"
import OrgRoleButtons from "../OrgRoleButtons"
import StatsGroup from "../StatsGroup"
import colours from "../../themes/colours"
import type { OrgUser } from "../../state_types"
import { StatefulFunctionLink } from "../Link"
import EditTagsBubbleDialog from "../EditTagsBubbleDialog"
import EditUserDialog from "../org_admin/EditUserDialog"
import {
  resendOrgUserInvite as resendAction,
  updateUserTags as updateTagsAction,
  updateUser as updateUserAction
} from "../../api_actions"
import { UserRow, SelectionContainer, StyledCheckbox, Cell } from "./common"

type Props = {
  orgUser: OrgUser
  resendOrgUserInvite: typeof resendAction
  updateUserTags: typeof updateTagsAction
  updateUser: typeof updateUserAction
}

const Anchor = styled.a({
  cursor: "pointer",
  color: colours.darkGrey,
  fontSize: "1.4em",
  margin: "0 10px",
  ":hover": {
    color: colours.blue
  }
})

const defineTags = (event, user, dialogCallbacks, updateUserTags) => {
  if (event) {
    const target: HTMLElement = event.currentTarget
    const updateTags = (email, tags) => {
      updateUserTags(email, tags)
      dialogCallbacks.toggle(user.name, target, "")
    }

    dialogCallbacks.toggle(
      user.name,
      target,
      <EditTagsBubbleDialog user={user} updateUserTags={updateTags} />
    )
  }
}

const editUser = (event, user, dialogCallbacks, updateUser) => {
  if (event) {
    const target: HTMLElement = event.currentTarget
    const updateUserDetails = (values) => {
      updateUser(user, values)
      dialogCallbacks.toggle(user.name, target, "")
    }

    dialogCallbacks.toggle(
      user.name,
      target,
      <EditUserDialog user={user} updateUser={updateUserDetails} />
    )
  }
}

const User = styled(Cell)({
  flexDirection: "column",
  gap: "10px"
})

const UserInfo = styled(Cell)({
  display: "flex",
  justifyContent: "space-between",
  flexWrap: "nowrap",
  columnGap: "10px"
})

const PendingDetails = styled(Cell)({
  color: colours.deemphasisedText
})

const OrgRoles = styled(Cell)({
  paddingTop: "5px"
})

const UserDetails = styled(Cell)({
  display: "flex",
  flexWrap: "wrap",
  columnGap: "30px",
  rowGap: "10px"
})

const UserActions = styled(Cell)({
  justifyContent: "flex-end",
  flex: "0 1 auto",
  textAlign: "right"
})

const UserCell = styled.div({
  flex: "0 1 auto"
})

const Name = styled(UserCell)({
  fontWeight: "bold"
})

const Email = styled(UserCell)()

const Phone = styled(UserCell)()

const UserStats = styled(UserCell)()

const Groups = styled(UserCell)()

const Tags = styled(UserCell)({
  cursor: "pointer",
  textDecoration: "underline",
  fontSize: "0.7em",
  fontStyle: "italic",
  color: colours.grey,
  whiteSpace: "normal"
})

const UserTableRow = ({
  orgUser,
  resendOrgUserInvite,
  updateUserTags,
  updateUser
}: Props) => {
  const { user, stats, orgRoles } = orgUser
  const isDeleting = user.syncing === "sending"

  return (
    <BubbleDialogContainer
      outsideClickCloses
      maxWidth={300}
      forceRight
      className="tr"
    >
      {(dialogCallbacks) => (
        <UserRow data-user-id={orgUser.user.id}>
          <SelectionContainer>
            <StyledCheckbox color="primary" />
          </SelectionContainer>
          <User>
            <UserInfo>
              <UserDetails>
                <Name>{user.name}</Name>
                <Email>
                  <EmailLink value={user.email} color="black" />
                </Email>
                {user.phone && (
                  <Phone>
                    <PhoneLink value={user.phone} color="black" />
                  </Phone>
                )}
                <UserStats>
                  <StatsGroup stats={stats} singleLine />
                </UserStats>
                {user.groups && user.groups.length > 0 && (
                  <Groups>
                    <span>Groups: </span>
                    {user.groups.join(", ")}
                  </Groups>
                )}
              </UserDetails>
              <UserActions>
                <Cell>
                  <Tags
                    className="user-tags"
                    onClick={(event) => {
                      defineTags(event, user, dialogCallbacks, updateUserTags)
                    }}
                  >
                    {user.tags && user.tags.length > 0 ? (
                      <span>{user.tags.join(", ")}</span>
                    ) : (
                      <span>-&nbsp;none&nbsp;-</span>
                    )}
                  </Tags>
                </Cell>
                <Cell>
                  <Anchor href="#" onClick={null}>
                    <FontAwesome
                      name="pencil-square-o"
                      className="test-edit-button"
                      onClick={(event) => {
                        editUser(event, user, dialogCallbacks, updateUser)
                      }}
                    />
                  </Anchor>
                  {isDeleting ? (
                    <CircularProgress
                      size={18}
                      color="primary"
                      thickness={2}
                      style={{ paddingTop: "5px" }}
                    />
                  ) : (
                    <RemoveOrgUserLink email={user.email} />
                  )}
                </Cell>
              </UserActions>
            </UserInfo>
            {user.pending && (
              <PendingDetails>
                pending -{" "}
                <StatefulFunctionLink
                  onClick={() => resendOrgUserInvite(user.email)}
                  afterClicked="sent"
                >
                  re-send invite
                </StatefulFunctionLink>
              </PendingDetails>
            )}
            <OrgRoles>
              <OrgRoleButtons
                orgRoles={orgRoles}
                email={user.email}
                isDisabled={isDeleting}
              />
            </OrgRoles>
          </User>
        </UserRow>
      )}
    </BubbleDialogContainer>
  )
}

export default React.memo(UserTableRow)
