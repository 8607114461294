import styled from "@emotion/styled"
import { HeadingTwo, HeadingThree } from "./TextStyles"
import colours from "../themes/colours"

export const PrimaryActions = styled.div({
  display: "flex",
  justifyContent: "center",
  marginBottom: "1em",
  gap: "1em",
  width: "min(500px, 100%)",
  flexWrap: "wrap",
  "@media(max-width: 500px)": {
    flexDirection: "column",
    " > *": {
      flex: "1 0"
    }
  }
})

export const AlternativeActions = styled.div({
  marginBottom: "1em",
  display: "flex",
  justifyContent: "center",
  " a": {
    color: colours.deemphasisedText
  }
})

export const Header = styled(HeadingTwo)({
  marginBottom: "1em",
  color: "black"
})

export const Inputs = styled.div({
  width: "min(250px, 100%)",
  maxWidth: "100%",
  display: "flex",
  flexDirection: "column",
  marginBottom: "1em"
})

export const AuthForm = styled.form({
  display: "flex",
  flexShrink: 0,
  flexGrow: 1,
  flexDirection: "column",
  justifyContent: "center",
  width: "min(500px, 100%)",
  alignItems: "center"
})

export const SubHeader = styled(HeadingThree)({
  marginRight: "auto",
  marginLeft: "auto",
  fontSize: "20px",
  marginTop: 0,
  marginBottom: "1em",
  width: "min(500px, 100%)"
})

export const Instructions = styled.div({
  margin: "0 0 1em",
  width: "min(500px, 100%)",
  maxWidth: "100%",
  lineHeight: 1.5,
  " p:first-of-type": {
    marginTop: 0
  },
  " p:last-of-type": {
    marginBottom: "0"
  }
})
