import React from "react"
import styled from "@emotion/styled"
import isNil from "lodash.isnil"
import FontAwesome from "react-fontawesome"
import IconButton from "../components/IconButton"
import ArrowButton from "../components/ArrowButton"
import AssessmentHeader from "../components/AssessmentHeader"
import Comment from "../components/Comment"
import { HeadingOne } from "../components/TextStyles"
import { gutter } from "../components/Layout"
import AssessmentCard from "../components/AssessmentCard"
import Actions from "../components/PageBottomActions"
import type { Question, QuestionInput } from "../contexts/assessment/types"
import ColouredBackground from "../components/ColouredBackground"
import colours from "../themes/colours"

const Background = styled.div({
  flex: 1,
  display: "flex",
  flexDirection: "row"
})

const ScoringWidgetContainer = styled.div({
  flex: 1,
  display: "flex",
  minHeight: 250,
  flexDirection: "row",
  justifyContent: "center"
})

const QuestionDetails = styled.div({
  marginTop: "1.5em"
})

const QuestionTitle = styled(HeadingOne)({
  marginBottom: "0.3em"
})

const QuestionHeader = styled(AssessmentHeader)({
  justifyContent: "flex-start",
  marginBottom: gutter,
  flexGrow: 0,
  flexShrink: 0
})

const QuestionBody = styled.div({
  fontWeight: 600,
  margin: 0,
  lineHeight: "140%"
})

const Instructions = styled.div({
  fontWeight: 300,
  margin: 0,
  lineHeight: "140%"
})

const defaultComment = ""

type Props = {
  question: Question
  current: boolean
  input?: QuestionInput
  previousInput?: QuestionInput
  previousPage: () => unknown
  nextPage: () => unknown
  attributeTotal: number
  attributeIndex: number
  updateCommentOnly: (value: string) => unknown
}

class CommentOnlyPage extends React.Component<Props, { showComment: boolean }> {
  static defaultProps = {
    previousInput: null
  }

  state = { showComment: false }

  onUpdate = () => {
    this.setState((state) => ({ showComment: !state.showComment }))
  }

  render() {
    const { question, current } = this.props
    const input = this.props.input
    const comment = input?.comment ? input?.comment : defaultComment
    const showSkip = isNil(input?.comment)

    return (
      <ColouredBackground colour={colours.blue}>
        <Background>
          <AssessmentCard
            id={current ? "current-question" : null}
            maxHeight={800}
          >
            <QuestionHeader>
              <QuestionDetails>
                <QuestionTitle>{question.title}</QuestionTitle>
                <QuestionBody>{question.question}</QuestionBody>
                <Instructions>{question.instructions}</Instructions>
              </QuestionDetails>
            </QuestionHeader>
            <ScoringWidgetContainer />
            <Comment
              comment={comment}
              onChange={this.props.updateCommentOnly}
              showComment={this.state.showComment}
              onUpdate={this.onUpdate}
            />
            <Actions>
              <ArrowButton
                onClick={this.props.previousPage}
                direction="left"
                text="Prev"
              />
              <IconButton
                onClick={this.onUpdate}
                style={{
                  fontSize: "2.3em",
                  padding: 0,
                  height: "2.3em",
                  width: "3em"
                }}
              >
                {comment ? (
                  <FontAwesome name="commenting" />
                ) : (
                  <FontAwesome name="comment-o" />
                )}
              </IconButton>
              <ArrowButton
                onClick={this.props.nextPage}
                direction="right"
                text={showSkip ? "Skip" : "Next"}
                secondary={showSkip}
              />
            </Actions>
          </AssessmentCard>
        </Background>
      </ColouredBackground>
    )
  }
}

export default CommentOnlyPage
