import React from "react"
import styled from "@emotion/styled"
import Vec2 from "vec2"
import type { Vector2 } from "./types"

const Arc = styled.path({
  fill: "none",
  strokeLinecap: "round"
})

const polarToCartesian = (offset, radius, angleInDegrees) => {
  const angleInRadians = ((angleInDegrees - 90) * Math.PI) / 180.0

  const circlePos = Vec2(
    radius * Math.cos(angleInRadians),
    radius * Math.sin(angleInRadians)
  )

  return circlePos.add(offset, true)
}

const describeTopArc = (centre, radius, halfAngle) => {
  const startAngle = -halfAngle
  const endAngle = halfAngle
  const start = polarToCartesian(centre, radius, endAngle)
  const end = polarToCartesian(centre, radius, startAngle)
  const largeArcFlag = endAngle - startAngle <= 180 ? "0" : "1"

  const d = [
    "M",
    start.x,
    start.y,
    "A",
    radius,
    radius,
    0,
    largeArcFlag,
    0,
    end.x,
    end.y
  ].join(" ")
  return d
}

const describeBottomArc = (centre, radius, halfAngle) => {
  const startAngle = 180 - halfAngle
  const endAngle = 180 + halfAngle
  const start = polarToCartesian(centre, radius, endAngle)
  const end = polarToCartesian(centre, radius, startAngle)
  const largeArcFlag = endAngle - startAngle <= 180 ? "0" : "1"

  const d = [
    "M",
    start.x,
    start.y,
    "A",
    radius,
    radius,
    0,
    largeArcFlag,
    0,
    end.x,
    end.y
  ].join(" ")
  return d
}

const describeArc = (atTop, centre, radius, halfAngle) => {
  return atTop
    ? describeTopArc(centre, radius, halfAngle)
    : describeBottomArc(centre, radius, halfAngle)
}

type Props = {
  centre: Vector2
  radius: number
  arcAngleDegrees: number
  startColour: string
  endColour: string
  thickness?: number
  atTop: boolean
}

const GradientArc = ({
  centre,
  radius,
  arcAngleDegrees,
  startColour,
  endColour,
  thickness,
  atTop
}: Props) => {
  const halfAngle = arcAngleDegrees / 2
  const timeStamp = Date.now()
  const gradientId = `arc-gradient-${timeStamp}`
  const shadowId = `arc-shadow-${timeStamp}`

  return (
    <>
      <defs key="gradient-def">
        <linearGradient id={gradientId}>
          <stop stopColor={startColour} offset="0" />
          <stop stopColor={endColour} offset="1" />
        </linearGradient>
        <filter id={shadowId} x="-30%" y="-30%" width="200%" height="200%">
          <feDropShadow dx="2" dy="4" stdDeviation="4" floodOpacity="0.5" />
        </filter>
      </defs>
      <Arc
        key="arc"
        stroke={`url(#${gradientId}`}
        filter={`url(#${shadowId}`}
        strokeWidth={thickness}
        d={describeArc(atTop, centre, radius, halfAngle)}
      />
    </>
  )
}

GradientArc.defaultProps = {
  thickness: 20
}

export default GradientArc
