import React from "react"
import styled from "@emotion/styled"
import moment from "moment"

type Props = {
  date: string
}

const DateContainer = styled.div({})

const DiscussionCommentDate = ({ date }: Props) => {
  const momDate = moment(date)
  const lastWeek = moment().subtract(1, "week").startOf("day")
  const formattedDate = momDate.isBefore(lastWeek)
    ? momDate.format("D MMM YYYY")
    : momDate.fromNow()

  return <DateContainer>{formattedDate}</DateContainer>
}

export default DiscussionCommentDate
