import type { State, RippleFormWizard } from "../../state_types"

export default class StateDecorator {
  form: RippleFormWizard

  constructor(state: State) {
    this.form = state.form
  }

  selectedOrganisationForRipple(): string | null | undefined {
    return (
      this.form &&
      this.form.ripple &&
      this.form.ripple.values &&
      this.form.ripple.values.organisation
    )
  }
}
