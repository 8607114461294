import map from "lodash.map"
import mean from "lodash.mean"
import find from "lodash.find"
import pickBy from "lodash.pickby"
import values from "lodash.values"
import includes from "lodash.includes"
import compact from "lodash.compact"
import isNil from "lodash.isnil"
import type { AssessmentAttributesInput, Attribute } from "./types"

const defaultOutOf = 100

function convertToPercent(value: number, outOf: number) {
  return value * (100 / outOf)
}

export default class InputModel {
  data: AssessmentAttributesInput

  questions: Array<Attribute>

  constructor(
    inputData: AssessmentAttributesInput,
    questions: Array<Attribute>
  ) {
    const questionIds = map(questions, "id")
    this.data = pickBy(inputData, (value, questionId) =>
      includes(questionIds, questionId)
    )
    this.questions = questions
  }

  averageRating(): number | null {
    const ratings = this.percentRatings()
    if (ratings.length === 0) return null
    return mean(ratings)
  }

  averageIntegerRating(): number | null {
    const rating = this.averageRating()
    if (rating == null) return null
    return Math.round(rating)
  }

  ratedQuestionCount() {
    return values(this.data).length
  }

  questionCount() {
    return this.questions.length
  }

  percentRatings() {
    return compact(
      map(this.data, (attributeInput, questionID) => {
        if (isNil(attributeInput.rating)) return null
        const outOf = this.outOf(questionID)
        return convertToPercent(attributeInput.rating, outOf)
      })
    )
  }

  outOf(questionID: string) {
    const question = find(this.questions, { id: questionID })
    return question && question.outOf ? question.outOf : defaultOutOf
  }
}
