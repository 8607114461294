import React from "react"
import isEmpty from "lodash.isempty"
import styled from "@emotion/styled"
import ReportFilters from "./ConnectedReportFilters"
import ReportLayout from "./ReportLayout"
import type { ReportSummary as ReportSummaryType } from "../../state_types"
import RiskReportSummaryCard from "./RiskReportSummaryCard"
import TopReportSummaryCard from "./TopReportSummaryCard"
import HeatmapReportSummaryCard from "./HeatmapReportSummaryCard"
import CompletionRatesSummaryCard from "./CompletionRatesSummaryCard"
import colours from "../../themes/colours"

const NoData = styled.div({
  color: colours.grey,
  textAlign: "center",
  marginTop: 30
})

const CardGrid = styled.div({
  display: "flex",
  flexWrap: "wrap",
  justifyContent: "left",
  gap: "20px",
  "@media (max-width: 646px)": {
    justifyContent: "center"
  }
})

type ReportIndexProps = {
  reports: Array<ReportSummaryType>
  goToReport: (reportId: string) => any
}

const reportTypes = {
  risk: RiskReportSummaryCard,
  top: TopReportSummaryCard,
  heatmap: HeatmapReportSummaryCard,
  completion_rates: CompletionRatesSummaryCard
}

const ReportIndex = ({ reports, goToReport }: ReportIndexProps) => {
  return (
    <ReportLayout header={<ReportFilters />}>
      <CardGrid>
        {reports.map((report: ReportSummaryType) =>
          React.createElement(reportTypes[report.reportType], {
            key: report.reportId,
            report,
            width: 275,
            height: 223,
            onClick: () => goToReport(report.reportId)
          })
        )}
        {isEmpty(reports) && (
          <NoData>There are no results for the current set of filters.</NoData>
        )}
      </CardGrid>
    </ReportLayout>
  )
}

export default ReportIndex
