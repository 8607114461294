import React from "react"
import styled from "@emotion/styled"
import { gutter } from "./Layout"
import colours from "../themes/colours"

export type PanelProps = {
  centered?: boolean
  topPadding: boolean
}

const Panel = styled.div<PanelProps>((props) => ({
  padding: gutter,
  paddingTop: props.topPadding ? gutter : 0,
  background: colours.white,
  display: "flex",
  width: "100%",
  justifyContent: props.centered ? "center" : "space-between"
}))

Panel.defaultProps = {
  centered: false,
  topPadding: true
}

const ButtonContainer = styled.ul<{ width }>((props) => ({
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-evenly",
  margin: "unset",
  width: props.width,
  padding: "unset",
  listStyle: "none",
  "> li": {
    display: "block"
  }
}))

type ButtonGroupProps = {
  buttons: Array<Node | React.ReactElement>
  group: string
  width?: string
}

export const ButtonGroup = ({ buttons, group, width }: ButtonGroupProps) => {
  const widthWithDefault = width || "50%"
  const items = buttons.map((b, i) => (
    // eslint-disable-next-line react/no-array-index-key
    <li key={`buttonGroup-${group}-${i}`}>{b}</li>
  ))
  return <ButtonContainer width={widthWithDefault}>{items}</ButtonContainer>
}

export const PanelAction = styled.span({
  fontSize: "1.125em",
  fontWeight: 700
})

export const PanelExplanation = styled.span({
  fontSize: "0.875em",
  fontWeight: 300,
  color: colours.grey,
  marginLeft: "25px"
})

export const LeftSide = styled.div()
export const RightSide = styled.div()

export default Panel
