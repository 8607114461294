import React from "react"
import styled from "@emotion/styled"
import map from "lodash.map"
import find from "lodash.find"
import type { ReactNode } from "react"
import { gutter } from "./Layout"
import ToggleButtonGroup from "./ToggleButtonGroup"

type Column = {
  name: string
  render: () => ReactNode
}

type TwoColumnTabbingProps = {
  columns: Array<Column>
}

type State = {
  selectedPane: string
}

const Container = styled.div({
  display: "flex",
  flex: 1,
  flexDirection: "column"
})

const Content = styled.div({
  flex: 1,
  overflowY: "scroll",
  overflowX: "hidden"
})

const TabSection = styled.div({
  justifyContent: "center",
  boxShadow: "0 0.5px 0 0 #C8C8C8",
  padding: gutter,
  display: "flex",
  backgroundColor: "white"
})

const TabWrapper = styled.div({
  display: "flex",
  justifyContent: "center",
  alignItems: "center"
})

class TwoColumnTabbing extends React.Component<TwoColumnTabbingProps, State> {
  constructor(props) {
    super(props)
    this.state = { selectedPane: props.columns[0].name }
  }

  changePane = (selectedPane: string) => {
    this.setState({ selectedPane })
  }

  columnNames() {
    return map(this.props.columns, "name")
  }

  renderColumn(columnName) {
    const column = find(this.props.columns, { name: columnName })
    return column.render()
  }

  render() {
    const { selectedPane } = this.state

    return (
      <Container>
        <TabSection>
          <TabWrapper>
            <ToggleButtonGroup
              buttonNames={this.columnNames()}
              value={selectedPane}
              onChange={this.changePane}
              height="24px"
              fontSize="12px"
            />
          </TabWrapper>
        </TabSection>
        <Content>{this.renderColumn(selectedPane)}</Content>
      </Container>
    )
  }
}

export default TwoColumnTabbing
